import React, { useEffect, useState, useContext, useCallback, useRef, useMemo } from 'react';
import Modal from '@common/modal/Modal';
import { createColumnHelper } from '@tanstack/react-table';
import DataTable from '@common/table/DataTable';
import { CaseStudyViewContext, FlowState } from '@pages/case_study/CaseStudyView';
import SubsystemCaseStudyProductEditModal from '../subsystem_case_study_product/SubsystemCaseStudyProductEditModal';
import SubsystemInputProductEditModal from '../subsystem_input_product/SubsystemInputProductEditModal';
import SubsystemElementaryFlowEditModal from '../subsystem_input_elementary_flow/SubsystemElementaryFlowEditModal';
import styled from 'styled-components';
import SubsystemInputProductSearchModal from '../product_search/input_Intermediate_flow/SubsystemInputProductSearchModal';
import SubsystemCaseStudyProductCreateModal from '../subsystem_case_study_product/SubsystemCaseStudyProductCreateModal';
import SubsystemInputElementaryFlowSearchModal from '../elementary_flow_search/SubsystemInputElementaryFlowSearchModal';
import SubsystemOutputElementaryFlowSearchModal from '../elementary_flow_search/SubsystemOutputElementaryFlowSearchModal';
import UpstreamSubsystemModal from './UpstreamSubsystemModal';
import DownstreamSubsystemModal from './DownstreamSubsystemModal';

import AddButton from '@common/button/AddButton';
import iconArrowTop from '@images/product_system/icon_arrow_top_right.svg'
import iconRemainder from '@images/table/icon_remainder.svg'
import iconArrowBack from '@images/product_system/icon_arrow_back.svg'
import UpperOutputUpstreamSubsystem from './UpperOutputUpstreamSubsystem';
import ReleaseButton from '@common/button/ReleaseBtton';
import { useTranslation } from 'react-i18next';

interface SubsystemListProps {
  currentNodeData: any;
  isOpen: boolean;
  onClose: () => void;
}

/**
 * 066 サブシステム一覧
 * @returns 
 */
const SubsystemList: React.FC<SubsystemListProps> = ({ currentNodeData, isOpen, onClose }) => {
  const {t} = useTranslation();
  const { setNodes, nodes, edges, setEdges, setFlowState, flowState } = useContext(CaseStudyViewContext);

  const [inputIntermediateFlowTableData, setInputIntermediateFlowTableData] = useState<any | null>(null);
  const [inputElementaryFlowTableData, setInputElementaryFlowTableData] = useState<any | null>(null);
  const [outputIntermediateFlowTableData, setOutputIntermediateFlowTableData] = useState<any | null>(null);
  const [outputElementaryFlowTableData, setOutputElementaryFlowTableData] = useState<any | null>(null);

  // 入力中間フローコンテキストメニュー
  const [inputIntermediateContextMenuVisible, setInputIntermediateContextMenuVisible] = useState(false);
  const toggleInputIntermediateContextMenu = () => setInputIntermediateContextMenuVisible(prev => !prev);
  const inputIntermediateContextMenuRef = useRef<HTMLDivElement | null>(null);
 // 入力基本フローコンテキストメニュー
  const [inputElementaryContextMenuVisible, setInputElementaryContextMenuVisible] = useState(false);
  const toggleInputElementaryContextMenu = () => setInputElementaryContextMenuVisible(prev => !prev);
  const inputElementaryContextMenuRef = useRef<HTMLDivElement | null>(null);
 // 出力基本フローコンテキストメニュー
  const [outputElementaryContextMenuVisible, setOutputElementaryContextMenuVisible] = useState(false);
  const toggleOutputElementaryContextMenu = () => setOutputElementaryContextMenuVisible(prev => !prev);
  const outputElementaryContextMenuRef = useRef<HTMLDivElement | null>(null);
 // 出力中間フローコンテキストメニュー
  const [outputContextMenuVisible, setOutputContextMenuVisible] = useState(false);
  const toggleOutputContextMenu = () => setOutputContextMenuVisible(prev => !prev);
  const outputContextMenuRef = useRef<HTMLDivElement | null>(null);

  // コンテキストメニューからのモーダル展開
  const [inputProductSearchModalVisible, setInputProductSearchModalVisible] = useState(false);
  const [createInputModalVisible, setCreateInputModalVisible] = useState(false);
  const [createOutputModalVisible, setCreateOutputModalVisible] = useState(false);
  const [elementaryFlowSearchModalVisible, setElementaryFlowSearchModalVisible] = useState(false);
  const [outputElementaryFlowSearchModalVisible, setOutputElementaryFlowSearchModalVisible] = useState(false);

  // 上流・下流接続モーダルの開閉ステート
  const [upstreamSubsystemModalVisible, setUpstreamSubsystemModalVisible] = useState(false);
  const [downstreamSubsystemModalVisible, setDownstreamSubsystemModalVisible] = useState(false);

  // 上流接続のモーダル表示
  const openUpstreamSubsystemModal = () => {
    setUpstreamSubsystemModalVisible(true);
    setInputIntermediateContextMenuVisible(false);
  };
  // 下流接続のモーダル表示
  const openDownstreamSubsystemModal = () => {
    setDownstreamSubsystemModalVisible(true);
    setOutputContextMenuVisible(false);
  };

  // サブシステムのフローの編集
  const handleEditSave = useCallback((updatedIO: any) => {
    const updatedNodes = flowState.nodes.map(node => {
      if (node.data.id === String(currentNodeData.id)) {
        const updatedSubsystemIos = node.data.subsystem_ios.map((io: any) =>
          io.id === updatedIO.id ? updatedIO : io
        );
        return {
          ...node,
          data: {
            ...node.data,
            subsystem_ios: updatedSubsystemIos
          }
        }
      }
      return node;
    });
    setFlowState(prev => ({
      ...prev,
      nodes: updatedNodes,
    }));
  }, [flowState.nodes, currentNodeData.id, setFlowState]);

  // サブシステムのフローの削除
  const handleDeleteClick = async (io: any) => {
    const io_process_io_no = io.row.original.process_io_no; 
    const io_direction = io.row.original.direction;

    setFlowState(prev => {
      const updatedNodes = prev.nodes.map(node => {
        if (String(node.data.id) === String(currentNodeData.id)) {
          const updatedSubsystemIos = node.data.subsystem_ios.filter(
            (item: any) => item.process_io_no !== io_process_io_no
          );
          return {
            ...node,
            data: {
              ...node.data,
              subsystem_ios: updatedSubsystemIos,
            },
          };
        }
        return node;
      });
  
      // エッジが結ばれていたら削除
      const updatedEdges = prev.edges.filter(edge => {
        if (io_direction === 'in') {
          return edge.targetHandle !== `input-${io_process_io_no}`;
        } else if (io_direction === 'out') {
          return edge.sourceHandle !== `output-${io_process_io_no}`;
        }
        return false;
      });

      console.log("updatedEdges", updatedEdges)
  
      // ここで setNodes と setEdges を呼び出す代わりに、直接 prev から更新する
      setNodes(updatedNodes);
      setEdges(updatedEdges);
  
      return {
        ...prev,
        nodes: updatedNodes,
        edges: updatedEdges,
      };
    });
  };
  

  //上流プロセス
  const nodeBoxRef = useRef<HTMLDivElement | null>(null);


  // 入力中間フロー
  useEffect(() => {
    if (currentNodeData !== null) {
      const subsystemIos =
        currentNodeData.subsystem_ios.filter((io: any) =>
          io.direction === 'in' && io.type !== 'elementary_flow'
        );
      setInputIntermediateFlowTableData(subsystemIos)
    } else {
      console.log('data is Null');
    }
  }, [currentNodeData]);

  const FlowColumnHelper = createColumnHelper<any>()
  const inputIntermediateFlowColumns = useMemo(() => [
    FlowColumnHelper.accessor('upstream', {
      header: () => t('上流'),
      cell: info => {
        const io = info.row.original;
        return (
          <>
            {io.type === 'defined_product' &&
              <UpperOutputUpstreamSubsystem input={io} currentNodeData={currentNodeData} nodeBoxRef={nodeBoxRef} type={"list"}/>
              //  top: 46%;
              // left: 10%;
            }
            {io.type === 'case_study_product' &&
              <NodeBoxDlButton
                type='button'
              >
                <NodeBoxDirectionButtonIcon />
              </NodeBoxDlButton>
            }
          </>

          );
      },
    }),
    FlowColumnHelper.accessor('exchange.name', {
      header: () => t('製品名'),
      cell: info => info.renderValue(),
    }),
    FlowColumnHelper.accessor('amount', {
      header: () => t('流量'),
      cell: info => {
        const io = info.row.original;
        return (
          <div>
            <span>
              {io.amount + ' ' + io.unit.name}
            </span>
          </div>
        );
      },
    }),
    FlowColumnHelper.accessor('edit', {
      header: () => t('編集'),
      cell: info => {
        const io = info.row.original;
        return (
          <div>
            {io.type === 'case_study_product' &&
              <SubsystemCaseStudyProductEditModal
                subsystemName={currentNodeData.name}
                currentNodeData={io}
                direction={"in"}
                onSave={handleEditSave}
              />
            }
            {io.type === 'defined_product' &&
              <SubsystemInputProductEditModal
                subsystemName={currentNodeData.name}
                currentNodeData={io}
                onSave={handleEditSave}
              />
            }
          </div>
        );
      },
    }),
    FlowColumnHelper.accessor('delete', {
      header: () => t('削除'),
      cell: info => {
        return (
          <div>
            <ReleaseButton onClick={() => handleDeleteClick(info)} >×</ReleaseButton>
          </div>
        );
      },
    }),
  ], [flowState]);

  // 入力基本フロー
  useEffect(() => {
    if (currentNodeData !== null) {
      const subsystemIos =
        currentNodeData.subsystem_ios.filter((io: any) =>
          io.direction === 'in' && io.type === 'elementary_flow'
        );
      setInputElementaryFlowTableData(subsystemIos)
    } else {
      console.log('data is Null');
    }
  }, [currentNodeData]);

  const inputElementaryFlowColumns = [
    FlowColumnHelper.accessor('exchange.name', {
      header: () => t('資源'),
      cell: info => info.renderValue(),
    }),
    FlowColumnHelper.accessor('amount', {
      header: () => t('流量'),
      cell: info => {
        const io = info.row.original;
        return (
          <div>
            <span>
              {io.amount + ' ' + io.unit.name}
            </span>
          </div>
        );
      },
    }),
    FlowColumnHelper.accessor('edit', {
      header: () => t('編集'),
      cell: info => {
        const io = info.row.original;
        return (
          <div>
            {io.type === 'elementary_flow' &&
              <SubsystemElementaryFlowEditModal
                currentNodeData={io}
                direction={"in"}
                onSave={handleEditSave}
              />
            }
          </div>
        );
      },
    }),
    FlowColumnHelper.accessor('delete', {
      header: () => t('削除'),
      cell: info => {
        return (
          <div>
            <ReleaseButton onClick={() => handleDeleteClick(info)} >×</ReleaseButton>
          </div>
        );
      },
    }),
  ]

  // 出力中間フロー
  useEffect(() => {
    if (currentNodeData !== null) {
      const subsystemIos =
        currentNodeData.subsystem_ios.filter((io: any) =>
          io.direction === 'out' && io.type !== 'elementary_flow'
        );
      setOutputIntermediateFlowTableData(subsystemIos)
    } else {
      console.log('data is Null');
    }
  }, [currentNodeData]);

  const outputIntermediateFlowColumns = [
    FlowColumnHelper.accessor('exchange.name', {
      header: () => t('製品名'),
      cell: info => info.renderValue(),
    }),
    FlowColumnHelper.accessor('amount', {
      header: () => t('流量'),
      cell: info => {
        const io = info.row.original;
        return (
          <div>
            <span>
              {io.amount + ' ' + io.unit.name}
            </span>
          </div>
        );
      },
    }),
    FlowColumnHelper.accessor('edit', {
      header: () => t('編集'),
      cell: info => {
        const io = info.row.original;
        return (
          <div>
            {info.row.original.type === 'case_study_product' &&
              <SubsystemCaseStudyProductEditModal
                subsystemName={currentNodeData.name}
                currentNodeData={io}
                direction={"out"}
                onSave={handleEditSave}
              />
            }
            {info.row.original.type === 'defined_product' &&
              <SubsystemInputProductEditModal
                subsystemName={currentNodeData.name}
                currentNodeData={io}
                onSave={handleEditSave}
              />
            }
          </div>
        );
      },
    }),
    FlowColumnHelper.accessor('delete', {
      header: () => t('削除'),
      cell: info => {
        return (
          <div>
            <ReleaseButton onClick={() => handleDeleteClick(info)} >×</ReleaseButton>
          </div>
        );
      },
    }),
  ]

  // 出力基本フロー
  useEffect(() => {
    if (currentNodeData !== null) {
      const subsystemIos =
        currentNodeData.subsystem_ios.filter((io: any) =>
          io.direction === 'out' && io.type === 'elementary_flow'
        );
      setOutputElementaryFlowTableData(subsystemIos)
    } else {
      console.log('data is Null');
    }
  }, [currentNodeData]);

  const outputElementaryFlowColumns = [
    FlowColumnHelper.accessor('exchange.name', {
      header: () => t('排出物'),
      cell: info => info.renderValue(),
    }),
    FlowColumnHelper.accessor('amount', {
      header: () => t('流量'),
      cell: info => {
        const io = info.row.original;
        return (
          <div>
            <span>
              {io.amount + ' ' + io.unit.name}
            </span>
          </div>
        );
      },
    }),
    FlowColumnHelper.accessor('edit', {
      header: () => t('編集'),
      cell: info => {
        const io = info.row.original;
        return (
          <div>
            {info.row.original.type === 'elementary_flow' &&
              <SubsystemElementaryFlowEditModal
                currentNodeData={io}
                direction={"out"}
                onSave={handleEditSave}
              />
            }
          </div>
        );
      },
    }),
    FlowColumnHelper.accessor('delete', {
      header: () => t('削除'),
      cell: info => {
        return (
          <div>
            <ReleaseButton onClick={() => handleDeleteClick(info)} >×</ReleaseButton>
          </div>
        );
      },
    }),
  ]

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <Inner>

        <MainTitle>{currentNodeData.name}{t('の入出力一覧')}</MainTitle>
        <Title>{t('入力中間フロー')}</Title>
        <section  ref={nodeBoxRef}>
          <DataTable count={0} columns={inputIntermediateFlowColumns} data={inputIntermediateFlowTableData} paging_flag={false} />
        </section>
        <AddButtonContainer>
          <AddButton onClick={toggleInputIntermediateContextMenu} text={t('追加する')}></AddButton>
          {inputIntermediateContextMenuVisible && (
            <InputIntermediateFlowContextMenu ref={inputIntermediateContextMenuRef}>
              <ContextMenuItem onClick={() => { setInputProductSearchModalVisible(true); setInputIntermediateContextMenuVisible(false); }}>
              {t('製品（DB登録済み）を入力として追加')}
              </ContextMenuItem>
              <ContextMenuItem onClick={() => { setCreateInputModalVisible(true); setInputIntermediateContextMenuVisible(false); }}>
              {t('製品（DB未登録）を入力として追加')}
              </ContextMenuItem>
              <ContextMenuItem onClick={openUpstreamSubsystemModal}>{t('上流のサブシステムと接続して追加')}</ContextMenuItem>
            </InputIntermediateFlowContextMenu>
          )}
        </AddButtonContainer>

        <Title>{t('入力基本フロー')}</Title>
        <DataTable count={0} columns={inputElementaryFlowColumns} data={inputElementaryFlowTableData} paging_flag={false} />
        <AddButtonContainer>
          <AddButton onClick={toggleInputElementaryContextMenu} text={t('追加する')}></AddButton>
          {inputElementaryContextMenuVisible && (
            <InputElementaryFlowContextMenu ref={inputElementaryContextMenuRef}>
              <ContextMenuItem onClick={() => { setElementaryFlowSearchModalVisible(true); setInputElementaryContextMenuVisible(false); }}>
              {t('入力基本フロー（天然資源）を追加')}
              </ContextMenuItem>
            </InputElementaryFlowContextMenu>
          )}
        </AddButtonContainer>

        <Title>{t('出力中間フロー')}</Title>
        <DataTable count={0} columns={outputIntermediateFlowColumns} data={outputIntermediateFlowTableData} paging_flag={false} />
        <AddButtonContainer>
          <AddButton onClick={toggleOutputContextMenu} text={t('追加する')}></AddButton>
          {outputContextMenuVisible && (
            <OutputIntermediateFlowContextMenu ref={outputContextMenuRef}>
              <ContextMenuItem onClick={() => { setCreateOutputModalVisible(true); setOutputContextMenuVisible(false); }}>
              {t('製品（DB未登録）を出力として追加')}
              </ContextMenuItem>
              <ContextMenuItem onClick={openDownstreamSubsystemModal}>{t('下流のサブシステムと接続して追加')}</ContextMenuItem>
            </OutputIntermediateFlowContextMenu>
          )}
        </AddButtonContainer>

        <Title>{t('出力基本フロー')}</Title>
        <DataTable count={0} columns={outputElementaryFlowColumns} data={outputElementaryFlowTableData} paging_flag={false} />
        <AddButtonContainer>
          <AddButton onClick={toggleOutputElementaryContextMenu} text={t('追加する')}></AddButton>
          {outputElementaryContextMenuVisible && (
            <OutputIntermediateFlowContextMenu ref={outputElementaryContextMenuRef}>
              <ContextMenuItem onClick={() => { setOutputElementaryFlowSearchModalVisible(true); setOutputElementaryContextMenuVisible(false); }}>
              {t('出力基本フロー（排出物）を追加')}
              </ContextMenuItem>
            </OutputIntermediateFlowContextMenu>
          )}
        </AddButtonContainer>


        <SubsystemInputProductSearchModal
          currentNodeData={currentNodeData}
          isOpen={inputProductSearchModalVisible}
          onClose={() => setInputProductSearchModalVisible(false)}
        />
        <SubsystemCaseStudyProductCreateModal
          currentNodeData={currentNodeData}
          direction={"in"}
          isOpen={createInputModalVisible}
          onClose={() => setCreateInputModalVisible(false)}
        />
        <SubsystemCaseStudyProductCreateModal
          currentNodeData={currentNodeData}
          direction={"out"}
          isOpen={createOutputModalVisible}
          onClose={() => setCreateOutputModalVisible(false)}
        />
        <SubsystemInputElementaryFlowSearchModal
          currentNodeData={currentNodeData}
          isOpen={elementaryFlowSearchModalVisible}
          onClose={() => setElementaryFlowSearchModalVisible(false)}
        />
        <SubsystemOutputElementaryFlowSearchModal
          currentNodeData={currentNodeData}
          isOpen={outputElementaryFlowSearchModalVisible}
          onClose={() => setOutputElementaryFlowSearchModalVisible(false)}
        />

        <UpstreamSubsystemModal
          currentNodeData={currentNodeData}
          nodes={nodes.filter(node => node.id !== String(currentNodeData.id))}
          isOpen={upstreamSubsystemModalVisible}
          onClose={() => setUpstreamSubsystemModalVisible(false)}
        />

        <DownstreamSubsystemModal
          currentNodeData={currentNodeData}
          nodes={nodes.filter(node => node.id !== String(currentNodeData.id))}
          isOpen={downstreamSubsystemModalVisible}
          onClose={() => setDownstreamSubsystemModalVisible(false)}
        />
      </Inner>
    </Modal>
  );
};

export default SubsystemList;

const ContextMenuItem = styled.div`
  padding: 5px;
  cursor: pointer;
  &:hover {
    background-color: #f0f0f0;
  }
`

const AddButtonContainer = styled.div`
  position: relative;
  display: flex;
  justify-content: flex-end;
  width: 100%;
`;

const InputIntermediateFlowContextMenu = styled.div`
  position: absolute;
  top: 100%; 
  white-space: nowrap;
  background: white;
  border: 1px solid #ddd;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.15);
  padding: 10px;
  z-index: 1;
`;

const InputElementaryFlowContextMenu = styled.div`
  position: absolute;
  top: 100%; 
  white-space: nowrap;
  background: white;
  border: 1px solid #ddd;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.15);
  padding: 10px;
  z-index: 1;
`;

const OutputIntermediateFlowContextMenu = styled.div`
  position: absolute;
  top: 100%; 
  white-space: nowrap;
  background: white;
  border: 1px solid #ddd;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.15);
  padding: 10px;
  z-index: 1;
`;

const Inner = styled.div`
    width: 100%;
    padding: 120px 10%;
    margin: 0 auto;
`

const MainTitle = styled.h2`
  margin-bottom: 34px;
  font-size: 18px;
  font-weight: 500;
  line-height: 1.25;
`

const Title = styled.h2`
  font-size: 18px;
  padding: 0 0 20px;
  margin-bottom: 20px;
  border-bottom: 1px solid var(--color-line-primary);
  font-size: 16px;
  font-weight: 500;    
  line-height: 1.25;    
`

const NodeBoxDlButton = styled.button`
  white-space: nowrap;
  cursor: pointer;

  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  background: transparent;
  border: none;
  border-radius: 0;
  font: inherit;
`

const NodeBoxDirectionTopButtonIcon = styled.span`
  mask: url(${iconArrowTop}) no-repeat center center / contain;
  -webkit-mask: url(${iconArrowTop}) no-repeat center center / contain;
  display: block;
  width: 16px;
  height: 16px;
  background: var(--color-txt-primary);
`

const NodeBoxReminderFlowButtonIcon = styled.span`
  mask: url(${iconRemainder}) no-repeat center center / contain;
  -webkit-mask: url(${iconRemainder}) no-repeat center center / contain;
  display: block;
  width: 16px;
  height: 16px;
  background: var(--color-txt-primary);
`

const NodeBoxDirectionButtonIcon = styled.span`
  mask: url(${iconArrowBack}) no-repeat center center / contain;
  -webkit-mask: url(${iconArrowBack}) no-repeat center center / contain;
  display: block;
  width: 16px;
  height: 16px;
  background: var(--color-txt-primary);
`