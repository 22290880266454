import React, { useState, useEffect } from 'react';
import Modal from '@common/modal/Modal';
import styled from 'styled-components';
import { RequiredSpan, LinkMenu } from '@styles/Common';

import { CreateCaseStudy, GetUserDetail } from '@typeList/types';
import { useNavigate } from 'react-router-dom';
import { useCreateCaseStudy, useGetUserDetail } from '@hooks/useBackendApi';
import { getCurrentDefaultDatabasePackId, getCurrentUserInfo } from '@services/Cookies';
import { getValueByKey } from '@utils/utils';
import { useForm, SubmitHandler } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import ModalButton from '@common/button/ModalButton';

interface CaseStudyCreateModalProps {
    userData: GetUserDetail | null;
}


/**
 * 074 ケーススタデ新規作成画面
 * @returns 
 */
const CaseStudyCreateModal: React.FC<CaseStudyCreateModalProps> = ({ userData }) => {
    const { t } = useTranslation();
    const { register, handleSubmit, formState: { errors } } = useForm<CreateCaseStudy>();

    // ユーザーのライセンスロールに紐づくデータベースパックの一覧
    const [inputDatabasePackId, setInputDatabasePackId] = useState<number | undefined>(userData?.default_database_pack_id);
    const [databasePacks, setDatabasePacks] = useState<any[]>([]);

    useEffect(() => {
        if (userData?.licenses) {
            const filteredDatabasePacks = userData?.licenses
                .filter(license => !license.is_inactive) // is_inactive = false のみを対象
                .map(license => ({
                    id: license.role.database_pack.id,
                    name: license.role.database_pack.name,
                }));

            // id の重複を Set で除去し、再度配列に戻す
            const uniqueDatabasePacks = Array.from(new Map(
                filteredDatabasePacks.map(pack => [pack.id, pack]) // id をキーにした Map を生成
            ).values());

            setDatabasePacks(uniqueDatabasePacks);
            // デフォルト設定のデータベースパック取得
            setInputDatabasePackId(getCurrentDefaultDatabasePackId() ?? uniqueDatabasePacks[0].id);
        }
    }, [userData]);

    useEffect(() => {
        if(getCurrentDefaultDatabasePackId()){
            setInputDatabasePackId(getCurrentDefaultDatabasePackId())
        }
    }, [getCurrentDefaultDatabasePackId()])

    // モーダル操作
    const [isModalOpen, setIsModalOpen] = useState(false);
    const openModal = () => setIsModalOpen(true);
    const closeModal = () => setIsModalOpen(false);

    // ケーススタディ名
    const [inputCaseStudyName, setInputCaseStudyName] = useState<string>("");
    // エラー
    // const [inputCaseStudyNameError, setInputCaseStudyNameError] = useState<any>(null);

    // const validateCheck = (): boolean => {
    //     setInputCaseStudyNameError(null);
    //     if (!inputCaseStudyName) {
    //         setInputCaseStudyNameError("ケーススタディ名は必須です");
    //         return false;
    //     }
    //     return true;
    // }

    // セレクトボックス
    const handleInputChangeSelect = (event: React.ChangeEvent<HTMLSelectElement>, key: string) => {
        switch (key) {
            case 'database_pack_id':
                setInputDatabasePackId(Number(event.target.value));
                break;
        }
    };

    // 作成
    const { createCaseStudy, createCaseStudyLoading } = useCreateCaseStudy();

    const [isDisabled, setIsDisabled] = useState(false);
    useEffect(() =>{
        if(isModalOpen){
            setIsDisabled(false)
        }
    },[isModalOpen])
    useEffect(() => {
        if(createCaseStudyLoading){
            setIsDisabled(true);
        }
    },[createCaseStudyLoading])

    const onSubmit: SubmitHandler<CreateCaseStudy> = async data => {
        if (isDisabled) return;

        try {
            if (inputDatabasePackId) {
                const param = {
                    locale: "ja",
                    name: data.name,
                    tags: "",
                    conductor_name: "",
                    summary: "",
                    goal: "",
                    functional_unit: "",
                    public_comment: "",
                    private_comment: ""
                }
                const response = await createCaseStudy(inputDatabasePackId, param);
                const id = response.id

                closeModal();
                window.location.href = `/case_study/case_study_view/${id}`;
            }
        } catch (error: any) {
            console.log(error.response.data.detail)
        }
    };


    return (
        <>
            <LinkMenu onClick={openModal}>
                {t('新規作成')}
            </LinkMenu>
            <Modal isOpen={isModalOpen} onClose={closeModal}>
                <Inner>
                    <Title>{t('ケーススタディ新規作成')}</Title>
                    <SectionBody>
                        <SectionTab>

                            <SectionTabContent>
                                <SectionColumn>
                                    <form>
                                        {databasePacks.length > 0 &&
                                            <>
                                                <SectionTitle>{t('データベースパック')}</SectionTitle>
                                                <SectionContent>
                                                    <SelectPrimary>
                                                        <SelectInner
                                                            name="database-pack"
                                                            id="database-pack-select"
                                                            onChange={(event) => handleInputChangeSelect(event, 'database_pack_id')}
                                                            value={inputDatabasePackId ?? ''}
                                                        >
                                                            {databasePacks.map((database_pack, index) => (
                                                                <option key={index} value={database_pack.id}>
                                                                    {database_pack.name}
                                                                </option>
                                                            ))}
                                                        </SelectInner>
                                                    </SelectPrimary>
                                                </SectionContent>
                                            </>
                                        }
                                        <SectionTitle>{t('名称')}<SectionRequired>※</SectionRequired></SectionTitle>
                                        <SectionContent>
                                            <InputPrimary id="name"  {...register("name", { required: true })} />
                                            {errors.name && <ErrorMsg>{t('名称は必須です')}</ErrorMsg>}
                                        </SectionContent>
                                        <ButtonCreateSectionTabel>
                                            <ModalButton onClick={handleSubmit(onSubmit)} text={t('作成する')} disabled={isDisabled}/>
                                        </ButtonCreateSectionTabel>
                                    </form>
                                </SectionColumn>
                            </SectionTabContent>
                        </SectionTab>
                    </SectionBody>
                </Inner>
            </Modal>
        </>
    );
};

export default CaseStudyCreateModal;

const ErrorMsg = styled.span`
    color: red;
`;

const Title = styled.h2`
    font-size: 18px;
    padding: 0 0 20px;
    margin-bottom: 20px;
    border-bottom: 1px solid var(--color-line-primary);
    font-size: 16px;
    font-weight: 500;    
    line-height: 1.25;    
`

const Inner = styled.div`
    width: 100%;
    padding: 120px 10%;
    margin: 0 auto;
`

const SectionTitle = styled.h2`
    font-size: 18px;
    font-weight: 500;
    line-height: 1.25;
    margin-bottom: 34px;
`

const SectionBody = styled.div`
    margin-bottom: 60px;
`

const InputPrimary = styled.input`
    width: min(100%, 340px);

    padding: 12px;
    color: var(--color-txt-primary);
    border: 1px solid var(--color-line-primary);
    border-radius: 4px;
    background-color: #fff;
    font-size: 16px;
    font-weight: 400;
    line-height: 1.25;
 `
const SelectPrimary = styled.label`
    width: min(100%, 340px);
    position: relative;
    display: block;
    cursor: pointer;
`

const SelectInner = styled.select`
    color: rgb(85, 85, 85);
    position: relative;
    width: 100%;
    padding: 12px 32px 12px 12px;
    cursor: pointer !important;
    border: 1px solid var(--color-line-primary);
    border-radius: 4px;
    background-color: #fff;
    font-size: 16px;
    font-weight: 400;
    line-height: 1.25;
`

const SectionColumn = styled.dl`
    margin-bottom: 40px;
`

const SectionContent = styled.dd`
    margin-bottom: 32px;
    display: flex;
    align-items: center;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: flex-start;
    gap: 16px;    
`

const DataContent = styled.dd`
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    flex-wrap: wrap;
    justify-content: flex-start;
    gap: 12px;
    margin-bottom: 32px;
`


const SectionRequired = styled.span`
    font-size: 0.7em;
    vertical-align: top;
    position: relative;
    top: 0.1em;
    color: var(--color-red--01);
`

const ButtonCreateSectionTabel = styled.div`
    margin-top: 40px;
    text-align: right;

    margin: 0;
    padding: 0;
    border: 0;
    font: inherit;
    vertical-align: baseline;
    box-sizing: border-box;    
`


const SectionTabContent = styled.div`
    display: block;
    z-index: 5;
    width: 100%;
    order: 2;
    position: relative;    
`
const SectionTab = styled.div`
    display: flex;
    align-items: flex-start;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: flex-start;
    gap: 32px 54px;
`
