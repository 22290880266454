import React, { useState, useCallback, useRef, Dispatch, SetStateAction, useEffect } from 'react';
import styled from 'styled-components';
import {
    flexRender,
    getCoreRowModel,
    useReactTable,
    getPaginationRowModel,
    getSortedRowModel,
    ColumnDef,
    SortingState,
} from '@tanstack/react-table';
import { useDrag, useDrop } from 'react-dnd';
import Modal from '@common/modal/Modal';
import { CategoryValues, EditDataTableType } from '@typeList/types';

import iconArrowDown from '@images/table/icon_arrow_down.svg';
import iconArrowUp from '@images/table/icon_arrow_up.svg';
import iconEdit from '@images/table/icon_edit.svg';
import iconDelete from '@images/table/icon_delete.svg';
import iconSelect from '@images/table/icon_arrow_back.svg';
import iconArrowLeft from '@images/table/icon_arrow_left.svg';
import iconArrowRight from '@images/table/icon_arrow_right.svg';
import { useTranslation } from 'react-i18next';

interface TableProps {
    columns: ColumnDef<any, any>[];
    data: any[];
    ModalComponent: React.FC<EditDataTableType>;
    setInputCategoryId: Dispatch<SetStateAction<number | undefined>>;
    setInputCategoryValueId: Dispatch<SetStateAction<number | undefined>>;
    setCategoryIds: Dispatch<SetStateAction<Array<{ id: number, name: string }> | null | undefined>>;
    setCategoryValueIds: Dispatch<SetStateAction<Array<{ id: number, name: string }> | null | undefined>>;
    setInputProductId: Dispatch<SetStateAction<number>>;
    setInputProductName: Dispatch<SetStateAction<string>>;
    setInputUnitName: Dispatch<SetStateAction<string>>;
    setInputUnitId: Dispatch<SetStateAction<number>>;
    setIsSearchModalOpen: Dispatch<SetStateAction<boolean>>;
}

const initialPageIndex = 0;
const initialPageSize = 100;

interface DragItem {
    index: number;
    id: string;
    type: string;
}

const DraggableHeader: React.FC<{
    header: any;
    index: number;
    moveColumn: (dragIndex: number, hoverIndex: number) => void;
}> = ({ header, index, moveColumn }) => {
    const ref = useRef<HTMLTableCellElement>(null);

    const [, drop] = useDrop({
        accept: 'COLUMN',
        hover: (item: DragItem, monitor) => {
            if (!ref.current) {
                return;
            }
            const dragIndex = item.index;
            const hoverIndex = index;

            if (dragIndex === hoverIndex) {
                return;
            }

            const hoverBoundingRect = ref.current?.getBoundingClientRect();
            const hoverMiddleX = (hoverBoundingRect.right - hoverBoundingRect.left) / 2;
            const clientOffset = monitor.getClientOffset();
            const hoverClientX = clientOffset!.x - hoverBoundingRect.left;

            if (dragIndex < hoverIndex && hoverClientX < hoverMiddleX) {
                return;
            }

            moveColumn(dragIndex, hoverIndex);
            item.index = hoverIndex;
        },
    });

    const [{ isDragging }, drag] = useDrag({
        type: 'COLUMN',
        item: { index },
        collect: (monitor) => ({
            isDragging: monitor.isDragging(),
        }),
    });

    drag(drop(ref));

    return (
        <Th
            ref={ref}
            style={{
                opacity: isDragging ? 0.5 : 1,
                cursor: isDragging ? 'grabbing' : 'grab',
            }}
            onClick={header.column.getCanSort() ? header.column.getToggleSortingHandler() : undefined}
        >
            <ThInner>
                {flexRender(header.column.columnDef.header, header.getContext())}
                {header.column.getIsSorted() ? (header.column.getIsSorted() === 'desc' ? <IconArrowDown /> : <IconArrowUp />) : ''}
            </ThInner>
        </Th>
    );
};

const ProcessProductSelectTable: React.FC<TableProps> = ({
    columns: initialColumns,
    data,
    ModalComponent,
    setInputCategoryId,
    setInputCategoryValueId,
    setCategoryIds,
    setCategoryValueIds,
    setInputProductId,
    setInputProductName,
    setInputUnitName,
    setInputUnitId,
    setIsSearchModalOpen
}) => {
    const {t} = useTranslation();
    const [columns, setColumns] = useState<ColumnDef<any, any>[]>([
        {
            id: 'select',
            header: () => t('選択'),
            cell: ({ row }) => (
                <div>
                    <SelectButton type='button' onClick={() => handleProductSelect(row.original)}>
                        <ButtonInner>
                            <SelectButtonIcon></SelectButtonIcon>
                        </ButtonInner>
                    </SelectButton>
                </div>
            ),
            enableSorting: false, // ソート不可にする
        },
        {
            id: 'edit',
            header: () => t('編集'),
            cell: ({ row }) => (
                <EditButton type='button' onClick={() => handleOpenModal(row.original.id)}>
                    <ButtonInner>
                        <EditButtonIcon></EditButtonIcon>
                    </ButtonInner>
                </EditButton>
            ),
            enableSorting: false, // ソート不可にする
        },
        ...initialColumns,
    ]);
    const [rows, setRows] = useState(data);
    useEffect(() => {
        setRows(data);
    }, [data])
    const [sorting, setSorting] = useState<SortingState>([]);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [currentRowId, setCurrentRowId] = useState<string | null>(null);

    const handleOpenModal = (id: string) => {
        setCurrentRowId(id);
        setIsModalOpen(true);
    };

    const handleCloseModal = () => {
        setIsModalOpen(false);
        setCurrentRowId(null);
    };

    // 製品選択
    const handleProductSelect = (row: any) => {
        setInputUnitName(row.unitCode);
        setInputUnitId(row.unitId)
        setInputProductId(row.id)
        setInputProductName(row.name)
        setCategoryValueIds(row.category_values.map((category_values: any) => ({ id: category_values.id, name: category_values.name })))
        setCategoryIds(
            row.category_values.map((categoryValue: CategoryValues) => {
                const category = categoryValue.category;
                return {
                    id: category.id,
                    name: category.name
                };
            })
        );
        // setCategoryValueIds
        // setIsModalOpen(false);
        setIsSearchModalOpen(false);
    }

    const moveColumn = useCallback(
        (dragIndex: number, hoverIndex: number) => {
            const newColumns = [...columns];
            const [movedColumn] = newColumns.splice(dragIndex, 1);
            newColumns.splice(hoverIndex, 0, movedColumn);
            setColumns(newColumns);
        },
        [columns]
    );

    const table = useReactTable({
        data: rows ?? [],
        columns,
        state: { sorting },
        onSortingChange: setSorting,
        initialState: {
            pagination: {
                pageIndex: initialPageIndex,
                pageSize: initialPageSize,
            },
        },
        getCoreRowModel: getCoreRowModel(),
        getPaginationRowModel: getPaginationRowModel(),
        getSortedRowModel: getSortedRowModel(),
    });

    const renderPageNumbers = () => {
        const totalPages = table.getPageCount();
        const currentPage = table.getState().pagination.pageIndex;

        const range = (start: number, end: number) => {
            return Array.from({ length: end - start + 1 }, (_, i) => i + start);
        };

        // 1〜9ページ目までを表示
        if (totalPages <= 9) {
            return range(0, totalPages - 1);
        }

        // 最初のページと最後のページは常に表示
        const pages = [0];

        // 9ページ目以降の場合、省略しつつ範囲を動的に設定
        if (currentPage >= 9) {
            pages.push(-1); // 省略記号として-1を挿入
            pages.push(...range(currentPage - 1, Math.min(currentPage + 1, totalPages - 2)));
        } else {
            pages.push(...range(1, Math.min(8, totalPages - 2)));
        }

        // 最後のページは常に表示
        pages.push(totalPages - 1);

        return pages.reduce<number[]>((acc, page) => {
            if (acc.length > 0 && page - acc[acc.length - 1] > 1) {
                acc.push(-1); // 省略記号
            }
            acc.push(page);
            return acc;
        }, []);
    };


    return (
        <SectionTableWrap>
            <Modal isOpen={isModalOpen} onClose={handleCloseModal}>
                <ModalComponent Id={Number(currentRowId)} onClose={handleCloseModal} selectted_database_pack_id={null} />
            </Modal>

            <Table>
                <Thead>
                    {table.getHeaderGroups().map((headerGroup) => (
                        <Tr key={headerGroup.id}>
                            {headerGroup.headers.map((header, index) => (
                                <DraggableHeader
                                    key={header.id}
                                    header={header}
                                    index={index}
                                    moveColumn={moveColumn}
                                />
                            ))}
                        </Tr>
                    ))}
                </Thead>
                <Tbody>
                    {table.getRowModel().rows.map((row) => (
                        <Tr key={row.id} style={{ background: row.index % 2 === 0 ? '#fafafa' : '#fff' }}>
                            {row.getVisibleCells().map((cell) => (
                                <Td key={cell.id} title={String(cell.getValue())}>
                                    <CellContent>
                                        {flexRender(cell.column.columnDef.cell, cell.getContext())}
                                    </CellContent>
                                </Td>
                            ))}
                        </Tr>
                    ))}
                </Tbody>
            </Table>
        </SectionTableWrap>
    );
};

export default ProcessProductSelectTable;

const CellContent = styled.div`
overflow: hidden;
text-overflow: ellipsis;
white-space: nowrap;
`;

const EditButton = styled.button`
cursor: pointer;
appearance: none;
background: transparent;
border: none;
border-radius: 0;
font: inherit;
outline: none;
`;

const ButtonInner = styled.div``;

const EditButtonIcon = styled.span`
mask: url(${iconEdit}) no-repeat center center / contain;
-webkit-mask: url(${iconEdit}) no-repeat center center / contain;
display: block;
width: 24px;
height: 24px;
background: var(--color-txt-primary);
`;

const SelectButton = styled.button`
    cursor: pointer;
    appearance: none;
    background: transparent;
    border: none;
    border-radius: 0;
    font: inherit;
    outline: none;
`;

const SelectButtonIcon = styled.span`
    mask: url(${iconSelect}) no-repeat center center / contain;
    -webkit-mask: url(${iconSelect}) no-repeat center center / contain;
    display: block;
    width: 24px;
    height: 24px;
    background: var(--color-txt-primary);
`;

const Table = styled.table`
    min-width: 100%;
    border-collapse: collapse;
    margin-bottom: 8px;
    background-color: #fff;
    border-spacing: 0;
`

const Thead = styled.thead`
    border-right: 1px solid var(--color-line-primary);
    border-left: 1px solid var(--color-line-primary);
`

const Tr = styled.tr`
    border-top: 1px solid var(--color-line-primary);
    border-bottom: 1px solid var(--color-line-primary);
`

const Th = styled.th`
    font-size: 13px;
    font-weight: 700;
    line-height: 1.25;
    color: var(--color-site-primary);
    padding: 12px;
    align-content: center;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    min-height: 40px;
`

const ThInner = styled.div`
    width: fit-content;
    display: flex;
    align-items: center;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: flex-start;
    gap: 4px;
    white-space: nowrap;
`

const Tbody = styled.tbody`
    border-right: 1px solid var(--color-line-primary);
    border-left: 1px solid var(--color-line-primary);
`
const Td = styled.td`
    font-size: 14px;
    font-weight: 500;
    line-height: 1.25;
    padding: 12px;
    align-content: center;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    min-height: 40px;
`

const IconArrowDown = styled.span`
    mask: url(${iconArrowDown}) no-repeat center center / contain;
    -webkit-mask: url(${iconArrowDown}) no-repeat center center / contain;
    display: block;
    width: 100%;
    height: 100%;
    width: 20px;
    height: 20px;
    background: var(--color-txt-primary);
}
`

const IconArrowUp = styled.span`
    mask: url(${iconArrowUp}) no-repeat center center / contain;
    -webkit-mask: url(${iconArrowUp}) no-repeat center center / contain;
    display: block;
    width: 100%;
    height: 100%;
    width: 20px;
    height: 20px;
    background: var(--color-txt-primary);
`

const SectionTableWrap = styled.div`
    overflow-x: auto;    
`