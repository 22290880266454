import React, { useContext, useEffect, useState } from 'react';
import ProcessImpactAssesmentMethodsTable from '@common/table/ProcessImpactAssesmentMethodsTable';
import { CaseStudyCalculate, CaseStudyLCIAResults, ImpactAssesmentMethods, LCIAResults, ResultItems0, ResultItems1 } from '@typeList/types';
import { useCaseStudyLciaResults, useCaseStudyLciaResultsCategory, useCaseStudyLciaResultsUpper } from '@hooks/useBackendApi';
import { CaseStudyViewContext } from '@pages/case_study/CaseStudyView';
import CaseStudyLciaResultTable from '@common/table/CaseStudyLciaResultTable';
import CaseStudyContributionAnalysisModal from '../contribution_analysis/CaseStudyContributionAnalysisModal';
import LciaModelSelectModal from '@specific/process/calculation/LciaModelSelectModal';
import CaseStudyImpactAssesmentMethodsTable from '@common/table/CaseStudyImpactAssesmentMethodsTable';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import ProessContributionAnalysisModal from '@specific/process/contribution_analysis/ProessContributionAnalysisModal';
import StackedBarChart from '@common/graph/StackedBarChart';
import { ColumnDef } from '@tanstack/react-table';
import { formatNumber } from '@utils/utils';
import CaseStudyStackedBarChart from '@common/graph/CaseStudyStackedBarChart';
import DamageAssessmentLciaModelSelectModal from './DamageAssessmentLciaModelSelectModal';
import { FadeLoader } from 'react-spinners';


interface DamageAssessmentProps {
    methodsData: ImpactAssesmentMethods[] | null;
    calculateData: CaseStudyCalculate | null;
}

/**
 * 039 被害評価
 * @returns 
 */
const DamageAssessment: React.FC<DamageAssessmentProps> = ({ methodsData, calculateData }) => {
    const { t } = useTranslation();
    const context = useContext(CaseStudyViewContext);
    const {
        userData,
        caseStudyData,
        inputDamageAssessmentGroupingValue,
        setInputDamageAssessmentGroupingValue,
        inputDamageAssessmentDisplayFormatValue,
        setInputDamageAssessmentDisplayFormatValue,
        subsystemCategorySetData,
        inventoryAnalysData,
    } = context;

    const [selectedRows, setSelectedRows] = useState<{ [key: string]: boolean } | null>(null);
    const [damageAssessmentResultData, setDamageAssessmentdamageAssessmentResultData] = useState<CaseStudyLCIAResults | null>(null);

    const { excuteCaseStudyLciaResults,　setCaseStudyLciaResultsData,  caseStudyLciaResultsData, caseStudyLciaResultsLoading } = useCaseStudyLciaResults();
    const { excuteCaseStudyLciaResultsCategory, setCaseStudyLciaResultsCategoryData, caseStudyLciaResultsCategoryData, caseStudyLciaResultsCategoryLoading } = useCaseStudyLciaResultsCategory();
    const { excuteCaseStudyLciaResultsUpper, setCaseStudyLciaResultsUpperData, caseStudyLciaResultsUpperData, caseStudyLciaResultsUpperLoading } = useCaseStudyLciaResultsUpper();

    const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>, key: string) => {
        switch (key) {
            case "grouping":
                setInputDamageAssessmentGroupingValue(event.target.value);
                break;
            case "displayFormat":
                setInputDamageAssessmentDisplayFormatValue(event.target.value);
                break;
        }
    };

    // サブシステムカテゴリ
    const [selectDamageAssessmentCategorySet, setSelectDamageAssessmentCategorySet] = useState<number>(0)
    useEffect(() => {
        if (subsystemCategorySetData && subsystemCategorySetData.length > 0) {
            setSelectDamageAssessmentCategorySet(subsystemCategorySetData[0].id)
        }
    }, [subsystemCategorySetData])

    const handleInputChangeSelect = (event: React.ChangeEvent<HTMLSelectElement>) => {
        const selectedValue = Number(event.target.value);
        setSelectDamageAssessmentCategorySet(selectedValue); // selectDamageAssessmentCategorySet を更新
    };

    const [selectModelIdList, setSelectModelIdList] = useState<Array<number>>([])
    useEffect(() => {
        const selectedModelIds = methodsData?.filter((method: ImpactAssesmentMethods) => method.is_default_selected)
            .map((method: ImpactAssesmentMethods) => method.id);

        setSelectModelIdList(selectedModelIds || []);
    }, [])

    //　取得済みのサブシステムカテゴリごとの被害評価LCIAのリスト
	const [damageAssessmentCategorySetList, setDamageAssessmentCategorySetList] = useState<Array<{id: number, CaseStudyLCIAResults: CaseStudyLCIAResults | null}>>([]);

        
    // 保存のタイミング等で計算結果がリセットされたときキャッシュをクリア
    useEffect(() =>{
        if(!inventoryAnalysData){
            setSelectModelIdList([])
            setDamageAssessmentdamageAssessmentResultData(null);
            setCaseStudyLciaResultsData(null);
            setCaseStudyLciaResultsCategoryData(null);
            setCaseStudyLciaResultsUpperData(null);
            setDamageAssessmentCategorySetList([]);
        }
    },[inventoryAnalysData])


    // 被害評価モデル選択時
    useEffect(() => {
        // モデル変更時はリセット
		setCaseStudyLciaResultsData(null);
		setCaseStudyLciaResultsCategoryData(null);
		setCaseStudyLciaResultsUpperData(null);
		setDamageAssessmentCategorySetList([]);
		setDamageAssessmentdamageAssessmentResultData(null);
        if (selectModelIdList && selectModelIdList.length > 0) {
            try {
                // excuteLciaResultsに抽出したIDリストを引数として渡す
                if (inputDamageAssessmentGroupingValue === "1") {
                    excuteCaseStudyLciaResults(caseStudyData?.id || 0, selectModelIdList);
                }
                if (inputDamageAssessmentGroupingValue === "2") {
                    excuteCaseStudyLciaResultsCategory(caseStudyData?.id || 0, selectDamageAssessmentCategorySet, selectModelIdList)
                    .then((result ) => {
                        // 条件を満たす場合にCharacterizationCategorySetListに追加
                        setDamageAssessmentCategorySetList(prevList => [
                            ...prevList,
                            {
                                id: selectDamageAssessmentCategorySet,
                                CaseStudyLCIAResults: result  || null
                            }
                        ]);
                    });
                }
                if (inputDamageAssessmentGroupingValue === "3") {
                    excuteCaseStudyLciaResultsUpper(caseStudyData?.id || 0, selectModelIdList);
                }
            } catch (error) {
                console.error('LCIA Results Error:', error);
            }
        } else {
            setDamageAssessmentdamageAssessmentResultData(null);
        }
    }, [selectModelIdList])


    // 被害評価グループ選択
    useEffect(() => {
        if (selectModelIdList && selectModelIdList.length > 0) {
            try {
                // excuteLciaResultsに抽出したIDリストを引数として渡す
                if (inputDamageAssessmentGroupingValue === "1" && !caseStudyLciaResultsData) {
                    excuteCaseStudyLciaResults(caseStudyData?.id || 0, selectModelIdList);
                }
                if (inputDamageAssessmentGroupingValue === "2") {
                    const exists = damageAssessmentCategorySetList.some(item => item.id === selectDamageAssessmentCategorySet);
                    if(!exists){
                        excuteCaseStudyLciaResultsCategory(caseStudyData?.id || 0, selectDamageAssessmentCategorySet, selectModelIdList)
                        .then((result) => {
                            setDamageAssessmentCategorySetList(prevList => [
                                ...prevList,
                                {
                                    id: selectDamageAssessmentCategorySet,
                                    CaseStudyLCIAResults: result  || null
                                }
                            ]);
                        });
                    }
                }
                if (inputDamageAssessmentGroupingValue === "3" && !caseStudyLciaResultsUpperData) {
                    excuteCaseStudyLciaResultsUpper(caseStudyData?.id || 0, selectModelIdList);
                }
            } catch (error) {
                console.error('LCIA Results Error:', error);
            }
        } else {
            setDamageAssessmentdamageAssessmentResultData(null);
        }
    }, [inputDamageAssessmentGroupingValue])


    // 被害評価カテゴリセット選択
    useEffect(() => {
        if (selectModelIdList && selectModelIdList.length > 0) {
            try {
                if (inputDamageAssessmentGroupingValue === "2") {
                    const exists = damageAssessmentCategorySetList.some(item => item.id === selectDamageAssessmentCategorySet);
                    if(!exists){
                        excuteCaseStudyLciaResultsCategory(caseStudyData?.id || 0, selectDamageAssessmentCategorySet, selectModelIdList)
                        .then((result) => {
                            setDamageAssessmentCategorySetList(prevList => [
                                ...prevList,
                                {
                                    id: selectDamageAssessmentCategorySet,
                                    CaseStudyLCIAResults: result  || null
                                }
                            ]);
                        });
                    }
                }
            } catch (error) {
                console.error('LCIA Results Error:', error);
            }
        } else {
            setDamageAssessmentdamageAssessmentResultData(null);
        }
    }, [selectDamageAssessmentCategorySet])


    // 被害評価テーブル表示用のデータ
    useEffect(() => {
        if(!selectModelIdList || selectModelIdList.length === 0){
            return
        }
        // サブシステムごと
        if (inputDamageAssessmentGroupingValue === "1" && caseStudyLciaResultsData) {
            setDamageAssessmentdamageAssessmentResultData(caseStudyLciaResultsData);
        }
        // カテゴリごと
        if (inputDamageAssessmentGroupingValue === "2" && caseStudyLciaResultsCategoryData) {
            const matchedData = damageAssessmentCategorySetList.find(item => item.id === selectDamageAssessmentCategorySet);
			if (matchedData && matchedData.CaseStudyLCIAResults) {
				setDamageAssessmentdamageAssessmentResultData(matchedData.CaseStudyLCIAResults);
			} else if (caseStudyLciaResultsCategoryData) {
				// 一致するデータがない場合は、caseStudyLciaResultsCategoryDataをセット
				setDamageAssessmentdamageAssessmentResultData(caseStudyLciaResultsCategoryData);
			}
        }
        // 上流ごと
        if (inputDamageAssessmentGroupingValue === "3" && caseStudyLciaResultsUpperData) {
            setDamageAssessmentdamageAssessmentResultData(caseStudyLciaResultsUpperData);
        }
    }, [selectDamageAssessmentCategorySet, inputDamageAssessmentGroupingValue, caseStudyLciaResultsData, caseStudyLciaResultsCategoryData, caseStudyLciaResultsUpperData]);

    // 型ガード関数の定義
    function isResultItems1(item: ResultItems0 | ResultItems1): item is ResultItems1 {
        return (item as ResultItems1).lcia_model !== undefined;
    }

    // フラットなデータを作成する関数
    const flattenResultData = () => {
        if (!damageAssessmentResultData) return [];

        return damageAssessmentResultData.result_items.map((item) => {
            // 型ガードで lcia_model プロパティが存在することを確認
            if (!isResultItems1(item)) {
                return { ...item, relatedBreakdown: [] };
            }

            const relatedBreakdown = damageAssessmentResultData.result_breakdown?.lci_result_breakdown.map((breakdown) => {
                // lcia_model が存在することを前提にフィルタリング
                const matchingItems = breakdown.lci_result_breakdown_items.filter(
                    (breakdownItem) => breakdownItem.result_key_id === item.lcia_model.id
                );

                return {
                    groupingKeyId:  breakdown.grouping_key_id,
                    subGroupingKeyId:  breakdown.sub_grouping_key_id,
                    groupingName: breakdown.grouping_name + ' ' + breakdown.sub_grouping_name,
                    amount: matchingItems.length > 0 ? matchingItems[0].amount : 0,
                    ratio: matchingItems.length > 0 ? matchingItems[0].ratio : 0,
                };
            });

            return {
                ...item,
                relatedBreakdown: relatedBreakdown || [],
            };
        });
    };

    // カラム定義
    const generateColumns = (): ColumnDef<any>[] => {
        let baseColumns: ColumnDef<any>[] = [
            {
                id: 'impact_assessment_method',
                header: () => t('影響評価手法'),
                accessorKey: 'lcia_model.impact_assessment_method.name', // ソートのためのキーを指定
                enableSorting: true,
                cell: (info) => {
                    const resultItem = info.row.original;
                    return isResultItems1(resultItem) ? resultItem.lcia_model.impact_assessment_method.name : '';
                },
            },
            {
                id: 'impact_category',
                header: () => t('影響カテゴリ'),
                accessorKey: 'lcia_model.impact_category.name', // ソートのためのキーを指定
                enableSorting: true,
                cell: (info) => {
                    const resultItem = info.row.original;
                    return isResultItems1(resultItem) ? resultItem.lcia_model.impact_category.name : '';
                },
            },
            {
                id: 'lcia_indicator',
                header: () => t('指標'),
                accessorKey: 'lcia_model.lcia_indicator.name', // ソートのためのキーを指定
                enableSorting: true,
                cell: (info) => {
                    const resultItem = info.row.original;
                    return isResultItems1(resultItem) ? resultItem.lcia_model.lcia_indicator.name : '';
                },
            },
            {
                id: 'total_amount',
                header: () => t('全体'),
                accessorKey: 'amount', // ソートのためのキーを指定
                enableSorting: true,
                cell: (info) => {
                    const resultItem = info.row.original;
                    const amount = resultItem.amount;
                    if (inputDamageAssessmentDisplayFormatValue === "2" && amount > 0) {
                        return ((resultItem.amount / amount) * 100).toFixed(2) + '%';
                    }
                    if (userData) {
                        return formatNumber(amount || 0, userData?.significant_figures, userData?.use_decimal_notation, userData?.decimal_places)
                    } else {
                        return amount; // デフォルトではそのままの値を表示
                    }
                },
            },
            {
                id: 'unit',
                header: () => t('単位'),
                accessorKey: 'unit.name', // ソートのためのキーを指定
                enableSorting: true,
                cell: (info) => {
                    const resultItem = info.row.original;
                    return isResultItems1(resultItem) ? resultItem.unit.name : '';
                },
            },
        ];

        // breakdown のデータを表示するカラムを追加
        if (damageAssessmentResultData?.result_breakdown?.lci_result_breakdown) {
            damageAssessmentResultData.result_breakdown.lci_result_breakdown.forEach((breakdown) => {
                baseColumns.push({
                    id: `breakdown_${breakdown.grouping_key_id}_${breakdown.sub_grouping_key_id}`,
                    header: () =>(
                        <span>
                            {breakdown.grouping_name}
                            <br />
                            {breakdown.sub_grouping_name}
                        </span>
                    ),
                    accessorFn: (row) => {
                        // ここにソートのための値を返す関数を設定
                        const relatedBreakdown = row.relatedBreakdown.find(
                            (b: any) => b.groupingKeyId === breakdown.grouping_key_id && b.subGroupingKeyId === breakdown.sub_grouping_key_id
                        );
                        return relatedBreakdown ? relatedBreakdown.amount : 0;
                    },
                    enableSorting: true,
                    cell: (info) => {
                        const relatedBreakdown = info.row.original.relatedBreakdown.find(
                            // (b: any) => b.groupingName === breakdown.grouping_name
                            (b: any) => b.groupingKeyId === breakdown.grouping_key_id && b.subGroupingKeyId === breakdown.sub_grouping_key_id
                        );
                        if (inputDamageAssessmentDisplayFormatValue === "2") {
                            return `${(relatedBreakdown?.ratio * 100).toFixed(2)}%`; // ratioをパーセント表示
                        }
                        const amount = relatedBreakdown ? relatedBreakdown.amount : 0;
                        if (userData) {
                            return formatNumber(amount || 0, userData?.significant_figures, userData?.use_decimal_notation, userData?.decimal_places)
                        } else {
                            return amount; // デフォルトではそのままの値を表示
                        }
                    },
                });
            });
        }

        return baseColumns;
    };

    const [columns, setColumns] = useState<ColumnDef<any>[]>(generateColumns());
    const [flattenedData, setFlattenedData] = useState<any[]>([]);

    useEffect(() => {
        if(damageAssessmentResultData && damageAssessmentResultData?.result_breakdown.lci_result_breakdown.length > 0){
            setFlattenedData(flattenResultData());
        }
    }, [damageAssessmentResultData]);

    useEffect(() => {
        setColumns(generateColumns());
    }, [damageAssessmentResultData, inputDamageAssessmentDisplayFormatValue, inputDamageAssessmentGroupingValue]);

    // エクスポート用のcolumn data
    const [exportColumns, setExportColumns] = useState<any>()
    const [exportData, setExportData] = useState<any>()
    const exportSet = (columns: any, data: any) => {
        setExportColumns(columns);
        setExportData(data);
    }

    // CSVダウンロード用の関数を定義
    const downloadCsv = (csv: string, filename: string) => {
        // UTF-8 BOMを追加
        const csvWithBom = `\uFEFF${csv}`;

        // CSV文字列をBlobオブジェクトに変換
        const blob = new Blob([csvWithBom], { type: 'text/csv;charset=utf-8;' });

        // ダウンロードリンクを作成してダウンロードをトリガー
        const link = document.createElement('a');
        const url = URL.createObjectURL(blob);
        link.href = url;
        link.setAttribute('download', filename);
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        URL.revokeObjectURL(url); // メモリリークを防ぐためにURLを解放
    };

    // データを整形する関数
    const transformData = (data: any[], columns: ColumnDef<any>[]) => {
        const headers = columns.map((column) => {
            if (typeof column.header === 'function') {
                const headerContent = column.header({} as any);
                return headerContent.props ? `${headerContent.props.children[0]} ${headerContent.props.children[2]}` : headerContent;
            }
            return column.header;
        });

        const transformedData = data.map((row) => {
            const transformedRow: any = {};

            columns.forEach((column) => {
                if (!column.id) return;

                switch (column.id) {
                    case 'impact_assessment_method':
                        transformedRow[column.id] = row.lcia_model?.impact_assessment_method?.name || '';
                        break;
                    case 'impact_category':
                        transformedRow[column.id] = row.lcia_model?.impact_category?.name || '';
                        break;
                    case 'lcia_indicator':
                        transformedRow[column.id] = row.lcia_model?.lcia_indicator?.name || '';
                        break;
                    case 'total_amount':
                        transformedRow[column.id] = row.amount || 0;
                        break;
                    case 'unit':
                        transformedRow[column.id] = row.unit?.name || '';
                        break;
                    default:
                        if (column.id.startsWith('breakdown_')) {
                            // breakdown_<grouping_key_id>_<sub_grouping_key_id> の形式から ID を取得
                            const [groupingKeyId, subGroupingKeyId] = column.id.split('_').slice(1).map(Number);

                            // `relatedBreakdown` の中から `groupingKeyId` と `subGroupingKeyId` に一致する項目を探す
                            const matchingBreakdowns = row.relatedBreakdown.filter(
                                (b: any) => b.groupingKeyId === groupingKeyId && b.subGroupingKeyId === subGroupingKeyId
                            );

                            // マッチする breakdown の amount を取り出して連結
                            transformedRow[column.id] = matchingBreakdowns.map((b: any) => b.amount).join(', ') || '0'; // カンマ区切りで連結
                        } else {
                            transformedRow[column.id] = ''; // その他のカラムは空に設定
                        }
                        break;
                }
            });

            return transformedRow;
        });

        return { headers, transformedData };
    };

    // エクスポート関数
    const exportCsv = (data: any[], columns: ColumnDef<any>[]) => {
        if (!data || data.length === 0 || !columns || columns.length === 0) {
            console.error('エクスポートするデータまたはカラム情報が不足しています。');
            return;
        }

        // データを変換
        const { headers, transformedData } = transformData(data, columns);

        try {
            // CSVを生成する
            const csvContent = [
                headers.map(header => `"${header}"`).join(','),
                ...transformedData.map(row =>
                    Object.values(row).map(value => `"${String(value).replace(/"/g, '""')}"`).join(',')
                )].join('\n');

            // Blobを使ってダウンロード
            downloadCsv(csvContent, "case_study_endpoint_lcia_results" || 'default.csv');
        } catch (error) {
            console.error('CSVのエクスポート中にエラーが発生しました:', error);
        }
    };

    useEffect(() => {
        // LCIAモデルのチェックがすべて外れたときはテーブルを空にする
        if (selectModelIdList && selectModelIdList.length === 0) {
            setFlattenedData([])
        }
    }, [selectModelIdList])

    return (
        <Section>
            <Title>{t('被害評価')}</Title>
            <div>
                <H2>{t('グルーピング')}</H2>
                <RadioWrap>
                    <RadioInput
                        type="radio"
                        name="grouping"
                        id="1"
                        value="1"
                        checked={inputDamageAssessmentGroupingValue === "1"}
                        onChange={(event) => handleInputChange(event, "grouping")}
                    />
                    <RadioLabel htmlFor="1">
                        <RadioText>
                            {t('サブシステム')}
                        </RadioText>
                    </RadioLabel>

                    <RadioInput
                        type="radio"
                        name="grouping"
                        id="2"
                        value="2"
                        checked={inputDamageAssessmentGroupingValue === "2"}
                        onChange={(event) => handleInputChange(event, "grouping")}
                    />
                    <RadioLabel htmlFor="2">
                        <RadioText>
                            {t('サブシステムカテゴリ')}
                        </RadioText>
                    </RadioLabel>

                    <SelectLabel>
                        <Select
                            value={selectDamageAssessmentCategorySet}
                            onChange={(event) => {
                                handleInputChangeSelect(event)
                            }}
                        >
                            {subsystemCategorySetData?.map(categorySet => (
                                <option key={categorySet.id} value={categorySet.id}>
                                    {categorySet.name}
                                </option>
                            ))}
                        </Select>
                    </SelectLabel>

                    <RadioInput
                        type="radio"
                        name="grouping"
                        id="3"
                        value="3"
                        checked={inputDamageAssessmentGroupingValue === "3"}
                        onChange={(event) => handleInputChange(event, "grouping")}
                    />
                    <RadioLabel htmlFor="3">
                        <RadioText>
                            {t('上流フロー')}
                        </RadioText>
                    </RadioLabel>
                </RadioWrap>
            </div>
            <div>
                <H2>{t('表示形式')}</H2>
                <RadioWrap>
                    <RadioInput
                        type="radio"
                        name="displayFormat"
                        id="4"
                        value="1"
                        checked={inputDamageAssessmentDisplayFormatValue === "1"}
                        onChange={(event) => handleInputChange(event, "displayFormat")}
                    />
                    <RadioLabel htmlFor="4">
                        <RadioText>
                            {t('数値')}
                        </RadioText>
                    </RadioLabel>

                    <RadioInput
                        type="radio"
                        name="displayFormat"
                        id="5"
                        value="2"
                        checked={inputDamageAssessmentDisplayFormatValue === "2"}
                        onChange={(event) => handleInputChange(event, "displayFormat")}
                    />
                    <RadioLabel htmlFor="5">
                        <RadioText>
                            {t('パーセント')}
                        </RadioText>
                    </RadioLabel>
                </RadioWrap>
            </div>
            {caseStudyLciaResultsLoading && 
                <FadeLoader
                    color="#48bdbb"
                    height={10}
                    radius={2}
                    width={5}
                />
            }
            {caseStudyLciaResultsUpperLoading && 
                <FadeLoader
                    color="#48bdbb"
                    height={10}
                    radius={2}
                    width={5}
                />
            }
            {caseStudyLciaResultsCategoryLoading && 
                <FadeLoader
                    color="#48bdbb"
                    height={10}
                    radius={2}
                    width={5}
                />
            }
            {!caseStudyLciaResultsLoading && !caseStudyLciaResultsUpperLoading && !caseStudyLciaResultsCategoryLoading &&
                <>
                    <ButtonWrap>
                        <Button type='button' onClick={() => exportCsv(exportData || [], exportColumns || [])}>
                            <ButtonText>{t('エクスポート')}</ButtonText>
                        </Button>
                        {userData?.is_admin &&
                            <CaseStudyContributionAnalysisModal resultData={damageAssessmentResultData} result_type="lcia" />
                        }
                    </ButtonWrap>

                    <DamageAssessmentLciaModelSelectModal
                        methodsData={methodsData}
                        setSelectModelIdList={setSelectModelIdList}
                        selectModelIdList={selectModelIdList}
                        calculateData={calculateData}
                    />

                    <CaseStudyImpactAssesmentMethodsTable
                        columns={columns}
                        resultData={flattenedData}
                        paging_flag={false}
                        selectedRows={selectedRows || {}}
                        setSelectedRows={setSelectedRows}
                        exportSet={exportSet}
                    />

                    <CaseStudyStackedBarChart data={flattenedData} />
                </>
            }
        </Section>
    );
};

export default DamageAssessment

const Title = styled.h2`
    padding: 0 0 20px;
    margin-bottom: 20px;
    font-size: 16px;
    font-weight: 500;
`

const Section = styled.section`
    margin-bottom: 32px;
    display: block;
`

const H2 = styled.h2`
    padding: 0 0 20px;
    margin-bottom: 20px;
    border-bottom: 1px solid var(--color-line-primary);
    font-size: 16px;
    font-weight: 500;
    line-height: 1.25;
`


const RadioWrap = styled.div`
    margin-bottom: 32px;
    gap: 24px;
    display: flex;
    align-items: center;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: flex-start;
`

const RadioInput = styled.input`
  display: none;

  &:checked + label::before {
    border-color: var(--color-site-secondary);
    background-color: var(--color-site-secondary);
  }

  &:checked + label::after {
    position: absolute;
    top: 50%;
    left: 8px;
    width: 4.5px;
    height: 4.5px;
    content: "";
    border-radius: 50%;
    background-color: #fff;
    transform: translate(-50%, -50%);
  }

  &:not(:checked) + label .c-input,
  &:not(:checked) + label .c-select,
  &:not(:checked) + label .c-select__inner {
    pointer-events: none;
    color: var(--color-line-primary) !important;
  }
`;

const RadioLabel = styled.label`
  display: flex;
  align-items: center;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: flex-start;
  gap: 6px;
  width: fit-content;
  cursor: pointer;
  position: relative;

  &::before {
    content: "";
    width: 14px;
    height: 14px;
    margin: 0;
    cursor: pointer;
    border: 1px solid var(--color-txt-primary);
    border-radius: 50%;
    background-color: #fff;
  }
`;

const RadioText = styled.span`
  display: flex;
  align-items: center;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: flex-start;
  gap: 0 20px;
  font-size: 16px;
  white-space: nowrap;
  font-weight: 500;
`;

const ButtonWrap = styled.div`
    margin: 10px 0px;
    display: flex;
    align-items: center;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: flex-end;
`

const Button = styled.button`
    margin-right: 10px;
    background-color: var(--color-site-secondary);
    border-radius: 4px;
    border: 1px solid var(--color-site-secondary);
    padding: 7px 16px;

    display: inline-flex;
    align-items: center;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: center;
    cursor: pointer;
    white-space: nowrap;
    gap: 0 4px;

    font: inherit;
    outline: none;
`

const ButtonText = styled.span`
    color: #fff;
    font-size: 13px;
    font-weight: 500;
`

const SelectLabel = styled.label`
    width: min(100%, 340px);
    position: relative;
    display: block;
    cursor: pointe
`

const Select = styled.select`
    position: relative;
    width: 100%;
    padding: 12px 32px 12px 12px;
    cursor: pointer !important;
    border: 1px solid var(--color-line-primary);
    border-radius: 4px;
    font-size: 16px;
    font-weight: 400;
    line-height: 1.25;
`