import React, { useState, useEffect } from 'react';
import Modal from '@common/modal/Modal'; // モーダルコンポーネントのインポート

import iconSelect from '@images/table/icon_arrow_back.svg';
import iconCheck from '@images/table/icon_check.svg';
import styled from 'styled-components';
import iconArrowDown from '@images/table/icon_arrow_down.svg';
import iconArrowUp from '@images/table/icon_arrow_up.svg';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

interface RefereedProcessInputsCellProps {
    value: number | null;
    getRefereedProcessInputs: (id: number) => Promise<any[]>;
}

/**
 * 075 フロー参照プロセス
 * 
 * @param param0 
 * @returns 
 */
const RefereedProcessInputsCell: React.FC<RefereedProcessInputsCellProps> = ({ value, getRefereedProcessInputs }) => {
    const { t } = useTranslation();
    const [refereedProcessInputsLength, setRefereedProcessInputsLength] = useState(0);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [inputsData, setInputsData] = useState<any[]>([]);

    useEffect(() => {
        const fetchData = async () => {
            try {
                const refereedProcessInputs = await getRefereedProcessInputs(value || 0);
                setRefereedProcessInputsLength(refereedProcessInputs.length);
                setInputsData(refereedProcessInputs); // データを保存
            } catch (error) {
                console.log(error);
                setRefereedProcessInputsLength(0);
            }
        };

        fetchData();
    }, [value, getRefereedProcessInputs]);

    const openModal = () => setIsModalOpen(true);
    const closeModal = () => setIsModalOpen(false);

    const navigate = useNavigate();
    const hundleUpperProcess = async (upper_output_id: number) => {
        const isConfirmed = window.confirm(t('上流プロセスへ移動します。よろしいですか？'));
        if (isConfirmed) {
            setIsModalOpen(false);
            window.location.href = `/process/process_view/${upper_output_id}`;
        }
    }

    return (
        <>
            <SelectButton type='button' onClick={openModal}>
                <ButtonInner>
                    {/* <SelectButtonIcon></SelectButtonIcon> */}
                    {refereedProcessInputsLength}
                </ButtonInner>
            </SelectButton>

            <Modal isOpen={isModalOpen} onClose={closeModal}>
                <Inner>
                    <SectionTableWrap>
                        <Table>
                            <Thead>
                                <Tr>
                                    <Th><ThInner>{t('選択')}</ThInner></Th>
                                    <Th><ThInner>{t('名前')}</ThInner></Th>
                                    <Th><ThInner>{t('方向')}</ThInner></Th>
                                    <Th><ThInner>{t('種別')}</ThInner></Th>
                                    <Th><ThInner>{t('マスターデータ')}</ThInner></Th>
                                </Tr>
                            </Thead>
                            <Tbody>
                                {inputsData.map((input, index) => (
                                    <Tr key={index}>
                                        <Td>
                                            {input.process_type === 'process' &&
                                                <SelectButton type='button' onClick={() => hundleUpperProcess(input.process_id)}>
                                                    <ButtonInner>
                                                        <SelectButtonIcon></SelectButtonIcon>
                                                    </ButtonInner>
                                                </SelectButton>
                                            }
                                        </Td>
                                        <Td>{input.process_name}</Td>
                                        <Td>
                                            {input.direction === 'in' ? t('入力') : t('出力')}
                                        </Td>
                                        <Td>
                                            {input.process_type === 'process' ? t('プロセス') : t('サブシステム')}
                                        </Td>
                                        <Td>
                                            {input.is_database_pack_master ?
                                                <span>
                                                    <CheckButton>
                                                        <CheckButtonIconWrap>
                                                            <CheckButtonIcon></CheckButtonIcon>
                                                        </CheckButtonIconWrap>
                                                    </CheckButton>
                                                </span>
                                                : ''
                                            }
                                        </Td>
                                    </Tr>
                                ))}
                            </Tbody>
                        </Table>
                    </SectionTableWrap>
                </Inner>
            </Modal>
        </>
    );
};

export default RefereedProcessInputsCell;

const Inner = styled.div`
    width: 100%;
    padding: 120px 10%;
    margin: 0 auto;
`

const SectionTableWrap = styled.div`
    overflow-x: auto;    
`;


const CellContent = styled.div`
overflow: hidden;
text-overflow: ellipsis;
white-space: nowrap;
`;

const ButtonInner = styled.div``;

const SelectButton = styled.button`
cursor: pointer;
appearance: none;
background: transparent;
border: none;
border-radius: 0;
font: inherit;
outline: none;
`;

const SelectButtonIcon = styled.span`
mask: url(${iconSelect}) no-repeat center center / contain;
-webkit-mask: url(${iconSelect}) no-repeat center center / contain;
display: block;
width: 24px;
height: 24px;
background: var(--color-txt-primary);
`;

const Table = styled.table`
min-width: 100%;
border-collapse: collapse;
margin-bottom: 8px;
background-color: #fff;
border-spacing: 0;
`

const Thead = styled.thead`
border-right: 1px solid var(--color-line-primary);
border-left: 1px solid var(--color-line-primary);
`

const Tr = styled.tr`
border-top: 1px solid var(--color-line-primary);
border-bottom: 1px solid var(--color-line-primary);
`

const Th = styled.th`
font-size: 13px;
font-weight: 700;
line-height: 1.25;
color: var(--color-site-primary);
padding: 12px;
align-content: center;
overflow: hidden;
text-overflow: ellipsis;
white-space: nowrap;
min-height: 40px;
`

const ThInner = styled.div`
width: fit-content;
display: flex;
align-items: left;
flex-direction: row;
flex-wrap: nowrap;
justify-content: flex-start;
gap: 4px;
white-space: nowrap;
`;

const Tbody = styled.tbody`
border-right: 1px solid var(--color-line-primary);
border-left: 1px solid var(--color-line-primary);
`;

const Td = styled.td`
font-size: 14px;
font-weight: 500;
line-height: 1.25;
padding: 12px;
align-content: center;
overflow: hidden;
text-overflow: ellipsis;
white-space: nowrap;
min-height: 40px;
`;

const IconArrowDown = styled.span`
mask: url(${iconArrowDown}) no-repeat center center / contain;
-webkit-mask: url(${iconArrowDown}) no-repeat center center / contain;
display: block;
width: 100%;
height: 100%;
width: 20px;
height: 20px;
background: var(--color-txt-primary);
`;

const IconArrowUp = styled.span`
mask: url(${iconArrowUp}) no-repeat center center / contain;
-webkit-mask: url(${iconArrowUp}) no-repeat center center / contain;
display: block;
width: 100%;
height: 100%;
width: 20px;
height: 20px;
background: var(--color-txt-primary);
`;

const CheckButton = styled.button`
appearance: none;
background: transparent;
border: none;
border-radius: 0;
font: inherit;
outline: none;
`

const CheckButtonIconWrap = styled.div`
appearance: none;
background: transparent;
border: none;
border-radius: 0;
font: inherit;
outline: none;
`

const CheckButtonIcon = styled.span`
background-color: var(--color-site-secondary);
mask: url(${iconCheck}) no-repeat center center / contain;
-webkit-mask: url(${iconCheck}) no-repeat center center / contain;
display: block;
width: 20px;
height: 20px;
`