import React, { Children } from 'react';
import styled from 'styled-components';
import { Button, ButtonProps as MUIButtonProps } from '@mui/material';
import iconAdd from '@images/product_system/icon_add.svg'

interface ErrorMsgProps {
  children: React.ReactNode;
}

const ErrorMsg: React.FC<ErrorMsgProps> = ({children}) => {
  return (
    <Error>
        {children}
    </Error>
  );
};

export default ErrorMsg;

const Error = styled.span`
    font-size: 14px;
    font-weight: 500;
    color: var(--color-error-primary);
    display: flex;
    align-items: center;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: flex-start;
    gap: 5px;
`