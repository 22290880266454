// AlertDialog.tsx
import React, { useState } from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import styled from 'styled-components';
import ModalButton from '@common/button/ModalButton';
import { useTranslation } from 'react-i18next';

type AlertDialogProps = {
  title: string;
  message: JSX.Element;
  open: boolean;
  onClose: () => void;
  onConfirm: () => void;
};

const CustomButton = styled(Button)`
background-color: var(--color-gray--01);
color: '#ffffff';
&:hover: {
  background-color: var(--color-site-secondary)
}
`

const AlertDialog: React.FC<AlertDialogProps> = ({ title, message, open, onClose, onConfirm }) => {
  const {t} = useTranslation();
  return (
    <Dialog
      open={open}
      onClose={onClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">{title}</DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          {message}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <ModalButton onClick={onClose} text={t('閉じる')}/>
        <ModalButton onClick={onConfirm} text='OK'/>
      </DialogActions>
    </Dialog>
  );
};

export default AlertDialog;
