/**
 * 単位関連の共通処理
 * 
 * @returns 
 */
export const useUnitHooks = () => {

    /**
     * getProductDetailDataとunitConversionsDataをチェックしてfactorを適用
     * 双方向変換を考慮
     * @param inputAmount - 現在の数量
     * @param inputUnitId - 現在の単位ID
     * @param newUnitId - 新しい単位ID
     * @param unitConversionsData - 単位変換データ
     * @param getProductDetailData - 製品詳細データ
     * @returns 新しいamount (変換がない場合は元のamountを返す)
     */
    const applyUnitConversion = (
        inputAmount: number | null | undefined,
        inputUnitId: number | undefined,
        newUnitId: number,
        unitConversionsData: any[] | null,
        getProductDetailData?: any,
    ): number | null | undefined => {
        if (!inputAmount || !inputUnitId || !unitConversionsData) {
            return inputAmount; // 入力値が無効な場合、元の値を返す
        }

        // 製品の場合
        if(getProductDetailData){
            // 双方向で単位変換をチェック
            const directConversion = getProductDetailData.unit_conversions?.find(
                (conversion: any) =>
                    getProductDetailData.unit?.id === inputUnitId &&
                    conversion.to_unit?.id === newUnitId
            );
    
            const reverseConversion = getProductDetailData.unit_conversions?.find(
                (conversion: any) =>
                    getProductDetailData.unit?.id === newUnitId &&
                    conversion.to_unit?.id === inputUnitId
            );
    
            // 直接変換が存在する場合
            if (directConversion) {
                return inputAmount * directConversion.factor;
            }
    
            // 逆方向変換が存在する場合 (逆の係数を適用)
            if (reverseConversion) {
                return inputAmount / reverseConversion.factor;
            }
        }

        // getProductDetailDataで該当する変換がない場合はunitConversionsDataをチェック
        const globalDirectConversion = unitConversionsData?.find(
            (conversion: any) =>
                conversion.from_unit.id === inputUnitId &&
                conversion.to_unit.id === newUnitId
        );

        const globalReverseConversion = unitConversionsData?.find(
            (conversion: any) =>
                conversion.from_unit.id === newUnitId &&
                conversion.to_unit.id === inputUnitId
        );

        // グローバル変換データでチェック
        if (globalDirectConversion) {
            return inputAmount * globalDirectConversion.factor;
        }

        if (globalReverseConversion) {
            return inputAmount / globalReverseConversion.factor;
        }

        return inputAmount; // 該当する変換がない場合、元の値を返す
    };

    return {applyUnitConversion}

};
