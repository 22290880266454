import React, { useState, useCallback, useRef, useEffect } from 'react';
import {
    flexRender,
    getCoreRowModel,
    useReactTable,
    getPaginationRowModel,
    getSortedRowModel,
    ColumnDef,
    SortingState,
} from '@tanstack/react-table';
import { useDrag, useDrop } from 'react-dnd';
import styled, { css } from 'styled-components';
import { useTranslation } from 'react-i18next';

import iconArrowDown from '@images/table/icon_arrow_down.svg'
import iconArrowUp from '@images/table/icon_arrow_up.svg'
import iconArrowLeft from '@images/table/icon_arrow_left.svg';
import iconArrowRight from '@images/table/icon_arrow_right.svg';


interface TableProps {
    count: number;
    columns: ColumnDef<any, any>[];
    data: any[];
    paging_flag: boolean;
}

const initialPageIndex = 0;
const initialPageSize = 10;

interface DragItem {
    index: number;
    id: string;
    type: string;
}

const DraggableHeader: React.FC<{
    header: any;
    index: number;
    moveColumn: (dragIndex: number, hoverIndex: number) => void;
}> = ({ header, index, moveColumn }) => {
    const ref = useRef<HTMLTableCellElement>(null);

    const [, drop] = useDrop({
        accept: 'COLUMN',
        hover: (item: DragItem, monitor) => {
            if (!ref.current) {
                return;
            }
            const dragIndex = item.index;
            const hoverIndex = index;

            if (dragIndex === hoverIndex) {
                return;
            }

            const hoverBoundingRect = ref.current?.getBoundingClientRect();
            const hoverMiddleX = (hoverBoundingRect.right - hoverBoundingRect.left) / 2;
            const clientOffset = monitor.getClientOffset();
            const hoverClientX = clientOffset!.x - hoverBoundingRect.left;

            if (dragIndex < hoverIndex && hoverClientX < hoverMiddleX) {
                return;
            }

            moveColumn(dragIndex, hoverIndex);
            item.index = hoverIndex;
        },
    });

    const [{ isDragging }, drag] = useDrag({
        type: 'COLUMN',
        item: { index },
        collect: (monitor) => ({
            isDragging: monitor.isDragging(),
        }),
    });

    // ドラッグ中のスタイル変更
    React.useEffect(() => {
        if (isDragging) {
            document.body.style.cursor = 'grabbing';
        } else {
            document.body.style.cursor = '';
        }

        // クリーンアップでスタイルをリセット
        return () => {
            document.body.style.cursor = '';
        };
    }, [isDragging]);

    drag(drop(ref));

    return (
        <StyledTh
            ref={ref}
            onClick={header.column.getToggleSortingHandler()}
            style={{
                opacity: isDragging ? 0.5 : 1,
                cursor: isDragging ? 'grabbing' : 'grab',
            }}
        >
            <StyledThInner>
                {flexRender(header.column.columnDef.header, header.getContext())}
                {header.column.getIsSorted() ? (header.column.getIsSorted() === 'desc' ? <IconArrowDown /> : <IconArrowUp />) : ''}
            </StyledThInner>
        </StyledTh>
    );
};

/**
 * 一覧データのテーブル（検索画面には非対応）
 * 
 * @param param0 
 * @returns 
 */
const DataTable: React.FC<TableProps> = ({ count, columns: initialColumns, data, paging_flag }) => {
    const { t } = useTranslation();
    const [columns, setColumns] = useState(initialColumns);
    const [sorting, setSorting] = useState<SortingState>([]);
    const [tableData, setTableData] = useState<any[]>(data)
    useEffect(() =>{
        setTableData(data);
    }, [data])
    useEffect(() =>{
        setColumns(initialColumns);
    }, [columns])

    const moveColumn = useCallback(
        (dragIndex: number, hoverIndex: number) => {
            const newColumns = [...columns];
            const [movedColumn] = newColumns.splice(dragIndex, 1);
            newColumns.splice(hoverIndex, 0, movedColumn);
            setColumns(newColumns);
        },
        [columns]
    );

    const table = useReactTable({
        data: tableData ?? [],
        columns,
        state: { sorting },
        onSortingChange: setSorting,
        initialState: {
            pagination: {
                pageIndex: initialPageIndex,
                pageSize: paging_flag ? initialPageSize : 50,
            },
        },
        getCoreRowModel: getCoreRowModel(),
        getPaginationRowModel: getPaginationRowModel(),
        getSortedRowModel: getSortedRowModel(),
    });

    const renderPageNumbers = () => {
        const totalPages = table.getPageCount();
        const currentPage = table.getState().pagination.pageIndex;

        const range = (start: number, end: number) => {
            return Array.from({ length: end - start + 1 }, (_, i) => i + start);
        };

        // 1〜9ページ目までを表示
        if (totalPages <= 9) {
            return range(0, totalPages - 1);
        }

        // 最初のページと最後のページは常に表示
        const pages = [0];

        // 9ページ目以降の場合、省略しつつ範囲を動的に設定
        if (currentPage >= 9) {
            pages.push(-1); // 省略記号として-1を挿入
            pages.push(...range(currentPage - 1, Math.min(currentPage + 1, totalPages - 2)));
        } else {
            pages.push(...range(1, Math.min(8, totalPages - 2)));
        }

        // 最後のページは常に表示
        pages.push(totalPages - 1);

        return pages.reduce<number[]>((acc, page) => {
            if (acc.length > 0 && page - acc[acc.length - 1] > 1) {
                acc.push(-1); // 省略記号
            }
            acc.push(page);
            return acc;
        }, []);
    };

    // ページネーションの状態が変わった場合に再設定
    if (!paging_flag && table.getState().pagination.pageSize !== 50) {
        table.setPageSize(50);
    }
    const pageSize = table.getState().pagination.pageSize
    return (
        <>
            {paging_flag && (
                <PageNationWrapper>
                    <DisplayCountView>
                        {t('displayRange', { count, pageSize})}
                    </DisplayCountView>
                    <PageOptionArrowButton
                        type='button'
                        onClick={() => {
                            table.previousPage();
                        }}
                        disabled={!table.getCanPreviousPage()}
                    >
                        <ButtonInner>
                            <IconArrowLeft />
                        </ButtonInner>
                    </PageOptionArrowButton>
                    {renderPageNumbers().map((page, index) => {
                        if (page === -1) {
                            return (
                                <span key={index} style={{ margin: '0 8px' }}>
                                    ...
                                </span>
                            );
                        }
                        return (
                            <PageOptionButton
                                type='button'
                                key={page}
                                onClick={() => table.setPageIndex(page)}
                                disabled={table.getState().pagination.pageIndex === page}
                            >
                                <PageOptionInner disabled={table.getState().pagination.pageIndex === page}>
                                    {page + 1}
                                </PageOptionInner>
                            </PageOptionButton>
                        );
                    })}
                    <PageOptionArrowButton
                        type='button'
                        onClick={() => {
                            table.nextPage();
                        }}
                        disabled={!table.getCanNextPage()}
                    >
                        <ButtonInner>
                            <IconArrowRight />
                        </ButtonInner>
                    </PageOptionArrowButton>
                    <div style={{ display: 'inline-block' }}>
                        <DisplayCount>{t('表示件数')}</DisplayCount>
                        <Label>
                            <PageNationViewSelect
                                style={{ margin: '5px' }}
                                value={table.getState().pagination.pageSize}
                                onChange={(e) => {
                                    table.setPageSize(Number(e.target.value));
                                }}
                            >
                                {[10, 20, 30].map((pageSize) => (
                                    <option key={pageSize} value={pageSize}>
                                        {pageSize}
                                    </option>
                                ))}
                            </PageNationViewSelect>
                        </Label>
                    </div>
                </PageNationWrapper>
            )}
            <TableWrapper>
                <StyledTable>
                    <StyledThead>
                        {table.getHeaderGroups().map(headerGroup => (
                            <StyledThTr key={headerGroup.id}>
                                {headerGroup.headers.map((header, index) => (
                                    <DraggableHeader
                                        key={header.id}
                                        header={header}
                                        index={index}
                                        moveColumn={moveColumn}
                                    />
                                ))}
                            </StyledThTr>
                        ))}
                    </StyledThead>
                    <StyledTbody>
                        {table.getRowModel().rows.map(row => (
                            <StyledTr key={row.id} $isEven={row.index % 2 === 0}>
                                {row.getVisibleCells().map(cell => (
                                    <StyledTd key={cell.id} title={String(cell.getValue())}>
                                        <StyledTdText>
                                            {flexRender(cell.column.columnDef.cell, cell.getContext())}
                                        </StyledTdText>
                                    </StyledTd>
                                ))}
                            </StyledTr>
                        ))}
                    </StyledTbody>
                </StyledTable>
            </TableWrapper>
            <div className="h-4" />
        </>
    );
};

export default DataTable;

const TableWrapper = styled.div`
    overflow-x: auto;
`;

const StyledTable = styled.table`
    min-width: 100%;
    border-collapse: collapse;
    margin-bottom: 8px;
    background-color: #fff;
`;

const StyledThead = styled.thead`
border-right: 1px solid var(--color-line-primary);
border-left: 1px solid var(--color-line-primary);
`

const StyledTbody = styled.tbody`
text-align: center;
border-right: 1px solid var(--color-line-primary);
border-left: 1px solid var(--color-line-primary);
`

const StyledTh = styled.th`
    cursor: grab;
    &:active {
        cursor: grabbing;
    }
    font-size: 13px;
    font-weight: 700;
    text-align: center;
    line-height: 1.25;
    color: var(--color-site-primary);
    padding: 12px;
    align-content: center;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    text-align: left;
}
`;

const StyledThInner = styled.div`
    width: fit-content;
    display: flex;
    align-items: center;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: flex-start;
    gap: 4px;
`

const StyledTd = styled.td`
    text-align: left;
    font-size: 14px;
    font-weight: 500;
    line-height: 1.25;
    padding: 12px;
    align-content: center;
    overflow: hidden;
    text-overflow: ellipsis;

    white-space: nowrap;
    overflow: hidden;
    max-width: 150px; /* 幅を調整 */
    &:hover {
        white-space: normal;
        overflow: visible;
        background-color: #f1f1f1;
        z-index: 1;
    }
}
`;

const StyledTdText = styled.div`
overflow: hidden;
text-overflow: ellipsis;
white-space: nowrap;
text-align: left;
`


const StyledThTr = styled.tr`
border-top: 1px solid var(--color-line-primary);
border-bottom: 1px solid var(--color-line-primary);
`

const StyledTr = styled.tr<{ $isEven: boolean }>`
    border-top: 1px solid var(--color-line-primary);
    border-bottom: 1px solid var(--color-line-primary);
    ${(props) => props.$isEven && css`
        background-color: #f9f9f9;
    `}
`;

const PaginationWrapper = styled.div`
    margin: 5px;
`;

const IconArrowDown = styled.span`
mask: url(${iconArrowDown}) no-repeat center center / contain;
-webkit-mask: url(${iconArrowDown}) no-repeat center center / contain;
display: block;
width: 100%;
height: 100%;
width: 20px;
height: 20px;
background: var(--color-txt-primary);
}
`

const IconArrowUp = styled.span`
mask: url(${iconArrowUp}) no-repeat center center / contain;
-webkit-mask: url(${iconArrowUp}) no-repeat center center / contain;
display: block;
width: 100%;
height: 100%;
width: 20px;
height: 20px;
background: var(--color-txt-primary);
}
`
const ButtonInner = styled.div``;

// ページネーション
const IconArrowLeft = styled.span`
mask: url(${iconArrowLeft}) no-repeat center center / contain;
-webkit-mask: url(${iconArrowLeft}) no-repeat center center / contain;
display: block;
width: 100%;
height: 100%;
width: 20px;
height: 20px;
background: var(--color-txt-primary);
`;

const IconArrowRight = styled.span`
mask: url(${iconArrowRight}) no-repeat center center / contain;
-webkit-mask: url(${iconArrowRight}) no-repeat center center / contain;
display: block;
width: 100%;
height: 100%;
width: 20px;
height: 20px;
background: var(--color-txt-primary);
`;

const PageOptionArrowButton = styled.button`
height: 18px;
width: 24px;
cursor: pointer;
text-decoration: none;
color: inherit;
appearance: none;
background: transparent;
border: none;
border-radius: 0;
font: inherit;
outline: none;
`;

const PageOptionButton = styled.button`
cursor: pointer;
width: 34px;
height: 24px;
appearance: none;
background: transparent;
border: none;
border-radius: 0;
font: inherit;
outline: none;
`;

const PageOptionInner = styled.span<{ disabled: boolean }>`
  display: block;
  width: 100%;
  height: 100%;
  font-size: 13px;
  line-height: 20px;
  font-weight: 500;
  border: 1px solid var(--color-line-primary);
  border-radius: 50%;
  text-align: center;
  color: ${({ disabled }) => (disabled ? '#fff' : 'inherit')};
  background-color: ${({ disabled }) => (disabled ? 'var(--color-site-secondary)' : 'transparent')};
  border-color: ${({ disabled }) => (disabled ? 'var(--color-site-secondary)' : 'var(--color-line-primary)')};
`;

const Label = styled.label`
cursor: pointer;
`;

const PageNationViewSelect = styled.select`
padding: 6px 20px 6px 20px;
font-size: 13px;
margin: 8px 0;
position: relative;
cursor: pointer !important;
border: 1px solid var(--color-line-primary);
border-radius: 4px;
background-color: #fff;
font-weight: 400;
line-height: 1.25;
font: inherit;
outline: none;
`;

const PageNationWrapper = styled.div`
gap: 0 24px;
align-items: center;
`;

const DisplayCount = styled.span`
margin-left: 10px;
font-size: 16px;
font-weight: 500;
line-height: 1.25;
`;

const DisplayCountView = styled.span`
font-size: 16px;
font-weight: 500;
line-height: 1.25;
`;
