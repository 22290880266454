// GETリクエストのカスタムフック
import { useState, useEffect } from 'react';
import { getRequest } from '../services/api/api';

export const useGetRequest = <T>(url: string, params?: object) => {
  const [data, setData] = useState<T | null>(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      try {
        const response = await getRequest<T>(url, params);
        setData(response);
      } catch (err:any) {
        console.log("NG", err.message);
        setError(err.message);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, []);
  return { data, loading, error };
};
