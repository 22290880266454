import React, { useState, useCallback, useRef, Dispatch, SetStateAction, useEffect } from 'react';
import styled from 'styled-components';
import {
    flexRender,
    getCoreRowModel,
    useReactTable,
    getPaginationRowModel,
    getSortedRowModel,
    ColumnDef,
    SortingState,
} from '@tanstack/react-table';
import { useDrag, useDrop } from 'react-dnd';

import iconArrowDown from '@images/table/icon_arrow_down.svg';
import iconArrowUp from '@images/table/icon_arrow_up.svg';
import iconEdit from '@images/table/icon_edit.svg';
import DeleteIconButton from '@common/button/DeleteIconButton';
import { useTranslation } from 'react-i18next';
import { useDeleteProcess, useGetUserDetail } from '@hooks/useBackendApi';
import { getCurrentUserInfo } from '@services/Cookies';
import { GetUserDetail } from '@typeList/types';
interface TableProps {
    columns: ColumnDef<any, any>[];
    data: any[];
    setIsModalOpen: Dispatch<SetStateAction<boolean>>;
    userData: GetUserDetail | null;
}

const initialPageIndex = 0;
const initialPageSize = 100;

interface DragItem {
    index: number;
    id: string;
    type: string;
}

const DraggableHeader: React.FC<{
    header: any;
    index: number;
    moveColumn: (dragIndex: number, hoverIndex: number) => void;
}> = ({ header, index, moveColumn }) => {
    const ref = useRef<HTMLTableCellElement>(null);

    const [, drop] = useDrop({
        accept: 'COLUMN',
        hover: (item: DragItem, monitor) => {
            if (!ref.current) {
                return;
            }
            const dragIndex = item.index;
            const hoverIndex = index;

            if (dragIndex === hoverIndex) {
                return;
            }

            const hoverBoundingRect = ref.current?.getBoundingClientRect();
            const hoverMiddleX = (hoverBoundingRect.right - hoverBoundingRect.left) / 2;
            const clientOffset = monitor.getClientOffset();
            const hoverClientX = clientOffset!.x - hoverBoundingRect.left;

            if (dragIndex < hoverIndex && hoverClientX < hoverMiddleX) {
                return;
            }

            moveColumn(dragIndex, hoverIndex);
            item.index = hoverIndex;
        },
    });

    const [{ isDragging }, drag] = useDrag({
        type: 'COLUMN',
        item: { index },
        collect: (monitor) => ({
            isDragging: monitor.isDragging(),
        }),
    });

    drag(drop(ref));

    return (
        <Th
            ref={ref}
            style={{
                opacity: isDragging ? 0.5 : 1,
                cursor: isDragging ? 'grabbing' : 'grab',
            }}
            onClick={header.column.getCanSort() ? header.column.getToggleSortingHandler() : undefined}
        >
            <ThInner>
                {flexRender(header.column.columnDef.header, header.getContext())}
                {header.column.getIsSorted() ? (header.column.getIsSorted() === 'desc' ? <IconArrowDown /> : <IconArrowUp />) : ''}
            </ThInner>
        </Th>
    );
};

const ProsessEditDataTable: React.FC<TableProps> = ({ columns: initialColumns, data, setIsModalOpen, userData }) => {
    const { t } = useTranslation();

    const isAdmin = userData?.is_admin; // ユーザーが管理者かを判定

    // 管理者用のID列の定義
    const adminColumn: ColumnDef<any, any> = {
        id: 'idAdminColumn',
        header: () => t('ID'),
        accessorKey: 'process_info.id', // ソート用のキーを指定
        cell: ({ row }) => {
            return (
                <div>
                    <span>{row.original.process_info.id ? row.original.process_info.id : 'N/A'}</span>
                </div>
            );
        },
        enableSorting: true, // ソート可にする
    };
    const [columns, setColumns] = useState<ColumnDef<any, any>[]>([
        {
            id: 'edit',
            header: () => t('編集'),
            cell: ({ row }) => (
                <EditButton type='button' onClick={() => handleOpenProsessView(row.original.process_info.id)}>
                    <ButtonInner>
                        <EditButtonIcon></EditButtonIcon>
                    </ButtonInner>
                </EditButton>
            ),
            enableSorting: false, // ソート不可にする
        },
        {
            id: 'delete',
            header: () => t('削除'),
            cell: ({ row }) => (
                <DeleteIconButton onClick={() => { handleDelete(row.original.process_info.id) }}></DeleteIconButton>
            ),
            enableSorting: false, // ソート不可にする
        },
        // ...(isAdmin ? [adminColumn] : []), // 管理者の場合のみID列を追加
        ...initialColumns,
    ]);
    // isAdminがtrueのときのみadminColumnを追加
    useEffect(() => {
        if (isAdmin) {
            setColumns((prevColumns) => [
                ...prevColumns.slice(0, 2),  // 編集と削除の後に
                adminColumn,                // adminColumnを追加
                ...prevColumns.slice(2),   // 残りの列（initialColumns）
            ]);
        }
    }, [isAdmin]);
    const [tableData, setTableData] = useState<any[]>(data);
    useEffect(() => {
        setTableData(data);
    }, [data])
    const [sorting, setSorting] = useState<SortingState>([]);

    // プロセス画面への遷移
    const handleOpenProsessView = (id: string) => {
        window.location.href = `/process/process_view/${id}`;
        setIsModalOpen(false); // モーダルを閉じる
    }

    // プロセス削除
    const { deleteProcess } = useDeleteProcess();
    const handleDelete = async (id: number) => {
        const isConfirmed = window.confirm(t('削除しますか？'));
        if (isConfirmed) {
            try {
                await deleteProcess(id);
                setTableData(prevData => prevData.filter(row => row.process_info.id !== id));

                // 開いてる画面ならマイページに戻る
                const pathArray = window.location.pathname.split('/');
                const pageId = parseInt(pathArray[pathArray.length - 1], 10);
                if (id === pageId) {
                    window.location.href = '/';
                }
            } catch (error) {
                alert(error);
            }
        }
    }

    const moveColumn = useCallback(
        (dragIndex: number, hoverIndex: number) => {
            const newColumns = [...columns];
            const [movedColumn] = newColumns.splice(dragIndex, 1);
            newColumns.splice(hoverIndex, 0, movedColumn);
            setColumns(newColumns);
        },
        [columns]
    );

    const table = useReactTable({
        data: tableData ?? [],
        columns,
        state: { sorting },
        onSortingChange: setSorting,
        initialState: {
            pagination: {
                pageIndex: initialPageIndex,
                pageSize: initialPageSize,
            },
        },
        getCoreRowModel: getCoreRowModel(),
        getPaginationRowModel: getPaginationRowModel(),
        getSortedRowModel: getSortedRowModel(),
    });

    return (
        <>
            <SectionTableWrap>
                <Table>

                    <Thead>
                        {table.getHeaderGroups().map((headerGroup) => (
                            <Tr key={headerGroup.id}>
                                {headerGroup.headers.map((header, index) => (
                                    <DraggableHeader
                                        key={header.id}
                                        header={header}
                                        index={index}
                                        moveColumn={moveColumn}
                                    />
                                ))}
                            </Tr>
                        ))}
                    </Thead>
                    <Tbody>
                        {table.getRowModel().rows.map((row) => (
                            <Tr key={row.id} style={{ background: row.index % 2 === 0 ? '#fafafa' : '#fff' }}>
                                {row.getVisibleCells().map((cell) => (
                                    // <Td>
                                    <Td key={cell.id}>
                                        <CellContent key={cell.id}>
                                            {flexRender(cell.column.columnDef.cell, cell.getContext())}
                                        </CellContent>
                                    </Td>
                                ))}
                            </Tr>
                        ))}
                    </Tbody>
                </Table>
            </SectionTableWrap>
        </>
    );
};

export default ProsessEditDataTable;

const SectionTableWrap = styled.div`
    overflow-x: auto;    
`;

const CellContent = styled.div`
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
`;

const EditButton = styled.button`
    cursor: pointer;
    appearance: none;
    background: transparent;
    border: none;
    border-radius: 0;
    font: inherit;
    outline: none;
`;

const ButtonInner = styled.div``;

const EditButtonIcon = styled.span`
    mask: url(${iconEdit}) no-repeat center center / contain;
    -webkit-mask: url(${iconEdit}) no-repeat center center / contain;
    display: block;
    width: 24px;
    height: 24px;
    background: var(--color-txt-primary);
`;

const Table = styled.table`
    min-width: 100%;
    border-collapse: collapse;
    margin-bottom: 8px;
    background-color: #fff;
    border-spacing: 0;
`

const Thead = styled.thead`
    border-right: 1px solid var(--color-line-primary);
    border-left: 1px solid var(--color-line-primary);
`

const Tr = styled.tr`
    border-top: 1px solid var(--color-line-primary);
    border-bottom: 1px solid var(--color-line-primary);
`

const Th = styled.th`
    font-size: 13px;
    font-weight: 700;
    line-height: 1.25;
    color: var(--color-site-primary);
    padding: 12px;
    align-content: center;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    min-height: 40px;
`

const ThInner = styled.div`
    width: fit-content;
    display: flex;
    align-items: center;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: flex-start;
    gap: 4px;
    white-space: nowrap;
`;

const Tbody = styled.tbody`
    border-right: 1px solid var(--color-line-primary);
    border-left: 1px solid var(--color-line-primary);
`;

const Td = styled.td`
    font-size: 14px;
    font-weight: 500;
    line-height: 1.25;
    padding: 12px;
    align-content: center;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    min-height: 40px;
`;

const IconArrowDown = styled.span`
    mask: url(${iconArrowDown}) no-repeat center center / contain;
    -webkit-mask: url(${iconArrowDown}) no-repeat center center / contain;
    display: block;
    width: 100%;
    height: 100%;
    width: 20px;
    height: 20px;
    background: var(--color-txt-primary);
`;

const IconArrowUp = styled.span`
    mask: url(${iconArrowUp}) no-repeat center center / contain;
    -webkit-mask: url(${iconArrowUp}) no-repeat center center / contain;
    display: block;
    width: 100%;
    height: 100%;
    width: 20px;
    height: 20px;
    background: var(--color-txt-primary);
`;
