import React, { useEffect, useState } from 'react';
import ReactApexChart from 'react-apexcharts';
import { ApexOptions } from 'apexcharts';

interface RelatedBreakdownItem {
    groupingName: string;
    amount: number;
    ratio: number;
}
interface ResultItem {
    lcia_model: {
        impact_category: {
            name: string;
        },
        impact_assessment_method: {
            name: string;
        },
        lcia_indicator: {
            name: string;
        }
    };
    amount: number;
    relatedBreakdown: RelatedBreakdownItem[];
}

interface Props {
    data: ResultItem[];
}

const StackedBarChart: React.FC<Props> = ({ data }) => {
    const [categories, setCategories] = useState<Array<string>>([]);
    const [series, setSeries] = useState<ApexOptions['series']>([]);


    useEffect(() => {
        // カテゴリを生成
        const categoriesData = data.map(
            (item, index) =>
                `${item.lcia_model?.impact_category?.name}/${item.lcia_model?.lcia_indicator?.name}`
        );
    
        // シリーズを生成
        const breakdownNames = Array.from(
            new Set(data.flatMap(item => item.relatedBreakdown.map(breakdown => breakdown.groupingName)))
        );

        const seriesData = breakdownNames.map(groupingName => ({
            name: groupingName,
            data: categoriesData.map((category, categoryIndex) => {
                const item = data[categoryIndex];
                const breakdown = item.relatedBreakdown.find(b => b.groupingName === groupingName);
                return breakdown ? breakdown.amount : 0; // データがあればその値、なければ0をセット
            }),
        }));

        setCategories(categoriesData);
        setSeries(seriesData);

    }, [data]);

    const chartHeight = 300 + (categories.length * 50);

    const options: ApexOptions = {
        chart: {
            type: 'bar',
            height: chartHeight,
            stacked: true,
            stackType: '100%'
        },
        plotOptions: {
            bar: {
                horizontal: true
            }
        },
        stroke: {
            width: 1,
            colors: ['#fff']
        },
        // title: {
        //     text: '100% Stacked Bar'
        // },
        xaxis: {
            categories: categories,
            labels: {
                formatter: function (value: number | string) {
                    // value が number であることを確認
                    if (typeof value === 'number') {
                        return `${value.toFixed(2)}`;
                    }
                    return value as string;
                }
            }
        },
        yaxis: {
            labels: {
                maxWidth: 250,  // Y軸ラベルの最大表示幅を指定（必要に応じて調整）
            }
        },
        tooltip: {
            y: {
                formatter: function (val: number) {
                    return `${val.toFixed(2)}`;
                }
            }
        },
        fill: {
            opacity: 1
        },
        legend: {
            position: 'top',
            horizontalAlign: 'left',
            offsetX: 40
        }
    };

    return (
        <div id="chart">
            {categories.length > 0 && series && series.length > 0  &&
                <ReactApexChart options={options} series={series} type="bar" height={chartHeight} />
            }
        </div>
    );
};

export default StackedBarChart;
