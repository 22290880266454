import React, { useContext, useEffect, useState } from 'react';
import Modal from '@common/modal/Modal';
import { SubsystemIOCaseStudyProductOut, UnitGroupList } from '@typeList/types';
import { useGetUnitGroups } from '@hooks/useBackendApi';
import FlowParameterSettingListModal from '@specific/process/flow_parameter_setting/FlowParameterSettingListModal';
import { CaseStudyViewContext } from '@pages/case_study/CaseStudyView';
import { getCurrentUserInfo } from '@services/Cookies';
import styled from 'styled-components';

import iconReload from '@images/button/icon_reload.svg';
import { useTranslation } from 'react-i18next';
import { RequiredSpan } from '@styles/Common';
import { checkFormula } from '@utils/utils';
import { useUnitHooks } from '@hooks/useUnitHook';

interface SubsystemCaseStudyProductCreateModalType {
    currentNodeData: any;
    direction: string;
    isOpen: boolean;
    onClose: () => void;
}

const SubsystemCaseStudyProductCreateModal: React.FC<SubsystemCaseStudyProductCreateModalType> = ({
    currentNodeData,
    direction,
    isOpen,
    onClose
}) => {
    const { t } = useTranslation();
    const context = useContext(CaseStudyViewContext);
    const { unitGroupsData, nodes, setNodes, flowState, setFlowState, unitConversionsData } = context;

    const [isModalOpen, setIsModalOpen] = useState(false);
    const openModal = () => setIsModalOpen(true);
    const closeModal = () => setIsModalOpen(false);

    const [inputProductName, setInputProductName] = useState<string>("");
    const [inputAmount, setInputAmount] = useState<number | null | undefined>(0);
    const [inputAmountFormula, setInputAmountFormula] = useState<string>();
    const [inputPublicComment, setInputPublicComment] = useState<string>("");
    const [inputPrivateComment, setInputPrivateComment] = useState<string>("");
    const [inputUnitId, setInputUnitId] = useState<string>("");
    const [inputUnitName, setInputUnitName] = useState<string>("");
    const [inputUnitCode, setInputUnitCode] = useState<string>("");
    const [inputUnitLocale, setInputUnitLocale] = useState<string>("");
    const [inputUnitPrivateComment, setInputUnitPrivateComment] = useState<string | null>(null);
    const [inputUnitPublicComment, setInputUnitPublicComment] = useState<string | null>(null);
    const [inputCompatibleProductName, setInputCompatibleProductName] = useState<string>("");
    const [inputAmountComment, setInputAmountComment] = useState<string>("");
    const [inputInformationSources, setInputInformationSources] = useState<string>("");
    const [inputCompatibilityLevel, setInputCompatibilityLevel] = useState<number>(1);
    const [unitList, setUnitList] = useState<UnitGroupList[]>([]);

    // エラーチェック
    const [productNameError, setProductNameError] = useState<any>(null);
    const [amountError, setAmountError] = useState<any>(null);
    const [amountFormulaError, setAmountFormulaError] = useState<any>(null);
    const validateCheck = (): boolean => {
        setProductNameError(null);
        setAmountError(null)
        setAmountFormulaError(null)
        if (!inputProductName) {
            setProductNameError(t('製品名は必須です'));
        }
        if (inputAmount === null || inputAmount === undefined) {
            setAmountError(t('流量は必須です'));
        }
        if (!checkFormula(inputAmountFormula)) {
            setAmountFormulaError(t('数式に使えない文字が混ざっています。使える文字は、英数と._+-*/()です'));
        }         
        if (!inputProductName || inputAmount === null || inputAmount === undefined ||  !checkFormula(inputAmountFormula)) {
            console.log("validateCheck false")
            return false;
        }
        return true;
    }

    useEffect(() => {
        if (unitGroupsData && unitGroupsData.length > 0) {
            setUnitList(unitGroupsData);

            // 初期単位
            const defaultUnit = unitGroupsData[0].units.find((unit) => unit.is_default_selected) || unitGroupsData[0].units[0];
            setInputUnitId(String(defaultUnit.id));
            setInputUnitName(defaultUnit.name);
        }
    }, [unitGroupsData]);

    const { applyUnitConversion } = useUnitHooks();

    const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>, key: string) => {
        const value = event.target.value;
        switch (key) {
            case "name":
                setInputProductName(value);
                break;
            case "amount":
                setInputAmount(value === '' ? null : Number(value));
                break;
            case "amount_formula":
                setInputAmountFormula(value);
                break;
            case "compatible_product_name":
                setInputCompatibleProductName(value);
                break;
        }
    }

    const handleInputChangeTextArea = (event: React.ChangeEvent<HTMLTextAreaElement>, key: string) => {
        switch (key) {
            case "public_comment":
                setInputPublicComment(event.target.value);
                break;
            case "private_comment":
                setInputPrivateComment(event.target.value);
                break;
            case "amount_comment":
                setInputAmountComment(event.target.value);
                break;
            case "information_sources":
                setInputInformationSources(event.target.value);
                break;
        }
    }

    const handleInputChangeSelect = (event: React.ChangeEvent<HTMLSelectElement>, key: string) => {
        switch (key) {
            case "unit":
                const selectedValue = event.target.value;
                setInputUnitId(selectedValue);
                for (const group of unitList ?? []) {
                    const selectedUnit = group.units.find(unit => unit.id === Number(selectedValue));
                    if (selectedUnit) {
                        setInputUnitName(selectedUnit.name);
                        setInputUnitCode(selectedUnit.code);
                        setInputUnitLocale(selectedUnit.locale);
                        setInputUnitPrivateComment(selectedUnit.private_comment);
                        setInputUnitPublicComment(selectedUnit.public_comment);
                        break;
                    }
                }

                // 単位換算の適用
                const newAmount = applyUnitConversion(
                    Number(inputAmount),
                    Number(inputUnitId),
                    Number(selectedValue),
                    unitConversionsData,
                );
                setInputAmount(Number(newAmount));

                break;
            case "compatibility_level":
                setInputCompatibilityLevel(Number(event.target.value));
                break
        }
    }

    const handleClickOK = async () => {
        if (!validateCheck()) {
            return
        }
        const targetNode = nodes.find(node => String(node.id) === String(currentNodeData.id));
        const maxId = targetNode ? Math.max(...targetNode.data.subsystem_ios.map((io: any) => io.id), 0) : 0;
        // const maxProcessIoNo = targetNode ? Math.max(...targetNode.data.subsystem_ios.map((io: any) => io.process_io_no), 0) : 0;
        const maxProcessIoNo = Math.max(
            ...flowState.nodes.flatMap(node => node.data.subsystem_ios.map((io: any) => io.process_io_no)),
            0
        );
        // const maxCaseStudyProductNo = targetNode ? Math.max(...targetNode.data.subsystem_ios.map((io: any) => io.exchange.case_study_product_no), 0) : 0;

        const maxCaseStudyProductNo = Math.max(
            ...flowState.nodes.flatMap(node =>
                node.data.subsystem_ios
                    .map((io: any) => io.exchange?.case_study_product_no)
                    .filter((no: number | undefined | null): no is number => no !== undefined && no !== null)
            ),
            0
        );

        const newParam: SubsystemIOCaseStudyProductOut = {
            ...param,
            id: null,
            process_io_no: maxProcessIoNo + 1,
            exchange: {
                ...param.exchange,
                case_study_product_no: maxCaseStudyProductNo + 1
            }
        };

        if (nodes !== undefined) {
            const updatedNodes = nodes.map(node => {
                if (String(node.id) === String(currentNodeData.id)) {
                    return {
                        ...node,
                        data: {
                            ...node.data,
                            subsystem_ios: [...node.data.subsystem_ios, newParam]
                        }
                    }
                }
                return node;
            });
            setNodes(updatedNodes);
        }

        if (flowState.nodes !== undefined) {
            const updatedFlowStateNodes = flowState.nodes.map(node => {
                if (String(node.id) === String(currentNodeData.id)) {
                    return {
                        ...node,
                        data: {
                            ...node.data,
                            subsystem_ios: [...node.data.subsystem_ios, newParam]
                        }
                    }
                }
                return node;
            });

            setFlowState({
                ...flowState,
                nodes: updatedFlowStateNodes
            });
        }
        onClose();
        clearAllStates();
    }

    const param: SubsystemIOCaseStudyProductOut = {
        locale: getCurrentUserInfo()?.default_locale_code || "ja",
        compatible_product_name: inputCompatibleProductName,
        public_comment: inputPublicComment,
        private_comment: inputPrivateComment,
        information_sources: inputInformationSources,
        amount_comment: inputAmountComment,
        process_io_no: 1, // 自動採番
        formula: inputAmountFormula || "",
        amount: inputAmount || 0,
        direction: direction,
        compatibility_level: inputCompatibilityLevel,
        id: 0,　// 自動採番
        global_id: "",
        unit: {
            id: Number(inputUnitId),
            name: inputUnitName || ""
        },
        type: "case_study_product",
        exchange: {
            locale: getCurrentUserInfo()?.default_locale_code || "ja",
            name: inputProductName,
            synonyms_text: "",
            include_items_text: "",
            related_items_text: "",
            public_comment: "",
            private_comment: "",
            id: 0,
            unit_id: 0,
            case_study_product_no: 0　// 自動採番
        },
        upper_subsystem_output_no: 0,
    }

    const clearAllStates = () => {
        const defaultUnit = unitGroupsData && unitGroupsData[0]?.units
            ? unitGroupsData[0].units.find((unit) => unit.is_default_selected) || unitGroupsData[0].units[0]
            : undefined;

        setInputProductName("");
        setInputAmount(0);
        setInputAmountFormula(undefined);
        setInputPublicComment("");
        setInputPrivateComment("");
        setInputUnitId(defaultUnit ? String(defaultUnit.id) : "");
        setInputUnitName(defaultUnit ? defaultUnit.name : "");
        setInputUnitCode("");
        setInputUnitLocale("");
        setInputUnitPrivateComment(null);
        setInputUnitPublicComment(null);
        setInputCompatibleProductName("");
        setInputAmountComment("");
        setInputInformationSources("");
        setInputCompatibilityLevel(1);
    };

    return (
        <div>
            {/* <a onClick={openModal} type='button'>製品（DB未登録）を入力として追加</a> */}
            <Modal isOpen={isOpen} onClose={onClose}>
                <Inner>
                    <Title>{t('製品システム：フローおよび製品の追加（未登録の製品）')}</Title>
                    <Section>
                        <Dl>
                            <DlWrap>
                                <Dt>{t('製品名')}<RequiredSpan>※</RequiredSpan></Dt>
                                <Dd>
                                    <Input
                                        type="text"
                                        onChange={(event) => handleInputChange(event, 'name')}
                                        value={inputProductName ?? ''}
                                    />
                                    {productNameError && <ErrorMsg>{productNameError}</ErrorMsg>}
                                </Dd>
                            </DlWrap>
                            <DlWrap>
                                <Dt>{t('流量の数式')}</Dt>
                                <Dd>
                                    <Input
                                        type="text"
                                        value={inputAmountFormula ?? ""}
                                        onChange={(event) => handleInputChange(event, 'amount_formula')}
                                    />
                                    <FlowParameterSettingListModal inputAmountFormula={inputAmountFormula} setInputAmountFormula={setInputAmountFormula} />
                                </Dd>
                                {amountFormulaError && <><Dd></Dd><ErrorMsg>{amountFormulaError}</ErrorMsg></>}
                            </DlWrap>
                            <DlWrap>
                                <Dt></Dt>
                                <Dd>
                                {t('*数式はケーススタディ保存時に評価され流量に反映されます')}
                                </Dd>
                            </DlWrap>
                            <DlWrap>
                                <Dt>{t('流量')}<RequiredSpan>※</RequiredSpan></Dt>
                                <DdShort>
                                    <AmountInput
                                        type="number"
                                        onChange={(event) => handleInputChange(event, 'amount')}
                                        value={inputAmount !== null && inputAmount !== undefined ? inputAmount : ''}
                                        disabled={!!inputAmountFormula}
                                    />
                                    <UnitSelectLabel>
                                        <UnitSelect
                                            value={inputUnitId ?? ''}
                                            onChange={(event) => handleInputChangeSelect(event, 'unit')}
                                        >
                                            {unitList?.map((units) => (
                                                units.units.map((unit) => (
                                                    <option key={unit.id} value={unit.id}>
                                                        {unit.name}
                                                    </option>
                                                ))
                                            ))}
                                        </UnitSelect>
                                    </UnitSelectLabel>
                                    {amountError && <ErrorMsg>{amountError}</ErrorMsg>}
                                </DdShort>
                            </DlWrap>
                            <DlWrap>
                                <Dt>{t('上流サブシステム')}</Dt>
                                <Dd>
                                    <DisabledInput
                                        type="text"
                                        disabled
                                        value={direction === "out" ? currentNodeData.name: ""}
                                    />
                                </Dd>
                            </DlWrap>
                            <DlWrap>
                                <Dt>{t('下流サブシステム')}</Dt>
                                <Dd>
                                    <DisabledInput
                                        type="text"
                                        disabled
                                        value={direction === "in" ? currentNodeData.name: ""}
                                    />
                                </Dd>
                            </DlWrap>
                        </Dl>
                    </Section>
                    <Section>
                        <Title>{t('適合性')}</Title>
                        <Dl>
                            <DlWrap>
                                <Dt>{t('情報源のフロー名')}</Dt>
                                <Dd>
                                    <FlowNameInput
                                        type="text"
                                        value={inputCompatibleProductName ?? ''}
                                        onChange={(event) => handleInputChange(event, 'compatible_product_name')}
                                    />
                                </Dd>
                            </DlWrap>
                            <DlWrap>
                                <Dt>{t('適合度')}</Dt>
                                <SelectLabel>
                                    <Select
                                        onChange={(event) => handleInputChangeSelect(event, 'compatibility_level')}
                                        value={inputCompatibilityLevel ?? "1"}
                                    >
                                        <option value="1">{t('よい')}</option>
                                        <option value="2">{t('普通')}</option>
                                        <option value="3">{t('悪い')}</option>
                                    </Select>
                                </SelectLabel>
                            </DlWrap>
                        </Dl>
                    </Section>
                    <Section>
                        <Title>{t('コメント')}</Title>
                        <Dl>
                            <DlWrap>
                                <Dt>{t('プライベートコメント')}</Dt>
                                <Dd>
                                    <TextArea
                                        cols={100}
                                        rows={3}
                                        onChange={(event) => handleInputChangeTextArea(event, 'private_comment')}
                                        value={inputPrivateComment ?? ''}
                                    />
                                </Dd>
                            </DlWrap>
                            <DlWrap>
                                <Dt>{t('公開コメント')}</Dt>
                                <Dd>
                                    <TextArea
                                        cols={50}
                                        rows={2}
                                        onChange={(event) => handleInputChangeTextArea(event, 'public_comment')}
                                        value={inputPublicComment ?? ''}
                                    />
                                </Dd>
                            </DlWrap>
                            <DlWrap>
                                <Dt>{t('情報源')}</Dt>
                                <Dd>
                                    <TextArea
                                        cols={50}
                                        rows={2}
                                        onChange={(event) => handleInputChangeTextArea(event, 'information_sources')}
                                        value={inputInformationSources ?? ''}
                                    />
                                </Dd>
                            </DlWrap>
                            <DlWrap>
                                <Dt>{t('流量の計算方法')}</Dt>
                                <Dd>
                                    <TextArea
                                        cols={50}
                                        rows={2}
                                        onChange={(event) => handleInputChangeTextArea(event, 'amount_comment')}
                                        value={inputAmountComment ?? ''}
                                    />
                                </Dd>
                            </DlWrap>
                        </Dl>
                    </Section>
                    <ButtonWrapper>
                        <Button type="button" onClick={handleClickOK}>
                            <ButtonText>{t('追加')}</ButtonText>
                        </Button>
                    </ButtonWrapper>
                </Inner>
            </Modal>
        </div>
    )
}

export default SubsystemCaseStudyProductCreateModal;

const Inner = styled.div`
    width: 100%;
    padding: 120px 10%;
    margin: 0 auto;
`

const Title = styled.h2`
    font-size: 18px;
    padding: 0 0 20px;
    margin-bottom: 20px;
    border-bottom: 1px solid var(--color-line-primary);
    font-size: 16px;
    font-weight: 500;    
    line-height: 1.25;    
`

const Section = styled.div`
    margin-bottom: 60px;
`

const Dl = styled.dl`
`

const DlWrap = styled.div`
margin-bottom: 8px;

display: grid;
align-items: center;
grid-template-columns: 27.0833333333% minmax(0, 1fr);
gap: 6px 40px;
`

const Dt = styled.dt`
margin-bottom: 0;
font-size: 16px;
font-weight: 500;
line-height: 1.25;
`
const Dd = styled.dd`
display: flex;
align-items: center;
flex-direction: row;
flex-wrap: nowrap;
justify-content: flex-start;
gap: 16px;
`
const DdShort = styled.dd`
width: min(100%, 340px);

display: flex;
align-items: center;
flex-direction: row;
flex-wrap: nowrap;
justify-content: flex-start;
gap: 16px;
`

const Input = styled.input`
width: 81.6176470588%;

padding: 12px;
color: var(--color-txt-primary);
border: 1px solid var(--color-line-primary);
border-radius: 4px;
background-color: #fff;
font-size: 16px;
font-weight: 400;
line-height: 1.25;
`

const AmountInput = styled.input<{ disabled?: boolean }>`
width: 50%;

padding: 12px;
color: var(--color-txt-primary);
border: 1px solid var(--color-line-primary);
border-radius: 4px;

background-color: ${(props) => (props.disabled ? 'var(--color-bg-primary)' : '#fff')};
font-size: 16px;
font-weight: 400;
line-height: 1.25;
&:disabled {
    pointer-events: none;
}
`

const FlowNameInput = styled.input`
width: min(100%, 340px);

padding: 12px;
color: var(--color-txt-primary);
border: 1px solid var(--color-line-primary);
border-radius: 4px;
background-color: #fff;
font-size: 16px;
font-weight: 400;
line-height: 1.25;
`

const UnitSelectLabel = styled.label`
width: 50%;
position: relative;
display: block;
`
const UnitSelect = styled.select`
color: rgb(85, 85, 85);

position: relative;
width: 100%;
padding: 10px 32px 10px 12px;
cursor: pointer !important;
border: 1px solid var(--color-line-primary);
border-radius: 4px;
background-color: #fff;
font-size: 16px;
font-weight: 400;
line-height: 1.25;
`

const SelectLabel = styled.label`
width: min(100%, 340px);
position: relative;
display: block;
`

const Select = styled.select`
color: rgb(85, 85, 85);

position: relative;
width: 100%;
padding: 12px 32px 12px 12px;
cursor: pointer !important;
border: 1px solid var(--color-line-primary);
border-radius: 4px;
background-color: #fff;
font-size: 16px;
font-weight: 400;
line-height: 1.25;
`

const DownInput = styled.input`
width: 100%;
background-color: var(--color-bg-primary);

padding: 12px;
color: var(--color-txt-primary);
border: 1px solid var(--color-line-primary);
border-radius: 4px;
font-size: 16px;
font-weight: 400;
line-height: 1.25;
`

const TextArea = styled.textarea`
padding: 12px;
color: var(--color-txt-primary);
border: 1px solid var(--color-line-primary);
border-radius: 4px;
background-color: #fff;
font-size: 16px;
font-weight: 400;
line-height: 1.25;
width: 100%;
`

const ButtonWrapper = styled.div`
margin-top: 20px;
text-align: right;
`
const Button = styled.button`
padding: 8px 24px;
border-radius: 20px;

display: inline-flex;
align-items: center;
flex-direction: row;
flex-wrap: nowrap;
justify-content: center;
cursor: pointer;
white-space: nowrap;
background-color: var(--color-gray--01);
gap: 0 4px;

-moz-appearance: none;
appearance: none;
border: none;
font: inherit;
outline: none;

&:hover {
    background-color: var(--color-site-secondary);
    text-decoration: none !important;

    div span {
        background-color: #fff;
    }
    span {
        color: #fff;
    }
}
`

const ButtonIconWrapper = styled.div`
`
const ButtonIcon = styled.span`
background-color: var(--color-site-secondary);

mask: url(${iconReload}) no-repeat center center / contain;
-webkit-mask: url(${iconReload}) no-repeat center center / contain;
display: block;
width: 100%;
height: 100%;
width: 24px;
height: 24px;
`
const ButtonText = styled.span`
font-size: 12px;
font-weight: 500;
`

const ErrorMsg = styled.span`
    font-size: 14px;
    font-weight: 500;
    color: var(--color-error-primary);
    display: flex;
    align-items: center;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: flex-start;
    gap: 5px;
`;


const DisabledInput = styled.input`
    width: 81.6176470588%;

    padding: 12px;
    color: var(--color-txt-primary);
    border: 1px solid var(--color-line-primary);
    border-radius: 4px;
    background-color: var(--color-bg-primary);
    font-size: 16px;
    font-weight: 400;
    line-height: 1.25;
`

