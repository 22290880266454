import React, { useState, useCallback, useRef, useContext, Dispatch, SetStateAction, useEffect } from 'react';
import styled from 'styled-components';
import {
    flexRender,
    getCoreRowModel,
    useReactTable,
    getPaginationRowModel,
    getSortedRowModel,
    ColumnDef,
    SortingState,
} from '@tanstack/react-table';
import { useDrag, useDrop } from 'react-dnd';
import Modal from '@common/modal/Modal'; 
import SubsystemOutputElementaryFlowCreateModal from '@specific/case_study/subsystem_output_elementary_flow/SubsystemOutputElementaryFlowCreateModal';

import iconArrowDown from '@images/table/icon_arrow_down.svg';
import iconArrowUp from '@images/table/icon_arrow_up.svg';
import iconSelect from '@images/table/icon_arrow_back.svg';
import { useTranslation } from 'react-i18next';

interface TableProps {
    columns: ColumnDef<any, any>[];
    data: any[];
    setIsSearchModalOpen: Dispatch<SetStateAction<boolean>>
    currentNodeData: any;
}

const initialPageIndex = 0;
const initialPageSize = 100;

interface DragItem {
    index: number;
    id: string;
    type: string;
}

const DraggableHeader: React.FC<{
    header: any;
    index: number;
    moveColumn: (dragIndex: number, hoverIndex: number) => void;
}> = ({ header, index, moveColumn }) => {
    const ref = useRef<HTMLTableCellElement>(null);

    const [, drop] = useDrop({
        accept: 'COLUMN',
        hover: (item: DragItem, monitor) => {
            if (!ref.current) {
                return;
            }
            const dragIndex = item.index;
            const hoverIndex = index;

            if (dragIndex === hoverIndex) {
                return;
            }

            const hoverBoundingRect = ref.current?.getBoundingClientRect();
            const hoverMiddleX = (hoverBoundingRect.right - hoverBoundingRect.left) / 2;
            const clientOffset = monitor.getClientOffset();
            const hoverClientX = clientOffset!.x - hoverBoundingRect.left;

            if (dragIndex < hoverIndex && hoverClientX < hoverMiddleX) {
                return;
            }

            moveColumn(dragIndex, hoverIndex);
            item.index = hoverIndex;
        },
    });

    const [{ isDragging }, drag] = useDrag({
        type: 'COLUMN',
        item: { index },
        collect: (monitor) => ({
            isDragging: monitor.isDragging(),
        }),
    });

    drag(drop(ref));

    return (
        <Th
            ref={ref}
            style={{
                opacity: isDragging ? 0.5 : 1,
                cursor: isDragging ? 'grabbing' : 'grab',
            }}
            onClick={header.column.getCanSort() ? header.column.getToggleSortingHandler() : undefined}
        >
            <ThInner>
                {flexRender(header.column.columnDef.header, header.getContext())}
                {header.column.getIsSorted() ? (header.column.getIsSorted() === 'desc' ? <IconArrowDown /> : <IconArrowUp />) : ''}
            </ThInner>
        </Th>
    );
};

const SubsystemOutputElementaryFlowSelectTable: React.FC<TableProps> = ({
    columns: initialColumns, 
    data, 
    setIsSearchModalOpen,
    currentNodeData
}) => {
    const {t} = useTranslation();
    const [columns, setColumns] = useState<ColumnDef<any, any>[]>([
        {
            id: 'select',
            header: () => t('選択'),
            cell: ({ row }) => (
                <div>
                    <SelectButton type='button' onClick={() => handleOpenInputElementaryFlowCreateModal(row.original, row.original.id)}>
                        <ButtonInner>
                            <SelectButtonIcon></SelectButtonIcon>
                        </ButtonInner>
                    </SelectButton>
                </div>
            ),
            enableSorting: false, // ソート不可にする
        },
        ...initialColumns,
    ]);
    const [sorting, setSorting] = useState<SortingState>([]);
    const [tableData, setTableData] = useState<any>(data);
    useEffect(() =>{
        setTableData(data);
    },[data])
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [currentRowId, setCurrentRowId] = useState<string | null>(null);

    const moveColumn = useCallback(
        (dragIndex: number, hoverIndex: number) => {
            const newColumns = [...columns];
            const [movedColumn] = newColumns.splice(dragIndex, 1);
            newColumns.splice(hoverIndex, 0, movedColumn);
            setColumns(newColumns);
        },
        [columns]
    );

    const handleCloseModal = () => {
        setIsModalOpen(false);
        setCurrentRowId(null);
    };

    
    const [isInputElementaryFlowCreateModalOpen, setIsInputElementaryFlowCreateModalOpen] = useState(false);
    const [inputElementaryFlowCreateRowId, setInputElementaryFlowCreateRowId] = useState<string | null>(null);
    const [selectedRowData, setSelectedRowData] = useState<any>(null);

    const handleOpenInputElementaryFlowCreateModal = (row: any, id: string) => {
        // console.log("id", id);
        setSelectedRowData(row);
        setIsInputElementaryFlowCreateModalOpen(true);
        setInputElementaryFlowCreateRowId(id);
    };

    const handleCloseInputElementaryFlowCreateModal = () => {
        // 検索のモーダルは閉じる
        setIsModalOpen(false);
        handleCloseModal();
        setIsInputElementaryFlowCreateModalOpen(false);
        setInputElementaryFlowCreateRowId(null);
        setSelectedRowData(null);
    };


    const table = useReactTable({
        data: tableData ?? [],
        columns,
        state: { sorting },
        onSortingChange: setSorting,
        initialState: {
            pagination: {
                pageIndex: initialPageIndex,
                pageSize: initialPageSize,
            },
        },
        getCoreRowModel: getCoreRowModel(),
        getPaginationRowModel: getPaginationRowModel(),
        getSortedRowModel: getSortedRowModel(),
    });

    return (
        <SectionTableWrap>
            <Modal isOpen={isInputElementaryFlowCreateModalOpen} onClose={handleCloseInputElementaryFlowCreateModal}>
                <SubsystemOutputElementaryFlowCreateModal 
                    data={selectedRowData} 
                    onClose={handleCloseInputElementaryFlowCreateModal} 
                    setIsSearchModalOpen={setIsSearchModalOpen}
                    currentNodeData={currentNodeData}
                />
            </Modal>

            <Table>
                <Thead>
                    {table.getHeaderGroups().map((headerGroup) => (
                        <Tr key={headerGroup.id}>
                            {headerGroup.headers.map((header, index) => (
                                <DraggableHeader
                                    key={header.id}
                                    header={header}
                                    index={index}
                                    moveColumn={moveColumn}
                                />
                            ))}
                        </Tr>
                    ))}
                </Thead>
                <Tbody>
                    {table.getRowModel().rows.map((row) => (
                        <Tr key={row.id} style={{ background: row.index % 2 === 0 ? '#fafafa' : '#fff' }}>
                            {row.getVisibleCells().map((cell) => (
                                <Td key={cell.id} title={String(cell.getValue())}>
                                    <CellContent>
                                        {flexRender(cell.column.columnDef.cell, cell.getContext())}
                                    </CellContent>
                                </Td>
                            ))}
                        </Tr>
                    ))}
                </Tbody>
            </Table>
        </SectionTableWrap>
    );
};

export default SubsystemOutputElementaryFlowSelectTable;

const CellContent = styled.div`
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
`;

const ButtonInner = styled.div`
`;

const SelectButton = styled.button`
    cursor: pointer;
    appearance: none;
    background: transparent;
    border: none;
    border-radius: 0;
    font: inherit;
    outline: none;
`;

const SelectButtonIcon = styled.span`
    mask: url(${iconSelect}) no-repeat center center / contain;
    -webkit-mask: url(${iconSelect}) no-repeat center center / contain;
    display: block;
    width: 24px;
    height: 24px;
    background: var(--color-txt-primary);
`;

const Table = styled.table`
    min-width: 100%;
    border-collapse: collapse;
    margin-bottom: 8px;
    background-color: #fff;
    border-spacing: 0;
` 

const Thead = styled.thead`
    border-right: 1px solid var(--color-line-primary);
    border-left: 1px solid var(--color-line-primary);
` 

const Tr = styled.tr`
    border-top: 1px solid var(--color-line-primary);
    border-bottom: 1px solid var(--color-line-primary);
` 

const Th = styled.th`
    font-size: 13px;
    font-weight: 700;
    line-height: 1.25;
    color: var(--color-site-primary);
    padding: 12px;
    align-content: center;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    min-height: 40px;
` 

const ThInner = styled.div`
    width: fit-content;
    display: flex;
    align-items: center;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: flex-start;
    gap: 4px;
    white-space: nowrap;
`

const Tbody = styled.tbody`
    border-right: 1px solid var(--color-line-primary);
    border-left: 1px solid var(--color-line-primary);
` 
const Td = styled.td`
    font-size: 14px;
    font-weight: 500;
    line-height: 1.25;
    padding: 12px;
    align-content: center;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    min-height: 40px;
`

const IconArrowDown = styled.span`
    mask: url(${iconArrowDown}) no-repeat center center / contain;
    -webkit-mask: url(${iconArrowDown}) no-repeat center center / contain;
    display: block;
    width: 100%;
    height: 100%;
    width: 20px;
    height: 20px;
    background: var(--color-txt-primary);
`

const IconArrowUp = styled.span`
    mask: url(${iconArrowUp}) no-repeat center center / contain;
    -webkit-mask: url(${iconArrowUp}) no-repeat center center / contain;
    display: block;
    width: 100%;
    height: 100%;
    width: 20px;
    height: 20px;
    background: var(--color-txt-primary);
`

const SectionTableWrap = styled.div`
    overflow-x: auto;    
`
