// AlertDialog.tsx
import React, { useState } from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import styled from 'styled-components';
import ModalButton from '@common/button/ModalButton';

type MessageDaialogProps = {
  message: string | JSX.Element;
  open: boolean;
  onClose: () => void;
};

const MessageDaialog: React.FC<MessageDaialogProps> = ({ message, open, onClose }) => {
  return (
    <Dialog
          open={open}
          onClose={onClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
      >
          <DialogContent>
              <DialogContentInner>
                  <DialogContentInnerText>
                      {message}
                  </DialogContentInnerText>
                <DialogActions>
                    <DialogActionsInner>
                        <ModalButton onClick={onClose} text='OK' />
                    </DialogActionsInner>
                </DialogActions>
              </DialogContentInner>
          </DialogContent>
      </Dialog>
  );
};

export default MessageDaialog;

const DialogContentInner = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100px; /* ダイアログの高さに応じて調整 */
    font-size: 16px;
    text-align: center;
    // margin-top: 10px;
    margin:10px 20px;

`

const DialogContentInnerText = styled.span`
    font-size: 16px;

`

const DialogActionsInner = styled.div`
    margin-top: 30px;
`