import React from 'react';
import styled from 'styled-components';
import { ButtonProps as MUIButtonProps } from '@mui/material';

interface CustomButtonProps extends MUIButtonProps {
  onClick: () => void;
  text: string;
}

const StyledButton = styled.button<{ disabled?: boolean }>`
    padding: 8px 24px;
    border-radius: 20px;
    display: inline-flex;
    align-items: center;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: center;
    cursor: pointer;
    white-space: nowrap;
    background-color: var(--color-gray--01);
    gap: 0 4px;    

    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    border: none;
    font: inherit;
    outline: none;

  &:hover {
    background-color: var(--color-site-secondary);
    color: #fff;
  }

  &:disabled {
    color: gray; /* disabled時の文字色を指定 */
  }

  &:disabled:hover {
    background-color: var(--color-gray--01);
  }
`;

const ModalButton: React.FC<CustomButtonProps> = ({ onClick, text, ...rest }) => {
  return (
    <StyledButton type='button' onClick={onClick} {...rest}>
      {text}
    </StyledButton>
  );
};

export default ModalButton;
