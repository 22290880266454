// src/Header.tsx
import React from 'react';
import styled from 'styled-components';
import { getCurrentUserInfo } from '@services/Cookies';
import { useLocation, useNavigate } from 'react-router-dom';
import { useGetUserDetail } from '@hooks/useBackendApi';
import iconMilca from '@images/header/logo.svg'
import { useTranslation } from 'react-i18next';

const Header: React.FC = () => {
  const { t } = useTranslation();
  const location = useLocation();

  const navigate = useNavigate();
  const id = getCurrentUserInfo()?.id;
  const {
    userData,
  } = useGetUserDetail(id);

  const handleClick = () => {
    // navigate("/");
    window.location.href = `/`;
  }

  return (
    <FixedHeader>
      <H1>
        <LogoLink onClick={handleClick}>
          <img src={iconMilca} alt="" />
        </LogoLink>
      </H1>
      <HeaderMypage>
        <HeaderMypageLink onClick={handleClick}>{t('マイページ')}</HeaderMypageLink>
      </HeaderMypage>
      <HeaderUser>
        {userData &&
          <HeaderUserName>
            {userData.username} {t('様')}
          </HeaderUserName>
        }
      </HeaderUser>
    </FixedHeader>
  );
};

export default Header;

const FixedHeader = styled.header`
    position: fixed;
    z-index: 500;
    top: 0;
    left: 0;
    width: 100%;
    height: 90px;
    border-bottom: 10px solid var(--color-site-primary);
    background-color: #fff;
    display: flex;
    align-items: center;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
`;

const H1 = styled.h1`
margin-top: 0;
margin-right: auto;
`;
const LogoLink = styled.a`
display: block;
padding: 20px 65px;
`

const HeaderMypage = styled.p`
  display: flex;
  align-items: center;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  font-size: 12px;
  height: 80px;
  padding: 0 16px;
  text-align: center;
  margin: 0;
`

const HeaderMypageLink = styled.a`
  color: var(--color-txt-secondary);
  font-weight: 500;
  line-height: 1;
`

const HeaderUser = styled.div`
  display: flex;
  align-items: center;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  height: 85px;
  padding: 0 12px;
  text-align: center;
  background: var(--color-site-primary);
`

const HeaderUserName = styled.p`
  color: #fff;
  font-size: 13px;
  font-weight: 500;
  line-height: 1;
`