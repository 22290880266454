import React, { useContext, useState } from 'react'
import Modal from '@common/modal/Modal'
import styled, { css } from 'styled-components';
import ProductCreateModal from '../product_create/ProductCreateModal';
import { ProcessViewContext } from '@pages/process/ProcessView';
import ProcessAllocationProductSearch from '../product_search/process_allocation/ProcessAllocationProductSearch';
import ProductTextTreeCategories from '../product_search/ProductTextTreeCategories';
import { useTranslation } from 'react-i18next';

interface ProcessAllocationProductSearchModalProps {
  isOpen: boolean;
  onClose: () => void;
  onSelectProduct: (product: { id: number, name: string }) => void;
}

/**
 * プロセス　配分：代替の製品検索
 * @returns 
 */
const ProcessAllocationProductSearchModal: React.FC<ProcessAllocationProductSearchModalProps> = ({ isOpen, onClose, onSelectProduct }) => {
  const {t} = useTranslation();
  const { databasePacks } = useContext(ProcessViewContext)
  const [isModalOpen, setIsModalOpen] = useState(false);
  const openModal = () => setIsModalOpen(true);
  const closeModal = () => setIsModalOpen(false);

  // 選択されたタブを管理するステート
  const [activeTab, setActiveTab] = useState<string>('tab1');

  const handleTabClick = (tab: string) => {
    setActiveTab(tab);
  };

  return (
    <>
      <Button onClick={openModal} type='button'>
        <ButtonInner>
          {t('選択')}
        </ButtonInner>
      </Button>
      <Modal isOpen={isModalOpen} onClose={closeModal}>
        <Inner>
          <SectionResult>
            <SectionResultTitle>{t('検索結果')}</SectionResultTitle>
            <ProductCreateModal selectted_database_pack_id={databasePacks} />
          </SectionResult>
          <div>
            {/* タブのボタン */}
            <TabButton onClick={() => handleTabClick('tab1')} isActive={activeTab === 'tab1'}>
            {t('検索して選ぶ')}
            </TabButton>
            <TabButton onClick={() => handleTabClick('tab2')} isActive={activeTab === 'tab2'}>
            {t('カテゴリーツリーから選ぶ')}
            </TabButton>

            {/* タブの内容 */}
            {activeTab === 'tab1' &&
              <TabPanel>
                <ProcessAllocationProductSearch
                  selectted_database_pack_id={1}
                  setIsSearchModalOpen={setIsModalOpen}
                  onSelectProduct={onSelectProduct}
                />
              </TabPanel>
            }
            {activeTab === 'tab2' &&
              <TabPanel>
                <ProductTextTreeCategories
                  type='select'
                  modal_type={"allocation"}
                  setIsSearchModalOpen={setIsModalOpen}
                  onSelectProduct={onSelectProduct}
                />
              </TabPanel>
            }
          </div>
        </Inner>

      </Modal>
    </>
  )
}

export default ProcessAllocationProductSearchModal

const TabButton = styled.button.withConfig({
  shouldForwardProp: (prop) => prop !== 'isActive'
}) <{ isActive: boolean }>`
  padding: 5px 15px;
  background-color: #e9ecef;
  color: black;
  border: none;
  margin-right: 10px;
  cursor: pointer;
  border-radius: 20px;

  ${(props) =>
    props.isActive &&
    css`
      background-color: #46A3B3;
      color: white;
    `
  }

  &:hover {
    background-color: #cfd8dc;
    ${(props) =>
    props.isActive &&
    css`
        background-color: #007bff;
      `
  }
  }
`;

const TabPanel = styled.div`
  padding: 20px;
  margin-top: 10px;
`;

const Button = styled.button`
padding: 7px 16px;
border-radius: 20px;
display: inline-flex;
align-items: center;
flex-direction: row;
flex-wrap: nowrap;
justify-content: center;
cursor: pointer;
white-space: nowrap;
background-color: var(--color-gray--01);
gap: 0 4px;

appearance: none;
border: none;
font: inherit;
outline: none;
`
const ButtonInner = styled.span`
font: inherit;
outline: none;
`

const SectionResult = styled.div`
    padding: 0 0 20px;
    margin-bottom: 20px;
    border-bottom: 1px solid var(--color-line-primary);
    display: flex;
    justify-content: space-between;
`

const SectionResultTitle = styled.h3`
    font-size: 18px;
    font-weight: 500;
`

const Inner = styled.div`
  width: 100%;
  padding: 120px 10%;
  margin: 0 auto;
`
