import React, { useState, useCallback, useRef, useEffect } from 'react';
import {
    flexRender,
    getCoreRowModel,
    useReactTable,
    getPaginationRowModel,
    getSortedRowModel,
    ColumnDef,
    SortingState,
    createColumnHelper,
} from '@tanstack/react-table';
import { useDrag, useDrop } from 'react-dnd';
import styled, { css } from 'styled-components';
import iconArrowDown from '@images/table/icon_arrow_down.svg';
import iconArrowUp from '@images/table/icon_arrow_up.svg';
import { useTranslation } from 'react-i18next';
import { use } from 'i18next';

interface TableProps {
    columns: ColumnDef<any, any>[];
    resultData: any[];
    paging_flag: boolean;
    exportSet: (columns: any, data: any) => void;
}

const initialPageIndex = 0;
const initialPageSize = 100000;

interface DragItem {
    index: number;
    id: string;
    type: string;
}

const DraggableHeader: React.FC<{
    header: any;
    index: number;
    moveColumn: (dragIndex: number, hoverIndex: number) => void;
}> = ({ header, index, moveColumn }) => {
    const ref = useRef<HTMLTableCellElement>(null);

    const [, drop] = useDrop({
        accept: 'COLUMN',
        hover: (item: DragItem, monitor) => {
            if (!ref.current) {
                return;
            }
            const dragIndex = item.index;
            const hoverIndex = index;

            if (dragIndex === hoverIndex) {
                return;
            }

            const hoverBoundingRect = ref.current?.getBoundingClientRect();
            const hoverMiddleX = (hoverBoundingRect.right - hoverBoundingRect.left) / 2;
            const clientOffset = monitor.getClientOffset();
            const hoverClientX = clientOffset!.x - hoverBoundingRect.left;

            if (dragIndex < hoverIndex && hoverClientX < hoverMiddleX) {
                return;
            }

            moveColumn(dragIndex, hoverIndex);
            item.index = hoverIndex;
        },
    });

    const [{ isDragging }, drag] = useDrag({
        type: 'COLUMN',
        item: { index },
        collect: (monitor) => ({
            isDragging: monitor.isDragging(),
        }),
    });

    drag(drop(ref));

    return (
        <StyledTh ref={ref} onClick={header.column.getToggleSortingHandler()}>
            <StyledThInner>
                {flexRender(header.column.columnDef.header, header.getContext())}
                {header.column.getIsSorted() ? (header.column.getIsSorted() === 'desc' ? <IconArrowDown /> : <IconArrowUp />) : ''}
            </StyledThInner>
        </StyledTh>
    );
};

const FilterComponent: React.FC<{ filterValue: string; setFilterValue: (value: string) => void }> = ( { filterValue, setFilterValue }) => {
    const { t } = useTranslation();
    return (
        <Wrap>
            <Label>{t('入出力の絞り込み')}</Label>
            <SelectLabel>
                <Select
                    value={filterValue}
                    onChange={(e) => setFilterValue(e.target.value)}
                >
                    <option value="">{t('全て')}</option>
                    <option value="in">{t('入力')}</option>
                    <option value="out">{t('出力')}</option>
                </Select>
            </SelectLabel>
        </Wrap>
    );
};

const CaseStudyCalculateResultTable: React.FC<TableProps> = ({ columns: initialColumns,  resultData, paging_flag, exportSet }) => {
    const { t } = useTranslation();
    const [filterValue, setFilterValue] = useState<string>('');
    const [filteredData, setFilteredData] = useState<any[]>(resultData);
    const [columns, setColumns] = useState(initialColumns);
    useEffect(() =>{
        setColumns(initialColumns);
    },[initialColumns])
    const [sorting, setSorting] = useState<SortingState>([]);

    useEffect(() => {
        if (filterValue) {
            setFilteredData(resultData.filter(item => item.direction === filterValue));
        } else {
            setFilteredData(resultData);
        }
    }, [resultData, filterValue]);

    // エクスポート用にセット
    useEffect(() =>{
        exportSet(columns, filteredData);
    },[columns, filteredData])

    const moveColumn = useCallback(
        (dragIndex: number, hoverIndex: number) => {
            const newColumns = [...columns];
            const [movedColumn] = newColumns.splice(dragIndex, 1);
            newColumns.splice(hoverIndex, 0, movedColumn);
            setColumns(newColumns);
        },
        [columns]
    );

    const table = useReactTable({
        data: filteredData ?? [],
        columns,
        state: { sorting },
        onSortingChange: setSorting,
        initialState: {
            pagination: {
                pageIndex: initialPageIndex,
                pageSize: initialPageSize,
            },
        },
        getCoreRowModel: getCoreRowModel(),
        getPaginationRowModel: getPaginationRowModel(),
        getSortedRowModel: getSortedRowModel(),
    });

    return (
        <TableWrapper>
            {paging_flag && (
                <PaginationWrapper>
                    <select
                        style={{ margin: '5px' }}
                        value={table.getState().pagination.pageSize}
                        onChange={(e) => {
                            table.setPageSize(Number(e.target.value));
                        }}
                    >
                        {[10, 20, 30].map((pageSize) => (
                            <option key={pageSize} value={pageSize}>
                                {t('表示数')} {pageSize}
                            </option>
                        ))}
                    </select>
                    <button onClick={() => table.previousPage()} disabled={!table.getCanPreviousPage()}>
                        {'<'}
                    </button>
                    {table.getPageOptions().map((page) => (
                        <button
                            key={page}
                            onClick={() => table.setPageIndex(page)}
                            disabled={table.getState().pagination.pageIndex === page}
                        >
                            {page + 1}
                        </button>
                    ))}
                    <button onClick={() => table.nextPage()} disabled={!table.getCanNextPage()}>
                        {'>'}
                    </button>
                </PaginationWrapper>
            )}
            <FilterComponent filterValue={filterValue} setFilterValue={setFilterValue} />
            <StyledTable>
                <StyledThead>
                    {table.getHeaderGroups().map(headerGroup => (
                        <StyledThTr key={headerGroup.id}>
                            {headerGroup.headers.map((header, index) => (
                                <DraggableHeader
                                    key={header.id}
                                    header={header}
                                    index={index}
                                    moveColumn={moveColumn}
                                />
                            ))}
                        </StyledThTr>
                    ))}
                </StyledThead>
                <StyledTbody>
                    {table.getRowModel().rows.map(row => (
                        <StyledTr key={row.id} $isEven={row.index % 2 === 0}>
                            {row.getVisibleCells().map(cell => (
                                <StyledTd key={cell.id} title={String(cell.getValue())}>
                                    <StyledTdText>
                                        {flexRender(cell.column.columnDef.cell, cell.getContext())}
                                    </StyledTdText>
                                </StyledTd>
                            ))}
                        </StyledTr>
                    ))}
                </StyledTbody>
            </StyledTable>
            <div className="h-4" />
        </TableWrapper>
    );
};

export default CaseStudyCalculateResultTable;

const TableWrapper = styled.div`
    overflow-x: auto;
`;

const StyledTable = styled.table`
    min-width: 100%;
    border-collapse: collapse;
    margin-bottom: 8px;
    background-color: #fff;
`;

const StyledThead = styled.thead`
border-right: 1px solid var(--color-line-primary);
border-left: 1px solid var(--color-line-primary);
`

const StyledTbody = styled.tbody`
text-align: center;
border-right: 1px solid var(--color-line-primary);
border-left: 1px solid var(--color-line-primary);
`

const StyledTh = styled.th`
    cursor: grab;

    &:active {
    cursor: grabbing;
    }
    font-size: 13px;
    font-weight: 700;
    text-align: center;
    line-height: 1.25;
    color: var(--color-site-primary);
    padding: 12px;
    align-content: center;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}
`;

const StyledThInner = styled.div`
    margin: auto;
    display: flex;
    align-items: center;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: flex-start;
    gap: 4px;
`

const StyledTd = styled.td`
    font-size: 14px;
    font-weight: 500;
    line-height: 1.25;
    padding: 12px;
    align-content: center;
    overflow: hidden;
    text-overflow: ellipsis;

    white-space: nowrap;
    overflow: hidden;
    max-width: 150px; /* 幅を調整 */
    &:hover {
        white-space: normal;
        overflow: visible;
        background-color: #f1f1f1;
        z-index: 1;
    }
}
`;

const StyledTdText = styled.div`
overflow: hidden;
text-overflow: ellipsis;
white-space: nowrap;
text-align: left;
`


const StyledThTr = styled.tr`
border-top: 1px solid var(--color-line-primary);
border-bottom: 1px solid var(--color-line-primary);
`

const StyledTr = styled.tr<{ $isEven: boolean }>`
    border-top: 1px solid var(--color-line-primary);
    border-bottom: 1px solid var(--color-line-primary);
    ${(props) => props.$isEven && css`
        background-color: #f9f9f9;
    `}
`;

const PaginationWrapper = styled.div`
    margin: 5px;
`;

const IconArrowDown = styled.span`
mask: url(${iconArrowDown}) no-repeat center center / contain;
-webkit-mask: url(${iconArrowDown}) no-repeat center center / contain;
display: block;
width: 100%;
height: 100%;
width: 20px;
height: 20px;
background: var(--color-txt-primary);
}
`

const IconArrowUp = styled.span`
mask: url(${iconArrowUp}) no-repeat center center / contain;
-webkit-mask: url(${iconArrowUp}) no-repeat center center / contain;
display: block;
width: 100%;
height: 100%;
width: 20px;
height: 20px;
background: var(--color-txt-primary);
}
`

const SelectLabel = styled.label`
margin-left: 40px;
width: min(100%, 340px);
`
const Select = styled.select`
width: 20%;
padding: 12px 32px 12px 12px;
cursor: pointer !important;
border: 1px solid var(--color-line-primary);
border-radius: 4px;
background-color: #fff;
font-size: 16px;
font-weight: 400;
line-height: 1.25;
`

const Label = styled.label`
padding: 0 0 20px;
margin-bottom: 20px;
// border-bottom: 1px solid var(--color-line-primary);
font-size: 16px;
font-weight: 500;
line-height: 1.25;
`

const Wrap = styled.div`
margin-bottom: 20px;
`