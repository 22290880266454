import React, { useContext, useEffect } from 'react';
import { createColumnHelper } from '@tanstack/react-table';
import { ProcessParametersType } from '@typeList/types';
import { useTranslation } from 'react-i18next';
import { ProcessViewContext } from '@pages/process/ProcessView';
import ProsessParameterSettingTable from '@common/table/ProsessParameterSettingTable';
import { arraysEqual } from '@utils/utils';
import styled from 'styled-components';
import { FadeLoader } from 'react-spinners';

interface Parameter extends ProcessParametersType {
    division: string | null;
}

/**
 * 056 プロセス閲覧画面　パラメーター設定タブ
 * @param param0 
 * @returns 
 */
const ProcessParameterSetting: React.FC = () => {
    const { t } = useTranslation();
    const context = useContext(ProcessViewContext);
    const { processParameters, setProcessParameters, updateProcessParameters, setUpdateProcessParameters, setchangeCheckParameter } = context;
    useEffect(() => {
        if (
            JSON.stringify(processParameters) !== JSON.stringify(updateProcessParameters)
            // !arraysEqual(processParameters, updateProcessParameters)
        ) {
            setchangeCheckParameter(true);
        }
    }, [updateProcessParameters])
    const handleTableDataChange = (parameter: Parameter[]) => {
        if (parameter) {
            const updatedParameters = processParameters ? [...processParameters, ...parameter] : [...parameter];
            setUpdateProcessParameters(parameter);
        }
    };

    const columnHelper = createColumnHelper<Parameter[]>();
    const columns = [
        columnHelper.accessor('name', {
            header: () => t('名前'),
            cell: info => info.renderValue(),
            meta: { editable: true },
        }),
        columnHelper.accessor('division', {
            header: () => t('区分'),
            cell: info => t('プロセス'),
            meta: { editable: false, type: "text" },
        }),
        columnHelper.accessor('value', {
            header: () => t('値'),
            cell: info => info.renderValue(),
            meta: { editable: true, type: "number" },
        }),
    ];

    if (!context) {
        return (
            <FadeLoader
                color="#48bdbb"
                height={10}
                radius={2}
                width={5}
            />
        );
    }

    return (
        <div className="app">
            <>
                <SectionTitle>{t('パラメーター設定')}</SectionTitle>
                {updateProcessParameters &&
                    <>
                        <ProsessParameterSettingTable columns={columns} data={updateProcessParameters} paging_flag={false} onDataChange={handleTableDataChange} />
                    </>
                }
            </>
        </div>
    );
};

export default ProcessParameterSetting;

const SectionTitle = styled.h2`
padding: 0 0 20px;
margin-bottom: 20px;
border-bottom: 1px solid var(--color-line-primary);
font-size: 16px;
font-weight: 500

line-height: 1.25;
`