import React, { useState, useCallback, useEffect, useContext, useMemo, useRef } from 'react';
import ReactFlow, {
    addEdge,
    Controls,
    Background,
    BackgroundVariant,
    Node,
    Edge,
    useReactFlow,
    ReactFlowProvider,
    applyNodeChanges,
    applyEdgeChanges,
    NodeChange,
    EdgeChange,
    NodeTypes,
    MarkerType,
    ControlButton,
} from 'reactflow';
import 'reactflow/dist/style.css';
import ContextMenu, { ContextMenuState } from '../product_system/ContextMenu';
import { CaseStudyViewContext } from '@pages/case_study/CaseStudyView';
import CustomNodeComponent from '../product_system/CustomNodeComponent';
import SubsystemCreate from '../product_system/SubsystemCreate';
import DownloadSvgAndPngButton from '../product_system/DownloadSvgAndPngButton';
import styled from 'styled-components';
import iconUndo from '@images/product_system/icon_undo.svg'
import iconRedo from '@images/product_system/icon_redo.svg'
import DownloadContextMenu from '../product_system/DownloadContextMenu';
import { useTranslation } from 'react-i18next';

interface FlowState {
    nodes: Node[];
    edges: Edge[];
}

const ProductSystem: React.FC = () => {
    const {t} =useTranslation();
    const context = useContext(CaseStudyViewContext);
    const {
        caseStudyData,
        nodes: initialNodes,
        edges: initialEdges,
        setNodes,
        setEdges,
        flowState,
        setFlowState,
        undo,
        canUndo,
        redo,
        canRedo,
        subsystemCategorySetData,
        selectCategorySetList,
        selectCategorySet,
        categorySetRows,
        setSelectCategorySet
    } = context;
    const [contextMenu, setContextMenu] = useState<ContextMenuState>({ x: 0, y: 0, visible: false, items: [] });
    const [downloadContextMenu, setDownloadContextMenu] = useState<ContextMenuState>({ x: 0, y: 0, visible: false, items: [] });
    const [showDialog, setShowDialog] = useState(false);
    const [dialogPosition, setDialogPosition] = useState({ x: 0, y: 0 });
    const reactFlowInstance = useReactFlow();
    const controlButtonRef = useRef<HTMLDivElement>(null);
    const [dropdownVisible, setDropdownVisible] = useState(false);

    // useEffect(() => {
    //     console.log("ProductSystem flowState", flowState);
    // }, [flowState]);

    // カスタムノードを適用    
    const nodeTypes: NodeTypes = useMemo(() => ({
        SUBSYSTEM: CustomNodeComponent,
    }), []);

    // undo、redo用の変更監視
    const updateFlowState = useCallback((updater: (prev: FlowState) => FlowState) => {
        setFlowState((prev) => {
            const newState = updater(prev);
            return { ...newState };
        });
    }, [setFlowState]);

    // エッジの接続
    const onConnect = useCallback(
        (params: any) => {
            updateFlowState((prev) => {
                const newEdges = addEdge({
                    ...params,
                    style: { stroke: '#2b2b2b', strokeWidth: 4 },
                    markerEnd: {
                        type: MarkerType.ArrowClosed,
                        color: '#2b2b2b',
                    }
                }, prev.edges);
                return {
                    ...prev,
                    edges: newEdges,
                };
            });
        },
        [updateFlowState],
    );

    // nodeの変更(ドラッグ中の変更未監視)
    const onNodesChange = useCallback(
        (changes: NodeChange[]) => {
            const newNodes = applyNodeChanges(changes, flowState.nodes);
            setNodes(newNodes);

            const hasNonPositionChange = changes.some(change => change.type !== 'position');

            if (hasNonPositionChange) {
                setFlowState((prev) => ({
                    ...prev,
                    nodes: newNodes,
                }));
            } else {
                flowState.nodes = newNodes;
            }
        },
        [flowState.nodes, setFlowState, setNodes]
    );

    // エッジの変更
    const onEdgesChange = useCallback(
        (changes: EdgeChange[]) => {
            setFlowState((prev) => {
                const newEdges = applyEdgeChanges(changes, prev.edges);
                setEdges(newEdges);
                return {
                    ...prev,
                    edges: newEdges,
                };
            });
        },
        [setFlowState, setEdges]
    );

    // ノードのドロップ位置を記憶
    const onNodeDragStop = useCallback(
        (event: React.MouseEvent, node: Node) => {
            updateFlowState((prev) => {
                const updatedNodes = prev.nodes.map((n) => (n.id === node.id ? node : n));
                setNodes(updatedNodes);
                return { ...prev, nodes: updatedNodes };
            });
        },
        [updateFlowState, setNodes]
    );

    // ノード削除の監視
    const onNodesDelete = useCallback(
        (nodesToDelete: Node[]) => {
            updateFlowState((prev) => {
                const updatedNodes = prev.nodes.filter((node) => !nodesToDelete.some((deletedNode) => deletedNode.id === node.id));
                return {
                    ...prev,
                    nodes: updatedNodes,
                };
            });
        },
        [updateFlowState]
    );

    // 新規ノード追加
    const addNode = useCallback((
        name: string,
        sampling_procedure: string,
        criteria_for_excluding_intermediate_flow: string,
        x: number, y: number,
        borderBottomColor: string,
        selectedCategoryIds: Array<number>,
        publicComment: string,
        privateComment: string,
    ) => {
        const maxId = flowState.nodes ? Math.max(...flowState.nodes.map((node: any) => node.id), 0) : 0;
        const position = { x, y };
        const newNode: Node = {
            id: (maxId + 1).toString(),
            position,
            data: {
                name,
                criteria_for_excluding_intermediate_flow: criteria_for_excluding_intermediate_flow,
                id: (maxId + 1).toString(),
                // id: null,
                locale: caseStudyData?.locale,
                private_comment: privateComment,
                public_comment: publicComment,
                sampling_procedure: sampling_procedure,
                subsystem_category_ids: selectedCategoryIds,
                subsystem_ios: [],
                subsystem_no: maxId + 1,
                borderBottomColor,
            },
            type: 'SUBSYSTEM',
            width: 212,
            height: 165,
        };
        updateFlowState((prev) => ({
            ...prev,
            nodes: prev.nodes.concat(newNode),
        }));
        setNodes((prevNodes) => prevNodes.concat(newNode));
    }, [flowState.nodes, updateFlowState, setNodes]);

    // 新規追加用のコンテキストメニュー
    const onPaneContextMenu = useCallback((event: React.MouseEvent) => {
        event.preventDefault();
        const { clientX, clientY } = event;
        const flowPosition = reactFlowInstance.screenToFlowPosition({ x: clientX, y: clientY });

        setContextMenu({
            x: clientX,
            y: clientY,
            visible: true,
            items: [
                {
                    label: t('新規サブシステムを作成'), onClick: () => {
                        setDialogPosition(flowPosition);
                        setShowDialog(true);
                    }
                }
            ]
        });
    }, [reactFlowInstance]);

    // コンテキストメニューを閉じる
    const closeContextMenu = useCallback(() => {
        setContextMenu({ visible: false, x: 0, y: 0, items: [] });
    }, []);
    // コンテキストメニューを閉じる
    const closeDownloadContextMenu = useCallback(() => {
        setDownloadContextMenu({ visible: false, x: 0, y: 0, items: [] });
    }, []);

    // ノードの作成
    const handleCreateNode = useCallback((
        name: string, 
        sampling_procedure: string, 
        criteria_for_excluding_intermediate_flow: string, 
        selectedCategoryColor: string,
        selectedCategoryIds: Array<number>,
        publicComment: string,
        privateComment: string,
    ) => {
        addNode(
            name, 
            sampling_procedure, 
            criteria_for_excluding_intermediate_flow, 
            dialogPosition.x, 
            dialogPosition.y, 
            selectedCategoryColor, 
            selectedCategoryIds,
            publicComment,
            privateComment,
        );
        setShowDialog(false);
    }, [addNode, dialogPosition]);

    useEffect(() => {
        const handleClickOutside = (event: MouseEvent) => {
            const target = event.target as HTMLElement;
            if (!target.closest('.context-menu')) {
                closeContextMenu();
            }
        };
        const handleClickDownloadeOutside = (event: MouseEvent) => {
            const target = event.target as HTMLElement;
            if (!target.closest('.react-flow__controls-button')) {
                closeDownloadContextMenu();
            }
        };

        document.addEventListener('click', handleClickOutside);
        document.addEventListener('click', handleClickDownloadeOutside);
        return () => {
            document.removeEventListener('click', handleClickOutside);
            document.removeEventListener('click', handleClickDownloadeOutside);
        };
    }, [closeContextMenu, closeDownloadContextMenu]);

    const handleInputChangeSelect = (event: React.ChangeEvent<HTMLSelectElement>) => {
        const selectedValue = Number(event.target.value);
        setSelectCategorySet(selectedValue); // selectCategorySet を更新
    };

    return (
        <>
            <StyledFlowContainer>
                <ReactFlow
                    nodes={flowState.nodes}
                    edges={flowState.edges}
                    onNodesChange={onNodesChange}
                    onEdgesChange={onEdgesChange}
                    onNodesDelete={onNodesDelete}
                    onNodeDragStop={onNodeDragStop}
                    onConnect={onConnect}
                    onPaneContextMenu={onPaneContextMenu}
                    nodeTypes={nodeTypes}
                >
                    <Controls />
                    <Background variant={BackgroundVariant.Cross} gap={12} size={1} />
                    <div style={{ position: 'absolute', top: 10, left: 10, zIndex: 4, display: 'flex', flexDirection: 'row', gap: '10px' }}>
                        <CustumControlButton onClick={() => { undo(); }} disabled={!canUndo}>
                            <UndoDiv>
                                <UndoSpan></UndoSpan>
                            </UndoDiv>
                        </CustumControlButton>
                        <CustumControlButton onClick={() => { redo(); }} disabled={!canRedo}>
                            <RedoDiv>
                                <RedoSpan></RedoSpan>
                            </RedoDiv>
                        </CustumControlButton>
                        <DropdownItem>
                            <SelectLabel>
                                <Select
                                    value={selectCategorySet}
                                    onChange={(event) => {
                                        handleInputChangeSelect(event)
                                    }}
                                >
                                    {subsystemCategorySetData?.map(categorySet => (
                                        <option key={categorySet.id} value={categorySet.id}>
                                            {categorySet.name}
                                        </option>
                                    ))}
                                </Select>
                            </SelectLabel>
                        </DropdownItem>
                    </div>
                    <DownloadSvgAndPngButton setContextMenu={setDownloadContextMenu} />
                </ReactFlow>
                {contextMenu.visible && (
                    <ContextMenu
                        x={contextMenu.x}
                        y={contextMenu.y}
                        visible={contextMenu.visible}
                        items={contextMenu.items}
                        onClose={closeContextMenu}
                    />
                )}
                {downloadContextMenu.visible && (
                    <DownloadContextMenu
                        x={downloadContextMenu.x}
                        y={downloadContextMenu.y}
                        visible={downloadContextMenu.visible}
                        items={downloadContextMenu.items}
                        onClose={closeDownloadContextMenu}
                    />
                )}
                {showDialog && (
                    <SubsystemCreate
                        isOpen={showDialog}
                        onClose={() => setShowDialog(false)}
                        onCreate={handleCreateNode}
                    />
                )}
            </StyledFlowContainer>
        </>
    );
};

const App: React.FC = () => (
    <ReactFlowProvider>
        <ProductSystem />
    </ReactFlowProvider>
);

export default App;

const StyledFlowContainer = styled.div`
    width: 100%;
    height: 40vw;

    .react-flow {
        background: #FFFFFF;
        border: 4px solid #C2C2C2;
        padding: 12px 20px;
    }
    .react-flow__handle {
        pointer-events: none !important;
        opacity: 0;
    }
    .react-flow__handle.connectionindicator {
        pointer-events: none !important;
        cursor: crosshair;
    }
`;

const CustumControlButton = styled(ControlButton)`
    width: 32px !important;
    height: 32px !important;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    background: transparent;
    border: none !important;
    border-radius: 0;
    font: inherit;
    outline: none;
`;

const UndoDiv = styled.div`
    width: 32px;
    height: 32px;
    display: grid;
    place-content: center;
    border: 2px solid var(--color-line-primary);
    background-color: #fff;
    border-radius: 50%;
`;

const UndoSpan = styled.span`
    mask: url(${iconUndo}) no-repeat center center / contain;
    -webkit-mask: url(${iconUndo}) no-repeat center center / contain;
    display: block;
    width: 10px;
    height: 10px;
    background: var(--color-txt-primary);
`;

const RedoDiv = styled.div`
    width: 32px;
    height: 32px;
    display: grid;
    place-content: center;
    border: 2px solid var(--color-line-primary);
    background-color: #fff;
    border-radius: 50%;
`;

const RedoSpan = styled.span`
    mask: url(${iconRedo}) no-repeat center center / contain;
    -webkit-mask: url(${iconRedo}) no-repeat center center / contain;
    display: block;
    width: 10px;
    height: 10px;
    background: var(--color-txt-primary);
`;

const DropdownItem = styled.div`
`;

const SelectLabel = styled.label`
    width: min(100%, 340px);
    position: relative;
    display: block;
    cursor: pointe
`

const Select = styled.select`
    position: relative;
    width: 100%;
    padding: 12px 32px 12px 12px;
    cursor: pointer !important;
    border: 1px solid var(--color-line-primary);
    border-radius: 4px;
    font-size: 16px;
    font-weight: 400;
    line-height: 1.25;
`