import React, { useContext, useState } from 'react';
import Modal from '@common/modal/Modal';
import styled, { css } from 'styled-components';
import ProductCreateModal from '@specific/process/product_create/ProductCreateModal';
import SubsystemInputProductSearch from './SubsystemInputProductSearch';
import { CaseStudyViewContext } from '@pages/case_study/CaseStudyView';
import { useTranslation } from 'react-i18next';
import ProductTextTreeCategories from '@specific/process/product_search/ProductTextTreeCategories';

interface SubsystemInputProductSearchModalProps {
  currentNodeData: any;
  isOpen: boolean;
  onClose: () => void;
}

/**
 * 製品検索　サブシステム　入力
 * @returns 
 */
const SubsystemInputProductSearchModal: React.FC<SubsystemInputProductSearchModalProps> = ({ currentNodeData, isOpen, onClose }) => {
  const {t} = useTranslation();
  const { databasePacks } = useContext(CaseStudyViewContext);
  const [activeTab, setActiveTab] = useState<string>('tab1');

  const handleTabClick = (tab: string) => {
    setActiveTab(tab);
  };

  const [isModalOpen, setIsModalOpen] = useState(false);

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <Inner>
        <SectionResult>
          <SectionResultTitle>{t('検索結果')}</SectionResultTitle>
          {/* <ProductCreateModal selectted_database_pack_id={databasePacks} /> */}
        </SectionResult>
        <SectionBody>
          <SectionTab>
            {/* タブのボタン */}
            <TabButton onClick={() => handleTabClick('tab1')} isActive={activeTab === 'tab1'}>
              <SectionResultTitleText>{t('検索して選ぶ')}</SectionResultTitleText>
            </TabButton>
            <TabButton onClick={() => handleTabClick('tab2')} isActive={activeTab === 'tab2'}>
              <SectionResultTitleText>{t('カテゴリーツリーから選ぶ')}</SectionResultTitleText>
            </TabButton>

            {/* タブの内容 */}
            {activeTab === 'tab1' &&
              <SectionTabContent>
                <SubsystemInputProductSearch
                  selectted_database_pack_id={1}
                  setIsSearchModalOpen={onClose}
                  currentNodeData={currentNodeData}
                />
              </SectionTabContent>
            }
            {activeTab === 'tab2' && 
            <SectionTabContent>
                <ProductTextTreeCategories
                  type='select'
                  modal_type={"subsystem"}
                  setIsSearchModalOpen={setIsModalOpen}
                  onSelectProduct={() => { }}
                  currentNodeData={currentNodeData}
                />
            </SectionTabContent>
            }
          </SectionTab>
        </SectionBody>
      </Inner>
    </Modal>
  );
}

export default SubsystemInputProductSearchModal;

const TabButton = styled.button.withConfig({
  shouldForwardProp: (prop) => prop !== 'isActive'
}) <{ isActive: boolean }>`
  background-color:  var(--color-gray--01);
  padding: 7px 16px;  
  border-radius: 20px;
  white-space: nowrap;

  display: inline-flex;
  align-items: center;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: center;
  cursor: pointer;
  white-space: nowrap;
  gap: 0 4px;

  appearance: none;
  border: none;
  font: inherit;
  outline: none;      

${(props) =>
    props.isActive &&
    css`
    background-color: var(--color-site-secondary);
    color: white;
  `
  }

&:hover {
  background-color:  var(--color-gray--01);
  ${(props) =>
    props.isActive &&
    css`
      background-color: var(--color-site-secondary);
    `
  }
}
`

const Inner = styled.div`
    width: 100%;
    padding: 120px 10%;
    margin: 0 auto;
`

const SectionResult = styled.div`
    padding: 0 0 20px;
    margin-bottom: 20px;
    border-bottom: 1px solid var(--color-line-primary);
    display: flex;
    justify-content: space-between;
`

const SectionResultTitle = styled.h3`
    font-size: 18px;
    font-weight: 500;
`

const SectionBody = styled.div`

`

const SectionTab = styled.div`
    gap: 24px 12px;
    display: flex;
    align-items: flex-start;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: flex-start;
`

const SectionResultTitleText = styled.span`
    color: #fff;
    font-size: 12px;
    font-weight: 500;
    display: flex;
    align-items: center;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: flex-start;
    gap: 0 20px;
    white-space: nowrap;
`

const SectionTabContent = styled.div`
    display: block;
    z-index: 5;
    width: 100%;
    order: 2;
    position: relative;    
`
