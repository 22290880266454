import React, { useContext, useEffect } from 'react';
import { createColumnHelper } from '@tanstack/react-table';
import { CaseStudyParametersrameters } from '@typeList/types';
import { useTranslation } from 'react-i18next';
import { arraysEqual } from '@utils/utils';
import { CaseStudyViewContext } from '@pages/case_study/CaseStudyView';
import CaseStudyParameterSettingTable from '@common/table/CaseStudyParameterSettingTable';
import styled from 'styled-components';
import { FadeLoader } from 'react-spinners';

interface Parameter extends CaseStudyParametersrameters {
    division: string | null;
}

/**
 * 056 プロセス閲覧画面　パラメーター設定タブ
 * @param param0 
 * @returns 
 */
const CaseStudyParameterSetting: React.FC = () => {
    const { t } = useTranslation();
    const context = useContext(CaseStudyViewContext);
    const { caseStudyParameters, setCaseStudyParameters, updateCaseStudyParameters, setUpdateCaseStudyParameters, setUnsaved} = context;
    useEffect(() => {
        if (
            !arraysEqual(caseStudyParameters, updateCaseStudyParameters)
        ) {
            setUnsaved(true);
        } else {
            setUnsaved(false);
        }
    }, [updateCaseStudyParameters])
    const handleTableDataChange = (parameter: Parameter[]) => {
        if (parameter) {
            // const updatedParameters = caseStudyParameters ? [...caseStudyParameters, ...parameter] : [...parameter];
            // setUpdateCaseStudyParameters(updatedParameters);
            setUpdateCaseStudyParameters(parameter);
        }
    };

    const columnHelper = createColumnHelper<Parameter[]>();
    const columns = [
        columnHelper.accessor('name', {
            header: () => t('名前'),
            cell: info => info.renderValue(),
            meta: { editable: true },
        }),
        columnHelper.accessor('division', {
            header: () => t('区分'),
            cell: info => t('ケーススタディ'),
            meta: { editable: false, type: "text" },
        }),
        columnHelper.accessor('value', {
            header: () => t('値'),
            cell: info => info.renderValue(),
            meta: { editable: true, type: "number" },
        }),
    ];

    if (!context) {
        return (
            <FadeLoader
                color="#48bdbb"
                height={10}
                radius={2}
                width={5}
            />
        );
    }

    return (
        <div className="app">
            <>
                <SectionTitle>{t('パラメーター設定')}</SectionTitle>
                {updateCaseStudyParameters &&
                    <>
                        <CaseStudyParameterSettingTable columns={columns} data={updateCaseStudyParameters} paging_flag={false} onDataChange={handleTableDataChange} />
                    </>
                }
            </>
        </div>
    );
};

export default CaseStudyParameterSetting;

const SectionTitle = styled.h2`
padding: 0 0 20px;
margin-bottom: 20px;
border-bottom: 1px solid var(--color-line-primary);
font-size: 16px;
font-weight: 500

line-height: 1.25;
`