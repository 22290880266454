import React from 'react';
import styled from 'styled-components';
import { Button, ButtonProps as MUIButtonProps } from '@mui/material';
import iconAdd from '@images/product_system/icon_add.svg'

interface CustomButtonProps extends MUIButtonProps {
  onClick: () => void;
  text: string;
}

const AddButton: React.FC<CustomButtonProps> = ({ onClick, text, ...rest }) => {
  return (
    <TableButton>
        <StyledButton type='button' onClick={onClick} {...rest}>
            <AddIcon>
                <AddIconImage />
            </AddIcon>
            <ReloadButtonText>{text}</ReloadButtonText>
        </StyledButton>
    </TableButton>
  );
};

export default AddButton;

const StyledButton = styled.button`
    padding: 6px 18px;
    border-radius: 20px;
    
    display: inline-flex;
    align-items: center;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: center;
    cursor: pointer;
    white-space: nowrap;
    background-color: var(--color-gray--01);
    gap: 0 4px;

    appearance: none;
    border: none;
    font: inherit;
    outline: none;

    color: inherit;

    &:hover {
        background-color: var(--color-site-secondary);
        text-decoration: none !important;
        color: white;
    }

    &:disabled {
        color: gray; /* disabled時の文字色を指定 */
    }

    &:disabled:hover {
        background-color: var(--color-gray--01);
        color: gray;
    }
`

const AddIcon = styled.div`   
    width: 16px;
    height: 16px;
    border: none;
    background-color: var(--color-site-secondary);
    display: grid;
    place-content: center;
    border-radius: 50%;
`

const AddIconImage = styled.span`
    width: 7.44px;
    height: 7.44px;
    background-color: #ffffff;
    position: relative;
    display: block;
    background: var(--color-bg-secondary);    
    mask: url(${iconAdd}) no-repeat center center / contain;
    -webkit-mask: url(${iconAdd}) no-repeat center center / contain;
`

const ReloadButtonText = styled.span`
    font-size: 12px;
    font-weight: 500;
`

const TableButton = styled.div`
`