import React, { useState, useCallback, useRef, useEffect } from 'react';
import {
    flexRender,
    getCoreRowModel,
    useReactTable,
    getPaginationRowModel,
    getSortedRowModel,
    ColumnDef,
    SortingState,
    createColumnHelper,
    Row,
} from '@tanstack/react-table';
import { useDrag, useDrop } from 'react-dnd';
import styled from 'styled-components';
import { ChromePicker, ColorResult } from 'react-color';
import { useTranslation } from 'react-i18next';
import { SubsystemCategories } from '@typeList/types';

import AddButton from '@common/button/AddButton';
import ReloadButton from '@common/button/ReloadButton';

import iconArrowDown from '@images/table/icon_arrow_down.svg';
import iconArrowUp from '@images/table/icon_arrow_up.svg';
import iconDelete from '@images/table/icon_delete.svg';


interface TableProps {
    data: any[];
    onUpdate: (tableData: any[]) => Promise<void>;
}

const initialPageIndex = 0;
const initialPageSize = 50;

interface DragItem {
    index: number;
    id: string;
    type: string;
}


const DraggableHeader: React.FC<{
    header: any;
    index: number;
    moveColumn: (dragIndex: number, hoverIndex: number) => void;
}> = ({ header, index, moveColumn }) => {
    const ref = useRef<HTMLTableCellElement>(null);

    const [, drop] = useDrop({
        accept: 'COLUMN',
        hover: (item: DragItem, monitor) => {
            if (!ref.current) {
                return;
            }
            const dragIndex = item.index;
            const hoverIndex = index;

            if (dragIndex === hoverIndex) {
                return;
            }

            const hoverBoundingRect = ref.current?.getBoundingClientRect();
            const hoverMiddleX = (hoverBoundingRect.right - hoverBoundingRect.left) / 2;
            const clientOffset = monitor.getClientOffset();
            const hoverClientX = clientOffset!.x - hoverBoundingRect.left;

            if (dragIndex < hoverIndex && hoverClientX < hoverMiddleX) {
                return;
            }

            moveColumn(dragIndex, hoverIndex);
            item.index = hoverIndex;
        },
    });

    const [{ isDragging }, drag] = useDrag({
        type: 'COLUMN',
        item: { index },
        collect: (monitor) => ({
            isDragging: monitor.isDragging(),
        }),
    });

    drag(drop(ref));

    return (
        <Th ref={ref} onClick={header.column.getToggleSortingHandler()}>
            <ThInner>
                {flexRender(header.column.columnDef.header, header.getContext())}
                {header.column.getIsSorted() ? (header.column.getIsSorted() === 'desc' ? <IconArrowDown /> : <IconArrowUp />) : ''}
            </ThInner>
        </Th>
    );
};

const SubsystemDivisionEditTable: React.FC<TableProps> = ({ data, onUpdate }) => {
    const columnHelper = createColumnHelper<SubsystemCategories>();
    const {t} = useTranslation();
    const [tableData, setTableData] = useState(data);
    useEffect(() =>{
        setTableData(data)
    },[data])

    const [sorting, setSorting] = useState<SortingState>([]);

    const initialColumns: ColumnDef<SubsystemCategories, any>[] = [
        columnHelper.accessor('name', {
            header: () => t('名前'),
            cell: info => (
                <Input
                    key={`name_${info.row.original.sort_no}`}
                    type='text' 
                    defaultValue={info.renderValue()} 
                    onChange={(e) => handleNameChange(info.row.index, e.target.value)}
                />
            )
        }),
        columnHelper.accessor('color', {
            header: () => t('色'),
            cell: info => (
                <ColorPickerCell 
                    key={`Color_${info.row.original.sort_no}`}
                    value={info.getValue()} 
                    onChange={(color) => handleColorChange(info.row.index, color)} 
                />
            ),
        }),
    ];


    const [columns, setColumns] = useState(initialColumns);

    const moveColumn = useCallback(
        (dragIndex: number, hoverIndex: number) => {
            const newColumns = [...columns];
            const [movedColumn] = newColumns.splice(dragIndex, 1);
            newColumns.splice(hoverIndex, 0, movedColumn);
            setColumns(newColumns);
        },
        [columns]
    );

    const handleNameChange = (rowIndex: number, name: string) => {
        setTableData(prevData => {
            // rowIndexが有効かチェック
            if (rowIndex < 0 || rowIndex >= prevData.length) {
                console.error(`Invalid rowIndex: ${rowIndex}`);
                return prevData;
            }
        
            const updatedData = [...prevData];
            if (!updatedData[rowIndex]) {
                console.error(`No data found at rowIndex: ${rowIndex}`);
                return prevData;
            }
    
            updatedData[rowIndex].name = name;
            return updatedData;
        });
    };

    const handleColorChange = (rowIndex: number, color: string) => {
        setTableData(prevData => {
            // rowIndexが有効かチェック
            if (rowIndex < 0 || rowIndex >= prevData.length) {
                console.error(`Invalid rowIndex: ${rowIndex}`);
                return prevData;
            }
    
            const updatedData = [...prevData];
            if (!updatedData[rowIndex]) {
                console.error(`No data found at rowIndex: ${rowIndex}`);
                return prevData;
            }
    
            updatedData[rowIndex].color = color;
            return updatedData;
        });
    };

    const addRow = () => {
        setTableData((prevData) => {
            // sort_no の最大値を計算
            const maxSortNo = prevData.length > 0 
                ? Math.max(...prevData.map(row => row.sort_no || 0)) 
                : 0;
    
            // 新しい行を追加
            const newRow: any = {
                locale: 'ja',
                name: '',  // 名前フィールドの初期値
                id: null, 
                sort_no: maxSortNo + 1,  // 最大の sort_no に 1 を足した値
                color: '#FFFFFF',  // デフォルトの色
            };
    
            return [...prevData, newRow];
        });
    };

    const removeRow = (row: Row<any>) => {
        const updatedData = tableData.filter((_, index) => index !== row.index);
        setTableData(updatedData);
    };

    const table = useReactTable({
        data: tableData ?? [],
        columns,
        state: { sorting },
        onSortingChange: setSorting,
        initialState: {
            pagination: {
                pageIndex: initialPageIndex,
                pageSize: initialPageSize,
            },
        },
        getCoreRowModel: getCoreRowModel(),
        getPaginationRowModel: getPaginationRowModel(),
        getSortedRowModel: getSortedRowModel(),
    });

    return (
        <SectionTableWrap>
            <Table>
                <Thead>
                    {table.getHeaderGroups().map(headerGroup => (
                        <Tr key={headerGroup.id}>
                            <Th>
                                <ThInner>
                                    {t('削除')}
                                </ThInner>
                            </Th>
                            {headerGroup.headers.map((header, index) => (
                                <DraggableHeader
                                    key={header.id}
                                    header={header}
                                    index={index}
                                    moveColumn={moveColumn}
                                />
                            ))}
                        </Tr>
                    ))}
                </Thead>
                <Tbody>
                    {table.getRowModel().rows.map(row => (
                        <Tr key={row.id}>
                            <Td>
                                <IconButton type="button" onClick={() => removeRow(row)}>
                                    <Icon></Icon>
                                    {/* {t('削除')} */}
                                </IconButton>
                            </Td>
                            {row.getVisibleCells().map(cell => (
                                <Td key={cell.id} title={String(cell.getValue())}>
                                    <CellContent>
                                        {flexRender(cell.column.columnDef.cell, cell.getContext())}
                                    </CellContent>
                                </Td>
                            ))}
                        </Tr>
                    ))}
                </Tbody>
            </Table>
            <div className="h-4" />
            <ButtonWrap>
                <AddButton type='button' onClick={addRow} text={t('行を追加')}></AddButton>
            </ButtonWrap>
            <ButtonReloadSectionTabel>
                <ReloadButton onClick={() => {onUpdate(tableData)}} text={t('更新する')} />
            </ButtonReloadSectionTabel>  
        </SectionTableWrap>
    );
};

const ColorPickerCell: React.FC<{key: string, value: string; onChange: (color: string) => void }> = ({key, value, onChange }) => {
    const [displayColorPicker, setDisplayColorPicker] = useState(false);
    const [color, setColor] = useState(value);
    const {t} = useTranslation();

    const handleClick = () => {
        setDisplayColorPicker(!displayColorPicker);
    };

    const handleClose = () => {
        setDisplayColorPicker(false);
    };

    const handleChange = (color: ColorResult) => {
        setColor(color.hex);
        onChange(color.hex);
    };

    return (
        <ColorPickerContainer>
            <ColorSwatch onClick={handleClick} color={color} />
            {displayColorPicker ? (
                <ColorPickerPopoverWrap>
                    <ColorPickerPopover>
                        <ChromePicker key={key} color={color} onChange={handleChange} />
                        <ColorPickerCloseButton onClick={handleClose}>{t('閉じる')}</ColorPickerCloseButton>
                    </ColorPickerPopover>
                </ColorPickerPopoverWrap>
            ) : null}
        </ColorPickerContainer>
    );
};

const ColorPickerContainer = styled.div`
    display: flex;
    align-items: center;
    width: 100%;
`;


export default SubsystemDivisionEditTable;

const ColorPickerPopoverWrap = styled.div`
    position: absolute; 
	top: 150px;
`;

const ColorSwatch = styled.div<{ color: string }>`
    width: 100%;
    height: 14px;  /* Adjust the height as needed */
    border-radius: 2px;
    background: ${({ color }) => color};
    cursor: pointer;
`;

const ColorPickerPopover = styled.div`
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 2;
`;

const CellContent = styled.div`
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
`;

const SectionTableWrap = styled.div`
    overflow-x: auto;    
`

const Table = styled.table`
    min-width: 100%;
    border-collapse: collapse;
    margin-bottom: 8px;
    background-color: #fff;
    border-spacing: 0;
`

const Thead = styled.thead`
    border-right: 1px solid var(--color-line-primary);
    border-left: 1px solid var(--color-line-primary);
`

const Tr = styled.tr`
    border-top: 1px solid var(--color-line-primary);
    border-bottom: 1px solid var(--color-line-primary);
`

const Th = styled.th`
    cursor: grab;

    &:active {
        cursor: grabbing;
    }
    font-size: 13px;
    font-weight: 700;
    line-height: 1.25;
    color: var(--color-site-primary);
    padding: 12px;
    align-content: center;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    min-height: 40px;
`

const ThInner = styled.div`
    width: fit-content;
    display: flex;
    align-items: left;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: flex-start;
    gap: 4px;
    white-space: nowrap;
`;

const Tbody= styled.tbody`
    border-right: 1px solid var(--color-line-primary);
    border-left: 1px solid var(--color-line-primary);
`

const Td= styled.td`
    font-size: 14px;
    font-weight: 500;
    line-height: 1.25;
    padding: 12px;
    align-content: center;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    min-height: 40px;
`


const IconArrowDown = styled.span`
    mask: url(${iconArrowDown}) no-repeat center center / contain;
    -webkit-mask: url(${iconArrowDown}) no-repeat center center / contain;
    display: block;
    width: 100%;
    height: 100%;
    width: 20px;
    height: 20px;
    background: var(--color-txt-primary);
`

const IconArrowUp = styled.span`
    mask: url(${iconArrowUp}) no-repeat center center / contain;
    -webkit-mask: url(${iconArrowUp}) no-repeat center center / contain;
    display: block;
    width: 100%;
    height: 100%;
    width: 20px;
    height: 20px;
    background: var(--color-txt-primary);
`

const IconButton = styled.button`
    cursor: pointer;
    text-decoration: none;
    color: inherit;
    appearance: none;
    background: transparent;
    border: none;
    border-radius: 0;
    font: inherit;
    outline: none;
`

const Icon = styled.span`
    mask: url(${iconDelete}) no-repeat center center / contain;
    -webkit-mask: url(${iconDelete}) no-repeat center center / contain;
    display: block;
    width: 30px;
    height: 30px;
    background: var(--color-txt-primary);
`

const Input = styled.input`
    width: 100%;
    padding: 12px;
    color: var(--color-txt-primary);
    border: 1px solid var(--color-line-primary);
    border-radius: 4px;
    background-color: #fff;
    font-size: 16px;
    font-weight: 400;
    line-height: 1.25;

    appearance: none;
    background: transparent;
    font: inherit;
    outline: none;
`


const ButtonWrap = styled.div`
    text-align: end;
`

const ButtonReloadSectionTabel = styled.div`
    margin-top: 40px;
    text-align: right;
    border: 0;
    font: inherit;
    vertical-align: baseline;
    box-sizing: border-box;    
`

const ColorPickerCloseButton = styled.button`
    margin-top: 10px;
    padding: 6px 18px;
    border-radius: 20px;
    
    display: inline-flex;
    align-items: center;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: center;
    cursor: pointer;
    white-space: nowrap;
    background-color: var(--color-gray--01);
    gap: 0 4px;

    appearance: none;
    border: none;
    font: inherit;
    outline: none;

    color: inherit;

    &:hover {
        background-color: var(--color-site-secondary);
        text-decoration: none !important;
        color: white;
    }    
`
