import React, { useContext, useEffect } from 'react';
import { createColumnHelper } from '@tanstack/react-table';
import { useTranslation } from 'react-i18next';
import ProsessParameterSettingTable from '@common/table/ProsessParameterSettingTable';
import { ProcessViewContext } from '@pages/process/ProcessView';
import { arraysEqual } from '@utils/utils';
import { FadeLoader } from 'react-spinners';

interface ExtendPropertyType {
    [key: string]: any;
}

const ExtendProperty: React.FC = () => {
    const { t } = useTranslation();
    const context = useContext(ProcessViewContext);
    const { extendProperty, setExtendProperty, updateExtendPropertys, setUpdateExtendPropertys, setChangeCheckExtendProperty } = context;

    const handleTableDataChange = (updatedData: ExtendPropertyType[]) => {
        if (updatedData) {
            const updatedProperties: ExtendPropertyType = {};
            updatedData.forEach((item) => {
                updatedProperties[item.name] = item.value;
            });
            setUpdateExtendPropertys(updatedProperties);
            // setChangeCheckExtendProperty(true);
        }
    };

    const columnHelper = createColumnHelper<ExtendPropertyType>();
    const columns = [
        columnHelper.accessor('name', {
            header: () => t('名前'),
            cell: info => info.renderValue(),
            meta: { editable: true, type: "text" },
        }),
        columnHelper.accessor('value', {
            header: () => t('値'),
            cell: info => info.renderValue(),
            meta: { editable: true, type: "number" },
        }),
    ];

    if (!context) {
        return (
            <FadeLoader
                color="#48bdbb"
                height={10}
                radius={2}
                width={5}
            />
        );
    }

    // convert extendProperty object to an array for the table
    const extendPropertyArray = Object.entries(updateExtendPropertys || {}).map(([key, value]) => ({ name: key, value }));

    return (
        <>
            <>
                {extendPropertyArray &&
                    <>
                        <ProsessParameterSettingTable columns={columns} data={extendPropertyArray} paging_flag={false} onDataChange={handleTableDataChange} />
                    </>
                }
            </>
        </>
    );
};

export default ExtendProperty;
