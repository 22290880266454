import React, { useContext, useEffect, useState } from 'react';
import { FieldErrors, UseFormRegister, useForm } from 'react-hook-form';
import styled, { css } from 'styled-components';
import InventoryAnalysis from '../calculation/InventoryAnalysis';
import ProcessCharacterization from '../calculation/ProcessCharacterization';
import { useCalculate, useGetLciResults } from '@hooks/useBackendApi';
import { ProcessViewContext } from '@pages/process/ProcessView';
import { LciResultItems, ProcessCalculate, UpdateProcess } from '@typeList/types';
import { formatToLocalTime } from '@utils/utils';
import { useTranslation } from 'react-i18next';
import ErrorMsg from '@common/error/ErrorMsg';
import { FadeLoader } from 'react-spinners';
import LoadingDialog from '@common/modal/LoadingDialog';
import SaveDialog from '@common/modal/SaveDialog';

interface FormProps {
    register: UseFormRegister<UpdateProcess>;
    errors: FieldErrors<UpdateProcess>;
}

const ProcessCalculation: React.FC<FormProps> = ({ register, errors }) => {
    const {t} = useTranslation();
    const context = useContext(ProcessViewContext);
    const { handleSubmit } = useForm<UpdateProcess>();
    const { 
        processData, 
        methodsData, 
        resultData, 
        setResultData, 
        onSubmit,
        changeCheckFlow,
        changeCheckAdditionInfo,
        changeCheckExtendProperty,
        changeCheckParameter,
        changeCheckAllocation,
        lciResultsData,
        setLciResultsData,
        lciResultsLoading,
        setSavedAlertEnabled,
        getLciData,
        setCalculateAndGetResultsLoading,
        calculateAndGetResultsLoading,
        getLciCalculatedDate,
        calculatedDateData,
        lciaResultsLoading
    } = context;

    const [activeTab, setActiveTab] = useState<string>('tab1');
    const handleTabClick = (tab: string) => {
        setActiveTab(tab);
    };

    // 計算実行
    const { executeCalculate, calculateData, calculateError, calculateLoading } = useCalculate();


    // プロセス計算結果を初期表示用にセット
    useEffect(() => {
        setResultData(lciResultsData);

        // 原単位プロセス用に保持
        setLciResultsData(lciResultsData);
    }, [lciResultsData]);


    // ボタン連打制御
    const [isDisabled, setIsDisabled] = useState(false);

    // 保存と計算
    const hundleSaveAndCalculate = async () =>{
        if (isDisabled) return;
        setIsDisabled(true);

        try {
            await handleSubmit(onSubmit)();
        } catch (error: any) {
            console.log("error", error);
            setCalculateAndGetResultsLoading(false);
			if(error.response && error.response.data?.detail){
                setDaialogMessage(<>{t('保存に失敗しました')} <br/> {error.response.data?.detail} </>)
            } else {
                setDaialogMessage(<>{t('保存に失敗しました')} <br/> {t('申しわけありませんが、システム管理者までお問い合わせください')}</>)
            }
            return; // エラーが発生した場合もここで処理を中断
        } finally {
            setIsDisabled(false);
        }

        await handleExecuteCalculate()
    }

    // 計算
    const handleExecuteCalculate = async () => {
        setCalculateAndGetResultsLoading(true);
        openLoadingDaialog();

        try {
            await executeCalculate(processData?.id ?? 0);
            await getLciData();
            setDaialogMessage(t('計算が完了しました'))
        } catch (error: any) {
            if(error.response && error.response.data?.detail){
                setDaialogMessage(<>{t('計算に失敗しました。')} <br/> {error.response.data?.detail} </>)
            } else {
                setDaialogMessage(<>{t('計算に失敗しました。')} <br/> {t('申しわけありませんが、システム管理者までお問い合わせください')}</>)
            }
            setCalculateAndGetResultsLoading(false);
            console.log("error", error);
        } finally {
            setIsDisabled(false);
            setCalculateAndGetResultsLoading(false);
        }
    };

    // 計算対象のフローのID
    const [selectedProcessId, setSelectedProcessId] = useState<number | null>(null);

    // 計算結果をフィルターしたデータ
    const [filteredData, setfilteredData] = useState<any[]>([])

    // 計算後の初期表示
    useEffect(() => {
        setfilteredData([]);
        // 計算結果をフィルター
        if (resultData && resultData.length > 0) {

            const initialProcessId = resultData && resultData.length > 0 ? resultData[0].id : null;
            const filteredData = selectedProcessId
                ? resultData.filter(data => data.id === initialProcessId) : resultData;
            
            setfilteredData(filteredData);
            setSelectedProcessId(initialProcessId)
        }
    }, [resultData]);


    // 計算対象のフロー変更
    const handleSelectChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
        const selectedId = parseInt(event.target.value);
        setSelectedProcessId(selectedId);
        // 表示する計算結果をフィルター
        if (resultData && resultData.length > 0) {
            const filteredData = selectedId
                ? resultData.filter(data => data.id === selectedId) : resultData;
            setfilteredData(filteredData);
        }
    };

    // 保存ダイアログ
    const [isAlertDialogOpen, setIsAlertDialogOpen] = useState(false);
    const openAlertDialog = () => setIsAlertDialogOpen(true);
    const closeAlertDialog = () => setIsAlertDialogOpen(false);
    const [alertDaialogMessage, setAlertDaialogMessage] = useState<string>('');
    useEffect(() => {
        if (changeCheckFlow ||
            changeCheckAdditionInfo ||
            changeCheckExtendProperty ||
            changeCheckParameter ||
            changeCheckAllocation
        ) {
            setAlertDaialogMessage(t('計算を行う前に保存が必要です。現在の状態を保存しますか？'))
        } else {
            setAlertDaialogMessage(t('計算を実行しますか？'))
        }
    }, [
        changeCheckFlow,
        changeCheckAdditionInfo,
        changeCheckExtendProperty,
        changeCheckParameter,
        changeCheckAllocation
    ])
    
    // ローディングダイアログ
    const [isLoadingDaialogOpen, setIsLoadingDaialogOpen] = useState<boolean>(false);
    const openLoadingDaialog = () => setIsLoadingDaialogOpen(true);
    const closeLoadingDaialog = () => setIsLoadingDaialogOpen(false);
    const [daialogMessage, setDaialogMessage] = useState<string | JSX.Element>("");
    const daialogLoadingMessage = t('計算しています');

    return (
        <>
            <SaveDialog
                open={isAlertDialogOpen}
                onClose={closeAlertDialog}
                onConfirm={
                    changeCheckFlow || 
                    changeCheckAdditionInfo || 
                    changeCheckExtendProperty || 
                    changeCheckParameter || 
                    changeCheckAllocation ?
                    hundleSaveAndCalculate: handleExecuteCalculate
                }
                message={alertDaialogMessage}
            />
            <LoadingDialog
                open={isLoadingDaialogOpen}
                onClose={closeLoadingDaialog}
                loading={calculateAndGetResultsLoading}
                loading_message={daialogLoadingMessage}
                result_message={daialogMessage}
            />

            {!processData?.is_database_pack_master && (
                <Button
                    type='button'
                    onClick={() => {
                        // 計算済みの結果を取得するボタンが表示されている場合のみ getLciData() を実行
                        if (calculatedDateData && 
                            (
                                !changeCheckFlow && 
                                !changeCheckAdditionInfo && 
                                !changeCheckExtendProperty && 
                                !changeCheckParameter &&
                                !changeCheckAllocation
                            )
                        ) {
                            getLciData();
                        } else {
                            setSavedAlertEnabled(false);
                            openAlertDialog();
                        }
                    }}
                    disabled={isDisabled}
                >
                    <ButtonText>{t('計算する')}</ButtonText>
                </Button>
            )}

            {processData?.is_database_pack_master && (
                <Button
                    type='button'
                    onClick={() => { getLciData();}}
                    disabled={isDisabled}
                >
                    <ButtonText>{t('計算する')}</ButtonText>
                </Button>
            )}

            {/* {!processData?.is_database_pack_master &&
                <Button type='button' onClick={() =>{setSavedAlertEnabled(false); openAlertDialog()}} disabled={isDisabled}><ButtonText> {t('計算する')}</ButtonText></Button>
            }
            {calculatedDateData && (!lciResultsData || lciResultsData.length === 0) &&
                <Button
                    type='button'
                    onClick={() => { getLciData() }}
                    disabled={isDisabled}
                    style={{ marginLeft: "10px" }}
                >
                    <ButtonText> {t('計算済みの結果を取得する')}</ButtonText>
                </Button>
            } */}
            {!processData?.is_database_pack_master &&
                <>
                    {(
                        changeCheckFlow || 
                        changeCheckAdditionInfo || 
                        changeCheckExtendProperty || 
                        changeCheckParameter || 
                        changeCheckAllocation
                    ) && <CalculateErrorMsg>{t('計算結果が古いため再計算が必要です')}</CalculateErrorMsg>}
                </>
            }
            {lciResultsLoading &&
                <FadeLoader
                    color="#48bdbb"
                    height={10}
                    radius={2}
                    width={5}
                />
            }
            {!lciResultsLoading &&
                <>
                <Wrap>
                    <Label> {t('計算対象の出力フロー')}</Label>
                    <SelectLabel>
                        <Select onChange={handleSelectChange} >
                            {resultData?.map((process) => (
                                <option key={process.id} value={process.id}>
                                    {process.process_output.product_name}  {process.process_output.amount}  {process.process_output.unit.name}  {t('あたり')}
                                </option>
                            ))}
                        </Select>
                    </SelectLabel>
                </Wrap>
                <Wrap>
                    <Label> {t('前回計算日時')} </Label>
                    {!calculatedDateData ? (
                        <SelectLabel>
                            <Label> {t('計算結果がありません')}</Label>
                        </SelectLabel>
                    ) : (
                        <SelectLabel key={calculatedDateData.id}>
                            <Label>{formatToLocalTime(calculatedDateData.calculated_at)}</Label>
                        </SelectLabel>
                    )}
                </Wrap>
                {/* <Wrap>
                    <Label> {t('前回計算日時')} </Label>
                    {!resultData || resultData.length === 0 ? (
                        <SelectLabel>
                            <Label> {t('計算結果がありません')}</Label>
                        </SelectLabel>
                    ) : (
                        resultData
                            .filter(data => data.id === selectedProcessId)
                            .map(data => (
                                <SelectLabel key={data.id}>
                                    <Label>{formatToLocalTime(data.calculated_at)}</Label>
                                </SelectLabel>
                            ))
                    )}
                </Wrap> */}
                </>
            }
            {lciaResultsLoading &&
                <FadeLoader
                    color="#48bdbb"
                    height={10}
                    radius={2}
                    width={5}
                />
            }
            {calculateError && <ErrorMsg>{calculateError}</ErrorMsg>}
            <Section>
                <Box>
                    <BoxInner>
                        <TabButton type='button' onClick={() => handleTabClick('tab1')} isActive={activeTab === 'tab1'}>
                            <TabButtonSpan>
                            {t('特性化')}
                            </TabButtonSpan>
                        </TabButton>
                        <TabButton type='button' onClick={() => handleTabClick('tab2')} isActive={activeTab === 'tab2'}>
                            <TabButtonSpan>
                            {t('インベントリ分析')}
                            </TabButtonSpan>
                        </TabButton>
                        {activeTab === 'tab1' && <TabPanel><ProcessCharacterization calculateData={resultData} selectedProcessId={selectedProcessId} /></TabPanel>}
                        {calculateLoading &&
                            <FadeLoader
                                color="#48bdbb"
                                height={10}
                                radius={2}
                                width={5}
                            />
                        }
                        {activeTab === 'tab2' && <TabPanel><InventoryAnalysis calculateData={resultData} filteredData={filteredData} selectedProcessId={selectedProcessId} /></TabPanel>}
                    </BoxInner>
                </Box>
            </Section>
        </>
    );
};

export default ProcessCalculation;

const TabButton = styled.button.withConfig({
    shouldForwardProp: (prop) => prop !== 'isActive'
}) <{ isActive: boolean }>`
    padding: 5px 16px;  
    color: black;
    border: none;
    margin-right: 10px;
    cursor: pointer;
    border-radius: 20px;
    font-size: 12px;
    font-weight: 500;
  
    ${(props) =>
        props.isActive &&
        css`
        background-color: var(--color-site-secondary);
        color: white;
    `}
  
    &:hover {
        background-color: var(--color-site-secondary);
        color: white;
      ${(props) =>
        props.isActive &&
        css`
            background-color: var(--color-site-secondary);
        `}
    }
`;

const TabPanel = styled.div`
display: block;
z-index: 5;
width: 100%;
order: 2;
position: relative
`;

const Button = styled.button`
background-color: var(--color-site-secondary);
border-radius: 4px;
border: 1px solid var(--color-site-secondary);
padding: 7px 16px;

display: inline-flex;
align-items: center;
flex-direction: row;
flex-wrap: nowrap;
justify-content: center;
cursor: pointer;
white-space: nowrap;
gap: 0 4px;

font: inherit;
outline: none;

&:hover {
    background-color: white;
    span {
        color: var(--color-site-secondary);
    }
}
margin-bottom: 10px;
`

const ButtonText = styled.span`
color: #fff;
font-size: 13px;
font-weight: 500;
`

const Section = styled.section`
display: block;
`

const Box = styled.div`
margin-top: 24px;
padding: 36px 20px;
border: 4px solid var(--color-line-primary);
background-color: #fff;
`

const BoxInner = styled.div`
gap: 24px 12px;
display: flex;
align-items: flex-start;
flex-direction: row;
flex-wrap: wrap;
justify-content: flex-start;
`

const TabButtonSpan = styled.span`
  font-family: "Noto Sans JP", sans-serif;
  font-size: 12px;
`

const Label = styled.label`
padding: 0 0 20px;
margin-bottom: 20px;
// border-bottom: 1px solid var(--color-line-primary);
font-size: 16px;
font-weight: 500;
line-height: 1.25;
`


const SelectLabel = styled.label`
margin-left: 10px;
width: min(100%, 340px);
`
const Select = styled.select`
width: 20%;
padding: 12px 32px 12px 12px;
cursor: pointer !important;
border: 1px solid var(--color-line-primary);
border-radius: 4px;
background-color: #fff;
font-size: 16px;
font-weight: 400;
line-height: 1.25;
`

const Wrap = styled.div`
margin-bottom: 20px;
`

const CalculateErrorMsg = styled.span`
    font-size: 14px;
    font-weight: 500;
    color: var(--color-error-primary);
    margin-left: 10px;
`