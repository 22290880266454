import React, { useState, useCallback, useEffect } from 'react';
import styled from 'styled-components';
import {
    getCoreRowModel,
    useReactTable,
    getPaginationRowModel,
    getSortedRowModel,
    ColumnDef,
    SortingState,
} from '@tanstack/react-table';
import Modal from '@common/modal/Modal';  // モーダルコンポーネントのインポート
import { EditDataTableType } from '@typeList/types';
import DraggableRow from './DraggableRow';
import DraggableHeader from './DraggableHeader';


import iconEdit from '@images/table/icon_edit.svg';
import iconArrowLeft from '@images/table/icon_arrow_left.svg';
import iconArrowRight from '@images/table/icon_arrow_right.svg';
import DeleteIconButton from '@common/button/DeleteIconButton';
import { useTranslation } from 'react-i18next';
import { useDeleteProduct } from '@hooks/useBackendApi';
import { use } from 'i18next';

interface TableProps {
    columns: ColumnDef<any, any>[];
    data: any[];
    ModalComponent: React.FC<EditDataTableType>;
    handleIsModalOpen: (isModalOpen: boolean) => void;
}

const initialPageIndex = 0;
const initialPageSize = 100;

const ProductsEditDataTable: React.FC<TableProps> = ({ columns: initialColumns, data, ModalComponent, handleIsModalOpen }) => {
    const { t } = useTranslation();
    const [columns, setColumns] = useState<ColumnDef<any, any>[]>([
        {
            id: 'edit',
            header: () => t('編集'),
            cell: ({ row }) => (
                <EditButton type='button' onClick={() => handleOpenModal(row.original.id)}>
                    <ButtonInner>
                        <EditButtonIcon></EditButtonIcon>
                    </ButtonInner>
                </EditButton>
            ),
            enableSorting: false, // ソート不可にする
        },
        {
            id: 'delete',
            header: () => t('削除'),
            cell: ({ row }) => (
                <>
                    {!row.original.is_database_pack_master && 
                        <DeleteIconButton onClick={() => { handleDelete(row.original.id) }}></DeleteIconButton>
                    }
                </>
            ),
            enableSorting: false, // ソート不可にする
        },
        ...initialColumns,
    ]);
    const [rows, setRows] = useState(data);
    useEffect(() => {
        setRows(data);
    }, [data])
    const [sorting, setSorting] = useState<SortingState>([]);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [currentRowId, setCurrentRowId] = useState<string | null>(null);

    const handleOpenModal = (id: string) => {
        setCurrentRowId(id);
        setIsModalOpen(true);
    };

    const handleCloseModal = () => {
        setIsModalOpen(false);
        setCurrentRowId(null);
        handleIsModalOpen(true);
    };

    // 製品削除
    const { deleteProduct } = useDeleteProduct();
    const handleDelete = async (id: number) => {
        const isConfirmed = window.confirm(t('削除しますか？'));
        if (isConfirmed) {
            try {
                // await api
                await deleteProduct(id);
                // await window.location.reload();
                await setRows(prevData => prevData.filter(row => row.id !== id));
            } catch (error: any) {
                alert(error.response.data.detail);
            }
        }
    }

    const moveRow = useCallback(
        (dragIndex: number, hoverIndex: number) => {
            const newRows = [...rows];
            const [movedRow] = newRows.splice(dragIndex, 1);
            newRows.splice(hoverIndex, 0, movedRow);
            setRows(newRows);
        },
        [rows]
    );

    const moveColumn = useCallback(
        (dragIndex: number, hoverIndex: number) => {
            const newColumns = [...columns];
            const [movedColumn] = newColumns.splice(dragIndex, 1);
            newColumns.splice(hoverIndex, 0, movedColumn);
            setColumns(newColumns);
        },
        [columns]
    );

    const table = useReactTable({
        data: rows,
        columns,
        state: { sorting },
        onSortingChange: setSorting,
        initialState: {
            pagination: {
                pageIndex: initialPageIndex,
                pageSize: initialPageSize,
            },
        },
        getCoreRowModel: getCoreRowModel(),
        getPaginationRowModel: getPaginationRowModel(),
        getSortedRowModel: getSortedRowModel(),
    });

    return (
        <>
            <Modal isOpen={isModalOpen} onClose={handleCloseModal}>
                <ModalComponent Id={Number(currentRowId)} onClose={handleCloseModal} selectted_database_pack_id={null} />
            </Modal>

            <SectionTableWrap>
                <Table>
                    <Thead>
                        {table.getHeaderGroups().map((headerGroup) => (
                            <Tr key={headerGroup.id}>
                                {headerGroup.headers.map((header, index) => (
                                    <DraggableHeader
                                        key={header.id}
                                        header={header}
                                        index={index}
                                        moveColumn={moveColumn}
                                    />
                                ))}
                            </Tr>
                        ))}
                    </Thead>
                    <Tbody>
                        {table.getRowModel().rows.map((row, index) => (
                            <DraggableRow key={row.id} row={row} index={index} moveRow={moveRow} />
                        ))}
                    </Tbody>
                </Table>
            </SectionTableWrap>
        </>
    );
};

export default ProductsEditDataTable;

const SectionTableWrap = styled.div`
overflow-x: auto;    
`;

const CellContent = styled.div`
overflow: hidden;
text-overflow: ellipsis;
white-space: nowrap;
`;

const EditButton = styled.button`
cursor: pointer;
appearance: none;
background: transparent;
border: none;
border-radius: 0;
font: inherit;
outline: none;
`;

const ButtonInner = styled.div`
`;

const EditButtonIcon = styled.span`
mask: url(${iconEdit}) no-repeat center center / contain;
-webkit-mask: url(${iconEdit}) no-repeat center center / contain;
display: block;
width: 24px;
height: 24px;
background: var(--color-txt-primary);
`;

const Table = styled.table`
min-width: 100%;
border-collapse: collapse;
margin-bottom: 8px;
background-color: #fff;
border-spacing: 0;
`

const Thead = styled.thead`
border-right: 1px solid var(--color-line-primary);
border-left: 1px solid var(--color-line-primary);
`

const Tr = styled.tr`
border-top: 1px solid var(--color-line-primary);
border-bottom: 1px solid var(--color-line-primary);
`

const Th = styled.th`
font-size: 13px;
font-weight: 700;
line-height: 1.25;
color: var(--color-site-primary);
padding: 12px;
align-content: center;
overflow: hidden;
text-overflow: ellipsis;
white-space: nowrap;
min-height: 40px;
`

const ThInner = styled.div`
width: fit-content;
display: flex;
align-items: center;
flex-direction: row;
flex-wrap: nowrap;
justify-content: flex-start;
gap: 4px;
white-space: nowrap;
`;

const Tbody = styled.tbody`
border-right: 1px solid var(--color-line-primary);
border-left: 1px solid var(--color-line-primary);
`;

const Td = styled.td`
font-size: 14px;
font-weight: 500;
line-height: 1.25;
padding: 12px;
align-content: center;
overflow: hidden;
text-overflow: ellipsis;
white-space: nowrap;
min-height: 40px;
`;


// ページネーション
const IconArrowLeft = styled.span`
mask: url(${iconArrowLeft}) no-repeat center center / contain;
-webkit-mask: url(${iconArrowLeft}) no-repeat center center / contain;
display: block;
width: 100%;
height: 100%;
width: 20px;
height: 20px;
background: var(--color-txt-primary);
`;

const IconArrowRight = styled.span`
mask: url(${iconArrowRight}) no-repeat center center / contain;
-webkit-mask: url(${iconArrowRight}) no-repeat center center / contain;
display: block;
width: 100%;
height: 100%;
width: 20px;
height: 20px;
background: var(--color-txt-primary);
`;

const PageOptionArrowButton = styled.button`
height: 18px;
width: 24px;
cursor: pointer;
text-decoration: none;
color: inherit;
appearance: none;
background: transparent;
border: none;
border-radius: 0;
font: inherit;
outline: none;
`;

const PageOptionButton = styled.button`
cursor: pointer;
width: 34px;
height: 24px;
appearance: none;
background: transparent;
border: none;
border-radius: 0;
font: inherit;
outline: none;
`;

const PageOptionInner = styled.span<{ disabled: boolean }>`
display: block;
width: 100%;
height: 100%;
font-size: 13px;
line-height: 20px;
font-weight: 500;
border: 1px solid var(--color-line-primary);
border-radius: 50%;
text-align: center;
color: ${({ disabled }) => (disabled ? '#fff' : 'inherit')};
background-color: ${({ disabled }) => (disabled ? 'var(--color-site-secondary)' : 'transparent')};
border-color: ${({ disabled }) => (disabled ? 'var(--color-site-secondary)' : 'var(--color-line-primary)')};
`;

const Label = styled.label`
cursor: pointer;
`;

const PageNationViewSelect = styled.select`
padding: 6px 20px 6px 20px;
font-size: 13px;
margin: 8px 0;
position: relative;
cursor: pointer !important;
border: 1px solid var(--color-line-primary);
border-radius: 4px;
background-color: #fff;
font-weight: 400;
line-height: 1.25;
font: inherit;
outline: none;
`;

const PageNationWrapper = styled.div`
gap: 0 24px;
align-items: center;
`;

const DisplayCount = styled.span`
margin-left: 10px;
font-size: 16px;
font-weight: 500;
line-height: 1.25;
`;

const DisplayCountView = styled.span`
font-size: 16px;
font-weight: 500;
line-height: 1.25;
`;

