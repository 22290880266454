import React from 'react';
import styled from 'styled-components';
import { ButtonProps as MUIButtonProps } from '@mui/material';
import iconDelete from '@images/table/icon_delete.svg';

interface CustomButtonProps extends MUIButtonProps {
  onClick: () => void;
}

// const handleDelete = async(id: string) => {
//     const isConfirmed = window.confirm(t('削除しますか？'));
//     if (isConfirmed) {
//       try {
//         // await api
//         await window.location.reload();
//       } catch (error) {
//         console.log(error)
//       }
//     }
// }

const DeleteIconButton: React.FC<CustomButtonProps> = ({ onClick }) => {
  return (
    <>
        <DeleteButton type='button' onClick={onClick}>
            <ButtonInner>
                <DeleteButtonIcon />
            </ButtonInner>
        </DeleteButton>
    </>
  );
};

export default DeleteIconButton;

const DeleteButton = styled.button`
cursor: pointer;
appearance: none;
background: transparent;
border: none;
border-radius: 0;
font: inherit;
outline: none;
`;
const ButtonInner = styled.div`
`;
const DeleteButtonIcon = styled.span`
mask: url(${iconDelete}) no-repeat center center / contain;
-webkit-mask: url(${iconDelete}) no-repeat center center / contain;
display: block;
width: 30px;
height: 30px;
background: var(--color-txt-primary);
`