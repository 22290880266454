import React, { useState, useCallback, useRef, useEffect } from 'react';
import {
    flexRender,
    getCoreRowModel,
    useReactTable,
    getPaginationRowModel,
    getSortedRowModel,
    ColumnDef,
    SortingState,
    createColumnHelper,
    CellContext,
} from '@tanstack/react-table';
import { useDrag, useDrop } from 'react-dnd';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import iconArrowBack from '@images/table/icon_arrow_back.svg';
import { CaseStudyLCIAResults, ResultItems0, ResultItems1 } from '@typeList/types';


import iconArrowDown from '@images/table/icon_arrow_down.svg'
import iconArrowUp from '@images/table/icon_arrow_up.svg'

interface TableProps {
    columns: ColumnDef<any, any>[];
    resultData: any[];
    paging_flag: boolean;
    selectedRows: { [key: string]: boolean };
    setSelectedRows: React.Dispatch<React.SetStateAction<{ [key: string]: boolean } | null>>;
    exportSet: (columns: any, data: any) => void;
}

const initialPageIndex = 0;
const initialPageSize = 100000;

interface DragItem {
    index: number;
    id: string;
    type: string;
}

interface ExtendedColumnMeta {
    editable?: boolean;
}

const DraggableHeader: React.FC<{
    header: any;
    index: number;
    moveColumn: (dragIndex: number, hoverIndex: number) => void;
}> = ({ header, index, moveColumn }) => {
    const ref = useRef<HTMLTableCellElement>(null);

    const [, drop] = useDrop<DragItem>({
        accept: 'COLUMN',
        hover: (item, monitor) => {
            if (!ref.current) {
                return;
            }
            const dragIndex = item.index;
            const hoverIndex = index;

            // ドラッグしている列とホバーしている列が同じ場合は何もしない
            if (dragIndex === hoverIndex) {
                return;
            }

            const hoverBoundingRect = ref.current.getBoundingClientRect();
            const hoverMiddleX = (hoverBoundingRect.right - hoverBoundingRect.left) / 2;
            const clientOffset = monitor.getClientOffset();
            const hoverClientX = clientOffset!.x - hoverBoundingRect.left;

            // ドラッグしている方向と反対側には移動しないようにする
            if (dragIndex < hoverIndex && hoverClientX < hoverMiddleX) {
                return;
            }

            if (dragIndex > hoverIndex && hoverClientX > hoverMiddleX) {
                return;
            }

            moveColumn(dragIndex, hoverIndex);
            item.index = hoverIndex;
        },
    });

    const [{ isDragging }, drag] = useDrag({
        type: 'COLUMN',
        item: { type: 'COLUMN', index },
        collect: (monitor) => ({
            isDragging: monitor.isDragging(),
        }),
    });

    drag(drop(ref));

    return (
        <StyledTh 
            ref={ref} 
            onClick={header.column.getToggleSortingHandler()}
            style={{
                opacity: isDragging ? 0.5 : 1,
                cursor: isDragging ? 'grabbing' : 'grab',
            }}
        >
            <StyledThInner>
                {flexRender(header.column.columnDef.header, header.getContext())}
                {header.column.getIsSorted() ? (header.column.getIsSorted() === 'desc' ? <IconArrowDown/> : <IconArrowUp/>) : ''}
            </StyledThInner>
        </StyledTh>
    );
};

const DraggableRow: React.FC<{
    row: any;
    index: number;
    moveRow: (dragIndex: number, hoverIndex: number) => void;
}> = ({ row, index, moveRow }) => {
    const ref = useRef<HTMLTableRowElement>(null);

    const [, drop] = useDrop({
        accept: 'ROW',
        hover: (item: DragItem, monitor) => {
            if (!ref.current) {
                return;
            }
            const dragIndex = item.index;
            const hoverIndex = index;

            if (dragIndex === hoverIndex) {
                return;
            }

            const hoverBoundingRect = ref.current.getBoundingClientRect();
            const hoverMiddleY = (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2;
            const clientOffset = monitor.getClientOffset();
            const hoverClientY = clientOffset!.y - hoverBoundingRect.top;

            if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) {
                return;
            }

            moveRow(dragIndex, hoverIndex);
            item.index = hoverIndex;
        },
    });

    const [{ isDragging }, drag] = useDrag({
        type: 'ROW',
        item: { index },
        collect: (monitor) => ({
            isDragging: monitor.isDragging(),
        }),
    });

    drag(drop(ref));

    return (
        <StyledTr ref={ref} style={{ background: row.index % 2 === 0 ? '#fafafa' : '#fff' }}>
            {row.getVisibleCells().map((cell: any) => (
                <StyledTd key={cell.id} style={{ padding: '8px' }} title={String(cell.getValue())}>
                    <StyledTdText>
                        {flexRender(cell.column.columnDef.cell, cell.getContext())}
                    </StyledTdText>
                </StyledTd>
            ))}
        </StyledTr>
    );
};


const CaseStudyImpactAssesmentMethodsTable: React.FC<TableProps> = ({ columns: initialColumns, resultData, paging_flag, selectedRows, setSelectedRows, exportSet }) => {
    const { t } = useTranslation();
    const [tableData, setTableData] = useState<any[]>([]);
    const [sorting, setSorting] = useState<SortingState>([]);
    const [columns, setColumns] = useState(initialColumns);

    useEffect(() => {
        setColumns(initialColumns);
    },[initialColumns])
    useEffect(() => {
        setTableData(resultData);
    }, [resultData]);

    // エクスポート用にセット
    useEffect(() =>{
        exportSet(columns, tableData);
    },[columns, tableData])

    const moveColumn = useCallback(
        (dragIndex: number, hoverIndex: number) => {
            const newColumns = [...columns];
            const [movedColumn] = newColumns.splice(dragIndex, 1);
            newColumns.splice(hoverIndex, 0, movedColumn);
            setColumns(newColumns);
        },
        [columns]
    );

    const moveRow = useCallback(
        (dragIndex: number, hoverIndex: number) => {
            const updatedData = [...tableData];
            const [movedRow] = updatedData.splice(dragIndex, 1);
            updatedData.splice(hoverIndex, 0, movedRow);
            setTableData(updatedData);
        },
        [tableData]
    );

    const table = useReactTable({
        data: tableData,
        columns,
        state: { sorting },
        onSortingChange: setSorting,
        initialState: {
            pagination: {
                pageIndex: initialPageIndex,
                pageSize: initialPageSize,
            },
        },
        getCoreRowModel: getCoreRowModel(),
        getPaginationRowModel: getPaginationRowModel(),
        getSortedRowModel: getSortedRowModel(), 
    });
    return (
        <Section>
            {paging_flag && (
                <div style={{ margin: '5px' }}>
                    <select
                        style={{ margin: '5px' }}
                        value={table.getState().pagination.pageSize}
                        onChange={(e) => {
                            table.setPageSize(Number(e.target.value));
                        }}
                    >
                        {[10, 20, 30].map((pageSize) => (
                            <option key={pageSize} value={pageSize}>
                                {t('表示数')} {pageSize}
                            </option>
                        ))}
                    </select>
                    <button onClick={() => table.previousPage()} disabled={!table.getCanPreviousPage()}>
                        {'<'}
                    </button>
                    {table.getPageOptions().map((page) => (
                        <button
                            key={page}
                            onClick={() => table.setPageIndex(page)}
                            disabled={table.getState().pagination.pageIndex === page}
                        >
                            {page + 1}
                        </button>
                    ))}
                    <button onClick={() => table.nextPage()} disabled={!table.getCanNextPage()}>
                        {'>'}
                    </button>
                </div>
            )}

            <StyledTable style={{ borderSpacing: 0, border: '1px solid #ddd', width: '100%' }}>
                <StyledThead>
                    {table.getHeaderGroups().map(headerGroup => (
                        <tr key={headerGroup.id}>
                            {/* <StyledTh>削除</StyledTh> */}
                            {headerGroup.headers.map((header, index) => (
                                <DraggableHeader
                                    key={header.id}
                                    header={header}
                                    index={index}
                                    moveColumn={moveColumn}
                                />
                            ))}
                        </tr>
                    ))}
                </StyledThead>
                <StyledTbody>
                    {table.getRowModel().rows.map((row, rowIndex) => (
                        <DraggableRow key={row.id} row={row} index={rowIndex} moveRow={moveRow} />
                    ))}
                </StyledTbody>
            </StyledTable>
            <div className="h-4" />
        </Section>
    );
};

export default CaseStudyImpactAssesmentMethodsTable;

const Section = styled.div`
    overflow: auto;
`

const StyledTable = styled.table`
    min-width: 100%;
    border-collapse: collapse;
    margin-bottom: 8px;
    background-color: #fff;
`;

const StyledThead = styled.thead`
    border-right: 1px solid var(--color-line-primary);
    border-left: 1px solid var(--color-line-primary);
`;

const StyledTbody = styled.tbody`
    text-align: center;
    border-right: 1px solid var(--color-line-primary);
    border-left: 1px solid var(--color-line-primary);
`;

const StyledTh = styled.th`
    cursor: pointer;
    font-size: 13px;
    font-weight: 700;
    text-align: center;
    line-height: 1.25;
    color: var(--color-site-primary);
    padding: 12px;
    align-content: center;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    text-align: left;
`;

const StyledThInner = styled.div`
    width: fit-content;
    display: flex;
    align-items: center;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: flex-start;
    gap: 4px;
`;

const StyledTd = styled.td`
    cursor: grab;
    text-align: left;
    font-size: 14px;
    font-weight: 500;
    line-height: 1.25;
    padding: 12px;
    align-content: center;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    max-width: 150px;
    &:hover {
        white-space: normal;
        overflow: visible;
        background-color: #f1f1f1;
        z-index: 1;
    }
`;

const StyledTdText = styled.div`
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    text-align: left;
`

const StyledTr = styled.tr`
    border-top: 1px solid var(--color-line-primary);
    border-bottom: 1px solid var(--color-line-primary);
`;

const IconArrowDown = styled.span`
    mask: url(${iconArrowDown}) no-repeat center center / contain;
    -webkit-mask: url(${iconArrowDown}) no-repeat center center / contain;
    display: block;
    width: 20px;
    height: 20px;
    background: var(--color-txt-primary);
`;

const IconArrowUp = styled.span`
    mask: url(${iconArrowUp}) no-repeat center center / contain;
    -webkit-mask: url(${iconArrowUp}) no-repeat center center / contain;
    display: block;
    width: 20px;
    height: 20px;
    background: var(--color-txt-primary);
`;
