import React, { useState, useCallback, useRef, Dispatch, SetStateAction, ChangeEvent, useContext, useEffect } from 'react';
import {
    flexRender,
    getCoreRowModel,
    useReactTable,
    getPaginationRowModel,
    getSortedRowModel,
    SortingState,
    createColumnHelper,
} from '@tanstack/react-table';
import { useDrag, useDrop } from 'react-dnd';
import styled, { css } from 'styled-components';
import { useTranslation } from 'react-i18next';
import { ProcessViewContext } from '@pages/process/ProcessView';
import { AllocationOut, AlternativeOut, Cutoff, DisposalOut, ProcessIosType } from '@typeList/types';

import iconArrowDown from '@images/table/icon_arrow_down.svg'
import iconArrowUp from '@images/table/icon_arrow_up.svg'
import { isEqualWithNullEmpty } from '@utils/utils';

const initialPageIndex = 0;
const initialPageSize = 10000;

interface DragItem {
    index: number;
    id: string;
    type: string;
}

const DraggableHeader: React.FC<{
    header: any;
    index: number;
    moveColumn: (dragIndex: number, hoverIndex: number) => void;
}> = ({ header, index, moveColumn }) => {
    const ref = useRef<HTMLTableCellElement>(null);

    const [, drop] = useDrop({
        accept: 'COLUMN',
        hover: (item: DragItem, monitor) => {
            if (!ref.current) {
                return;
            }
            const dragIndex = item.index;
            const hoverIndex = index;

            if (dragIndex === hoverIndex) {
                return;
            }

            const hoverBoundingRect = ref.current?.getBoundingClientRect();
            const hoverMiddleX = (hoverBoundingRect.right - hoverBoundingRect.left) / 2;
            const clientOffset = monitor.getClientOffset();
            const hoverClientX = clientOffset!.x - hoverBoundingRect.left;

            if (dragIndex < hoverIndex && hoverClientX < hoverMiddleX) {
                return;
            }

            moveColumn(dragIndex, hoverIndex);
            item.index = hoverIndex;
        },
    });

    const [{ isDragging }, drag] = useDrag({
        type: 'COLUMN',
        item: { index },
        collect: (monitor) => ({
            isDragging: monitor.isDragging(),
        }),
    });

    drag(drop(ref));

    return (
        <StyledTh ref={ref} onClick={header.column.getToggleSortingHandler()}>
            <StyledThInner>
                {flexRender(header.column.columnDef.header, header.getContext())}
                {header.column.getIsSorted() ? (header.column.getIsSorted() === 'desc' ? <IconArrowDown /> : <IconArrowUp />) : ''}
            </StyledThInner>
        </StyledTh>
    );
};

/**
 * 
 * @returns 配分方式
 */
const ProsessAllocationMethodDataTable = () => {
    const { t } = useTranslation();
    const context = useContext(ProcessViewContext);
    const {
        outProduct,
        setOutProduct,
        inputAllocationType,
        setInputAllocationType,
        otherRelationshipUnitName,
        setOtherRelationshipUnitName,
        otherRelationshipName,
        setOtherRelationshipName,
        inputAllocationMethod,
        inputAllocationComment,
        inputAllocationCoProductName,
        inputAllocationCoProductComment,
        setInputAllocationMethod,
        setInputAllocationComment,
        setInputAllocationCoProductName,
        setInputAllocationCoProductComment,
        setChangeCheckAllocation,
        processData
    } = context;


    // 入力操作
    const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>, key: string) => {
        switch (key) {
            case "allocation_type":
                setInputAllocationType(event.target.value);
                break;
            case "other_relationship_name":
                setOtherRelationshipName(event.target.value);
                break;
            case "other_relationship_unit_name":
                setOtherRelationshipUnitName(event.target.value);
                break;
            case "allocation_method":
                setInputAllocationMethod(event.target.value);
                break;
            case "allocation_co_product_name":
                setInputAllocationCoProductName(event.target.value);
                break;
        }
    };
    const handleInputTextAreaChange = (event: React.ChangeEvent<HTMLTextAreaElement>, key: string) => {
        switch (key) {
            case "allocation_comment":
                setInputAllocationComment(event.target.value);
                break;
            case "allocation_co_product_comment":
                setInputAllocationCoProductComment(event.target.value);
                break;
        }
    };

    const [filteredData, setFilteredData] = useState(() => (outProduct ?? []).filter(product => product.treatment?.type === 'allocation'));

    useEffect(() => {
        setFilteredData((outProduct ?? []).filter(product => product.treatment?.type === 'allocation'));
    }, [outProduct]);

    // 型ガード関数
    const isAllocationOut = (treatment: AllocationOut | Cutoff | DisposalOut | AlternativeOut | null): treatment is AllocationOut => {
        return treatment !== null && treatment.type === 'allocation';
    }


    const columnHelper = createColumnHelper<any>();
    const createColumns = useCallback(() => [
        columnHelper.accessor('exchange.name', {
            header: () => t('出力製品名'),
            cell: info => info.renderValue(),
        }),
        columnHelper.accessor('amount', {
            header: () => t('質量'),
            cell: info => info.renderValue(),
        }),
        columnHelper.accessor('additionalInfo', {
            header: () => t('価格'),
            cell: info => info.renderValue(),
        }),
        columnHelper.accessor('other_relationship_amount', {
            id: 'other_relationship_amount',
            header: () => otherRelationshipName,
            // cell: info => (info.getValue() ?? '') + otherRelationshipUnitName,
            cell: info => {
                const rowIndex = info.row.index;
                const data = filteredData[rowIndex];
                
                if (data && data.treatment && isAllocationOut(data.treatment) && outProduct) {
                    return (
                        <>
                            {!processData?.is_database_pack_master &&
                                <CommentInput
                                    type="number"
                                    value={data.treatment.other_relationship_amount ?? ''}
                                    onChange={(e) => {
                                        const newValue = Number(e.target.value);
                                        const updatedData = [...filteredData];
                
                                        // process_io_noで一致するoutProductのtreatmentを取得
                                        const updatedOutProduct = [...outProduct];
                                        const matchingOutProduct = updatedOutProduct.find(product => product.process_io_no === updatedData[rowIndex].process_io_no);
                
                                        if (matchingOutProduct && matchingOutProduct.treatment && isAllocationOut(matchingOutProduct.treatment)) {
                                            // updatedData と outProduct の treatment を更新
                                            const treatment = updatedData[rowIndex].treatment;
                                            const outProductTreatment = matchingOutProduct.treatment;
                
                                            if (treatment && isAllocationOut(treatment)) {
                                                treatment.other_relationship_amount = newValue;
                                                outProductTreatment.other_relationship_amount = newValue;
                
                                                setFilteredData(updatedData);
                                                setOutProduct(updatedOutProduct); // outProductも更新
                                                setChangeCheckAllocation(true);
                                            }
                                        }
                                    }}
                                />
                            }
                            {processData?.is_database_pack_master && 
                            <DisabledInput value={data.treatment.other_relationship_amount ?? ''} readOnly></DisabledInput>
                            }
                            <UnitName>{otherRelationshipUnitName}</UnitName>
                        </>
                    );
                }
                return null;
            },
        }),
        columnHelper.accessor('treatment.allocation_rate', {
            header: () => t('配分比'),
            cell: info => info.renderValue(),
        }),
    ], [filteredData, otherRelationshipName, otherRelationshipUnitName]);

    const [columns, setColumns] = useState(createColumns);
    const [sorting, setSorting] = useState<SortingState>([]);

    useEffect(() => {
        setColumns(createColumns());
    }, [otherRelationshipName, createColumns]);

    const moveColumn = useCallback(
        (dragIndex: number, hoverIndex: number) => {
            const newColumns = [...columns];
            const [movedColumn] = newColumns.splice(dragIndex, 1);
            newColumns.splice(hoverIndex, 0, movedColumn);
            setColumns(newColumns);
        },
        [columns]
    );

    const table = useReactTable({
        data: filteredData,
        columns,
        state: { sorting },
        onSortingChange: setSorting,
        initialState: {
            pagination: {
                pageIndex: initialPageIndex,
                pageSize: initialPageSize,
            },
        },
        getCoreRowModel: getCoreRowModel(),
        getPaginationRowModel: getPaginationRowModel(),
        getSortedRowModel: getSortedRowModel(),
    });

    // 変更監視
    useEffect(() => {
        if (
            !isEqualWithNullEmpty(inputAllocationType, processData?.allocation_type)
            || !isEqualWithNullEmpty(otherRelationshipName, processData?.other_relationship_name)
            || !isEqualWithNullEmpty(otherRelationshipUnitName, processData?.other_relationship_unit_name)
            || !isEqualWithNullEmpty(inputAllocationMethod, processData?.allocation_method)
            || !isEqualWithNullEmpty(inputAllocationComment, processData?.allocation_comment)
            || !isEqualWithNullEmpty(inputAllocationCoProductComment, processData?.allocation_co_product_comment)
            || !isEqualWithNullEmpty(inputAllocationCoProductName, processData?.allocation_co_product_name)
        ) {
            setChangeCheckAllocation(true);
        }
    }, [
        inputAllocationType, 
        otherRelationshipName, 
        otherRelationshipUnitName, 
        inputAllocationMethod,
        inputAllocationComment,
        inputAllocationCoProductComment,
        inputAllocationCoProductName
    ])

    return (
        <>
            <H2>{t('配分方式')}</H2>
            <AllocationMethodWrap>
                <AllocationMethodRadioWrap>
                    <RadioInput
                        type="radio"
                        id="1"
                        value="mass"
                        checked={inputAllocationType === "mass"}
                        onChange={(event) => handleInputChange(event, "allocation_type")}
                        disabled={processData?.is_database_pack_master} // 条件付きで無効化
                    />
                    <RadioLabel htmlFor="1">
                        <RadioText>
                        {t('質量基準')}
                        </RadioText>
                    </RadioLabel>

                    <RadioInput
                        type="radio"
                        id="2"
                        value="economic"
                        checked={inputAllocationType === "economic"}
                        onChange={(event) => handleInputChange(event, "allocation_type")}
                        disabled={processData?.is_database_pack_master} // 条件付きで無効化
                    />
                    <RadioLabel htmlFor="2">
                        <RadioText>
                        {t('市場価格基準')}
                        </RadioText>
                    </RadioLabel>

                    <RadioInput
                        type="radio"
                        id="3"
                        value="other"
                        checked={inputAllocationType === "other"}
                        onChange={(event) => handleInputChange(event, "allocation_type")}
                        disabled={processData?.is_database_pack_master} // 条件付きで無効化
                    />
                    <RadioLabel htmlFor="3">
                        <RadioText>
                        {t('その他基準')}
                        </RadioText>
                    </RadioLabel>
                </AllocationMethodRadioWrap>

                <AllocationMethodOtherWrap>
                    <InputWrapText>{t('基準名')}</InputWrapText>
                    {!processData?.is_database_pack_master &&
                        <InputBaseName
                            type="text"
                            value={otherRelationshipName}
                            onChange={(event) => handleInputChange(event, "other_relationship_name")}
                        />
                    }
                    {processData?.is_database_pack_master &&
                        <DisabledInputBaseName 
                            disabled
                            type="text"
                            value={otherRelationshipName}
                            readOnly
                        />
                    }
                </AllocationMethodOtherWrap>
                <AllocationMethodOtherWrap>
                    <InputWrapText>{t('単位')}</InputWrapText>
                    {!processData?.is_database_pack_master && 
                        <InputBaseName
                            type="text"
                            value={otherRelationshipUnitName}
                            onChange={(event) => handleInputChange(event, "other_relationship_unit_name")}
                        />
                    }
                    {processData?.is_database_pack_master &&
                        <DisabledInputBaseName
                            disabled
                            type="text"
                            value={otherRelationshipUnitName}
                            readOnly
                        />
                    }
                </AllocationMethodOtherWrap>

            </AllocationMethodWrap>
            <TableWrapper>
                <StyledTable>
                    <colgroup>
                        <col width="261px" />
                        <col width="261px" />
                        <col width="261px" />
                        <col width="261px" />
                        <col width="262px" />
                    </colgroup>
                    <StyledThead>
                        {table.getHeaderGroups().map(headerGroup => (
                            <StyledThTr key={headerGroup.id}>
                                {headerGroup.headers.map((header, index) => (
                                    <DraggableHeader
                                        key={header.id}
                                        header={header}
                                        index={index}
                                        moveColumn={moveColumn}
                                    />
                                ))}
                            </StyledThTr>
                        ))}
                    </StyledThead>
                    <StyledTbody>
                        {table.getRowModel().rows.map(row => (
                            <StyledTr key={row.id} $isEven={row.index % 2 === 0}>
                                {row.getVisibleCells().map(cell => (
                                    <StyledTd key={cell.id}>
                                        <StyledTdText>{flexRender(cell.column.columnDef.cell, cell.getContext())}</StyledTdText>
                                    </StyledTd>
                                ))}
                            </StyledTr>
                        ))}
                    </StyledTbody>
                </StyledTable>
                <div className="h-4" />
            </TableWrapper>

            <CommentWrap>
                <CommentTitle>{t('配分手法')}</CommentTitle>
                {!processData?.is_database_pack_master &&
                    <CommentInput
                        type="text"
                        value={inputAllocationMethod || ''}
                        onChange={(event) => handleInputChange(event, "allocation_method")}
                    />
                }
                {processData?.is_database_pack_master &&
                    <DisabledInput
                        type="text"
                        value={inputAllocationMethod || ''}
                        disabled
                        readOnly
                    />
                }
            </CommentWrap>

            <CommentWrap>
                <CommentTitle>{t('配分に関するコメント')}</CommentTitle>

                {!processData?.is_database_pack_master && 
                    <CommentTextArea
                        cols={100}
                        rows={3}
                        value={inputAllocationComment || ''}
                        onChange={(event) => handleInputTextAreaChange(event, "allocation_comment")}
                    />
                }
                {processData?.is_database_pack_master &&
                    <DisabledTextArea
                        cols={100}
                        rows={3}
                        value={inputAllocationComment || ''}
                        disabled
                        readOnly
                    />
                }  
            </CommentWrap>

            <CommentWrap>
                <CommentTitle>{t('共製品')}</CommentTitle>
                {!processData?.is_database_pack_master &&
                    <CommentInput
                        type="text"
                        value={inputAllocationCoProductName || ''}
                        onChange={(event) => handleInputChange(event, "allocation_co_product_name")}
                    />
                }
                {processData?.is_database_pack_master &&
                    <DisabledInput
                        type="text"
                        value={inputAllocationCoProductName || ''}
                        disabled
                        readOnly
                    />
                }
            </CommentWrap>
            <CommentWrap>
                <CommentTitle>{t('共製品に関するコメント')}</CommentTitle>
                {!processData?.is_database_pack_master && 
                    <CommentTextArea
                        cols={100}
                        rows={3}
                        value={inputAllocationCoProductComment || ''}
                        onChange={(event) => handleInputTextAreaChange(event, "allocation_co_product_comment")}
                    />
                }
                {processData?.is_database_pack_master &&
                    <DisabledTextArea
                        cols={100}
                        rows={3}
                        value={inputAllocationCoProductComment || ''}
                        disabled
                        readOnly
                    />
                }               
            </CommentWrap>
        </>
    );
};

export default ProsessAllocationMethodDataTable;


const TableWrapper = styled.div`
    overflow-x: auto;
    margin-bottom: 32px;
`;

const StyledTable = styled.table`
    min-width: 100%;
    border-collapse: collapse;
    margin-bottom: 8px;
    background-color: #fff;
`;

const StyledThead = styled.thead`
    border-right: 1px solid var(--color-line-primary);
    border-left: 1px solid var(--color-line-primary);
`

const StyledTbody = styled.tbody`
    text-align: center;
    border-right: 1px solid var(--color-line-primary);
    border-left: 1px solid var(--color-line-primary);
`

const StyledTh = styled.th`
    cursor: grab;

    &:active {
    cursor: grabbing;
    }
    font-size: 13px;
    font-weight: 700;
    text-align: left;
    line-height: 1.25;
    color: var(--color-site-primary);
    padding: 12px;
    align-content: center;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    }
`;

const StyledThInner = styled.div`
    width: fit-content;
    display: flex;
    align-items: center;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: flex-start;
    gap: 4px;
    white-space: nowrap;
`

const StyledTd = styled.td`
    font-size: 14px;
    font-weight: 500;
    line-height: 1.25;
    padding: 12px;
    align-content: center;
    overflow: hidden;
    text-overflow: ellipsis;

    white-space: nowrap;
    overflow: hidden;
    max-width: 150px; /* 幅を調整 */
    &:hover {
        white-space: normal;
        overflow: visible;
        background-color: #f1f1f1;
        z-index: 1;
    }
}
`;

const StyledTdText = styled.div`
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    text-align: left;
`


const StyledThTr = styled.tr`
    border-top: 1px solid var(--color-line-primary);
    border-bottom: 1px solid var(--color-line-primary);
`

const StyledTr = styled.tr<{ $isEven: boolean }>`
    border-top: 1px solid var(--color-line-primary);
    border-bottom: 1px solid var(--color-line-primary);
    ${(props) => props.$isEven && css`
        background-color: #f9f9f9;
    `}
`;

const IconArrowDown = styled.span`
    mask: url(${iconArrowDown}) no-repeat center center / contain;
    -webkit-mask: url(${iconArrowDown}) no-repeat center center / contain;
    display: block;
    width: 100%;
    height: 100%;
    width: 20px;
    height: 20px;
    background: var(--color-txt-primary);
}
`

const IconArrowUp = styled.span`
    mask: url(${iconArrowUp}) no-repeat center center / contain;
    -webkit-mask: url(${iconArrowUp}) no-repeat center center / contain;
    display: block;
    width: 100%;
    height: 100%;
    width: 20px;
    height: 20px;
    background: var(--color-txt-primary);
    }
`

const H2 = styled.h2`
    padding: 0 0 20px;
    margin-bottom: 20px;
    border-bottom: 1px solid var(--color-line-primary);
    font-size: 16px;
    font-weight: 500;
    line-height: 1.25;
`

const AllocationMethodWrap = styled.div`
    display: flex;
    align-items: center;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: flex-start;
    gap: 24px;
    margin-bottom: 20px;
`

const AllocationMethodRadioWrap = styled.div`
    gap: 24px;
    display: flex;
    align-items: center;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: flex-start;
`

const AllocationMethodOtherWrap = styled.div`
    display: flex;
    align-items: center;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: flex-start;
    gap: 8px;
`

const RadioInput = styled.input`
    display: none;

    &:checked + label::before {
        border-color: var(--color-site-secondary);
        background-color: var(--color-site-secondary);
    }

    &:checked:disabled + label::before {
        border-color: gray;
        background-color: gray;
    }

    &:checked + label::after {
        position: absolute;
        top: 50%;
        left: 8px;
        width: 4.5px;
        height: 4.5px;
        content: "";
        border-radius: 50%;
        background-color: #fff;
        transform: translate(-50%, -50%);
    }

    &:not(:checked) + label .c-input,
    &:not(:checked) + label .c-select,
    &:not(:checked) + label .c-select__inner {
        pointer-events: none;
        color: var(--color-line-primary) !important;
    }
`;

const RadioLabel = styled.label`
    display: flex;
    align-items: center;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: flex-start;
    gap: 6px;
    width: fit-content;
    cursor: pointer;
    position: relative;

    &::before {
        content: "";
        width: 14px;
        height: 14px;
        margin: 0;
        cursor: pointer;
        border: 1px solid var(--color-txt-primary);
        border-radius: 50%;
        background-color: #fff;
    }
`;

const RadioText = styled.span`
    display: flex;
    align-items: center;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: flex-start;
    gap: 0 20px;
    font-size: 16px;
    white-space: nowrap;
    font-weight: 500;
`;

const InputWrapText = styled.span`
    font-size: 16px;
    font-weight: 500;
`

const InputBaseName = styled.input`
    width: 190px;
    padding: 12px;
    color: var(--color-txt-primary);
    border: 1px solid var(--color-line-primary);
    border-radius: 4px;
    background-color: #fff;
    font-size: 16px;
    font-weight: 400;
    line-height: 1.25;
`

const DisabledInputBaseName = styled.input`
    width: 190px;
    padding: 12px;
    color: var(--color-txt-primary);
    border: 1px solid var(--color-line-primary);
    border-radius: 4px;
    background-color: var(--color-bg-primary);
    font-size: 16px;
    font-weight: 400;
    line-height: 1.25;
`

const CommentWrap = styled.section`
    margin-bottom: 20px;
    display: block;
`

const CommentTitle = styled.h2`
    font-size: 16px;
    font-weight: 500;
    margin-bottom: 12px;
    line-height: 1.25;
`

const CommentInput = styled.input`
    width: 50%;
    padding: 12px;
    color: var(--color-txt-primary);
    border: 1px solid var(--color-line-primary);
    border-radius: 4px;
    background-color: #fff;
    font-size: 16px;
    font-weight: 400;
    line-height: 1.25;
`

const DisabledInput = styled.input`
    width: 50%;
    padding: 12px;
    color: var(--color-txt-primary);
    border: 1px solid var(--color-line-primary);
    border-radius: 4px;
    background-color: var(--color-bg-primary);
    font-size: 16px;
    font-weight: 400;
    line-height: 1.25;
`

const CommentTextArea = styled.textarea`
    padding: 12px;
    color: var(--color-txt-primary);
    border: 1px solid var(--color-line-primary);
    border-radius: 4px;
    background-color: #fff;
    font-size: 16px;
    font-weight: 400;
    line-height: 1.25;
    width: 100%;
`

const DisabledTextArea = styled.textarea`
    padding: 12px;
    color: var(--color-txt-primary);
    border: 1px solid var(--color-line-primary);
    border-radius: 4px;
    background-color: var(--color-bg-primary);
    font-size: 16px;
    font-weight: 400;
    line-height: 1.25;
    width: 100%;
`

const UnitName = styled.span`
    margin-left: 10px;
`