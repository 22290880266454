import React, { useContext, useEffect, useState } from 'react'
import Modal from '@common/modal/Modal';
import { createColumnHelper } from '@tanstack/react-table';
import { useSearchElementaryFlows } from '@hooks/useBackendApi';
import { SearchElementaryFlows } from '@typeList/types';
import SubsystemOutputElementaryFlowSelectTable from '@common/table/SubsystemOutputElementaryFlowSelectTable';
import { CaseStudyViewContext } from '@pages/case_study/CaseStudyView';
import styled from 'styled-components';
import TablePageNation from '@common/pagenation/TablePageNation';
import { useTranslation } from 'react-i18next';
import { FadeLoader } from 'react-spinners';

interface SubsystemInputElementaryFlowSearchModalProps {
    currentNodeData: any;
    isOpen: boolean;
    onClose: () => void;
}

/**
 * 製品システム　基本フロー検索（基本フロー選択） 出力基本フロー
 * @returns 
 */
const SubsystemOutputElementaryFlowSearchModal: React.FC<SubsystemInputElementaryFlowSearchModalProps> = ({
    currentNodeData,
    isOpen,
    onClose,
}) => {
    const {t} = useTranslation();
    const context = useContext(CaseStudyViewContext);
    const { caseStudyData } = context;

    const [isModalOpen, setIsModalOpen] = useState(false);
    const openModal = () => setIsModalOpen(true);
    const closeModal = () => setIsModalOpen(false);

    // テキスト入力
    const [inputSearchText, setInputSearchText] = useState<string>("");
    //  基本フロー名で検索 or 基本フロー情報に含まれる文言で検索
    const [inputFulltextSearch, setInputFulltextSearch] = useState<boolean>(false);
    const [inputFulltextSearchRadio, setInputFulltextSearchRadio] = useState<string>("1");
    const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>, key: string) => {
        switch (key) {
            case "search_text":
                setInputSearchText(event.target.value);
                break;
            case "is_fulltext_search":
                if (event.target.value === "1") {
                    setInputFulltextSearch(false);
                    setInputFulltextSearchRadio("1");
                }
                if (event.target.value === "2") {
                    setInputFulltextSearch(true);
                    setInputFulltextSearchRadio("2");
                }
                break;
        }
    };


    const [tableData, setTableData] = useState<any | null>(null);
    const [tableDataLoading, setTableDataLoading] = useState<boolean>(false);
    const [tableDataCount, setTableDataCount] = useState<number>(0);


    // カテゴリのチェックボックスの状態を保持する配列
    const [selectedCategories, setSelectedCategories] = useState<number[]>([]);

    const handleCategoryChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const { value, checked } = event.target;
        const categoryValue = Number(value);
        setSelectedCategories((prevCategories) =>
            checked
                ? [...prevCategories, categoryValue]
                : prevCategories.filter((category) => category !== categoryValue)
        );
    };


    // ページング
    const [page, setPage] = useState<number>(1)
    const [pageSize, setPageSize] = useState<number>(10)

    // 検索
    const { searchElementaryFlows,
        searchElementaryFlowsData,
        searchElementaryFlowsLoading,
        searchElementaryFlowsError
    } = useSearchElementaryFlows();

    const [isDisabled, setIsDisabled] = useState(false);
    useEffect(() => {
        if(tableDataLoading){
            setIsDisabled(true);
        } else {
            setIsDisabled(false);
        }
    },[tableDataLoading])

    const handleSearchButtonClick = async () => {
        if(page !== 1){
            setPage(1)
        } else {
            search();
        }
    };

    const search = async() =>{
        setTableDataLoading(true);

        if (isDisabled) return;

        try {
            const searchResults = await searchElementaryFlows(
                caseStudyData?.database_pack.id,
                inputSearchText,
                page,
                pageSize,
                1,
                selectedCategories,
                inputFulltextSearch,
                false
            );
            await flattenedData(searchResults.results);
            // console.log("searchResults", searchResults);
        } catch (error) {
            console.log(error);
        } finally {
            setTableDataLoading(false);
        }
    }

    // API呼び出し
    useEffect(() => {
        if(isOpen){
            search();
        }
       
    }, [page])

    // 表示切替時はページをリセット
    useEffect(() =>{
        if(page === 1 && isOpen) {
            search();
        }
        setPage(1)
    },[pageSize])

    // テーブル表示用にフラットなデータに変換する
    const flattenedData = async (jsonData: SearchElementaryFlows[]) => {
        if (jsonData === null) {
            return;
        }

        const flatData = jsonData.flatMap((item: SearchElementaryFlows) => {
            return [{
                id: item.id,
                name: item.name,
                unitLocale: item.unit.locale,
                unitId: item.unit.id,
                unitCode: item.unit.code,
                unitName: item.unit.name,
                unit_group_id: item.unit.unit_group_id,
                synonyms_text: item.synonyms_text,
                include_items_text: item.include_items_text,
                related_items_text: item.related_items_text,
                public_comment: item.public_comment,
                private_comment: item.private_comment,
                identifierScValue: null,
                identifierSheme: null,
                global_id: item.global_id,
                locale: item.locale,
                category1_code: item.category1_code,
                category2_code: item.category2_code,
                category3_code: item.category3_code,
                category1_name: item.category1_name,
                category2_name: item.category2_name,
                category3_name: item.category3_name,
            }];
        });

        setTableData(flatData);
        setTableDataCount(flatData.length);
        // console.log("TableData", tableData);
    };


    const columnHelper = createColumnHelper<any>();
    const columns = [
        columnHelper.accessor('name', {
            header: () => t('名前'),
            cell: info => info.renderValue(),
        }),
        columnHelper.accessor('unitCode', {
            header: () => t('単位'),
            cell: info => info.renderValue(),
        }),
        columnHelper.accessor('category1_name', {
            header: () => t('カテゴリ1'),
            cell: info => info.renderValue(),
        }),
        columnHelper.accessor('category2_name', {
            header: () => t('カテゴリ2'),
            cell: info => info.renderValue(),
        }),
        columnHelper.accessor('category3_name', {
            header: () => t('カテゴリ3'),
            cell: info => info.renderValue(),
        }),
    ];


    const categories = [
        { id: 1, name: t('水圏') },
        { id: 2, name: t('大気') },
        { id: 3, name: t('陸域') },
        { id: 4, name: t('生物系') },
        { id: 5, name: t('土地利用') },
        { id: 0, name: t('不明') }
    ];

    return (
        <>
            <Modal isOpen={isOpen} onClose={onClose}>
                <Inner>
                    <Title>{t('基本フロー検索')}</Title>
                    <SectionBody>
                        <SearchBox>
                            <SearchWrap>
                                <InputSerch
                                    type="search"
                                    value={inputSearchText ?? ""}
                                    onChange={(event) => handleInputChange(event, "search_text")}
                                    onKeyDown={(event) => event.key === 'Enter' && handleSearchButtonClick()}
                                />
                            </SearchWrap>
                            <StyledButton onClick={handleSearchButtonClick} type='button' disabled={isDisabled}>{t('検索')}</StyledButton>
                        </SearchBox>
                        <Radio>
                            <RadioLabel>
                                <RadioInput
                                    type="radio"
                                    id="1"
                                    value="1"
                                    checked={inputFulltextSearchRadio === "1"}
                                    onChange={(event) => handleInputChange(event, "is_fulltext_search")}
                                />
                                <RadioText>{t('基本フロー名で検索')}</RadioText>
                            </RadioLabel>
                            <RadioLabel>
                                <RadioInput
                                    type="radio"
                                    id="2"
                                    value="2"
                                    checked={inputFulltextSearchRadio === "2"}
                                    onChange={(event) => handleInputChange(event, "is_fulltext_search")}
                                />
                                <RadioText>{t('基本フロー情報に含まれる文言で検索')}</RadioText>
                            </RadioLabel>
                        </Radio>
                    </SectionBody>

                    <SectionBody>
                        <SectionColumn>
                            <SectionColumnTitle>
                                <CheckBoxText>{t('データベースパック')}</CheckBoxText>
                            </SectionColumnTitle>
                            <input type="text" hidden value={caseStudyData?.database_pack.id} />
                            <SectionColumnContent>
                                <SelectPrimary>
                                    <InputPrimary type="text" disabled value={caseStudyData?.database_pack.name} />
                                </SelectPrimary>
                                <SectionColumnContentText>{t('データベースパックはケーススタディに使うので変更不可')}</SectionColumnContentText>
                            </SectionColumnContent>
                        </SectionColumn>
                    </SectionBody>

                    <SectionBody>
                        <SectionColumn>
                            <SectionColumnTitle>{t('基本的フロータイプ')}</SectionColumnTitle>
                            <SectionColumnContent>
                                <CheckBoxLabel>
                                    <CheckBoxInput type="checkbox" disabled />
                                    <CheckBoxText>{t('資源')}</CheckBoxText>
                                </CheckBoxLabel>
                                <CheckBoxLabel>
                                    <CheckBoxInput type="checkbox" checked />
                                    <CheckBoxText>{t('排出物')}</CheckBoxText>
                                </CheckBoxLabel>
                                <CheckBoxLabel>
                                    <CheckBoxInput type="checkbox" disabled />
                                    <CheckBoxText>{t('無し')}</CheckBoxText>
                                </CheckBoxLabel>
                            </SectionColumnContent>
                        </SectionColumn>
                    </SectionBody>

                    <SectionBody>
                        <SectionColumn>
                            <SectionColumnTitle>{t('カテゴリ')}</SectionColumnTitle>
                            <SectionColumnContent>
                                {categories.map((category) => (
                                    <div key={category.id}>
                                        <CheckBoxLabel>
                                            <CheckBoxInput
                                                type="checkbox"
                                                value={category.id}
                                                checked={selectedCategories.includes(category.id)}
                                                onChange={handleCategoryChange}
                                            />
                                            <CheckBoxText>{category.name}</CheckBoxText>
                                        </CheckBoxLabel>
                                    </div>
                                ))}
                            </SectionColumnContent>
                        </SectionColumn>
                    </SectionBody>

                    {searchElementaryFlowsLoading && 
                        <FadeLoader
                            color="#48bdbb"
                            height={10}
                            radius={2}
                            width={5}
                        />
                    }
                    <SectionBodyLast>
                        {tableData && !searchElementaryFlowsLoading &&
                            <div>
                                <TablePageNation
                                    count={searchElementaryFlowsData?.count || 0}
                                    page={page}
                                    setPage={setPage}
                                    pageSize={pageSize}
                                    setPageSize={setPageSize}
                                />
                                <SubsystemOutputElementaryFlowSelectTable
                                    columns={columns}
                                    data={tableData}
                                    setIsSearchModalOpen={onClose}
                                    currentNodeData={currentNodeData}
                                />
                            </div>
                        }
                    </SectionBodyLast>
                </Inner>
            </Modal>
        </>

    )
}

export default SubsystemOutputElementaryFlowSearchModal;


const Inner = styled.div`
    width: 100%;
    padding: 120px 10%;
    margin: 0 auto;
`

const Title = styled.h2`
    font-size: 18px;
    padding: 0 0 20px;
    margin-bottom: 20px;
    border-bottom: 1px solid var(--color-line-primary);
    font-size: 16px;
    font-weight: 500;    
    line-height: 1.25;    
`

const SectionBody = styled.div`
    margin-bottom: 60px;
`

const SectionBodyLast = styled.div`
`

const InputPrimary = styled.input`
    width: min(100%, 340px);

    padding: 12px;
    color: var(--color-txt-primary);
    border: 1px solid var(--color-line-primary);
    border-radius: 4px;
    background-color: #fff;
    font-size: 16px;
    font-weight: 400;
    line-height: 1.25;
`
const SelectPrimary = styled.label`
    width: min(100%, 340px);
    position: relative;
    display: block;
    cursor: pointer;
`


const CheckBoxLabel = styled.label`
    display: flex;
    align-items: center;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: flex-start;
    gap: 6px;
    cursor: pointer;
  
`
const CheckBoxText = styled.span`
    font-size: 16px;
    font-weight: 500;
`

const Radio = styled.div`
    margin: 24px 0 40px;
    display: flex;
    align-items: center;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: flex-start;
    gap: 54px; 
`

const RadioLabel = styled.label`
    order: 1;
    
    isplay: flex;
    align-items: center;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: flex-start;
    gap: 6px;
    width: fit-content;
    cursor: pointer;
    position: relative;    
`
const RadioText = styled.span`
    align-items: center;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: flex-start;
    gap: 0 20px;
    font-size: 16px;
    white-space: nowrap;
    font-weight: 500; 
`

const SectionColumn = styled.div`
    margin-bottom: 40px;
`

const SectionColumnTitle = styled.div`
    margin-bottom: 12px;
    font-size: 16px;
    font-weight: 500;
    line-height: 1.25;

`

const SectionColumnContent = styled.div`
    margin-bottom: 32px;
    display: flex;
    align-items: center;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: flex-start;
    gap: 16px;
`

const StyledButton = styled.button`
    background-color: var(--color-site-secondary);
    color: #fff;

    padding: 14px 30px;
    border-radius: 4px;
    white-space: nowrap;

    display: inline-flex;
    align-items: center;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: center;
    cursor: pointer;
    white-space: nowrap;
    gap: 0 4px;

    appearance: none;
    border: none;
    font: inherit;
    outline: none;      

  &:hover {
    background-color: var(--color-gray--01);
    color: inherit;

  }
`

const SectionResult = styled.div`
    padding: 0 0 20px;
    margin-bottom: 20px;
    border-bottom: 1px solid var(--color-line-primary);
    display: flex;
    justify-content: space-between;
`

const SectionResultTitle = styled.h3`
    font-size: 18px;
    font-weight: 500;
`

const SectionResultTitleText = styled.p`
    font-size: 18px;
    font-weight: 500;
`

const CheckBoxInput = styled.input`
    accent-color: var(--color-site-secondary);
`

const RadioInput = styled.input`
    accent-color: var(--color-site-secondary);
`

const SearchBox = styled.div`
    display: flex;
    align-items: center;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: flex-start;
    gap: 16px;    
`

const SearchWrap = styled.div`
    width: 100%;
    position: relative;
`

const InputSerch = styled.input`
    width: 100%;
    padding-left: 44px;

    padding: 12px;
    color: var(--color-txt-primary);
    border: 1px solid var(--color-line-primary);
    border-radius: 4px;
    background-color: #fff;
    font-size: 16px;
    font-weight: 400;
    line-height: 1.25;
`

const SectionColumnContentText = styled.p`
    font-size: 16px;
    white-space: nowrap;
    font-weight: 500;
    line-height: 1.25;
`

const SectionButtonAdd = styled.div`
    margin-top: 20px;
    text-align: right;
`
