import ProsessAllocationInputDataTable from '@common/table/ProsessAllocationInputDataTable';
import ProsessAllocationMethodDataTable from '@common/table/ProsessAllocationMethodDataTable';
import { ProcessViewContext } from '@pages/process/ProcessView';
import { UpdateProcess } from '@typeList/types';
import React, { useContext } from 'react'
import { FieldErrors, UseFormRegister } from 'react-hook-form';
import styled from 'styled-components';

interface FormProps {
    register: UseFormRegister<UpdateProcess>;
    errors: FieldErrors<UpdateProcess>;
}

/**
 * 055 プロセス閲覧画面 出力フローの取り扱い方法（配分）タブ
 * @param param0 
 * @returns 
 */
const ProsessAllocation: React.FC<FormProps> = ({ register, errors }) => {
    const context = useContext(ProcessViewContext);
    const { outProduct } = context;
    return (
        <StyledSection>
            {outProduct &&
                <>
                    <ProsessAllocationInputDataTable />
                    <ProsessAllocationMethodDataTable/>
                </>
            }
        </StyledSection>
    )
}

export default ProsessAllocation

const StyledSection = styled.section`
`