import React, { ReactNode } from 'react';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import styled from 'styled-components';
import iconClose from '@images/modal/icon_cancel.svg'
import iconCancel from '@images/product_system/icon_cancel.svg'

interface ModalProps {
  isOpen: boolean;
  onClose: () => void;
  children: ReactNode;
}

const Modal: React.FC<ModalProps> = ({ isOpen, onClose, children }) => {
  return (
    <StyledDialog open={isOpen} onClose={onClose} fullWidth={true} maxWidth ={'lg'}>
      {/* <StyledDialogActions> */}
        
        <CloseButton onClick={onClose}>
            <Icon>
              <CancelIcon></CancelIcon>
            </Icon>
        </CloseButton>
      {/* </StyledDialogActions> */}
      <StyledDialogContent className={"dialog-scroll-container"}>
        {children}
      </StyledDialogContent>
    </StyledDialog>
  );
};

export default Modal;

const StyledDialog = styled(Dialog)`
  .MuiDialog-paper {
    width: min(83.3333333333vw, 1200px);
  }
`

const StyledDialogContent = styled(DialogContent)`
  background-color: var(--color-bg-secondary);
`

const StyledDialogActions = styled(DialogActions)`
  background-color: var(--color-bg-secondary);
`

const CloseButton = styled.button`
    position: absolute;
    top: 40px;
    right: 60px;
    cursor: pointer;

    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    background: transparent;
    border: none;
    border-radius: 0;
    font: inherit;
    outline: none;    

    padding: 0;
    color: inherit;
`

const Icon = styled.div`
`

const CancelIcon = styled.span`
    display: flex;
    align-items: center;
    flex-direction: column;
    flex-wrap: wrap;
    justify-content: center;
    position: relative;
    width: 100%;
    height: 100%;
    text-align: center;
    color: #fff;
    font-size: 11px;
    font-weight: 700;
    gap: 4px;
    cursor: pointer;

    &::before {
      width: 40px;
      height: 40px;
      content: "";
      background-repeat: no-repeat;
      background-position: center center;
      background-size: 30px;
      background-image: url(${iconCancel});
  }

`


