import React, { useState, useCallback, useRef } from 'react';
import {
    flexRender,
    getCoreRowModel,
    useReactTable,
    getPaginationRowModel,
    getSortedRowModel,
    ColumnDef,
    SortingState,
} from '@tanstack/react-table';
import { useDrag, useDrop } from 'react-dnd';
import styled, { css } from 'styled-components';
import { useTranslation } from 'react-i18next';

import iconArrowDown from '@images/table/icon_arrow_down.svg'
import iconArrowUp from '@images/table/icon_arrow_up.svg'

interface TableProps {
    columns: ColumnDef<any, any>[];
    data: any[];
}

const initialPageIndex = 0;
const initialPageSize = 100;

interface DragItem {
    index: number;
    id: string;
    type: string;
}

const DraggableHeader: React.FC<{
    header: any;
    index: number;
    moveColumn: (dragIndex: number, hoverIndex: number) => void;
}> = ({ header, index, moveColumn }) => {
    const ref = useRef<HTMLTableCellElement>(null);

    const [, drop] = useDrop({
        accept: 'COLUMN',
        hover: (item: DragItem, monitor) => {
            if (!ref.current) {
                return;
            }
            const dragIndex = item.index;
            const hoverIndex = index;

            if (dragIndex === hoverIndex) {
                return;
            }

            const hoverBoundingRect = ref.current?.getBoundingClientRect();
            const hoverMiddleX = (hoverBoundingRect.right - hoverBoundingRect.left) / 2;
            const clientOffset = monitor.getClientOffset();
            const hoverClientX = clientOffset!.x - hoverBoundingRect.left;

            if (dragIndex < hoverIndex && hoverClientX < hoverMiddleX) {
                return;
            }

            moveColumn(dragIndex, hoverIndex);
            item.index = hoverIndex;
        },
    });

    const [{ isDragging }, drag] = useDrag({
        type: 'COLUMN',
        item: { index },
        collect: (monitor) => ({
            isDragging: monitor.isDragging(),
        }),
    });

    // ドラッグ中のスタイル変更
    React.useEffect(() => {
        if (isDragging) {
            document.body.style.cursor = 'grabbing';
        } else {
            document.body.style.cursor = '';
        }

        // クリーンアップでスタイルをリセット
        return () => {
            document.body.style.cursor = '';
        };
    }, [isDragging]);

    drag(drop(ref));

    return (
        <StyledTh
            ref={ref}
            onClick={header.column.getToggleSortingHandler()}
            style={{
                opacity: isDragging ? 0.5 : 1,
                cursor: isDragging ? 'grabbing' : 'grab',
            }}
        >
            <StyledThInner>
                {flexRender(header.column.columnDef.header, header.getContext())}
                {header.column.getIsSorted() ? (header.column.getIsSorted() === 'desc' ? <IconArrowDown /> : <IconArrowUp />) : ''}
            </StyledThInner>
        </StyledTh>
    );
};

const ElementaryFlowsSearchTable: React.FC<TableProps> = ({ columns: initialColumns, data }) => {
    const { t } = useTranslation();
    const [columns, setColumns] = useState(initialColumns);
    const [sorting, setSorting] = useState<SortingState>([]);

    const moveColumn = useCallback(
        (dragIndex: number, hoverIndex: number) => {
            const newColumns = [...columns];
            const [movedColumn] = newColumns.splice(dragIndex, 1);
            newColumns.splice(hoverIndex, 0, movedColumn);
            setColumns(newColumns);
        },
        [columns]
    );

    const table = useReactTable({
        data: data ?? [],
        columns,
        state: { sorting },
        onSortingChange: setSorting,
        initialState: {
            pagination: {
                pageIndex: initialPageIndex,
                pageSize: initialPageSize,
            },
        },
        getCoreRowModel: getCoreRowModel(),
        getPaginationRowModel: getPaginationRowModel(),
        getSortedRowModel: getSortedRowModel(),
    });

    return (
        <>
            <TableWrapper>
                <StyledTable>
                    <StyledThead>
                        {table.getHeaderGroups().map(headerGroup => (
                            <StyledThTr key={headerGroup.id}>
                                {headerGroup.headers.map((header, index) => (
                                    <DraggableHeader
                                        key={header.id}
                                        header={header}
                                        index={index}
                                        moveColumn={moveColumn}
                                    />
                                ))}
                            </StyledThTr>
                        ))}
                    </StyledThead>
                    <StyledTbody>
                        {table.getRowModel().rows.map(row => (
                            <StyledTr key={row.id} $isEven={row.index % 2 === 0}>
                                {row.getVisibleCells().map(cell => (
                                    <StyledTd key={cell.id} title={String(cell.getValue())}>
                                        <StyledTdText>
                                            {flexRender(cell.column.columnDef.cell, cell.getContext())}
                                        </StyledTdText>
                                    </StyledTd>
                                ))}
                            </StyledTr>
                        ))}
                    </StyledTbody>
                </StyledTable>
            </TableWrapper>
        </>
    );
};

export default ElementaryFlowsSearchTable;

const TableWrapper = styled.div`
    overflow-x: auto;
`;

const StyledTable = styled.table`
    min-width: 100%;
    border-collapse: collapse;
    margin-bottom: 8px;
    background-color: #fff;
`;

const StyledThead = styled.thead`
    border-right: 1px solid var(--color-line-primary);
    border-left: 1px solid var(--color-line-primary);
`

const StyledTbody = styled.tbody`
    text-align: center;
    border-right: 1px solid var(--color-line-primary);
    border-left: 1px solid var(--color-line-primary);
`

const StyledTh = styled.th`
    cursor: grab;
    &:active {
        cursor: grabbing;
    }
    font-size: 13px;
    font-weight: 700;
    text-align: center;
    line-height: 1.25;
    color: var(--color-site-primary);
    padding: 12px;
    align-content: center;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    text-align: left;
}
`;

const StyledThInner = styled.div`
    width: fit-content;
    display: flex;
    align-items: center;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: flex-start;
    gap: 4px;
`

const StyledTd = styled.td`
    text-align: left;
    font-size: 14px;
    font-weight: 500;
    line-height: 1.25;
    padding: 12px;
    align-content: center;
    overflow: hidden;
    text-overflow: ellipsis;

    white-space: nowrap;
    overflow: hidden;
    max-width: 400px; /* 幅を調整 */
    &:hover {
        white-space: normal;
        overflow: visible;
        background-color: #f1f1f1;
        z-index: 1;
    }
}
`;

const StyledTdText = styled.div`
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    text-align: left;
`


const StyledThTr = styled.tr`
    border-top: 1px solid var(--color-line-primary);
    border-bottom: 1px solid var(--color-line-primary);
`

const StyledTr = styled.tr<{ $isEven: boolean }>`
    border-top: 1px solid var(--color-line-primary);
    border-bottom: 1px solid var(--color-line-primary);
    ${(props) => props.$isEven && css`
        background-color: #f9f9f9;
    `}
`;

const IconArrowDown = styled.span`
    mask: url(${iconArrowDown}) no-repeat center center / contain;
    -webkit-mask: url(${iconArrowDown}) no-repeat center center / contain;
    display: block;
    width: 100%;
    height: 100%;
    width: 20px;
    height: 20px;
    background: var(--color-txt-primary);
`

const IconArrowUp = styled.span`
    mask: url(${iconArrowUp}) no-repeat center center / contain;
    -webkit-mask: url(${iconArrowUp}) no-repeat center center / contain;
    display: block;
    width: 100%;
    height: 100%;
    width: 20px;
    height: 20px;
    background: var(--color-txt-primary);
`
