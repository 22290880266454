import React, {useEffect, useMemo} from 'react';
import { useReactTable, getCoreRowModel, ColumnDef, flexRender, CellContext } from '@tanstack/react-table';
import styled, { css } from 'styled-components';
import { UnitConversionsList } from '@typeList/types';
import { useTranslation } from 'react-i18next';

interface TableProps {
    data: any[];
}

interface TransformedRow {
    rowId: number;
    name: string;
    values: Record<string, string>;
}

const transformData = (data: UnitConversionsList[]): TransformedRow[] => {
    const transformed: TransformedRow[] = [];

    data.forEach((item, index) => {
        const existingRow = transformed.find(row => row.name === item.from_unit.name);

        if (existingRow) {
            existingRow.values[item.to_unit.name] = `${item.factor}`;
        } else {
            transformed.push({
                rowId: index + 1,
                name: item.from_unit.name,
                values: {
                    [item.to_unit.name]: `${item.factor}`,
                },
            });
        }
    });

    return transformed;
};

const MatrixTable: React.FC<TableProps> = ({ data }) => {
    const { t } = useTranslation();
    const transformedData = useMemo(() => transformData(data), [data]);

    const uniqueHeaders = useMemo(() => {
        const headers = new Set<string>();
        data.forEach(item => headers.add(item.to_unit.name));
        return Array.from(headers);
    }, [data]);

    const columns: ColumnDef<TransformedRow, string>[] = useMemo(() => [
        {
            accessorKey: 'name',
            header: () => t('変換元'),
        },
        ...uniqueHeaders.map((header) => ({
            accessorKey: `values.${header}`,
            header,
            cell: (props: CellContext<TransformedRow, string>) => props.getValue() ?? '-',
        })),
    ], [uniqueHeaders]);

    const table = useReactTable({
        data: transformedData,
        columns,
        getCoreRowModel: getCoreRowModel(),
    });

    return (
        <TableWrapper>
            <StyledTable>
                <StyledThead>
                    {table.getHeaderGroups().map((headerGroup) => (
                        <StyledThTr key={headerGroup.id}>
                            {headerGroup.headers.map((header) => (
                                <StyledTh key={header.id}>
                                    {header.isPlaceholder ? null : flexRender(header.column.columnDef.header, header.getContext())}
                                </StyledTh>
                            ))}
                        </StyledThTr>
                    ))}
                </StyledThead>
                <StyledTbody>
                    {table.getRowModel().rows.map((row) => (
                        <StyledTr key={row.id} $isEven={row.index % 2 === 0}>
                            {row.getVisibleCells().map((cell) => (
                                <StyledTd key={cell.id} title={String(cell.getValue())}>
                                        <StyledTdText>
                                            {flexRender(cell.column.columnDef.cell, cell.getContext())}
                                        </StyledTdText>
                                </StyledTd>
                            ))}
                        </StyledTr>
                    ))}
                </StyledTbody>
            </StyledTable>
        </TableWrapper>
    );
};

export default MatrixTable;

const TableWrapper = styled.div`
    overflow-x: auto;
`;

const StyledTable = styled.table`
    min-width: 100%;
    border-collapse: collapse;
    margin-bottom: 8px;
    background-color: #fff;
`;

const StyledThead = styled.thead`
border-right: 1px solid var(--color-line-primary);
border-left: 1px solid var(--color-line-primary);
`

const StyledTbody = styled.tbody`
text-align: center;
border-right: 1px solid var(--color-line-primary);
border-left: 1px solid var(--color-line-primary);
`

const StyledTh = styled.th`
    cursor: grab;

    &:active {
    cursor: grabbing;
    }
    font-size: 13px;
    font-weight: 700;
    text-align: center;
    line-height: 1.25;
    color: var(--color-site-primary);
    padding: 12px;
    align-content: center;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    text-align: left;
}
`;

const StyledThInner = styled.div`
    width: fit-content;
    margin: auto;
    display: flex;
    align-items: center;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: flex-start;
    gap: 4px;
`

const StyledTd = styled.td`
    font-size: 14px;
    font-weight: 500;
    line-height: 1.25;
    padding: 12px;
    align-content: center;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    max-width: 150px; /* 幅を調整 */
    &:hover {
        white-space: normal;
        overflow: visible;
        background-color: #f1f1f1;
        z-index: 1;
    }
}
`;

const StyledTdText = styled.div`
overflow: hidden;
text-overflow: ellipsis;
white-space: nowrap;
text-align: left;
`


const StyledThTr = styled.tr`
border-top: 1px solid var(--color-line-primary);
border-bottom: 1px solid var(--color-line-primary);
`

const StyledTr = styled.tr<{ $isEven: boolean }>`
    border-top: 1px solid var(--color-line-primary);
    border-bottom: 1px solid var(--color-line-primary);
    ${(props) => props.$isEven && css`
        background-color: #f9f9f9;
    `}
`;