import { useCaseStudyCalculate, useGetCaseStudyLciResults, useGetCaseStudyLciResultsCategory, useGetCaseStudyLciResultsUpper } from '@hooks/useBackendApi';
import { CaseStudyViewContext } from '@pages/case_study/CaseStudyView';
import { CaseStudyCalculate, CaseStudyLciResultEachSubsystem, UpdateCaseStudy } from '@typeList/types';
import React, { useContext, useEffect, useState } from 'react';
import styled, { css } from 'styled-components';
import InventoryAnalysis from '../calculation/InventoryAnalysis';
import CaseStudyCharacterization from '../calculation/CaseStudyCharacterization';
import Integration from '../calculation/Integration';
import DamageAssessment from '../calculation/DamageAssessment';
import { useForm } from 'react-hook-form';
import { formatToLocalTime } from '@utils/utils';
import { useTranslation } from 'react-i18next';
import ErrorMsg from '@common/error/ErrorMsg';
import { FadeLoader } from 'react-spinners';
import LoadingDialog from '@common/modal/LoadingDialog';
import AlertDialog from '@common/modal/AlertDialog';
import SaveDialog from '@common/modal/SaveDialog';

/**
 * ケーススタディ閲覧画面（計算）
 * @returns 
 */
const CaseStudyCalculation = () => {
    const {t} = useTranslation();
    const { handleSubmit } = useForm<UpdateCaseStudy>();
    const context = useContext(CaseStudyViewContext);
    const {
        caseStudyData,
        midpointData,
        endpointData,
        integrationData,
        inputGroupingValue,
        setInputGroupingValue,
        inputDsplayFormatValue,
        setInputDisplayFormatValue,
        subsystemCategorySetData,
        onSubmit,
        changeCheckSubsystem, 
        changeCheckParameter,
        changeCheckReviewLevel,
        changeCheckInfo,
        changeCheckReport,
        lciResultsCategoryData,
        lciResultsUpperData,
        lciResultsData,
        getInitLciData,
        setSavedAlertEnabled,
        setCalculateAndGetResultsLoading,
        calculateAndGetResultsLoading,
        inventoryAnalysData,
        setInventoryAnalysData,
        // calculatedAt,
        // setCalculatedAt,
        calculatedDateData,
        calculatedDateLoading,
        lciResultsCategoryLoading,
        lciResultsLoading,
        lciResultsUpperLoading,
        getLciCalculatedDate,
    } = context;

    const [activeTab, setActiveTab] = useState<string>('tab1');
    const handleTabClick = (tab: string) => {
        setActiveTab(tab);
    };

    // ケーススタディ計算結果
    // const [resultData, setResultData] = useState<CaseStudyCalculate | null>(null);

    // 計算
    const { executeCalculate, calculateData, calculateError, calculateLoading } = useCaseStudyCalculate();

    // ボタン連打制御
    const [isDisabled, setIsDisabled] = useState(false);

    // 保存と計算
    const hundleSaveAndCalculate = async () =>{
        if (isDisabled) return;
        setIsDisabled(true);

        try {
            await handleSubmit(onSubmit)();
        } catch (error: any) {
            console.log("error", error);
            if(error.response && error.response.data?.detail){
                setDaialogMessage(<>{t('保存に失敗しました')} <br/> {error.response.data?.detail} </>)
            } else {
                setDaialogMessage(<>{t('保存に失敗しました')} <br/> {t('申しわけありませんが、システム管理者までお問い合わせください')}</>)
            }
            setCalculateAndGetResultsLoading(false);
            return; // エラーが発生した場合もここで処理を中断
        } finally {
            setIsDisabled(false);
        }

        await handleExecuteCalculate()
    }
    
    // 計算
    const handleExecuteCalculate = async () => {
        setCalculateAndGetResultsLoading(true);
        openLoadingDaialog();
        
        try {
            await executeCalculate(caseStudyData?.id ?? 0);
            setInputGroupingValue("1")
            await getInitLciData();
            setDaialogMessage(t('計算が完了しました'))
        } catch (error: any) {
            // alert("計算に失敗しました。");
            if(error.response && error.response.data?.detail){
                setDaialogMessage(<>{t('計算に失敗しました。')} <br/> {error.response.data?.detail} </>)
            } else {
                setDaialogMessage(<>{t('計算に失敗しました。')} <br/> {t('申しわけありませんが、システム管理者までお問い合わせください')}</>)
            }
            setCalculateAndGetResultsLoading(false);
            console.log(error);
        } finally {
            setIsDisabled(false);
            setCalculateAndGetResultsLoading(false);
        }
    };


    // 保存ダイアログ
    const [isAlertDialogOpen, setIsAlertDialogOpen] = useState(false);
    const openAlertDialog = () => setIsAlertDialogOpen(true);
    const closeAlertDialog = () => setIsAlertDialogOpen(false);
    const [alertDaialogMessage, setAlertDaialogMessage] = useState<string>(t('計算を行う前に保存が必要です。現在の状態を保存しますか？'));
    useEffect(() => {
        if (changeCheckSubsystem ||
            changeCheckParameter ||
            changeCheckReviewLevel ||
            changeCheckInfo ||
            changeCheckReport
        ) {
            setAlertDaialogMessage(t('計算を行う前に保存が必要です。現在の状態を保存しますか？'))
        } else {
            setAlertDaialogMessage(t('計算を実行しますか？'))
        }
    }, [
        changeCheckSubsystem, 
        changeCheckParameter,
        changeCheckReviewLevel,
        changeCheckInfo,
        changeCheckReport,
    ])


    // ローディングダイアログ
    const [isLoadingDaialogOpen, setIsLoadingDaialogOpen] = useState(false);
    const openLoadingDaialog = () => setIsLoadingDaialogOpen(true);
    const closeLoadingDaialog = () => setIsLoadingDaialogOpen(false);
    const [daialogMessage, setDaialogMessage] = useState<string | JSX.Element>("");
    const daialogLoadingMessage = t('計算しています');

    return (
        <>
            <SaveDialog
                open={isAlertDialogOpen}
                onClose={closeAlertDialog}
                onConfirm={
                    changeCheckSubsystem || 
                    changeCheckParameter || 
                    changeCheckReviewLevel || 
                    changeCheckInfo || 
                    changeCheckReport ?
                    hundleSaveAndCalculate: handleExecuteCalculate
                }
                message={alertDaialogMessage}
            />
            <LoadingDialog
                open={isLoadingDaialogOpen}
                onClose={closeLoadingDaialog}
                loading={calculateAndGetResultsLoading}
                loading_message={daialogLoadingMessage}
                result_message={daialogMessage}
            />

            <Button
                type='button'
                onClick={() => {
                    // 計算済みの結果を取得するボタンが表示されている場合のみ getLciData() を実行
                    setInputGroupingValue("1");
                    if (calculatedDateData &&
                        (
                            !changeCheckSubsystem &&
                            !changeCheckParameter &&
                            !changeCheckReviewLevel &&
                            !changeCheckInfo &&
                            !changeCheckReport
                        )
                    ) {
                        getInitLciData();
                    } else {
                        setSavedAlertEnabled(false);
                        openAlertDialog();
                    }
                }}
                disabled={isDisabled}
            >
                <ButtonText>{t('計算する')}</ButtonText>
            </Button>

            {/* <Button type='button' onClick={()=>{setSavedAlertEnabled(false); openAlertDialog()}} disabled={isDisabled}><ButtonText>{t('計算する')}</ButtonText></Button> */}
            {/* {calculatedDateData && !lciResultsData &&
                <Button
                    type='button'
                    onClick={() => { getInitLciData() }}
                    disabled={isDisabled}
                    style={{ marginLeft: "10px" }}
                >
                    <ButtonText> {t('計算済みの結果を取得する')}</ButtonText>
                </Button>
            } */}
            {
                (
                    changeCheckSubsystem ||
                    changeCheckParameter ||
                    changeCheckReviewLevel ||
                    changeCheckInfo ||
                    changeCheckReport
                ) && <CalculateErrorMsg>{t('計算結果が古いため再計算が必要です')}</CalculateErrorMsg>
            }

            {(calculateLoading || 
                calculatedDateLoading ||
                lciResultsCategoryLoading ||
                lciResultsLoading ||
                lciResultsUpperLoading )
                &&
                <FadeLoader
                    color="#48bdbb"
                    height={10}
                    radius={2}
                    width={5}
                />
            }

            <Wrap>
                <Label>{t('前回計算日時')}</Label>
                {!calculatedDateData ? (
                    <SelectLabel>
                        <Label> {t('計算結果がありません')}</Label>
                    </SelectLabel>
                ) : (
                    <SelectLabel>
                        <Label>{formatToLocalTime(calculatedDateData.calculated_at) || t('計算結果がありません')}</Label>
                    </SelectLabel>
                )}
            </Wrap>

            {calculateError && <ErrorMsg>{calculateError}</ErrorMsg>}
            <Section>
                <Box>
                    <BoxInner>
                        <TabButton type='button' onClick={() => handleTabClick('tab1')} isActive={activeTab === 'tab1'}>
                            <TabButtonSpan>
                            {t('特性化')}
                            </TabButtonSpan>
                        </TabButton>
                        <TabButton type='button' onClick={() => handleTabClick('tab2')} isActive={activeTab === 'tab2'}>
                            <TabButtonSpan>
                            {t('被害評価')}
                            </TabButtonSpan>
                        </TabButton>
                        <TabButton type='button' onClick={() => handleTabClick('tab3')} isActive={activeTab === 'tab3'}>
                            <TabButtonSpan>
                            {t('統合化')}
                            </TabButtonSpan>
                        </TabButton>
                        <TabButton type='button' onClick={() => handleTabClick('tab4')} isActive={activeTab === 'tab4'}>
                            <TabButtonSpan>
                            {t('インベントリ分析')}
                            </TabButtonSpan>
                        </TabButton>
                        {activeTab === 'tab1' &&
                            <TabPanel>
                                <CaseStudyCharacterization methodsData={midpointData} calculateData={inventoryAnalysData}  />
                            </TabPanel>
                        }
                        {activeTab === 'tab2' &&
                            <TabPanel>
                                <DamageAssessment methodsData={endpointData} calculateData={inventoryAnalysData}/>
                            </TabPanel>
                        }
                        {activeTab === 'tab3' &&
                            <TabPanel>
                                <Integration methodsData={integrationData} calculateData={inventoryAnalysData} />
                            </TabPanel>
                        }
                        {activeTab === 'tab4' &&
                            <TabPanel>
                                <InventoryAnalysis calculateData={inventoryAnalysData} calculateLoading={calculateLoading} />
                            </TabPanel>
                        }
                    </BoxInner>
                </Box>
            </Section>
        </>
    );
}

export default CaseStudyCalculation;

const TabButton = styled.button.withConfig({
    shouldForwardProp: (prop) => prop !== 'isActive'
}) <{ isActive: boolean }>`
    padding: 5px 16px;
    color: black;
    border: none;
    margin-right: 10px;
    cursor: pointer;
    border-radius: 20px;
    font-size: 12px;
    font-weight: 500;

    ${(props) =>
        props.isActive &&
        css`
            background-color: var(--color-site-secondary);
            color: white;
        `}
  
    &:hover {
        background-color: var(--color-site-secondary);
        color: white;
        ${(props) =>
        props.isActive &&
        css`
            background-color: var(--color-site-secondary);
        `}
    }
`;

const TabPanel = styled.div`
display: block;
z-index: 5;
width: 100%;
order: 2;
position: relative
`;


const Button = styled.button`
background-color: var(--color-site-secondary);
border-radius: 4px;
border: 1px solid var(--color-site-secondary);
padding: 7px 16px;

display: inline-flex;
align-items: center;
flex-direction: row;
flex-wrap: nowrap;
justify-content: center;
cursor: pointer;
white-space: nowrap;
gap: 0 4px;

font: inherit;
outline: none;

&:hover {
    background-color: white;
    span {
        color: var(--color-site-secondary);
    }
}
`

const ButtonText = styled.span`
color: #fff;
font-size: 13px;
font-weight: 500;
`

const Section = styled.section`
display: block;
`

const Box = styled.div`
margin-top: 24px;
padding: 36px 20px;
border: 4px solid var(--color-line-primary);
background-color: #fff;
`

const BoxInner = styled.div`
gap: 24px 12px;
display: flex;
align-items: flex-start;
flex-direction: row;
flex-wrap: wrap;
justify-content: flex-start;
`

const TabButtonSpan = styled.span`
  font-family: "Noto Sans JP", sans-serif;
  font-size: 12px;
`

const Label = styled.label`
padding: 0 0 20px;
margin-bottom: 20px;
// border-bottom: 1px solid var(--color-line-primary);
font-size: 16px;
font-weight: 500;
line-height: 1.25;
`


const SelectLabel = styled.label`
margin-left: 10px;
width: min(100%, 340px);
`

const Wrap = styled.div`
margin-top: 10px;
margin-bottom: 20px;
`

const CalculateErrorMsg = styled.span`
    font-size: 14px;
    font-weight: 500;
    color: var(--color-error-primary);
    margin-left: 10px;
`
