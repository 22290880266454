import React, { useContext, useEffect, useState } from 'react';
import { useGetProcessForProcessView, useGetReferredIo } from '@hooks/useBackendApi';
import { ProcessViewContext } from '@pages/process/ProcessView';
import { DisposalOut, AllocationOut, Cutoff, AlternativeOut, UpdateProcess, ProcessIosType, GetProcessType } from '@typeList/types'; // 適切な型をインポート
import styled from 'styled-components';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

interface DisposalSelectBoxProps {
    exchangeId: number;
    rowIndex: number;
}

const DisposalSelectBox: React.FC<DisposalSelectBoxProps> = ({ exchangeId, rowIndex }) => {
    const {t} = useTranslation()
    const context = useContext(ProcessViewContext);
    const { processData, outProduct, setOutProduct, inProduct, setChangeCheckAllocation } = context;
    // const { getReferredIo, referredIoData } = useGetReferredIo();
    const [options, setOptions] = useState<any[]>([]);
    const [selectedValue, setSelectedValue] = useState<string>("");

    // 型ガード関数
    const isDisposalOut = (treatment: AllocationOut | Cutoff | DisposalOut | AlternativeOut | null): treatment is DisposalOut => {
        return treatment !== null && treatment.type === 'disposal';
    }

    // 新規追加時にプルダウンに追加
    useEffect(() => {
        setOptions(inProduct || []);
        console.log("setOptions", inProduct)
    }, [inProduct]);

    // プルダウンの初期値の設置
    useEffect(() => {
        if (outProduct && outProduct[rowIndex]) {
            const currentTreatment = outProduct[rowIndex].treatment;
            if (isDisposalOut(currentTreatment)) {
                const targetOption = options.find(
                    (option) => option.process_io_no === currentTreatment.disposal_service_process_input?.process_input_no
                );
                if (targetOption) {
                    setSelectedValue(String(targetOption.process_io_no));

                    // 初期表示時にdisposal_service_process_inputを設定
                    if (isDisposalOut(currentTreatment)) {
                        const updatedOutProduct = [...outProduct];
                        updatedOutProduct[rowIndex].treatment = {
                            type: 'disposal',
                            disposal_service_process_input: {
                                process_input_id: null,
                                process_input_no: targetOption.process_io_no,
                                upper_process_id: targetOption.process_io_no,
                                upper_process_global_id: "",
                                upper_process_io_exchange_name: targetOption.exchange.name,
                                upper_process_name: targetOption.exchange.name,
                                upper_process_io_id: targetOption.treatment?.disposal_service_process_input?.upper_process_io_id || null,
                            },
                        };
                        setOutProduct(updatedOutProduct);
                    }
                }
            }
        }
    }, [rowIndex, options]);


    const handleChange = async(event: React.ChangeEvent<HTMLSelectElement>) => {
        const selectedId = event.target.value;
        const selectedName = options.find(option => option.id === parseInt(selectedId))?.process_name;
        const selectedIoId = options.find(option => option.id === parseInt(selectedId))?.treatment?.disposal_service_process_input?.upper_process_io_id || null

        if(!outProduct) {
            return
        }
        setSelectedValue(selectedId);

        const updatedOutProduct = [...outProduct];
        const treatment = updatedOutProduct[rowIndex].treatment

        // 廃棄以外からの変更の場合
        if (!isDisposalOut(treatment)) {
            updatedOutProduct[rowIndex].treatment = {
                type: 'disposal',
                disposal_service_process_input: {
                    process_input_id: null,
                    process_input_no: parseInt(selectedId),
                    upper_process_id: parseInt(selectedId),
                    upper_process_global_id: "",
                    upper_process_io_exchange_name: selectedName || "",
                    upper_process_name: selectedName || "",
                    upper_process_io_id: selectedIoId,
                }
            }
        } else {
            // 廃棄からの変更の場合
            updatedOutProduct[rowIndex].treatment = {
                type: 'disposal',
                disposal_service_process_input: {
                    process_input_id: null,
                    process_input_no: parseInt(selectedId),
                    upper_process_id: parseInt(selectedId),
                    upper_process_global_id: "",
                    upper_process_io_exchange_name: selectedName || "",
                    upper_process_name: selectedName || "",
                    upper_process_io_id: selectedIoId,
                }
            };
        }
        setOutProduct(updatedOutProduct);
        setChangeCheckAllocation(true);
    };

    useEffect(() =>{
        console.log("selectged value" , selectedValue)
    },[selectedValue])

    return (
        <SelectLabel>
            <Select value={selectedValue} onChange={handleChange}>
                <option value="">{t('選択しない')}</option>
                {options.map((process) => (
                    <option key={process.exchange.id} value={process.process_io_no}>
                        {process.exchange.name}
                    </option>
                ))}
            </Select>
        </SelectLabel>
    );
};

export default DisposalSelectBox;

const SelectLabel = styled.label`
    width: min(100%, 340px);
    position: relative;
    display: block;
`
const Select = styled.select`
    position: relative;
    width: 100%;
    padding: 12px 32px 12px 12px;
    cursor: pointer !important;
    border: 1px solid var(--color-line-primary);
    border-radius: 4px;
    background-color: #fff;
    font-size: 16px;
    font-weight: 400;
    line-height: 1.25;
`