import React from 'react';
import styled from 'styled-components';

interface ContextMenuProps {
  x: number;
  y: number;
  visible: boolean;
  items: { label: string; onClick: () => void }[];
  onClose: () => void;
}

export interface ContextMenuState {
  x: number;
  y: number;
  visible: boolean;
  items: { label: string; onClick: () => void }[];
}

const MenuContainer = styled.div<{ x: number; y: number }>`
  position: absolute;
  top: ${(props) => props.y}px;
  left: ${(props) => props.x}px;
  background-color: white;
  border: 1px solid #ccc;
  z-index: 1000;
`;

const Ul = styled.ul`
  list-style: none;
  padding: 0;
  margin: 0;
`;

const Li = styled.li`
  padding: 8px 16px;
  cursor: pointer;

  &:hover {
    background-color: #f0f0f0;
  }
`;

const ContextMenu: React.FC<ContextMenuProps> = ({ x, y, visible, items, onClose }) => {
  if (!visible) return null;

  const handleClick = (event: React.MouseEvent, onClick: () => void) => {
    event.stopPropagation();
    onClick();
    onClose();
  };

  return (
    <MenuContainer x={x} y={y} onClick={(e) => e.stopPropagation()} className="context-menu">
      <Ul>
        {items.map((item, index) => (
          <Li key={index} onClick={(e) => handleClick(e, item.onClick)}>{item.label}</Li>
        ))}
      </Ul>
    </MenuContainer>
  );
};

export default ContextMenu;
