import React, { useEffect, useState } from 'react';
import Modal from '@common/modal/Modal';
import styled from 'styled-components';
import { LinkMenu } from '@styles/Common';

import { GetUserDetail, UnitGroupList, UnitGroupUnit } from '@typeList/types'

import { createColumnHelper } from '@tanstack/react-table'
import DataTable from '@common/table/DataTable';
import { getCurrentDefaultDatabasePackId, getCurrentUserInfo } from '@services/Cookies';
import { useGetUnitGroups, useGetUserDetail } from '@hooks/useBackendApi';
import { useTranslation } from 'react-i18next';
import ErrorMsg from '@common/error/ErrorMsg';
import { FadeLoader } from 'react-spinners';
interface flattenedData {
  name: string;
  unitName: string;
  unitCode: string;
  unitId: number;
}

interface UnitGroupModalProps {
  userData: GetUserDetail | null
}

/**
 * 002　単位マスタ
 * @returns 
 */
const UnitGroupModal: React.FC<UnitGroupModalProps> = ({ userData }) => {
  const { t } = useTranslation();
  const [data, setData] = useState<any | null>(null);
  const [dataCount, setDataCount] = useState<number>(0);

  // データベースパック
  const [inputDatabasePackId, setInputDatabasePackId] = useState<number | undefined>(userData?.default_database_pack_id);
  const [databasePacks, setDatabasePacks] = useState<any[]>([]);
  // const [inputDatabasePackName, setInputDatabasePackName] = useState("")
  useEffect(() => {
    if (userData?.licenses) {
      const filteredDatabasePacks = userData?.licenses
        .filter(license => !license.is_inactive) // is_inactive = false のみを対象
        .map(license => ({
          id: license.role.database_pack.id,
          name: license.role.database_pack.name,
        }));

      // id の重複を Set で除去し、再度配列に戻す
      const uniqueDatabasePacks = Array.from(new Map(
        filteredDatabasePacks.map(pack => [pack.id, pack]) // id をキーにした Map を生成
      ).values());

      setDatabasePacks(uniqueDatabasePacks);
      // デフォルト設定のデータベースパック取得
      setInputDatabasePackId(getCurrentDefaultDatabasePackId() ?? uniqueDatabasePacks[0].id);
      // setInputDatabasePackName(getValueByKey(database_pack, 'id', Number(inputDatabasePackId), 'name'))
    }
  }, [userData]);

  useEffect(() => {
    if(getCurrentDefaultDatabasePackId()){
        setInputDatabasePackId(getCurrentDefaultDatabasePackId())
    }
}, [getCurrentDefaultDatabasePackId()])

  // セレクトボックス
  const handleInputChangeSelect = (event: React.ChangeEvent<HTMLSelectElement>, key: string) => {
    switch (key) {
      case 'database_pack_id':
        setInputDatabasePackId(Number(event.target.value));
        break;
    }
  };

  // モーダル開閉
  const [isModalOpen, setIsModalOpen] = useState(false);
  const openModal = () => setIsModalOpen(true);
  const closeModal = () => setIsModalOpen(false);

  // テーブル設定
  const columnHelper = createColumnHelper<flattenedData>()
  const columns = [
    // columnHelper.accessor('unitId', {
    //   header: () => sortableHeader('id'),
    //   cell: info => info.renderValue(),
    // }),
    columnHelper.accessor(row => row.name, {
      id: 'name',
      cell: info => <i>{info.getValue()}</i>,
      header: () => t('単位分類'),
    }),
    columnHelper.accessor('unitCode', {
      header: () => t('単位コード'),
      cell: info => info.renderValue(),
    }),
    columnHelper.accessor('unitName', {
      header: () => t('表示名'),
      cell: info => info.renderValue(),
    }),
  ]

  // 単位マスタデータ取得
  const { getUnitGroups, unitGroupsData, unitGroupsError, unitGroupsLoading } = useGetUnitGroups();
  useEffect(() => {
    try {
      if (inputDatabasePackId && isModalOpen) {
        getUnitGroups(inputDatabasePackId);
      }
    } catch (error) {
      console.log(error);
    }
  }, [inputDatabasePackId, isModalOpen])

  useEffect(() => {
    if (unitGroupsData) {
      flattenedData(unitGroupsData);
    }
  }, [unitGroupsData])

  // テーブル表示用にフラットなデータに変換する
  const flattenedData = async (jsonData: any) => {
    if (jsonData === null) {
      return
    }
    const flatData = jsonData.flatMap((item: UnitGroupList) => {
      return item.units.map((unit: UnitGroupUnit) => ({
        unitId: unit.id,
        name: item.name,
        unitName: unit.name,
        unitCode: unit.code
      }));
    });
    setData(flatData);
    setDataCount(flatData.length)
    // console.log("flatdata: ", data);
  };

  return (
    <div className="app">
      <LinkMenu onClick={() => {
        openModal();
      }
      }>
        {t('単位マスタ')}
      </LinkMenu>
      <Modal isOpen={isModalOpen} onClose={closeModal}>
        <Inner>
          <Title>{t('単位の閲覧')}</Title>
          {unitGroupsError && <ErrorMsg>Error: {unitGroupsError}</ErrorMsg>}
            <>
              <SectionBody>
                <SectionColumn>
                  <SectionTitle>{t('データベースパック')}: </SectionTitle>
                  <SectionContent>
                    <SelectPrimary>
                      <SelectInner
                        name="database-pack"
                        id="database-pack-select"
                        onChange={(event) => handleInputChangeSelect(event, 'database_pack_id')}
                        value={inputDatabasePackId ?? ''}
                      >
                        {databasePacks.map((database_pack) => (
                          <option key={database_pack.id} value={database_pack.id}>
                            {database_pack.name}
                          </option>
                        ))}
                      </SelectInner>
                    </SelectPrimary>
                  </SectionContent>

                </SectionColumn>
              </SectionBody>
              {unitGroupsLoading &&
                <FadeLoader
                  color="#48bdbb"
                  height={10}
                  radius={2}
                  width={5}
                />
              }
              <SectionTable>
                {data && !unitGroupsLoading &&
                  <DataTable count={dataCount} columns={columns} data={data} paging_flag={true} />
                }
              </SectionTable>

            </>
        </Inner>
      </Modal>
    </div>
  );
};

export default UnitGroupModal;

const Title = styled.h2`
    font-size: 18px;
    padding: 0 0 20px;
    margin-bottom: 20px;
    border-bottom: 1px solid var(--color-line-primary);
    font-size: 16px;
    font-weight: 500;    
    line-height: 1.25;    
`



const Inner = styled.div`
    width: 100%;
    padding: 120px 10%;
    margin: 0 auto;
`

const SectionTable = styled.div`
    background-color: #fff;
    padding: 40px 20px 55px;
  
`

const SectionTitle = styled.h2`
    margin-bottom: 12px;
    font-size: 16px;
    font-weight: 500;
    line-height: 1.25;
`

const SectionBody = styled.div`
`

const SectionContent = styled.dd`
    margin-bottom: 32px;
    display: flex;
    align-items: center;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: flex-start;
    gap: 16px;    
`

const SelectPrimary = styled.label`
    width: min(100%, 340px);
    position: relative;
    display: block;
    cursor: pointer;
`

const SelectInner = styled.select`
    color: rgb(85, 85, 85);
    position: relative;
    width: 100%;
    padding: 12px 32px 12px 12px;
    cursor: pointer !important;
    border: 1px solid var(--color-line-primary);
    border-radius: 4px;
    background-color: #fff;
    font-size: 16px;
    font-weight: 400;
    line-height: 1.25;
`

const SectionColumn = styled.dl`
    margin-bottom: 40px;
`


