import React, { useEffect, useState } from 'react';
import Modal from '@common/modal/Modal';
import styled from 'styled-components';
import { LinkMenu } from '@styles/Common';

import MatrixTable from '@common/table/MatrixTable';
import { getCurrentDefaultDatabasePackId, getCurrentUserInfo } from '@services/Cookies';
import { useGetUnitConversions, useGetUnitGroups, useGetUserDetail } from '@hooks/useBackendApi';
import { getValueByKey } from '@utils/utils';
import { useTranslation } from 'react-i18next';
import { GetUserDetail } from '@typeList/types';
import ErrorMsg from '@common/error/ErrorMsg';
import { FadeLoader } from 'react-spinners';

interface UnitConversionsModalProps {
    userData: GetUserDetail | null
}

/**
 * 003　単位換算マスタ
 * @returns 
 */
const UnitConversionsModal: React.FC<UnitConversionsModalProps> = ({ userData }) => {
    const { t } = useTranslation();
    // データベースパック
    const [inputDatabasePackId, setInputDatabasePackId] = useState<number | undefined>(userData?.default_database_pack_id);
    const [databasePacks, setDatabasePacks] = useState<any[]>([]);
    const [inputDatabasePackName, setInputDatabasePackName] = useState("")
    useEffect(() => {
        if (userData?.licenses) {
            const filteredDatabasePacks = userData?.licenses
                .filter(license => !license.is_inactive) // is_inactive = false のみを対象
                .map(license => ({
                    id: license.role.database_pack.id,
                    name: license.role.database_pack.name,
                }));

            // id の重複を Set で除去し、再度配列に戻す
            const uniqueDatabasePacks = Array.from(new Map(
                filteredDatabasePacks.map(pack => [pack.id, pack]) // id をキーにした Map を生成
            ).values());

            setDatabasePacks(uniqueDatabasePacks);
            // デフォルト設定のデータベースパック取得
            setInputDatabasePackId(getCurrentDefaultDatabasePackId() ?? uniqueDatabasePacks[0].id);
            setInputDatabasePackName(getValueByKey(databasePacks, 'id', Number(inputDatabasePackId), 'name'))
        }
    }, [userData]);

    useEffect(() => {
        if(getCurrentDefaultDatabasePackId()){
            setInputDatabasePackId(getCurrentDefaultDatabasePackId())
        }
    }, [getCurrentDefaultDatabasePackId()])

    // セレクトボックス
    const handleInputChangeSelect = (event: React.ChangeEvent<HTMLSelectElement>, key: string) => {
        switch (key) {
            case 'database_pack_id':
                setInputDatabasePackId(Number(event.target.value));
                break;
            case 'unit_group':
                setUnitGroupId(Number(event.target.value));
                break;
        }
    };

    // モーダル開閉
    const [isModalOpen, setIsModalOpen] = useState(false);
    const openModal = () => setIsModalOpen(true);
    const closeModal = () => setIsModalOpen(false);

    // 単位換算マスタデータ取得
    const { getUnitConversions, unitConversionsData, unitConversionsError, unitConversionsLoading } = useGetUnitConversions();
    useEffect(() => {
        try {
            if (inputDatabasePackId && isModalOpen) {
                getUnitConversions(inputDatabasePackId);
            }
        } catch (error) {
            console.log(error);
        }
    }, [inputDatabasePackId, isModalOpen])

    const [UnitGroupId, setUnitGroupId] = useState<number>(1);
    // 単位マスタからグループ名取得
    const { getUnitGroups, unitGroupsData, unitGroupsError, unitGroupsLoading } = useGetUnitGroups();
    useEffect(() => {
        try {
            if (inputDatabasePackId && isModalOpen) {
                getUnitGroups(inputDatabasePackId)
            }
        } catch (error) {
            console.log(error);
        }
    }, [inputDatabasePackId, isModalOpen])

    const filteredUnitConversions = unitConversionsData?.filter(conversion => conversion.to_unit.unit_group_id === UnitGroupId);

    return (
        <div className="app">
            <LinkMenu onClick={() => {
                openModal();
            }
            }>
                {t('単位換算マスタ')}
            </LinkMenu>
            <Modal isOpen={isModalOpen} onClose={closeModal}>
                <Inner>
                    <Title>{t('単位分類ごとの単位変換の閲覧 / 検索')}</Title>
                    {unitConversionsError && <ErrorMsg>{unitConversionsError}</ErrorMsg>}
                    {unitGroupsError && <ErrorMsg>{unitGroupsError}</ErrorMsg>}

                    {filteredUnitConversions &&
                        <>
                            <SectionBody>
                                <SectionColumn>
                                    <SectionTitle>{t('データベースパック')}</SectionTitle>
                                    <SectionContent>
                                        <SelectPrimary>
                                            <SelectInner
                                                name="database-pack"
                                                id="database-pack-select"
                                                onChange={(event) => handleInputChangeSelect(event, 'database_pack_id')}
                                                value={inputDatabasePackId ?? ''}
                                            >
                                                {databasePacks.map((database_pack) => (
                                                    <option key={database_pack.id} value={database_pack.id}>
                                                        {database_pack.name}
                                                    </option>
                                                ))}
                                            </SelectInner>
                                        </SelectPrimary>
                                    </SectionContent>

                                    <SectionTitle>{t('単位分類の選択')}</SectionTitle>
                                    <SectionContent>
                                        <SelectPrimary>
                                            <SelectInner
                                                name="unit_group"
                                                id="unit_group-select"
                                                onChange={(event) => handleInputChangeSelect(event, 'unit_group')}
                                            >
                                                {unitGroupsData?.map((unit: any) => (
                                                    <option key={unit.id} value={unit.id} >
                                                        {unit.name}
                                                    </option>
                                                ))}
                                            </SelectInner>
                                        </SelectPrimary>
                                    </SectionContent>

                                </SectionColumn>
                            </SectionBody>
                            {unitConversionsLoading &&
                                <FadeLoader
                                    color="#48bdbb"
                                    height={10}
                                    radius={2}
                                    width={5}
                                />
                            }
                            <SectionTable>
                                <SectionResult>
                                    <SectionResultTitle>{t('検索結果')}</SectionResultTitle>
                                    <SectionResultTitleText>{filteredUnitConversions.length}{t('件')}</SectionResultTitleText>
                                </SectionResult>
                                <MatrixTable data={filteredUnitConversions} />
                            </SectionTable>
                        </>
                    }
                </Inner>
            </Modal>
        </div>
    );
};

export default UnitConversionsModal;

const Title = styled.h2`
    font-size: 18px;
    padding: 0 0 20px;
    margin-bottom: 20px;
    border-bottom: 1px solid var(--color-line-primary);
    font-size: 16px;
    font-weight: 500;    
    line-height: 1.25;    
`



const Inner = styled.div`
    width: 100%;
    padding: 120px 10%;
    margin: 0 auto;
`

const SectionTable = styled.div`
    background-color: #fff;
    padding: 40px 20px 55px;
  
`

const SectionResult = styled.div`
    padding: 0 0 20px;
    margin-bottom: 20px;
    border-bottom: 1px solid var(--color-line-primary);
    display: flex;
    justify-content: space-between;
`

const SectionResultTitle = styled.h3`
    font-size: 18px;
    font-weight: 500;
`

const SectionResultTitleText = styled.p`
    font-size: 18px;
    font-weight: 500;
`

const SectionTitle = styled.h2`
    margin-bottom: 12px;
    font-size: 16px;
    font-weight: 500;
    line-height: 1.25;
`

const SectionBody = styled.div`
`

const SectionContent = styled.dd`
    margin-bottom: 32px;
    display: flex;
    align-items: center;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: flex-start;
    gap: 16px;    
`

const InputPrimary = styled.input`
    width: min(100%, 340px);

    padding: 12px;
    color: var(--color-txt-primary);
    border: 1px solid var(--color-line-primary);
    border-radius: 4px;
    background-color: #fff;
    font-size: 16px;
    font-weight: 400;
    line-height: 1.25;
`

const DataError = styled.p`
    font-size: 14px;
    font-weight: 500;
    color: var(--color-error-primary);
    display: flex;
    align-items: center;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: flex-start;
    gap: 5px;
`


const SelectPrimary = styled.label`
    width: min(100%, 340px);
    position: relative;
    display: block;
    cursor: pointer;
`

const SelectInner = styled.select`
    color: rgb(85, 85, 85);
    position: relative;
    width: 100%;
    padding: 12px 32px 12px 12px;
    cursor: pointer !important;
    border: 1px solid var(--color-line-primary);
    border-radius: 4px;
    background-color: #fff;
    font-size: 16px;
    font-weight: 400;
    line-height: 1.25;
`



const Radio = styled.div`
    display: flex;
    align-items: center;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: flex-start;
    gap: 54px; 
`

const RadioLabel = styled.label`
    order: 1;
    
    isplay: flex;
    align-items: center;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: flex-start;
    gap: 6px;
    width: fit-content;
    cursor: pointer;
    position: relative;    
`
const RadioText = styled.span`
    align-items: center;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: flex-start;
    gap: 0 20px;
    font-size: 16px;
    white-space: nowrap;
    font-weight: 500; 
`

const SectionColumn = styled.dl`
    margin-bottom: 40px;
`

const StyledButton = styled.button`
    background-color: var(--color-gray--01);
    color: inherit;

    padding: 14px 30px;
    border-radius: 4px;
    white-space: nowrap;

    display: inline-flex;
    align-items: center;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: center;
    cursor: pointer;
    white-space: nowrap;
    gap: 0 4px;

    appearance: none;
    border: none;
    font: inherit;
    outline: none;   
  &:hover {
    background-color: var(--color-site-secondary);
    color: #fff;
  }
`

