import Modal from '@common/modal/Modal';
import { createColumnHelper } from '@tanstack/react-table';
import React, { useEffect, useState, useContext } from 'react';
import { Edge, MarkerType, Node } from 'reactflow';
import DataTable from '@common/table/DataTable';
import { CaseStudyViewContext } from '@pages/case_study/CaseStudyView';
import styled from 'styled-components';
import iconSelect from '@images/table/icon_arrow_back.svg';
import { useTranslation } from 'react-i18next';

interface DownstreamSubsystemModalProps {
  currentNodeData: any;
  nodes: any[];
  isOpen: boolean;
  onClose: () => void;
}

const DownstreamSubsystemModal: React.FC<DownstreamSubsystemModalProps> = ({
  currentNodeData,
  nodes,
  isOpen,
  onClose,
}) => {
  const {t} = useTranslation();
  const { setNodes, setEdges,edges,  setFlowState, flowState } = useContext(CaseStudyViewContext);
  const [tableData, setTableData] = useState<any | null>(null);

  useEffect(() => {
    if (nodes !== null) {
        const flatData = nodes.flatMap((item: Node) => {

        // 既に接続されてるioデータのprocess_io_no
        const exclusionList = flowState.edges.map(edge => edge.targetHandle?.replace("input-", ""))

        const subsystemIos =
          item.data?.subsystem_ios.filter(
            (io: any) =>
              io.direction === 'in' && io.type === 'case_study_product' && !exclusionList.includes(String(io.process_io_no))
          ) || [];
        return subsystemIos.map((io: any) => ({
          nodeId: item.id,
          name: item.data.name || '',
          exchangeName: io.exchange?.name || '',
          ioData: io,
        }));
      });
      setTableData(flatData);
    } else {
      // console.log('data is Null');
    }
  }, [nodes, edges]);

  if (!isOpen) return null;

  const handleConnect = (selectedNodeId: string, ioData: any) => {
    const targetNode = nodes.find(node => node.id !== currentNodeData.id);
    // console.log("ioData", ioData);
    // console.log("currentNodeData", currentNodeData)
    const maxId = currentNodeData && currentNodeData.subsystem_ios.length > 0
      ? Math.max(...currentNodeData.subsystem_ios.map((io: any) => io.id), 0)
      : 0;

    const maxProcessIoNo = Math.max(
      ...flowState.nodes.flatMap(node => node.data.subsystem_ios.map((io: any) => io.process_io_no)),
      0
    );

    const updatedIoData = { ...ioData, id: null, process_io_no: maxProcessIoNo + 1, direction: 'out' };
    // console.log("updatedIoData", updatedIoData);
    const updatedNodes = flowState.nodes.map(node => {
      if (String(node.id) === String(currentNodeData.id)) {
        return {
          ...node,
          data: {
            ...node.data,
            subsystem_ios: [...(node.data.subsystem_ios || []), updatedIoData]
          }
        };
      }
      return node;
    });

    const newEdgeId = `e${currentNodeData.id}-${selectedNodeId}-${updatedIoData.process_io_no}-${ioData.process_io_no}`;
    const newEdge: Edge = {
      id: newEdgeId,
      source: String(currentNodeData.id),
      target: String(selectedNodeId),
      sourceHandle: `output-${updatedIoData.process_io_no}`,
      targetHandle: `input-${ioData.process_io_no}`,
      style: { stroke: '#2b2b2b', strokeWidth: 2 },
      markerEnd: {
        type: MarkerType.ArrowClosed,
        color: '#2b2b2b',
      },
    };

    setNodes(updatedNodes);
    setEdges(prevEdges => [...prevEdges, newEdge]);
    setFlowState(prev => ({
      ...prev,
      nodes: updatedNodes,
      edges: [...prev.edges, newEdge],
    }));

    onClose();
  };

  const columnHelper = createColumnHelper<any>();
  const columns = [
    columnHelper.accessor('connect', {
      header: () => t('連結'),
      cell: (info) => (
        <SelectButton onClick={() => handleConnect(info.row.original.nodeId, info.row.original.ioData)}>
          <ButtonInner>
            <SelectButtonIcon></SelectButtonIcon>
          </ButtonInner>
        </SelectButton>
      ),
    }),
    columnHelper.accessor('name', {
      header: () => t('サブシステム名'),
      cell: (info) => info.renderValue(),
    }),
    columnHelper.accessor('exchangeName', {
      header: () => t('製品名'),
      cell: (info) => info.renderValue(),
    }),
  ];

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <Inner>
        <div>
          <Title>{t('downstreamProductsTitle', { name: currentNodeData.name })}</Title>
        </div>
        <div>
          <DataTable count={0} columns={columns} data={tableData} paging_flag={false} />
        </div>
      </Inner>
    </Modal>
  );
};

export default DownstreamSubsystemModal;

const Inner = styled.div`
    width: 100%;
    padding: 120px 10%;
    margin: 0 auto;
`

const Title = styled.h2`
    font-size: 18px;
    padding: 0 0 20px;
    margin-bottom: 20px;
    border-bottom: 1px solid var(--color-line-primary);
    font-size: 16px;
    font-weight: 500;    
    line-height: 1.25;    
`


const ButtonInner = styled.div``;

const SelectButton = styled.button`
cursor: pointer;
appearance: none;
background: transparent;
border: none;
border-radius: 0;
font: inherit;
outline: none;
`;

const SelectButtonIcon = styled.span`
mask: url(${iconSelect}) no-repeat center center / contain;
-webkit-mask: url(${iconSelect}) no-repeat center center / contain;
display: block;
width: 24px;
height: 24px;
background: var(--color-txt-primary);
`;