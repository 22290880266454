import React, { useContext, useEffect, useState } from 'react';
import ProcessDataQualityValueTable from '@common/table/ProcessDataQualityValueTable';
import { ProcessViewContext } from '@pages/process/ProcessView';
import { DataQualityIndicators, ReviewLevelType, UpdateProcess } from '@typeList/types';
import { Controller, Control, FieldErrors, UseFormRegister, UseFormWatch } from 'react-hook-form';
import ReviewLevelTypeTable from '@common/table/ProcessReviewLevelTypeTable';
import ExtendProperty from '../addition_info/ExtendProperty';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import iconDownLoad from '@images/table/icon_download.svg'
import iconDelete from '@images/table/icon_delete.svg';
import iconCross from '@images/table/icon_cross.svg';
import iconImg from '@images/file/icon_file_upload.svg'
import iconAdd from '@images/text_tree/icon_add.svg'
import { formatToLocalTime, formatDateTimeForInput } from '@utils/utils';
import DateInput from '@common/date_picker/DateInput';
import AddButton from '@common/button/AddButton';

interface FormProps {
    register: UseFormRegister<UpdateProcess>;
    errors: FieldErrors<UpdateProcess>;
    control: Control<UpdateProcess, any>;
    watch: UseFormWatch<UpdateProcess>;
}

/**
 * 007 プロセス閲覧 追加情報
 * 
 * @param param0 
 * @returns 
 */
const AdditionInfo: React.FC<FormProps> = ({ watch, control, register, errors }) => {
    const { t } = useTranslation();
    const context = useContext(ProcessViewContext);
    const {
        processData,
        // unsaved,
        // setUnsaved,
        changeCheckAdditionInfo,
        setChangeCheckAdditionInfo,
        dataQualityIndicatorsData,
        setSelectQualityIndicatorsData,
        selectQualityIndicatorsData,
        qualityIndicatorsDataList,
        setQualityIndicatorsDataList,
        reviewLevelTypeData,
        selectReviewLevelData,
        reviewLevelsDataList,
        setSelectReviewLevelData,
        setReviewLevelsDataList,
        previewImage,
        setPreviewImage,
        setImageFile,
        imageSrc, 
        setImageSrc,
        imageName,
        setImageName,
        referenceFiles,
        setReferenceFiles,
    } = context;

    // 変更監視
    const watchedName = watch('name', processData?.name || '');
    const watchedTags = watch('tags', processData?.tags || '');
    const watchedPublicComment = watch('public_comment', processData?.public_comment || '');
    const watchedPrivateComment = watch('private_comment', processData?.private_comment || '');
    const watchedTechnicalFeature = watch('technical_feature', processData?.technical_feature || '');
    const watchedTechnicalContentAndFunctionality = watch('technical_content_and_functionality', processData?.technical_content_and_functionality || '');
    const watchedTnicalScope = watch('technical_scope', processData?.technical_scope || '');
    const watchedTechnologyLevel = watch('technology_level', processData?.technology_level || '');
    const watchedIntendedApplication = watch('intended_application', processData?.intended_application || '');
    const watchedInformationSources = watch('information_sources', processData?.information_sources || '');
    const watchedDataSelectionPrinciple = watch('data_selection_principle', processData?.data_selection_principle || '');
    const watchedApplicationPrinciple = watch('application_principle', processData?.application_principle || '');
    const watchedCriteriaForExcludingElementaryFlow = watch('criteria_for_excluding_elementary_flow', processData?.criteria_for_excluding_elementary_flow || '');
    const watchedCriteriaForExcludingIntermediateFlow = watch('criteria_for_excluding_intermediate_flow', processData?.criteria_for_excluding_intermediate_flow || '');
    const watchedSamplingProcedure = watch('sampling_procedure', processData?.sampling_procedure || '');
    const watchedSamplingSites = watch('sampling_sites', processData?.sampling_sites || '');
    const watchedNumberOfSamplingSites = watch('number_of_sampling_sites', processData?.number_of_sampling_sites || '');
    const watchedSamplingRelativeVolume = watch('sampling_relative_volume', processData?.sampling_relative_volume || '');
    const watchedSamplingAbsoluteVolume = watch('sampling_absolute_volume', processData?.sampling_absolute_volume || '');
    const watchedTimeSpanDescription = watch('time_span_description', processData?.time_span_description || '');
    const watchedGeographyAreaName = watch('geography_area_name', processData?.geography_area_name || '');
    const watchedGeographyAreaDescription = watch('geography_area_description', processData?.geography_area_description || '');
    const watchedGeographySites = watch('geography_sites', processData?.geography_sites || '');
    const watchedCreatorOrganization = watch('creator_organization', processData?.creator_organization || '');
    const watchedCreatorDepartment = watch('creator_department', processData?.creator_department || '');
    const watchedCreatorName = watch('creator_name', processData?.creator_name || '');
    const watchedCreatorContactInfo = watch('creator_contact_info', processData?.creator_contact_info || '');
    const watchedCreatorDescription = watch('creator_description', processData?.creator_description || '');
    const watchedExtrapolation = watch('extrapolation', processData?.extrapolation || '');
    const watchedReferenceUri = watch('reference_uri', processData?.reference_uri || '');
    const watchedInformationSourceType = watch('information_source_type', processData?.information_source_type ?? t('未設定'));
    const watchedDataRetrievalStartAt = watch('data_retrieval_start_at', processData?.data_retrieval_start_at || '');
    const watchedDataRetrievalEndAt = watch('data_retrieval_end_at', processData?.data_retrieval_end_at || '');
    const watchedGisReference = watch('gis_reference', processData?.gis_reference || '');
    
    
    useEffect(() => {
        if (
            watchedName !== (processData?.name ?? '') 
            || watchedTags !== (processData?.tags ?? '') 
            || watchedPublicComment !== (processData?.public_comment ?? '') 
            || watchedPrivateComment !== (processData?.private_comment ?? '') 
            || watchedTechnicalFeature !== (processData?.technical_feature ?? '')
            || watchedTechnicalContentAndFunctionality !== (processData?.technical_content_and_functionality ?? '')
            || watchedTnicalScope !== (processData?.technical_scope ?? '')
            || String(watchedTechnologyLevel)  !== String(processData?.technology_level)
            || watchedIntendedApplication !== (processData?.intended_application ?? '')
            || watchedInformationSources !== (processData?.information_sources ?? '')
            || watchedDataSelectionPrinciple !== (processData?.data_selection_principle ?? '')
            || watchedApplicationPrinciple !== (processData?.application_principle ?? '')
            || watchedCriteriaForExcludingElementaryFlow !== (processData?.criteria_for_excluding_elementary_flow ?? '')
            || watchedCriteriaForExcludingIntermediateFlow !== (processData?.criteria_for_excluding_intermediate_flow ?? '')
            || watchedSamplingProcedure !== (processData?.sampling_procedure ?? '')
            || watchedSamplingSites !== (processData?.sampling_sites ?? '')
            || watchedNumberOfSamplingSites !== (processData?.number_of_sampling_sites ?? '')
            || watchedSamplingRelativeVolume !== (processData?.sampling_relative_volume ?? '')
            || watchedSamplingAbsoluteVolume !== (processData?.sampling_absolute_volume ?? '')
            || watchedTimeSpanDescription !== (processData?.time_span_description ?? '')
            || watchedGeographyAreaName !== (processData?.geography_area_name ?? '')
            || watchedGeographyAreaDescription !== (processData?.geography_area_description ?? '')
            || watchedGeographySites !== (processData?.geography_sites ?? '')
            || watchedCreatorOrganization !== (processData?.creator_organization ?? '')
            || watchedCreatorDepartment !== (processData?.creator_department ?? '')
            || watchedCreatorName !== (processData?.creator_name ?? '')
            || watchedCreatorContactInfo !== (processData?.creator_contact_info ?? '')
            || watchedCreatorDescription !== (processData?.creator_description ?? '')
            || watchedExtrapolation !== (processData?.extrapolation ?? '')
            || watchedReferenceUri !== (processData?.reference_uri ?? '')
            || watchedInformationSourceType !== (!processData?.information_source_type ? t('未設定') : processData?.information_source_type)
            || watchedGisReference !== (processData?.gis_reference ?? '')
            || watchedDataRetrievalStartAt !== (processData?.data_retrieval_start_at ?? '')
            || watchedDataRetrievalEndAt !== (processData?.data_retrieval_end_at ?? '') 
            || (selectQualityIndicatorsData && JSON.stringify(selectQualityIndicatorsData) !== JSON.stringify(processData?.process_data_quality_values))
            || (selectReviewLevelData && JSON.stringify(selectReviewLevelData) !== JSON.stringify(processData?.process_review_level_values))

        ) {
            // console.log("selectQualityIndicatorsData", selectQualityIndicatorsData)
            // console.log("JSON.stringify(processData?.process_data_quality_values)", JSON.stringify(processData?.process_data_quality_values))
            // console.log("selectReviewLevelData", selectReviewLevelData)
            // console.log("JSON.stringify(processData?.process_review_level_values)", JSON.stringify(processData?.process_review_level_values))

            setChangeCheckAdditionInfo(true);
        }
    }, [
        watchedName, watchedTags, watchedPublicComment, watchedPrivateComment, watchedTechnicalFeature, watchedTnicalScope, watchedTechnologyLevel,
        watchedTechnicalContentAndFunctionality,watchedIntendedApplication, watchedInformationSources, watchedDataSelectionPrinciple,
        watchedApplicationPrinciple,watchedCriteriaForExcludingElementaryFlow, watchedCriteriaForExcludingIntermediateFlow, 
        watchedSamplingProcedure, watchedSamplingSites, watchedNumberOfSamplingSites, watchedSamplingRelativeVolume, watchedSamplingAbsoluteVolume,
        watchedTimeSpanDescription, watchedGeographyAreaName, watchedGeographyAreaDescription, watchedGeographySites,
        watchedCreatorOrganization, watchedCreatorDepartment, watchedCreatorName, watchedCreatorContactInfo, watchedCreatorDescription,
        watchedExtrapolation, watchedReferenceUri, watchedInformationSourceType, watchedDataRetrievalStartAt, watchedDataRetrievalEndAt,
        watchedGisReference, selectQualityIndicatorsData, selectReviewLevelData
    ]);

    // 品質の初期設定
    useEffect(() => {
        if (processData?.process_data_quality_values
            && dataQualityIndicatorsData
            && !selectQualityIndicatorsData
            && !qualityIndicatorsDataList
        ) {
            const matchedData = processData.process_data_quality_values.map((qualityValue) => {
                return dataQualityIndicatorsData.find((indicator) => indicator.id === qualityValue.indicator_id);
            }).filter(Boolean);
            setSelectQualityIndicatorsData(processData?.process_data_quality_values);
            setQualityIndicatorsDataList(matchedData as DataQualityIndicators[]);
        }
    }, [processData, dataQualityIndicatorsData, setQualityIndicatorsDataList]);

    // レビュータイプの初期設定
    useEffect(() => {
        if (processData?.process_review_level_values
            && reviewLevelTypeData
            && !selectReviewLevelData
            && !reviewLevelsDataList
        ) {
            const matchedData = processData.process_review_level_values.map((leviewValue) => {
                return reviewLevelTypeData.find((review_type) => review_type.id === leviewValue.review_type_id);
            }).filter(Boolean);
            setSelectReviewLevelData(processData?.process_review_level_values);
            setReviewLevelsDataList(matchedData as ReviewLevelType[]);
        }
    }, [processData, reviewLevelTypeData, setReviewLevelsDataList]);

     // 画像の追加ハンドラー
    const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const file = event.target.files?.[0];
        if (file) {
            setImageName(file.name);
            const reader = new FileReader();
            reader.onloadend = () => {
                setPreviewImage(reader.result as string);
                setImageFile(file);
            };
            reader.readAsDataURL(file);
            setChangeCheckAdditionInfo(true);
        }
    };

    const triggerFileInput = () => {
        document.getElementById('fileImg')?.click();
    };

    // 画像の削除ハンドラー
    const handleImageDelete = (event: React.MouseEvent<HTMLButtonElement>) => {
        event.preventDefault(); // デフォルト動作を防止
        event.stopPropagation(); // イベントの伝播を防止
        setImageSrc(null);
        setPreviewImage(null);
        setImageName(null);
        setImageFile(undefined);
        setChangeCheckAdditionInfo(true);
        // ファイル入力フィールドをリセット
        const fileInput = document.getElementById('fileImg') as HTMLInputElement;
        if (fileInput) {
            fileInput.value = ''; // ファイル選択のリセット
        }
    };

    // 参考ファイル
    const handleReferenceFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const files = event.target.files;
        if (files) {
            const newFilesArray = Array.from(files).map(file => ({
                name: file.name,
                file: file
            }));
            setReferenceFiles(prevFiles => [...prevFiles, ...newFilesArray]);
        }
    };

    const handleFileDownload = async (file: File ) => {
        try {
            // File オブジェクトから URL を作成
            const url = URL.createObjectURL(file);
    
            // ダウンロードリンクを作成
            const a = document.createElement('a');
            a.href = url;
            a.download = file.name; // ファイル名を設定
            document.body.appendChild(a);
    
            // ダウンロードリンクをクリックしてダウンロードを開始
            a.click();
    
            // ダウンロードが完了したらクリーンアップ
            URL.revokeObjectURL(url);
            document.body.removeChild(a);
        } catch (error) {
            console.error('ファイルのダウンロードに失敗しました:', error);
        }
    };


    const triggerReferenceFileInput = () => {
        const fileInput = document.getElementById('referenceFileInput') as HTMLInputElement;
        if (fileInput) {
            fileInput.click();
        } else {
            console.error('The file input element was not found!');
        }
    };

    // 日付形式フォーマット yyyy-mm-dd  hh:mm
    const formatDateForInput = (dateString: string) => {
        return dateString ? new Date(dateString).toISOString().slice(0, 16) : '';
    };
    
    return (
        <>
            <Section>
                <SectionTitle>{t('システム')}</SectionTitle>
                <Dl>
                    <Dt>{t('UUID')}</Dt>
                    <Dd>
                        <DisableInput type="text" disabled value={processData?.global_id} />
                    </Dd>
                    <Dt>{t('プロセス名')}</Dt>
                    <Dd>
                        <Controller
                            name="name"
                            control={control}
                            rules={{ required: t('プロセス名は必須です') }}
                            defaultValue={processData?.name || ''}
                            render={({ field }) => (
                                <>
                                    {!processData?.is_database_pack_master&& 
                                        <Input
                                            type="text"
                                            {...field}
                                        />
                                    }
                                    {processData?.is_database_pack_master&& 
                                        <DisableInput
                                            type="text"
                                            disabled
                                            {...field}
                                        />
                                    }
                                    {errors.name && <p style={{ color: 'red' }}>{errors.name.message}</p>}
                                </>
                            )}
                        />
                    </Dd>
                    <Dt>{t('チームUUID')}</Dt>
                    <Dd>
                        <DisableInput disabled type="text" />
                    </Dd>
                    <Dt>{t('タグ')}</Dt>
                    <Dd>
                        <Controller
                            name="tags"
                            control={control}
                            defaultValue={processData?.tags || ''}
                            render={({ field }) => (
                                <>
                                    {!processData?.is_database_pack_master &&
                                        <Input
                                            type="text"
                                            {...field}
                                            value={field.value || ''}
                                        />
                                    }
                                    {processData?.is_database_pack_master &&
                                        <DisableInput
                                            type="text"
                                            disabled
                                            {...field}
                                            value={field.value || ''}
                                        />
                                    }
                                </>
                            )}
                        />
                    </Dd>

                    <Dt>{t('初期作成日時')}</Dt>
                    <Dd>
                        <DateColumn>
                            <DisableInput
                                disabled
                                type="text"
                                defaultValue={formatToLocalTime(String(processData?.created_at)) || ''}
                                {...register('data_retrieval_start_at')}
                            />
                            <DisableInput
                                disabled
                                type="text"
                                defaultValue={processData?.creator_name || ''}
                                {...register('creator_name')}
                            />
                        </DateColumn>
                    </Dd>

                    <Dt>{t('最終更新日時')}</Dt>
                    <Dd>
                        <DateColumn>
                            <DisableInput
                                disabled
                                type="text"
                                defaultValue={formatToLocalTime(String(processData?.updated_at)) || ''}
                                {...register('data_retrieval_end_at')}
                            />
                            <DisableInput
                                disabled
                                type="text"
                                defaultValue={processData?.creator_name ?? ''}
                                {...register('creator_name')}
                            />
                        </DateColumn>
                    </Dd>
                    <Dt>{t('公開コメント')}</Dt>
                    <Dd>
                        <Controller
                            name="public_comment"
                            control={control}
                            defaultValue={processData?.public_comment || ''}
                            render={({ field }) => (
                                <>
                                    {!processData?.is_database_pack_master &&
                                        <Textarea
                                            cols={100}
                                            rows={3}
                                            {...field}
                                            value={field.value || ''}
                                        />
                                    }
                                    {processData?.is_database_pack_master &&
                                        <DisableTextarea
                                            disabled
                                            cols={100}
                                            rows={3}
                                            {...field}
                                            value={field.value || ''}
                                        />
                                    }
                                </>
                            )}
                        />
                    </Dd>
                    <Dt>{t('プライベートコメント')}</Dt>
                    <Dd>
                        <Controller
                            name="private_comment"
                            control={control}
                            defaultValue={processData?.private_comment || ''}
                            render={({ field }) => (

                                <>
                                    {!processData?.is_database_pack_master &&
                                        <Textarea
                                            cols={100}
                                            rows={3}
                                            {...field}
                                            value={field.value || ''}
                                        />
                                    }
                                    {processData?.is_database_pack_master &&
                                        <DisableTextarea
                                            disabled
                                            cols={100}
                                            rows={3}
                                            {...field}
                                            value={field.value || ''}
                                        />
                                    }
                                </>
                            )}
                        />
                    </Dd>
                </Dl>
            </Section>

            <Section>
                <SectionTitle>{t('技術')}</SectionTitle>
                <Dl>
                    <Dt>{t('技術的特徴(処理法、規格、製法など)')}</Dt>
                    <Dd>
                        <Controller
                            name="technical_feature"
                            control={control}
                            defaultValue={processData?.technical_feature || ''}
                            render={({ field }) => (
                                <>
                                    {!processData?.is_database_pack_master &&
                                        <Input
                                            type="text"
                                            {...field}
                                            value={field.value || ''}
                                        />
                                    }
                                    {processData?.is_database_pack_master &&
                                        <DisableInput
                                            type="text"
                                            {...field}
                                            value={field.value || ''}
                                        />
                                    }
                                </>
                            )}
                        />
                    </Dd>

                    <Dt>{t('技術の内容と機能')}</Dt>
                    <Dd>
                        <Controller
                            name="technical_content_and_functionality"
                            control={control}
                            defaultValue={processData?.technical_content_and_functionality || ''}
                            render={({ field }) => (
                                <>
                                    {!processData?.is_database_pack_master &&
                                        <Input
                                            type="text"
                                            {...field}
                                            value={field.value || ''}
                                        />
                                    }
                                    {processData?.is_database_pack_master &&
                                        <DisableInput
                                            type="text"
                                            disabled
                                            {...field}
                                            value={field.value || ''}
                                        />
                                    }
                                </>
                            )}
                        />
                    </Dd>

                    <Dt>{t('技術の範囲')}</Dt>
                    <Dd>
                        <Controller
                            name="technical_scope"
                            control={control}
                            defaultValue={processData?.technical_scope || ''}
                            render={({ field }) => (
                                <>
                                    {!processData?.is_database_pack_master &&
                                        <Input
                                            type="text"
                                            {...field}
                                            value={field.value || ''}
                                        />
                                    }
                                    {processData?.is_database_pack_master &&
                                        <DisableInput
                                            type="text"
                                            disabled
                                            {...field}
                                            value={field.value || ''}
                                        />
                                    }
                                </>
                            )}
                        />
                    </Dd>


                    <Dt>{t('技術のレベル')}</Dt>
                    <Dd>
                        <Controller
                            name="technology_level"
                            control={control}
                            defaultValue={processData?.technology_level || '3'}
                            render={({ field }) => (
                                <SelectLabel>
                                    <Select {...field} name='technology_level' value={field.value ?? '3'} disabled={processData?.is_database_pack_master}>
                                        <option value="0">undefined</option>
                                        <option value="1">New</option>
                                        <option value="2">Modern</option>
                                        <option value="3"> Current (default)</option>
                                        <option value="4">Old</option>
                                        <option value="5">Outdated</option>
                                    </Select>
                                </SelectLabel>
                            )}
                        />
                    </Dd>

                    <Dt>{t('技術の図')}</Dt>
                    <Dd>
                        <FileDiv>
                            <FileWrap>
                                <FileInput type="file" id="fileImg" onChange={handleFileChange} disabled={processData?.is_database_pack_master}/>
                                <FileLabel htmlFor="fileImg"  onClick={(e) => e.stopPropagation()}>
                                    {previewImage ? (
                                        <ImgWrapper>
                                            <Img src={previewImage} width="100%" height="382px" alt={t('技術の図')} />
                                            <DeleteButton onClick={handleImageDelete}>
                                                <FileDeleteIcon />
                                            </DeleteButton>
                                        </ImgWrapper>
                                    ) : imageSrc ? (
                                        <ImgWrapper>
                                            <Img src={`data:image/png;base64,${imageSrc}`} width="100%" height="380px" alt={t('技術の図')} />
                                            <DeleteButton onClick={handleImageDelete}>
                                                <FileDeleteIcon />
                                            </DeleteButton>
                                        </ImgWrapper>
                                    ) : (
                                        <Img src={iconImg} alt={t('技術の図')} />
                                    )}
                                </FileLabel>
                            </FileWrap>
                            <p>{t('ファイル名')}</p>
                            <DisableInput type="text" value={imageName || ''} readOnly />
                            <label>
                                <AddButton type='button' onClick={triggerFileInput} text={t('ファイルを追加')} disabled={processData?.is_database_pack_master} />
                            </label>
                        </FileDiv>
                    </Dd>
                </Dl>
            </Section>

            <Section>
                <SectionTitle>{t('モデル化')}</SectionTitle>
                <Dl>
                    <Dt>{t('意図する用途(処理法、規格、製法など)')}</Dt>
                    <Dd>
                        <Controller
                            name="intended_application"
                            defaultValue={processData?.intended_application || ''}
                            control={control}
                            render={({ field }) => (
                                <>
                                    {!processData?.is_database_pack_master &&
                                        <Input
                                            type="text"
                                            {...field}
                                            value={field.value || ''}
                                        />
                                    }
                                    {processData?.is_database_pack_master &&
                                        <DisableInput
                                            type="text"
                                            disabled
                                            {...field}
                                            value={field.value || ''}
                                        />
                                    }
                                </>
                            )}
                        />
                    </Dd>
                    <Dt>{t('情報源')}</Dt>
                    <Dd>
                        <Controller
                            name="information_sources"
                            defaultValue={processData?.information_sources || ''}
                            control={control}
                            render={({ field }) => (
                                <>
                                    {!processData?.is_database_pack_master &&
                                        <Input
                                            type="text"
                                            {...field}
                                            value={field.value || ''}
                                        />
                                    }
                                    {processData?.is_database_pack_master &&
                                        <DisableInput
                                            type="text"
                                            disabled
                                            {...field}
                                            value={field.value || ''}
                                        />
                                    }
                                </>
                            )}
                        />
                    </Dd>

                    <Dt>{t('情報源分類')}</Dt>
                    <Dd>
                        <Controller
                            name="information_source_type"
                            control={control}
                            defaultValue={processData?.information_source_type || t('未設定')}
                            render={({ field }) => (
                                <SelectLabel>
                                    <Select {...field} name='information_source_type' value={field.value || t('未設定')} disabled={processData?.is_database_pack_master}>
                                        <option value={t('未設定')}>{t('未設定')}</option>
                                        <option value={t('（GISの記述）')}>{t('（GISの記述）')}</option>
                                    </Select>
                                </SelectLabel>
                            )}
                        />
                    </Dd>

                    <Dt>{t('データ選定の原則')}</Dt>
                    <Dd>
                        <Controller
                            name="data_selection_principle"
                            defaultValue={processData?.data_selection_principle || ''}
                            control={control}
                            render={({ field }) => (
                                <>
                                    {!processData?.is_database_pack_master &&
                                        <Input
                                            type="text"
                                            {...field}
                                            value={field.value || ''}
                                        />
                                    }
                                    {processData?.is_database_pack_master &&
                                        <DisableInput
                                            type="text"
                                            disabled
                                            {...field}
                                            value={field.value || ''}
                                        />
                                    }
                                </>
                            )}
                        />
                    </Dd>

                    <Dt>{t('適応原則')}</Dt>
                    <Dd>
                        <Controller
                            name="application_principle"
                            defaultValue={processData?.application_principle || ''}
                            control={control}
                            render={({ field }) => (
                                <>
                                    {!processData?.is_database_pack_master &&
                                        <Input
                                            type="text"
                                            {...field}
                                            value={field.value || ''}
                                        />
                                    }
                                    {processData?.is_database_pack_master &&
                                        <DisableInput
                                            type="text"
                                            disabled
                                            {...field}
                                            value={field.value || ''}
                                        />
                                    }
                                </>
                            )}
                        />
                    </Dd>

                    <Dt>{t('基本フローの除外基準')}</Dt>
                    <Dd>
                        <Controller
                            name="criteria_for_excluding_elementary_flow"
                            defaultValue={processData?.criteria_for_excluding_elementary_flow || ''}
                            control={control}
                            render={({ field }) => (
                                <>
                                    {!processData?.is_database_pack_master &&
                                        <Input
                                            type="text"
                                            {...field}
                                            value={field.value || ''}
                                        />
                                    }
                                    {processData?.is_database_pack_master &&
                                        <DisableInput
                                            type="text"
                                            disabled
                                            {...field}
                                            value={field.value || ''}
                                        />
                                    }
                                </>
                            )}
                        />
                    </Dd>

                    <Dt>{t('中間製品フローの除外基準')}</Dt>
                    <Dd>
                        <Controller
                            name="criteria_for_excluding_intermediate_flow"
                            defaultValue={processData?.criteria_for_excluding_intermediate_flow || ''}
                            control={control}
                            render={({ field }) => (
                                <>
                                    {!processData?.is_database_pack_master &&
                                        <Input
                                            type="text"
                                            {...field}
                                            value={field.value || ''}
                                        />
                                    }
                                    {processData?.is_database_pack_master &&
                                        <DisableInput
                                            type="text"
                                            disabled
                                            {...field}
                                            value={field.value || ''}
                                        />
                                    }
                                </>
                            )}
                        />
                    </Dd>

                    <Dt>{t('参考URL')}</Dt>
                    <Dd>
                        <Controller
                            name="reference_uri"
                            defaultValue={processData?.reference_uri || ''}
                            control={control}
                            render={({ field }) => (
                                <>
                                    {!processData?.is_database_pack_master &&
                                        <Input
                                            type="text"
                                            {...field}
                                            value={field.value || ''}
                                        />
                                    }
                                    {processData?.is_database_pack_master &&
                                        <DisableInput
                                            type="text"
                                            disabled
                                            {...field}
                                            value={field.value || ''}
                                        />
                                    }
                                </>
                            )}
                        />
                    </Dd>

                    <Dt>{t('参考ファイル')}</Dt>
                    <Dd>
                        <input
                            type="file"
                            id="referenceFileInput"
                            style={{ display: 'none' }}
                            multiple
                            onChange={handleReferenceFileChange}
                        />

                        <ReferenceFileWrap>
                            <Table>
                                <Thead>
                                    <Tr>
                                        <Th><ThInner>{t('削除')}</ThInner></Th>
                                        <Th><ThInner>{t('ダウンロード')}</ThInner></Th>
                                        <Th><ThInner>{t('ファイル名')}</ThInner></Th>
                                    </Tr>
                                </Thead>
                                <Tbody>
                                    {referenceFiles.map((file, index) => (
                                        <Tr key={index}>
                                            <Td>
                                                <IconButton onClick={() => {
                                                    setReferenceFiles(current => current.filter((_, i) => i !== index));
                                                }}><Icon></Icon></IconButton>
                                            </Td>
                                            <Td>
                                            <DownloadButton onClick={() =>{ handleFileDownload(file.file)}}>
                                                <div>
                                                    <DownloadIcon></DownloadIcon>
                                                </div>
                                                {/* {value} */}
                                            </DownloadButton>
                                            </Td>
                                            <Td>{file.name}</Td>
                                        </Tr>
                                    ))}
                                </Tbody>
                            </Table>
                            <TableButtonWrap>
                                <AddButton type='button' onClick={triggerReferenceFileInput} text={t('ファイルを追加')} disabled={processData?.is_database_pack_master} />
                            </TableButtonWrap>
                        </ReferenceFileWrap>
                    </Dd>
                </Dl>
            </Section>

            <Section>
                <SectionTitle>{t('サンプリング')}</SectionTitle>
                <Dl>
                    <Dt>{t('サンプリング方法')}</Dt>
                    <Dd>
                        <Controller
                            name="sampling_procedure"
                            defaultValue={processData?.sampling_procedure || ''}
                            control={control}
                            render={({ field }) => (
                                <>
                                    {!processData?.is_database_pack_master &&
                                        <Input
                                            type="text"
                                            {...field}
                                            value={field.value || ''}
                                        />
                                    }
                                    {processData?.is_database_pack_master &&
                                        <DisableInput
                                            type="text"
                                            disabled
                                            {...field}
                                            value={field.value || ''}
                                        />
                                    }
                                </>
                            )}
                        />
                    </Dd>
                    <Dt>{t('サイト')}</Dt>
                    <Dd>
                        <Controller
                            name="sampling_sites"
                            defaultValue={processData?.sampling_sites || ''}
                            control={control}
                            render={({ field }) => (
                                <>
                                    {!processData?.is_database_pack_master &&
                                        <Input
                                            type="text"
                                            {...field}
                                            value={field.value || ''}
                                        />
                                    }
                                    {processData?.is_database_pack_master &&
                                        <DisableInput
                                            type="text"
                                            disabled
                                            {...field}
                                            value={field.value || ''}
                                        />
                                    }
                                </>
                            )}
                        />
                    </Dd>

                    <Dt>{t('サイト数')}</Dt>
                    <Dd>
                        <Controller
                            name="number_of_sampling_sites"
                            defaultValue={processData?.number_of_sampling_sites || ''}
                            control={control}
                            render={({ field }) => (
                                <>
                                    {!processData?.is_database_pack_master &&
                                        <Input
                                            type="text"
                                            {...field}
                                            value={field.value || ''}
                                        />
                                    }
                                    {processData?.is_database_pack_master &&
                                        <DisableInput
                                            type="text"
                                            disabled
                                            {...field}
                                            value={field.value || ''}
                                        />
                                    }
                                </>
                            )}
                        />
                    </Dd>

                    <Dt>{t('サンプル数(相対)')}</Dt>
                    <Dd>
                        <Controller
                            name="sampling_relative_volume"
                            defaultValue={processData?.sampling_relative_volume || ''}
                            control={control}
                            render={({ field }) => (
                                <>
                                    {!processData?.is_database_pack_master &&
                                        <Input
                                            type="text"
                                            {...field}
                                            value={field.value || ''}
                                        />
                                    }
                                    {processData?.is_database_pack_master &&
                                        <DisableInput
                                            type="text"
                                            disabled
                                            {...field}
                                            value={field.value || ''}
                                        />
                                    }
                                </>
                            )}
                        />
                    </Dd>

                    <Dt>{t('サンプル数(絶対)')}</Dt>
                    <Dd>
                        <Controller
                            name="sampling_absolute_volume"
                            defaultValue={processData?.sampling_absolute_volume || ''}
                            control={control}
                            render={({ field }) => (
                                <>
                                    {!processData?.is_database_pack_master &&
                                        <Input
                                            type="text"
                                            {...field}
                                            value={field.value || ''}
                                        />
                                    }
                                    {processData?.is_database_pack_master &&
                                        <DisableInput
                                            type="text"
                                            disabled
                                            {...field}
                                            value={field.value || ''}
                                        />
                                    }
                                </>
                            )}
                        />
                    </Dd>

                    <Dt>{t('外挿法')}</Dt>
                    <Dd>
                        <Controller
                            name="extrapolation"
                            defaultValue={processData?.extrapolation || ''}
                            control={control}
                            render={({ field }) => (
                                <>
                                    {!processData?.is_database_pack_master &&
                                        <Input
                                            type="text"
                                            {...field}
                                            value={field.value || ''}
                                        />
                                    }
                                    {processData?.is_database_pack_master &&
                                        <DisableInput
                                            type="text"
                                            disabled
                                            {...field}
                                            value={field.value || ''}
                                        />
                                    }
                                </>
                            )}
                        />
                    </Dd>
                </Dl>
            </Section>

            <Section>
                <SectionTitle>{t('時間的有効範囲')}</SectionTitle>
                <Dl>
                    <Dt>{t('データ収集期間')}</Dt>
                    <Dd>
                        <DateWrap>
                            <Controller
                                name="data_retrieval_start_at"
                                defaultValue={formatDateForInput(processData?.data_retrieval_start_at || "")}
                                control={control}
                                render={({ field }) => (
                                    <>
                                        {!processData?.is_database_pack_master &&
                                            <DateInput
                                                value={field.value}
                                                onChange={field.onChange} // field.onChange を DateInput に渡す
                                            />
                                        }
                                        {processData?.is_database_pack_master &&
                                            <DateInput
                                                disabled={true}
                                                value={field.value}
                                                onChange={field.onChange} // field.onChange を DateInput に渡す
                                            />
                                        }
                                    </>
                                )}
                            />
                            <span>〜</span>
                            <Controller
                                name="data_retrieval_end_at"
                                defaultValue={formatDateForInput(processData?.data_retrieval_end_at || "")}
                                control={control}
                                render={({ field }) => (
                                    <>
                                        {!processData?.is_database_pack_master &&
                                            <DateInput
                                                value={field.value}
                                                onChange={field.onChange} // field.onChange を DateInput に渡す
                                            />
                                        }
                                        {processData?.is_database_pack_master &&
                                            <DateInput
                                                disabled={true}
                                                value={field.value}
                                                onChange={field.onChange} // field.onChange を DateInput に渡す
                                            />
                                        }
                                    </>
                                )}
                            />
                        </DateWrap>
                    </Dd>

                    <Dt>{t('時間的有効範囲に関する記述')}</Dt>
                    <Dd>        
                        <Controller
                            name="time_span_description"
                            defaultValue={processData?.time_span_description || ''}
                            control={control}
                            render={({ field }) => (
                                <>
                                    {!processData?.is_database_pack_master &&
                                        <Textarea
                                            cols={100}
                                            rows={3}
                                            {...field}
                                            value={field.value || ''}
                                        />
                                    }
                                    {processData?.is_database_pack_master &&
                                        <DisableTextarea
                                            disabled
                                            cols={100}
                                            rows={3}
                                            {...field}
                                            value={field.value || ''}
                                        />
                                    }
                                </>
                            )}
                        />
                    </Dd>
                </Dl>
            </Section>

            <Section>
                <SectionTitle>{t('地理的有効範囲')}</SectionTitle>
                <Dl>
                    <Dt>{t('収集地域の名称')}</Dt>
                    <Dd>
                        <Controller
                            name="geography_area_name"
                            defaultValue={processData?.geography_area_name || ''}
                            control={control}
                            render={({ field }) => (
                                <>
                                    {!processData?.is_database_pack_master &&
                                        <Input
                                            type="text"
                                            {...field}
                                            value={field.value || ''}
                                        />
                                    }
                                    {processData?.is_database_pack_master &&
                                        <DisableInput
                                            type="text"
                                            disabled
                                            {...field}
                                            value={field.value || ''}
                                        />
                                    }
                                </>
                            )}
                        />
                    </Dd>

                    <Dt>{t('収集地域に関する記述')}</Dt>
                    <Dd>
                        <Controller
                            name="geography_area_description"
                            defaultValue={processData?.geography_area_description || ''}
                            control={control}
                            render={({ field }) => (
                                <>
                                    {!processData?.is_database_pack_master &&
                                        <Textarea
                                            cols={100}
                                            rows={3}
                                            {...field}
                                            value={field.value || ''}
                                        />
                                    }
                                    {processData?.is_database_pack_master &&
                                        <DisableTextarea
                                            disabled
                                            cols={100}
                                            rows={3}
                                            {...field}
                                            value={field.value || ''}
                                        />
                                    }
                                </>
                            )}
                        />
                    </Dd>

                    <Dt>{t('サイト')}</Dt>
                    <Dd>
                        <Controller
                            name="geography_sites"
                            defaultValue={processData?.geography_sites || ''}
                            control={control}
                            render={({ field }) => (
                                <>
                                    {!processData?.is_database_pack_master &&
                                        <Input
                                            type="text"
                                            {...field}
                                            value={field.value || ''}
                                        />
                                    }
                                    {processData?.is_database_pack_master &&
                                        <DisableInput
                                            type="text"
                                            disabled
                                            {...field}
                                            value={field.value || ''}
                                        />
                                    }
                                </>
                            )}
                        />
                    </Dd>

                    <Dt>{t('GISの参照')}</Dt>
                    <Dd>
                        <Controller
                            name="gis_reference"
                            defaultValue={processData?.gis_reference || ''}
                            control={control}
                            render={({ field }) => (
                                <>
                                    {!processData?.is_database_pack_master &&
                                        <Input
                                            type="text"
                                            {...field}
                                            value={field.value || ''}
                                        />
                                    }
                                    {processData?.is_database_pack_master &&
                                        <DisableInput
                                            type="text"
                                            disabled
                                            {...field}
                                            value={field.value || ''}
                                        />
                                    }
                                </>
                            )}
                        />
                    </Dd>
                </Dl>
            </Section>

            <Section>
                <SectionTitle>{t('品質')}</SectionTitle>
                {dataQualityIndicatorsData && (
                    <ProcessDataQualityValueTable
                        selectQualityIndicatorsData={selectQualityIndicatorsData}
                        setSelectQualityIndicatorsData={setSelectQualityIndicatorsData}
                        qualityIndicatorsDataList={qualityIndicatorsDataList}
                        setQualityIndicatorsDataList={setQualityIndicatorsDataList}
                    />
                )}
            </Section>

            <Section>
                <SectionTitle>{t('プロセスレビューレベル')}</SectionTitle>
                {reviewLevelTypeData && (
                    <ReviewLevelTypeTable
                        selectReviewLevelData={selectReviewLevelData}
                        setSelectReviewLevelData={setSelectReviewLevelData}
                        reviewLevelsDataList={reviewLevelsDataList}
                        setReviewLevelsDataList={setReviewLevelsDataList}
                    />
                )}
            </Section>

            <Section>
                <SectionTitle>{t('作成者')}</SectionTitle>
                <Dl>
                    <Dt>{t('組織')}</Dt>
                    <Dd>
                        <Controller
                            name="creator_organization"
                            defaultValue={processData?.creator_organization || ''}
                            control={control}
                            render={({ field }) => (
                                <>
                                    {!processData?.is_database_pack_master &&
                                        <Input
                                            type="text"
                                            {...field}
                                            value={field.value || ''}
                                        />
                                    }
                                    {processData?.is_database_pack_master &&
                                        <DisableInput
                                            type="text"
                                            disabled
                                            {...field}
                                            value={field.value || ''}
                                        />
                                    }
                                </>
                            )}
                        />
                    </Dd>

                    <Dt>{t('部署')}</Dt>
                    <Dd>                        
                        <Controller
                            name="creator_department"
                            defaultValue={processData?.creator_department || ''}
                            control={control}
                            render={({ field }) => (
                                <>
                                    {!processData?.is_database_pack_master &&
                                        <Input
                                            type="text"
                                            {...field}
                                            value={field.value || ''}
                                        />
                                    }
                                    {processData?.is_database_pack_master &&
                                        <DisableInput
                                            type="text"
                                            disabled
                                            {...field}
                                            value={field.value || ''}
                                        />
                                    }
                                </>
                            )}
                        />
                    </Dd>

                    <Dt>{t('氏名')}</Dt>
                    <Dd>
                        <Controller
                            name="creator_name"
                            defaultValue={processData?.creator_name || ''}
                            control={control}
                            render={({ field }) => (
                                <>
                                    {!processData?.is_database_pack_master &&
                                        <Input
                                            type="text"
                                            {...field}
                                            value={field.value || ''}
                                        />
                                    }
                                    {processData?.is_database_pack_master &&
                                        <DisableInput
                                            type="text"
                                            disabled
                                            {...field}
                                            value={field.value || ''}
                                        />
                                    }
                                </>
                            )}
                        />
                    </Dd>

                    <Dt>{t('問い合わせ先')}</Dt>
                    <Dd>
                        <Controller
                            name="creator_contact_info"
                            defaultValue={processData?.creator_contact_info || ''}
                            control={control}
                            render={({ field }) => (
                                <>
                                    {!processData?.is_database_pack_master &&
                                        <Input
                                            type="text"
                                            {...field}
                                            value={field.value || ''}
                                        />
                                    }
                                    {processData?.is_database_pack_master &&
                                        <DisableInput
                                            type="text"
                                            disabled
                                            {...field}
                                            value={field.value || ''}
                                        />
                                    }
                                </>
                            )}
                        />
                    </Dd>

                    <Dt>{t('備考(著作権等)')}</Dt>
                    <Dd>
                        <Controller
                            name="creator_description"
                            defaultValue={processData?.creator_description || ''}
                            control={control}
                            render={({ field }) => (
                                <>
                                    {!processData?.is_database_pack_master &&
                                        <Textarea
                                            cols={100}
                                            rows={3}
                                            {...field}
                                            value={field.value || ''}
                                        />
                                    }
                                    {processData?.is_database_pack_master &&
                                        <DisableTextarea
                                            disabled
                                            cols={100}
                                            rows={3}
                                            {...field}
                                            value={field.value || ''}
                                        />
                                    }
                                </>
                            )}
                        />
                    </Dd>
                </Dl>
            </Section>
            <Section>
                <SectionTitle>{t('拡張プロパティ')}</SectionTitle>
                <ExtendProperty />
            </Section>
        </>
    );
}

export default AdditionInfo;

const Section = styled.section`
    margin-bottom: 80px;
    width: max(53.47222222vw, 770px);
`

const SectionTitle = styled.h2`
    padding: 0 0 20px;
    margin-bottom: 20px;
    border-bottom: 1px solid var(--color-line-primary);
    font-size: 16px;
    font-weight: 500

    line-height: 1.25;
`

const Dl = styled.dl`
    display: grid;
    gap: 8px 40px;
    grid-template-columns: 25% 70%;
`

const Dt = styled.dt`
    font-size: 12px;
    font-weight: 500;
    padding: 12px 0;
`

const Dd = styled.dd`
    font-size: 12px;
    font-weight: 500;
`

const DisableInput = styled.input`
    background-color: var(--color-bg-primary);
    width: 100%;
    padding: 12px;
    color: var(--color-txt-primary);
    border: 1px solid var(--color-line-primary);
    border-radius: 4px;
    font-size: 16px;
    font-weight: 400;
    line-height: 1.25;
    }
`
const Input = styled.input`
    background-color: #fff;
    width: 100%;
    padding: 12px;
    color: var(--color-txt-primary);
    border: 1px solid var(--color-line-primary);
    border-radius: 4px;
    font-size: 16px;
    font-weight: 400;
    line-height: 1.25;
    }
`

const DateColumn = styled.div`
    display: grid;
    grid-template-columns: 62.264% 1fr;
    gap: 20px;
`

const Textarea = styled.textarea`
    padding: 12px;
    color: var(--color-txt-primary);
    border: 1px solid var(--color-line-primary);
    border-radius: 4px;
    background-color: #fff;
    font-size: 16px;
    font-weight: 400;
    line-height: 1.25;
    width: 100%;
`

const DisableTextarea = styled.textarea`
    padding: 12px;
    background-color: var(--color-bg-primary);
    color: var(--color-txt-primary);
    border: 1px solid var(--color-line-primary);
    border-radius: 4px;
    font-size: 16px;
    font-weight: 400;
    line-height: 1.25;
    width: 100%;
`

const Select = styled.select`
    position: relative;
    width: 100%;
    padding: 12px 32px 12px 12px;
    cursor: pointer !important;
    // color: var(--color-line-primary);
    border: 1px solid var(--color-line-primary);
    border-radius: 4px;
    // background-color: #fff;
    font-size: 16px;
    font-weight: 400;
    line-height: 1.25;
    // appearance: none;
`

const SelectLabel = styled.label`
    width: min(100%, 340px);
    position: relative;
    display: block;
    cursor: pointe
`

const FileDiv = styled.div`
    display: grid;
    grid-template-columns: auto 1fr auto;
    align-items: center;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: flex-start;
    gap: 16px;
`
const FileWrap = styled.div`
    grid-column: 1 / 4;
    width: 100%;
    height: 382px;
    border: 1px solid var(--color-line-primary);
    border-radius: 4px;
    background-color: var(--color-bg-primary);
    position: relative;
`

const FileInput = styled.input`
    display: none;
`

const FileLabel = styled.label`
    width: 100%;
    height: 100%;
    cursor: pointer;
    display: grid;
    place-items: center;
`
const Img = styled.img`
`

const ReferenceFileWrap = styled.div`
    overflow-x: auto;
`

// 画像削除ボタン用のスタイル
const ImgWrapper = styled.div`
    position: relative;
`;

const DeleteButton = styled.button`
    position: absolute;
    top: 10px;
    right: 10px;
    background: transparent;
    border: none;
    cursor: pointer;
    padding: 0;
`;

const DeleteIcon = styled.span`
    display: block;
    width: 20px;
    height: 20px;
    mask: url(${iconDelete}) no-repeat center center / contain;
    -webkit-mask: url(${iconDelete}) no-repeat center center / contain;
    background-color: var(--color-txt-primary);
`;

const FileDeleteIcon = styled.span`
    display: block;
    width: 40px;
    height: 40px;
    mask: url(${iconCross}) no-repeat center center / contain;
    -webkit-mask: url(${iconCross}) no-repeat center center / contain;
    background-color: var(--color-txt-primary);
`;

const Table = styled.table`
    min-width: 100%;
    border-collapse: collapse;
    margin-bottom: 8px;
    background-color: #fff;

    border-spacing: 0;
`
const Thead = styled.thead`
    border-right: 1px solid var(--color-line-primary);
    border-left: 1px solid var(--color-line-primary);
`
const Tr = styled.tr`
    border-top: 1px solid var(--color-line-primary);
    border-bottom: 1px solid var(--color-line-primary);
`
const Th = styled.th`
    font-size: 13px;
    font-weight: 700;
    line-height: 1.25;
    color: var(--color-site-primary);
    padding: 12px;
    align-content: center;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    min-height: 40px;
`
const ThInner = styled.div`
    width: fit-content;
    display: flex;
    align-items: center;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: flex-start;
    gap: 4px;
    white-space: nowrap;
`
const Tbody = styled.tbody`
    border-right: 1px solid var(--color-line-primary);
    border-left: 1px solid var(--color-line-primary);
`
const Td = styled.td`
    font-size: 14px;
    font-weight: 500;
    line-height: 1.25;
    padding: 12px;
    align-content: center;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    min-height: 40px;
`
const TableButtonWrap = styled.div`
    display: flex;
    align-items: center;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: flex-end;
`

const DateWrap = styled.div`
    display: grid;
    grid-template-columns: 1fr 16px 1fr;
    align-items: center;
    gap: 12px;
`

const IconButton = styled.button`
    cursor: pointer;
    text-decoration: none;
    color: inherit;
    appearance: none;
    background: transparent;
    border: none;
    border-radius: 0;
    font: inherit;
    outline: none;
`

const Icon = styled.span`
    mask: url(${iconDelete}) no-repeat center center / contain;
    -webkit-mask: url(${iconDelete}) no-repeat center center / contain;
    display: block;
    width: 30px;
    height: 30px;
    background: var(--color-txt-primary);
`

const DownloadButton = styled.button`
    cursor: pointer;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    background: transparent;
    border: none;
    border-radius: 0;
    font: inherit;
    outline: none;
`;

const DownloadIcon = styled.span`
    mask: url(${iconDownLoad}) no-repeat center center / contain;
    -webkit-mask: url(${iconDownLoad}) no-repeat center center / contain;
    display: block;
    width: 100%;
    height: 100%;
    width: 16px;
    height: 16px;
    background: var(--color-txt-primary);
`