import React from 'react';
import packageJson from '../../../../package.json'; // パスは適宜調整してください
import styled from 'styled-components';

// versionだけを取り出す
const { version } = packageJson;

const Version: React.FC = () => {
  return (
    <>
      <H1>version: {version}</H1>
    </>
  );
};

export default Version;

const H1 = styled.h1`
    color: #fff;
`