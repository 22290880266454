import React, { useState, useEffect, useContext, useImperativeHandle, forwardRef } from 'react';
import styled from 'styled-components';
import { useGetCategoriesList } from '@hooks/useBackendApi';
import { processCreateContext } from './ProcessCreateModal';
import CategoryValueSelectModal from '../category_select/CategoryValueSelectModal';
import { useTranslation } from 'react-i18next';

import iconDelete from '@images/table/icon_delete.svg'
import iconAdd from '@images/product_system/icon_add.svg'

interface ProcessProductCategrySelectProps {
    ref: any;
}

/**
 * 製品カテゴリ選択
 * 
 */
const ProcessProductCategrySelect: React.FC<ProcessProductCategrySelectProps> = forwardRef((props, ref) => {
    const { t } = useTranslation();
    const context = useContext(processCreateContext);
    const {
        inputDatabasePackId,
        categoryIds,
        categoryValueIds,
        ids,
        setIds,
        setCategoryIds,
        inputPuroductRadioValue
    } = context;

    const {
        categoriesListData,
        categoriesListError,
        categoriesListLoading
    } = useGetCategoriesList();

    const [rows, setRows] = useState<Array<{ id: number, inputCategoryId: number, inputCategoryName: string, selectedCategoryValue: string, selectedCategoryValueId: number }>>([{ id: Date.now(), inputCategoryId: 1, inputCategoryName: categoriesListData && categoriesListData[0] ? categoriesListData[0].name : "", selectedCategoryValue: '', selectedCategoryValueId: 0 }]);
    useEffect(() => {
        setRows([{ id: Date.now(), inputCategoryId: 1, inputCategoryName: categoriesListData && categoriesListData[0] ? categoriesListData[0].name : "", selectedCategoryValue: '', selectedCategoryValueId: 0 }])
    }, [categoriesListData])

    useEffect(() => {
        if (categoriesListData) {
            setCategoryIds(categoriesListData.map(category => ({ id: category.id, name: category.name })));
        }
    }, [categoriesListData]);

    // カテゴリ値を取得する関数
    useImperativeHandle(ref, () => ({
        getCategoryValueIds: () => rows.map(row => String(row.selectedCategoryValueId))
    }));

    // 分類選択
    const handleInputChangeSelect = (event: React.ChangeEvent<HTMLSelectElement>, rowId: number) => {
        // const newRows = rows.map(row =>
        //     row.id === rowId ? { ...row, inputCategoryId: Number(event.target.value) } : row
        // );
        // setRows(newRows);
        const selectedCategoryId = Number(event.target.value);
        const selectedCategory = categoriesListData?.find(category => category.id === selectedCategoryId);

        const newRows = rows.map(row => {
            if (row.id === rowId) {
                return {
                    ...row,
                    inputCategoryId: selectedCategoryId,
                    inputCategoryName: selectedCategory ? selectedCategory.name : ''
                };
            }
            return row;
        });
        setRows(newRows);
    };

    // 削除
    const handleDelete = (rowId: number) => {
        const rowToDelete = rows.find(row => row.id === rowId);
        if (rowToDelete) {
            setIds((prevIds) => prevIds?.filter(id => id !== String(rowToDelete.selectedCategoryValueId)) ?? []);
        }
        setRows(rows.filter(row => row.id !== rowId));
    };

    // 追加
    const handleAddRow = () => {
        // デフォルトカテゴリの選択
        const defaultCategory = categoriesListData && categoriesListData[0];
        const newRow = {
            id: Date.now(),
            inputCategoryId: defaultCategory ? defaultCategory.id : 0,  // デフォルトのカテゴリID
            inputCategoryName: defaultCategory ? defaultCategory.name : '',
            selectedCategoryValue: '',
            selectedCategoryValueId: 0
        };

        setRows([...rows, newRow]);
        // setRows([...rows, { id: Date.now(), inputCategoryId: 1, selectedCategoryValue: '', selectedCategoryValueId: 0 }]);
    };

    // カテゴリ値選択
    const handleSelectCategoryValue = (rowId: number, categoryValueId: number, categoryValueName: string) => {
        // 行に対応するidを更新または追加
        setIds((prevIds) => {
            const newIds = [...(prevIds ?? [])];
            const index = newIds.findIndex(id => id === String(rowId));
            if (index !== -1) {
                newIds[index] = String(categoryValueId);
            } else {
                newIds.push(String(categoryValueId));
            }
            return newIds;
        });

        // 選択したカテゴリ値の更新
        const newRows = rows.map(row =>
            row.id === rowId ? { ...row, selectedCategoryValue: categoryValueName, selectedCategoryValueId: categoryValueId } : row
        );
        setRows(newRows);
    };

    return (
        <>
            {inputPuroductRadioValue === "1" &&
                <>
                    {rows.map(row => (
                        <Dd key={`row-${row.id}`}>
                            <Label>
                                <Select
                                    name="category"
                                    id={`category-select-${row.id}`}
                                    onChange={(event) => handleInputChangeSelect(event, row.id)}
                                    value={row.inputCategoryId ?? ''}
                                >
                                    {categoryIds?.map((category) => (
                                        <option key={category.id} value={category.id}>
                                            {category.name}
                                        </option>
                                    ))}
                                </Select>
                            </Label>

                            <Input
                                type='text'
                                hidden
                                value={row.selectedCategoryValueId}
                                key={`value-id-${row.id}`}
                                readOnly
                            />
                            <Input
                                type='text'
                                disabled
                                value={row.selectedCategoryValue}
                                key={`value-input-${row.id}`}
                                readOnly
                            />
                            <CategoryValueSelectModal
                                key={`value-select-${row.id}`}
                                rowId={row.id}
                                rowName={row.inputCategoryName}
                                categoryId={row.inputCategoryId}
                                // categoryValueId={row.categoryValueId}
                                onSelectCategoryValue={(categoryValueId, categoryValueName) => handleSelectCategoryValue(row.id, categoryValueId, categoryValueName)}
                            />

                            <IconButton type='button' onClick={() => handleDelete(row.id)}>
                                <Icon>
                                </Icon>
                            </IconButton>
                        </Dd>
                    ))}
                    <AddWrapper>
                        <AddButton type='button' onClick={handleAddRow}>
                            <AddButtonIconWrapper>
                                <AddButtonIcon></AddButtonIcon>
                            </AddButtonIconWrapper>
                            <AddButtonInner>{t('製品カテゴリを追加する')}</AddButtonInner>
                        </AddButton>
                    </AddWrapper>
                </>
            }
            {inputPuroductRadioValue === "2" &&
                <>
                    {categoryValueIds?.map(row => (
                        <Dd key={row.id}>
                            <Label>
                                <Select
                                    name="category"
                                    id={`category-select-${row.id}`}
                                    onChange={(event) => handleInputChangeSelect(event, row.id)}
                                    value={row.id ?? ''}
                                >
                                    {categoryIds?.map((category) => (
                                        <option key={category.id} value={category.id}>
                                            {category.name}
                                        </option>
                                    ))}
                                </Select>
                            </Label>

                            <Input
                                type='text'
                                hidden
                                value={row.id}
                                key={`value-id-${row.id}`}
                                readOnly
                            />
                            <Input
                                type='text'
                                disabled
                                value={row.name}
                                key={`value-select-${row.id}`}
                                readOnly
                            />
                        </Dd>
                    ))}
                </>
            }
        </>
    );
});

export default ProcessProductCategrySelect;


const Label = styled.label`
width: min(100%, 340px);
position: relative;
display: block;
`

const Select = styled.select`
position: relative;
width: 100%;
padding: 12px 32px 12px 12px;
cursor: pointer !important;
border: 1px solid var(--color-line-primary);
border-radius: 4px;
background-color: #fff;
font-size: 16px;
font-weight: 400;
line-height: 1.25;
// appearance: none;
`

const Input = styled.input`
width: min(100%, 340px);
padding: 12px;
color: var(--color-txt-primary);
border: 1px solid var(--color-line-primary);
border-radius: 4px;
background-color: #fff;
font-size: 16px;
font-weight: 400;
line-height: 1.25;
`

const Dd = styled.dd`
display: flex;
align-items: center;
flex-direction: row;
flex-wrap: nowrap;
justify-content: flex-start;
gap: 16px;
margin-bottom: 10px;
`

const IconButton = styled.button`
cursor: pointer;
text-decoration: none;
color: inherit;
appearance: none;
background: transparent;
border: none;
border-radius: 0;
font: inherit;
outline: none;
`

const Icon = styled.span`
mask: url(${iconDelete}) no-repeat center center / contain;
-webkit-mask: url(${iconDelete}) no-repeat center center / contain;
display: block;
width: 30px;
height: 30px;
background: var(--color-txt-primary);
`

const AddWrapper = styled.div`
margin-top: 20px;
`

const AddButton = styled.button`
padding: 6px 18px;
border-radius: 20px;

display: inline-flex;
align-items: center;
flex-direction: row;
flex-wrap: nowrap;
justify-content: center;
cursor: pointer;
white-space: nowrap;
background-color: var(--color-gray--01);
gap: 0 4px;

appearance: none;
border: none;
font: inherit;
outline: none;

&:hover {
    background-color: var(--color-site-secondary);
    text-decoration: none !important;

    span {
        color: #fff;
    }
}
`

const AddButtonInner = styled.span`
font-size: 12px;
font-weight: 500;
`

const AddButtonIconWrapper = styled.div`
width: 16px;
height: 16px;
border: none;
background-color: var(--color-site-secondary);
display: grid;
place-content: center;
border-radius: 50%;
`

const AddButtonIcon = styled.span`
width: 7.44px;
height: 7.44px;
background-color: #fff;
position: relative;
mask: url(${iconAdd}) no-repeat center center / contain;
-webkit-mask: url(${iconAdd}) no-repeat center center / contain;
display: block;
`