import React, { useState, useContext, Dispatch, SetStateAction, useEffect } from 'react';
import Modal from '@common/modal/Modal';
import { getRequest } from '@services/api/api';
import { UnitGroupList, UnitGroupUnit } from '@typeList/types'
import { createColumnHelper } from '@tanstack/react-table'
import ButtonDataTable from './table/ButtonDataTable';
import { processCreateContext } from '@specific/process/process_create/ProcessCreateModal';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import ErrorMsg from './error/ErrorMsg';
import { FadeLoader } from 'react-spinners';

interface flattenedData {
  name: string;
  unitName: string;
  unitCode: string;
  unitId: number;
}

interface UnitGroupModal {
  link_text: string;
  inputDatabasePackName: string;
  setInputUnitId: Dispatch<SetStateAction<number>>;
  inputDatabasePackId: number | undefined;
  setInputUnitName: Dispatch<SetStateAction<string>>;
  editMode: boolean;
}


const UnitSelectModal: React.FC<UnitGroupModal> = ({ link_text, inputDatabasePackName, setInputUnitId, inputDatabasePackId, setInputUnitName, editMode }) => {
  const { t } = useTranslation();
  const context = useContext(processCreateContext);
  // const { inputDatabasePackName, inputUnitId, setInputUnitId, inputDatabasePackId, inputUnitName , setInputUnitName  } = context;

  const [data, setData] = useState<any | null>(null);
  const [dataCount, setDataCount] = useState<number>(0);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);

  // モーダル開閉
  const [isModalOpen, setIsModalOpen] = useState(false);
  const openModal = () => setIsModalOpen(true);
  const closeModal = () => setIsModalOpen(false);

  // テーブル設定
  const columnHelper = createColumnHelper<flattenedData>()
  const columns = [
    columnHelper.accessor(row => row.name, {
      id: 'name',
      cell: info => <i>{info.getValue()}</i>,
      header: () => t('単位分類'),
    }),
    columnHelper.accessor('unitCode', {
      header: () => t('単位コード'),
      cell: info => info.renderValue(),
    }),
    columnHelper.accessor('unitName', {
      header: () => t('表示名'),
      cell: info => info.renderValue(),
    }),
  ]

  // 単位マスタデータ取得
  const getUnitGroups = async (database_pack_id: any) => {
    setLoading(true);
    try {
      const response = await getRequest('/database_packs/' + database_pack_id + '/unit_groups');
      flattenedData(response);
    } catch (err: any) {
      console.log("NG", err.message);
      setError(err.message);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    getUnitGroups(inputDatabasePackId);
  }, [inputDatabasePackId])

  // テーブル表示用にフラットなデータに変換する
  const flattenedData = async (jsonData: any) => {
    if (jsonData === null) {
      return
    }
    const flatData = jsonData.flatMap((item: UnitGroupList) => {
      return item.units.map((unit: UnitGroupUnit) => ({
        unitId: unit.id,
        name: item.name,
        unitName: unit.name,
        unitCode: unit.code,
        isDefaultSelected: unit.is_default_selected,
      }));
    });
    setData(flatData);
    setDataCount(flatData.length)

    // デフォルトで単位をセット(編集画面は上書きされないよう除外)
    const defaultUnit = flatData.find((unit: any) => unit.isDefaultSelected);
    if (defaultUnit && !editMode) {
      setInputUnitId(defaultUnit.unitId);
      setInputUnitName(defaultUnit.unitName);
    }
  };

  // 単位選択からの取得
  const handleUnitSelectButtonClick = async (id: number, name: string) => {
    setInputUnitId(id);
    setInputUnitName(name);
    closeModal();
  }

  return (
    <>
      <StyledButton
        type='button'
        onClick={() => {
          openModal();
          // getUnitGroups(inputDatabasePackId);
        }
        }
      >
        {link_text}
      </StyledButton>
      <Modal isOpen={isModalOpen} onClose={closeModal}>
        <Wrapper>
          <dl>
            {loading &&
              <FadeLoader
                color="#48bdbb"
                height={10}
                radius={2}
                width={5}
              />
            }
            {error && <ErrorMsg>{error}</ErrorMsg>}
            <Title>{t('単位選択')}</Title>
            <SelectTitle>{t('データベースパック')}</SelectTitle>
            <SelectPrimary>
              <SelectInner disabled>
                <option value={inputDatabasePackId}>{inputDatabasePackName}</option>
              </SelectInner>
            </SelectPrimary>
          </dl>
          {data &&
            <>
              <ButtonDataTable count={dataCount} columns={columns} data={data} paging_flag={true} handleClick={handleUnitSelectButtonClick} />
            </>
          }
        </Wrapper>
      </Modal>
    </>
  );
};

export default UnitSelectModal;

const Wrapper = styled.div`
width: 100%;
padding: 120px 10%;
margin: 0 auto;
`

const Title = styled.h2`
    font-size: 18px;
    padding: 0 0 20px;
    margin-bottom: 20px;
    border-bottom: 1px solid var(--color-line-primary);
    font-size: 16px;
    font-weight: 500;    
    line-height: 1.25;    
`

const StyledButton = styled.button`
    background-color: var(--color-site-secondary);
    color: #fff;

    padding: 14px 30px;
    border-radius: 4px;
    white-space: nowrap;

    display: inline-flex;
    align-items: center;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: center;
    cursor: pointer;
    white-space: nowrap;
    gap: 0 4px;

    appearance: none;
    border: none;
    font: inherit;
    outline: none;      

  &:hover {
    background-color: var(--color-gray--01);
    color: inherit;

  }
`;

const SelectTitle = styled.dt`
margin-bottom: 12px;
font-size: 16px;
font-weight: 500;
line-height: 1.25;
`

const SelectPrimary = styled.label`
    width: min(100%, 340px);
    position: relative;
    display: block;
    cursor: pointer;
    margin-bottom: 10px;
`

const SelectInner = styled.select`
    color: rgb(85, 85, 85);
    position: relative;
    width: 100%;
    padding: 12px 32px 12px 12px;
    cursor: pointer !important;
    border: 1px solid var(--color-line-primary);
    border-radius: 4px;
    background-color: #fff;
    font-size: 16px;
    font-weight: 400;
    line-height: 1.25;
`