import React, { useState, useEffect, useContext, useImperativeHandle, forwardRef } from 'react';
import styled from 'styled-components';
import { useGetCategoriesList } from '@hooks/useBackendApi';
import CategoryValueSelectModal from '../category_select/CategoryValueSelectModal';
import { productEditContext } from './ProductEditModal';

import iconDelete from '@images/table/icon_delete.svg'
import iconAdd from '@images/product_system/icon_add.svg'
import { CategoryValues } from '@typeList/types';
import { useTranslation } from 'react-i18next';

interface ProductEditCategrySelectProps {
    categoryValues: CategoryValues[] | null | undefined
}

/**
 * 製品カテゴリ選択コンポーネント
 * @returns 
 */
const ProductEditCategrySelect: React.FC<ProductEditCategrySelectProps> = ({ categoryValues }) => {
    const { t } = useTranslation();
    const context = useContext(productEditContext);
    const {
        categoryData, 
        setCategoryData
    } = context;

    // テーブル表示用にフラットなデータに変換する
    const flattenedData = (categoryValues: CategoryValues[]) => {
        if (!categoryValues || categoryValues.length === 0) {
            return [];
        }

        return categoryValues.flatMap((categoryValue, index) => {
            if (!categoryValue) {
                return [];
            }
            return [{
                id: index,
                categoryId: categoryValue.category.id,
                categoryName: categoryValue.category.name,
                categoryValueId: categoryValue.id,
                categoryValueName: categoryValue.name
            }];
        });
    };

    useEffect(() => {
        if (categoryValues && categoryValues.length > 0) {
            setCategoryData(flattenedData(categoryValues));
        }
    }, [categoryValues])

    const {
        categoriesListData,
        categoriesListError,
        categoriesListLoading
    } = useGetCategoriesList();

    // categoriesListDataが更新されたときにカテゴリIDをセット
    const [categoryList, setCategoryList] = useState<{ id: number; name: string; }[]>()

    useEffect(() => {
        if (categoriesListData) {
            setCategoryList(categoriesListData.map(category => ({ id: category.id, name: category.name })));
        }
    }, [categoriesListData]);


    // セレクトボックスの選択が変更されたときのハンドラー
    const handleInputChangeSelect = (event: React.ChangeEvent<HTMLSelectElement>, rowId: number) => {
        const selectedCategoryId = Number(event.target.value);
        const selectedCategory = categoriesListData?.find(category => category.id === selectedCategoryId);

        const newRows = categoryData.map(row => {
            if (row.id === rowId) {
                return {
                    ...row,
                    categoryId: selectedCategoryId,
                    categoryName: selectedCategory ? selectedCategory.name : ''
                };
            }
            return row;
        });
        setCategoryData(newRows);
    };

    // 行の削除ハンドラー
    const handleDelete = (rowId: number) => {
        setCategoryData(categoryData?.filter(row => row.id !== rowId));
    };

    // 行の追加ハンドラー
    const handleAddRow = () => {
        const defaultCategory = categoriesListData && categoriesListData[0];
        const newRow = {
            id: Date.now(),
            categoryId: defaultCategory ? defaultCategory.id : 1,  // デフォルトのカテゴリID
            categoryName: defaultCategory ? defaultCategory.name : '',  // デフォルトのカテゴリ名
            categoryValueId: 0,
            categoryValueName: "",
        };
        setCategoryData([...categoryData, newRow]);
    };

    // カテゴリ値選択ハンドラー
    const handleSelectCategoryValue = (rowId: number, categoryValueId: number, categoryValueName: string) => {
        // まず、categoryValueIdがtableDataに既に存在するか確認します
        const isDuplicate = categoryData.some(row => String(row.categoryValueId) === String(categoryValueId));

        if (isDuplicate) {
            // もしIDが既に存在する場合、categoryIdsやtableDataを更新しない
            console.log(`CategoryValueId ${categoryValueId} は既にtableDataに存在します`);
            return;
        }

        // 選択したカテゴリ値でtableDataを更新します
        const newRows = categoryData.map(row =>
            row.id === rowId
                ? { ...row, categoryValueId: categoryValueId, categoryValueName: categoryValueName }
                : row
        );
        setCategoryData(newRows);
    };

    return (
        <>
            <Div>
                {categoryData?.map((row, index) => (
                    <>
                        <Label key={`${row.id}-${index}`}>
                            <Select
                                name="category"
                                id={`category-select-${row.id}-${index}`}
                                onChange={(event) => handleInputChangeSelect(event, row.id)}
                                value={String(row.categoryId) ?? ''}
                            >
                                {categoryList?.map((category) => (
                                    <option key={`${row.id}-${category.id}`} value={category.id}>
                                        {category.name}
                                    </option>
                                ))}
                            </Select>
                        </Label>

                        <Input
                            type='text'
                            hidden
                            value={row.categoryValueId}
                            key={`value-id-${row.id}-${index}`}
                        />
                        <Input
                            type='text'
                            disabled
                            value={row.categoryValueName}
                            key={`value-select-${row.id}-${index}`}
                        />

                        <CategoryValueSelectModal
                            key={`value-select-${row.categoryId}`}
                            rowId={row.id}
                            rowName={row.categoryName}
                            categoryId={row.categoryId}
                            categoryValueId={row.categoryValueId}
                            onSelectCategoryValue={(categoryValueId, categoryValueName) => handleSelectCategoryValue(row.id, categoryValueId, categoryValueName)}
                        />
                        <IconButton type='button' onClick={() => handleDelete(row.id)}>
                            <Icon>
                            </Icon>
                        </IconButton>
                    </>
                ))}
                <AddWrapper>
                    <AddButton type='button' onClick={handleAddRow}>
                        <AddButtonIconWrapper>
                            <AddButtonIcon></AddButtonIcon>
                        </AddButtonIconWrapper>
                        <AddButtonInner>{t('製品カテゴリを追加する')}</AddButtonInner>
                    </AddButton>
                </AddWrapper>
            </Div>
        </>
    );
};

export default ProductEditCategrySelect;


const Label = styled.label`
    width: min(100%, 340px);
    position: relative;
    display: block;
`

const Select = styled.select`
    position: relative;
    width: 100%;
    padding: 12px 32px 12px 12px;
    cursor: pointer !important;
    border: 1px solid var(--color-line-primary);
    border-radius: 4px;
    background-color: #fff;
    font-size: 16px;
    font-weight: 400;
    line-height: 1.25;
`

const Input = styled.input`
    width: min(100%, 340px);
    padding: 12px;
    color: var(--color-txt-primary);
    border: 1px solid var(--color-line-primary);
    border-radius: 4px;
    background-color: #fff;
    font-size: 16px;
    font-weight: 400;
    line-height: 1.25;
`

const Div = styled.div`
    display: grid;
    gap: 8px 12px;
    grid-template-columns: 2fr 2fr 1fr auto;
    align-items: center;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: flex-start;
`

const IconButton = styled.button`
    cursor: pointer;
    text-decoration: none;
    color: inherit;
    appearance: none;
    background: transparent;
    border: none;
    border-radius: 0;
    font: inherit;
    outline: none;
`

const Icon = styled.span`
    mask: url(${iconDelete}) no-repeat center center / contain;
    -webkit-mask: url(${iconDelete}) no-repeat center center / contain;
    display: block;
    width: 30px;
    height: 30px;
    background: var(--color-txt-primary);
`

const AddWrapper = styled.div`
    margin-top: 20px;
    grid-column: 1 / 5;
    width: fit-content;
    align-content: end;
    margin: 0 0 0 auto;
`

const AddButton = styled.button`
    padding: 6px 18px;
    border-radius: 20px;

    display: inline-flex;
    align-items: center;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: center;
    cursor: pointer;
    white-space: nowrap;
    background-color: var(--color-gray--01);
    gap: 0 4px;

    appearance: none;
    border: none;
    font: inherit;
    outline: none;

    &:hover {
        background-color: var(--color-site-secondary);
        text-decoration: none !important;

        span {
            color: #fff;
        }
    }
`

const AddButtonInner = styled.span`
    font-size: 12px;
    font-weight: 500;
`

const AddButtonIconWrapper = styled.div`
    width: 16px;
    height: 16px;
    border: none;
    background-color: var(--color-site-secondary);
    display: grid;
    place-content: center;
    border-radius: 50%;
`

const AddButtonIcon = styled.span`
    width: 7.44px;
    height: 7.44px;
    background-color: #fff;
    position: relative;
    mask: url(${iconAdd}) no-repeat center center / contain;
    -webkit-mask: url(${iconAdd}) no-repeat center center / contain;
    display: block;
`