import React, { useContext, useEffect, useState } from 'react';
import { CaseStudyCalculate, CaseStudyLCIAResults, ImpactAssesmentMethods, LCIAResults, ResultItems0, ResultItems1 } from '@typeList/types';
import { useCaseStudyLciaResults, useCaseStudyLciaResultsCategory, useCaseStudyLciaResultsUpper, useGetCaseStudyLciResultsCategory, useGetCaseStudyLciResultsUpper } from '@hooks/useBackendApi';
import { CaseStudyViewContext } from '@pages/case_study/CaseStudyView';
import CaseStudyContributionAnalysisModal from '../contribution_analysis/CaseStudyContributionAnalysisModal';
import LciaModelSelectModal from '@specific/process/calculation/LciaModelSelectModal';
import CaseStudyImpactAssesmentMethodsTable from '@common/table/CaseStudyImpactAssesmentMethodsTable';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { ColumnDef } from '@tanstack/react-table';
import { formatNumber } from '@utils/utils';
import CaseStudyStackedBarChart from '@common/graph/CaseStudyStackedBarChart';
import CharacterizationLciaModelSelectModal from './CharacterizationLciaModelSelectModal';
import { FadeLoader } from 'react-spinners';

interface CharacterizationProps {
    methodsData: ImpactAssesmentMethods[] | null;
    calculateData: CaseStudyCalculate | null;
}

/**
 * 037 ケーススタディ　影響評価画面（特性化）
 * @returns 
 */
const CaseStudyCharacterization: React.FC<CharacterizationProps> = ({ methodsData, calculateData }) => {
    const { t } = useTranslation();
    const context = useContext(CaseStudyViewContext);
    const {
        userData,
        caseStudyData,
        inputCharacterizationGroupingValue,
        setInputCharacterizationGroupingValue,
        inputCharacterizationDisplayFormatValue,
        setInputCharacterizationDisplayFormatValue,
        subsystemCategorySetData,
        selectCharacterizationModelIdList,
        setSelectCharacterizationModelIdList,
        selectCharacterizationCategorySet,
        setSelectCharacterizationCategorySet,
        caseStudyLciaResultsData,
        caseStudyLciaResultsCategoryData,
        caseStudyLciaResultsUpperData,
        caseStudyLciaResultsLoading,
        caseStudyLciaResultsCategoryLoading,
        caseStudyLciaResultsUpperLoading,
        characterizationResultData
    } = context;

    const [selectedRows, setSelectedRows] = useState<{ [key: string]: boolean } | null>(null);
    // const [resultData, setResultData] = useState<CaseStudyLCIAResults | null>(null);

    const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>, key: string) => {
        switch (key) {
            case "grouping":
                setInputCharacterizationGroupingValue(event.target.value);
                break;
            case "displayFormat":
                setInputCharacterizationDisplayFormatValue(event.target.value);
                break;
        }
    };

    const handleInputChangeSelect = (event: React.ChangeEvent<HTMLSelectElement>) => {
        const selectedValue = Number(event.target.value);
        setSelectCharacterizationCategorySet(selectedValue); // selectCategorySet を更新
    };

    // useEffect(() => {
    //     // サブシステムごと
    //     if (inputCharacterizationGroupingValue === "1" && caseStudyLciaResultsData) {
    //         setResultData(caseStudyLciaResultsData);
    //     }
    //     // カテゴリごと
    //     if (inputCharacterizationGroupingValue === "2" && caseStudyLciaResultsCategoryData) {
    //         setResultData(caseStudyLciaResultsCategoryData);
    //     }
    //     // 上流ごと
    //     if (inputCharacterizationGroupingValue === "3" && caseStudyLciaResultsUpperData) {
    //         setResultData(caseStudyLciaResultsUpperData);
    //     }
    // }, [caseStudyLciaResultsData, caseStudyLciaResultsCategoryData, caseStudyLciaResultsUpperData]);


    // 型ガード関数の定義
    function isResultItems1(item: ResultItems0 | ResultItems1): item is ResultItems1 {
        return (item as ResultItems1).lcia_model !== undefined;
    }

    // フラットなデータを作成する関数
    const flattenResultData = () => {
        if (!characterizationResultData) return [];

        return characterizationResultData.result_items.map((item) => {
            // 型ガードで lcia_model プロパティが存在することを確認
            if (!isResultItems1(item)) {
                return { ...item, relatedBreakdown: [] };
            }

            const relatedBreakdown = characterizationResultData.result_breakdown?.lci_result_breakdown.map((breakdown) => {
                // lcia_model が存在することを前提にフィルタリング
                const matchingItems = breakdown.lci_result_breakdown_items.filter(
                    (breakdownItem) => breakdownItem.result_key_id === item.lcia_model.id
                );

                return {
                    groupingKeyId:  breakdown.grouping_key_id,
                    subGroupingKeyId:  breakdown.sub_grouping_key_id,
                    groupingName: breakdown.grouping_name + ' ' + breakdown.sub_grouping_name,
                    amount: matchingItems.length > 0 ? matchingItems[0].amount : 0,
                    ratio: matchingItems.length > 0 ? matchingItems[0].ratio : 0,
                };
            });

            return {
                ...item,
                relatedBreakdown: relatedBreakdown || [],
            };
        });
    };

    // カラム定義
    const generateColumns = (): ColumnDef<any>[] => {
        let baseColumns: ColumnDef<any>[] = [
            {
                id: 'impact_assessment_method',
                header: () => t('影響評価手法'),
                accessorKey: 'lcia_model.impact_assessment_method.name', // ソートのためのキーを指定
                enableSorting: true,
                cell: (info) => {
                    const resultItem = info.row.original;
                    return isResultItems1(resultItem) ? resultItem.lcia_model.impact_assessment_method.name : '';
                },
            },
            {
                id: 'impact_category',
                header: () => t('影響カテゴリ'),
                accessorKey: 'lcia_model.impact_category.name', // ソートのためのキーを指定
                enableSorting: true,
                cell: (info) => {
                    const resultItem = info.row.original;
                    return isResultItems1(resultItem) ? resultItem.lcia_model.impact_category.name : '';
                },
            },
            {
                id: 'lcia_indicator',
                header: () => t('指標'),
                accessorKey: 'lcia_model.lcia_indicator.name', // ソートのためのキーを指定
                enableSorting: true,
                cell: (info) => {
                    const resultItem = info.row.original;
                    return isResultItems1(resultItem) ? resultItem.lcia_model.lcia_indicator.name : '';
                },
            },
            {
                id: 'total_amount',
                header: () => t('全体'),
                accessorKey: 'amount', // ソートのためのキーを指定
                enableSorting: true,
                cell: (info) => {
                    const resultItem = info.row.original;
                    const amount = resultItem.amount;
                    if (inputCharacterizationDisplayFormatValue === "2" && amount > 0) {
                        return ((resultItem.amount / amount) * 100).toFixed(2) + '%';
                    }
                    if (userData) {
                        return formatNumber(amount || 0, userData?.significant_figures, userData?.use_decimal_notation, userData?.decimal_places)
                    } else {
                        return amount; // デフォルトではそのままの値を表示
                    }
                },
            },
            {
                id: 'unit',
                header: () => t('単位'),
                accessorKey: 'unit.name', // ソートのためのキーを指定
                enableSorting: true,
                cell: (info) => {
                    const resultItem = info.row.original;
                    return isResultItems1(resultItem) ? resultItem.unit.name : '';
                },
            },
        ];

        // breakdown のデータを表示するカラムを追加
        if (characterizationResultData?.result_breakdown?.lci_result_breakdown) {
            characterizationResultData.result_breakdown.lci_result_breakdown.forEach((breakdown) => {
                baseColumns.push({
                    id: `breakdown_${breakdown.grouping_key_id}_${breakdown.sub_grouping_key_id}`,
                    header: () =>(
                        <span>
                            {breakdown.grouping_name}
                            <br />
                            {breakdown.sub_grouping_name}
                        </span>
                    ),
                    accessorFn: (row) => {
                        // ここにソートのための値を返す関数を設定
                        const relatedBreakdown = row.relatedBreakdown.find(
                            (b: any) => b.groupingKeyId === breakdown.grouping_key_id && b.subGroupingKeyId === breakdown.sub_grouping_key_id
                        );
                        return relatedBreakdown ? relatedBreakdown.amount : 0;
                    },
                    enableSorting: true,
                    cell: (info) => {
                        const relatedBreakdown = info.row.original.relatedBreakdown.find(
                            // (b: any) => b.groupingName === breakdown.grouping_name
                            (b: any) => b.groupingKeyId === breakdown.grouping_key_id && b.subGroupingKeyId === breakdown.sub_grouping_key_id
                        );
                        
                        if (inputCharacterizationDisplayFormatValue === "2") {
                            return `${(relatedBreakdown?.ratio * 100).toFixed(2)}%`
                        }
                        const amount = relatedBreakdown ? relatedBreakdown.amount : 0;
                        if (userData) {
                            return formatNumber(amount || 0, userData?.significant_figures, userData?.use_decimal_notation, userData?.decimal_places)
                        } else {
                            return amount; // デフォルトではそのままの値を表示
                        }
                    },
                });
            });
        }

        return baseColumns;
    };

    const [columns, setColumns] = useState<ColumnDef<any>[]>(generateColumns());
    const [flattenedData, setFlattenedData] = useState<any[]>([]);

    useEffect(() => {
        if(characterizationResultData && characterizationResultData?.result_breakdown.lci_result_breakdown.length > 0){
            setFlattenedData(flattenResultData());
        } else {
            setFlattenedData([]);
        }
    }, [characterizationResultData]);

    useEffect(() => {
        setColumns(generateColumns());
    }, [characterizationResultData, inputCharacterizationDisplayFormatValue, inputCharacterizationGroupingValue]);


    // エクスポート用のcolumn data
    const [exportColumns, setExportColumns] = useState<any>()
    const [exportData, setExportData] = useState<any>()
    const exportSet = (columns: any, data: any) => {
        setExportColumns(columns);
        setExportData(data);
    }

    // CSVダウンロード用の関数を定義
    const downloadCsv = (csv: string, filename: string) => {
        // UTF-8 BOMを追加
        const csvWithBom = `\uFEFF${csv}`;

        // CSV文字列をBlobオブジェクトに変換
        const blob = new Blob([csvWithBom], { type: 'text/csv;charset=utf-8;' });

        // ダウンロードリンクを作成してダウンロードをトリガー
        const link = document.createElement('a');
        const url = URL.createObjectURL(blob);
        link.href = url;
        link.setAttribute('download', filename);
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        URL.revokeObjectURL(url); // メモリリークを防ぐためにURLを解放
    };

    // データを整形する関数
    const transformData = (data: any[], columns: ColumnDef<any>[]) => {
        const headers = columns.map((column) => {
            if (typeof column.header === 'function') {
                const headerContent = column.header({} as any);
                return headerContent.props ? `${headerContent.props.children[0]} ${headerContent.props.children[2]}` : headerContent;
            }
            return column.header;
        });

        const transformedData = data.map((row) => {
            const transformedRow: any = {};

            columns.forEach((column) => {
                if (!column.id) return;

                switch (column.id) {
                    case 'impact_assessment_method':
                        transformedRow[column.id] = row.lcia_model?.impact_assessment_method?.name || '';
                        break;
                    case 'impact_category':
                        transformedRow[column.id] = row.lcia_model?.impact_category?.name || '';
                        break;
                    case 'lcia_indicator':
                        transformedRow[column.id] = row.lcia_model?.lcia_indicator?.name || '';
                        break;
                    case 'total_amount':
                        transformedRow[column.id] = row.amount || 0;
                        break;
                    case 'unit':
                        transformedRow[column.id] = row.unit?.name || '';
                        break;
                    default:
                        if (column.id.startsWith('breakdown_')) {
                            // breakdown_<grouping_key_id>_<sub_grouping_key_id> の形式から ID を取得
                            const [groupingKeyId, subGroupingKeyId] = column.id.split('_').slice(1).map(Number);
                            // `relatedBreakdown` の中から `groupingKeyId` と `subGroupingKeyId` に一致する項目を探す
                            const matchingBreakdowns = row.relatedBreakdown.filter(
                                (b: any) => b.groupingKeyId === groupingKeyId && b.subGroupingKeyId === subGroupingKeyId
                            );

                            // マッチする breakdown の amount を取り出して連結
                            transformedRow[column.id] = matchingBreakdowns.map((b: any) => b.amount).join(', ') || '0'; // カンマ区切りで連結
                        } else {
                            transformedRow[column.id] = ''; // その他のカラムは空に設定
                        }
                        break;
                }
            });

            return transformedRow;
        });

        return { headers, transformedData };
    };

    // エクスポート関数
    const exportCsv = (data: any[], columns: ColumnDef<any>[]) => {
        if (!data || data.length === 0 || !columns || columns.length === 0) {
            console.error('エクスポートするデータまたはカラム情報が不足しています。');
            return;
        }

        // データを変換
        const { headers, transformedData } = transformData(data, columns);

        try {
            // CSVを生成する
            const csvContent = [
                headers.map(header => `"${header}"`).join(','), 
                ...transformedData.map(row => 
                    Object.values(row).map(value => `"${String(value).replace(/"/g, '""')}"`).join(',') 
                )].join('\n');

            // Blobを使ってダウンロード
            downloadCsv(csvContent, "case_study_midpoint_lcia_results" || 'default.csv');
        } catch (error) {
            console.error('CSVのエクスポート中にエラーが発生しました:', error);
        }
    };

    useEffect(()=>{
        // LCIAモデルのチェックがすべて外れたときはテーブルを空にする
        if(selectCharacterizationModelIdList && selectCharacterizationModelIdList.length === 0){
            setFlattenedData([])
        }
    },[selectCharacterizationModelIdList])

    return (
        <Section>
            <Title>{t('特性化')}</Title>
            <div>
                <H2>{t('グルーピング')}</H2>
                <RadioWrap>
                    <RadioInput
                        type="radio"
                        name="grouping"
                        id="1"
                        value="1"
                        checked={inputCharacterizationGroupingValue === "1"}
                        onChange={(event) => handleInputChange(event, "grouping")}
                    />
                    <RadioLabel htmlFor="1">
                        <RadioText>
                            {t('サブシステム')}
                        </RadioText>
                    </RadioLabel>

                    <RadioInput
                        type="radio"
                        name="grouping"
                        id="2"
                        value="2"
                        checked={inputCharacterizationGroupingValue === "2"}
                        onChange={(event) => handleInputChange(event, "grouping")}
                    />
                    <RadioLabel htmlFor="2">
                        <RadioText>
                            {t('サブシステムカテゴリ')}
                        </RadioText>
                    </RadioLabel>

                    <SelectLabel>
                        <Select
                            value={selectCharacterizationCategorySet}
                            onChange={(event) => {
                                handleInputChangeSelect(event)
                            }}
                        >
                            {subsystemCategorySetData?.map(categorySet => (
                                <option key={categorySet.id} value={categorySet.id}>
                                    {categorySet.name}
                                </option>
                            ))}
                        </Select>
                    </SelectLabel>

                    <RadioInput
                        type="radio"
                        name="grouping"
                        id="3"
                        value="3"
                        checked={inputCharacterizationGroupingValue === "3"}
                        onChange={(event) => handleInputChange(event, "grouping")}
                    />
                    <RadioLabel htmlFor="3">
                        <RadioText>
                            {t('上流フロー')}
                        </RadioText>
                    </RadioLabel>
                </RadioWrap>
            </div>
            <div>
                <H2>{t('表示形式')}</H2>
                <RadioWrap>
                    <RadioInput
                        type="radio"
                        name="displayFormat"
                        id="4"
                        value="1"
                        checked={inputCharacterizationDisplayFormatValue === "1"}
                        onChange={(event) => handleInputChange(event, "displayFormat")}
                    />
                    <RadioLabel htmlFor="4">
                        <RadioText>
                            {t('数値')}
                        </RadioText>
                    </RadioLabel>

                    <RadioInput
                        type="radio"
                        name="displayFormat"
                        id="5"
                        value="2"
                        checked={inputCharacterizationDisplayFormatValue === "2"}
                        onChange={(event) => handleInputChange(event, "displayFormat")}
                    />
                    <RadioLabel htmlFor="5">
                        <RadioText>
                            {t('パーセント')}
                        </RadioText>
                    </RadioLabel>
                </RadioWrap>
            </div>
            {caseStudyLciaResultsLoading && 
                <FadeLoader
                    color="#48bdbb"
                    height={10}
                    radius={2}
                    width={5}
                />
            }
            {caseStudyLciaResultsUpperLoading && 
                <FadeLoader
                    color="#48bdbb"
                    height={10}
                    radius={2}
                    width={5}
                />
            }
            {caseStudyLciaResultsCategoryLoading && 
                <FadeLoader
                    color="#48bdbb"
                    height={10}
                    radius={2}
                    width={5}
                />
            }
            {!caseStudyLciaResultsLoading &&  !caseStudyLciaResultsUpperLoading &&  !caseStudyLciaResultsCategoryLoading && 
                <>
                    <ButtonWrap>
                        <Button type='button' onClick={() => exportCsv(exportData || [], exportColumns || [])}>
                            <ButtonText>{t('エクスポート')}</ButtonText>
                        </Button>
                        {userData?.is_admin &&
                            <CaseStudyContributionAnalysisModal resultData={characterizationResultData} result_type="lcia" />
                        }
                    </ButtonWrap>
                    <CharacterizationLciaModelSelectModal
                        methodsData={methodsData}
                        setSelectModelIdList={setSelectCharacterizationModelIdList}
                        selectModelIdList={selectCharacterizationModelIdList}
                        calculateData={calculateData}
                    />
                    <CaseStudyImpactAssesmentMethodsTable
                        columns={columns}
                        resultData={flattenedData}
                        paging_flag={false}
                        selectedRows={selectedRows || {}}
                        setSelectedRows={setSelectedRows}
                        exportSet={exportSet}
                    />

                    <CaseStudyStackedBarChart data={flattenedData} />
                </>
            }
        </Section>
    );
};

export default CaseStudyCharacterization;

const Title = styled.h2`
    padding: 0 0 20px;
    margin-bottom: 20px;
    font-size: 16px;
    font-weight: 500;
`

const Section = styled.section`
    margin-bottom: 32px;
    display: block;
`

const H2 = styled.h2`
    padding: 0 0 20px;
    margin-bottom: 20px;
    border-bottom: 1px solid var(--color-line-primary);
    font-size: 16px;
    font-weight: 500;
    line-height: 1.25;
`


const RadioWrap = styled.div`
    margin-bottom: 32px;
    gap: 24px;
    display: flex;
    align-items: center;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: flex-start;
`

const RadioInput = styled.input`
  display: none;

  &:checked + label::before {
    border-color: var(--color-site-secondary);
    background-color: var(--color-site-secondary);
  }

  &:checked + label::after {
    position: absolute;
    top: 50%;
    left: 8px;
    width: 4.5px;
    height: 4.5px;
    content: "";
    border-radius: 50%;
    background-color: #fff;
    transform: translate(-50%, -50%);
  }

  &:not(:checked) + label .c-input,
  &:not(:checked) + label .c-select,
  &:not(:checked) + label .c-select__inner {
    pointer-events: none;
    color: var(--color-line-primary) !important;
  }
`;

const RadioLabel = styled.label`
  display: flex;
  align-items: center;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: flex-start;
  gap: 6px;
  width: fit-content;
  cursor: pointer;
  position: relative;

  &::before {
    content: "";
    width: 14px;
    height: 14px;
    margin: 0;
    cursor: pointer;
    border: 1px solid var(--color-txt-primary);
    border-radius: 50%;
    background-color: #fff;
  }
`;

const RadioText = styled.span`
  display: flex;
  align-items: center;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: flex-start;
  gap: 0 20px;
  font-size: 16px;
  white-space: nowrap;
  font-weight: 500;
`;

const ButtonWrap = styled.div`
    margin: 10px 0px;
    display: flex;
    align-items: center;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: flex-end;
`

const Button = styled.button`
    margin-right: 10px;
    background-color: var(--color-site-secondary);
    border-radius: 4px;
    border: 1px solid var(--color-site-secondary);
    padding: 7px 16px;

    display: inline-flex;
    align-items: center;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: center;
    cursor: pointer;
    white-space: nowrap;
    gap: 0 4px;

    font: inherit;
    outline: none;
`

const ButtonText = styled.span`
    color: #fff;
    font-size: 13px;
    font-weight: 500;
`

const SelectLabel = styled.label`
    width: min(100%, 340px);
    position: relative;
    display: block;
    cursor: pointe
`

const Select = styled.select`
    position: relative;
    width: 100%;
    padding: 12px 32px 12px 12px;
    cursor: pointer !important;
    border: 1px solid var(--color-line-primary);
    border-radius: 4px;
    font-size: 16px;
    font-weight: 400;
    line-height: 1.25;
`