import React, { Dispatch, SetStateAction, useState } from 'react'
import Modal from '@common/modal/Modal'
import styled, { css } from 'styled-components';
import { A } from '@styles/Common';
import ProcessProductSearch from './ProcessProductSearch';
import ProductCreateModal from '../../product_create/ProductCreateModal';
import ProductTextTreeCategories from '../ProductTextTreeCategories';
import { CategoryValues } from '@typeList/types';
import { useTranslation } from 'react-i18next';

interface ProcessProductSearchModalType {
    // プロセス作成からの選択済みのデータベースパック
    selectted_database_pack_id: number | undefined;
    setInputCategoryId: Dispatch<SetStateAction<number | undefined>>;
    setInputCategoryValueId: Dispatch<SetStateAction<number | undefined>>;
    setCategoryIds: Dispatch<SetStateAction<Array<{ id: number, name: string }> | null | undefined>>;
    setCategoryValueIds: Dispatch<SetStateAction<Array<{ id: number, name: string }> | null | undefined>>;
    setInputProductId: Dispatch<SetStateAction<number>>;
    setInputProductName: Dispatch<SetStateAction<string>>;
    setInputUnitName: Dispatch<SetStateAction<string>>;
    setInputUnitId: Dispatch<SetStateAction<number>>;
}

/**
 * 014 製品（中間フロー）検索画面　プロセス新規作成　
 * @param param0 
 * @returns 
 */
const ProcessProductSearchModal: React.FC<ProcessProductSearchModalType> = ({
    selectted_database_pack_id,
    setInputCategoryId,
    setInputCategoryValueId,
    setCategoryIds,
    setCategoryValueIds,
    setInputProductId,
    setInputProductName,
    setInputUnitName,
    setInputUnitId,
}) => {
    const {t} = useTranslation();
    const [isModalOpen, setIsModalOpen] = useState(false);
    const openModal = () => setIsModalOpen(true);
    const closeModal = () => setIsModalOpen(false);

    // 選択されたタブを管理するステート
    const [activeTab, setActiveTab] = useState<string>('tab1');

    const handleTabClick = (tab: string) => {
        setActiveTab(tab);
    };


    const onSelectProduct = async (
        product: {
            id: number,
            name: string,
            unit: {
                id: number,
                name: string,
                unit_group_id: number
            },
            category_values: CategoryValues[]
        }
    ) => {
        setCategoryValueIds(product.category_values.map((category_values: any) => ({ id: category_values.id, name: category_values.name })))
        setCategoryIds(
            product.category_values.map((categoryValue: CategoryValues) => {
                const category = categoryValue.category;
                return {
                    id: category.id,
                    name: category.name
                };
            })
        );
        setInputProductId(product.id);
        setInputProductName(product.name);
        setIsModalOpen(false);
        setInputUnitName(product.unit.name);
        setInputUnitId(product.unit.id);
    }

    return (
        <>
            <StyledButton type='button' onClick={openModal}>
                {t('選択')}
            </StyledButton>
            <Modal isOpen={isModalOpen} onClose={closeModal}>
                <Inner>
                    <SectionResult>
                        <SectionResultTitle>{t('検索結果')}</SectionResultTitle>
                        <ProductCreateModal selectted_database_pack_id={selectted_database_pack_id} />
                    </SectionResult>
                    <SectionBody>
                        <SectionTab>
                            {/* タブのボタン */}
                            <TabButton onClick={() => handleTabClick('tab1')} isActive={activeTab === 'tab1'}>
                                <SectionResultTitleText>{t('検索して選ぶ')}</SectionResultTitleText>
                            </TabButton>
                            <TabButton onClick={() => handleTabClick('tab2')} isActive={activeTab === 'tab2'}>
                                <SectionResultTitleText>{t('カテゴリーツリーから選ぶ')}</SectionResultTitleText>
                            </TabButton>

                            {/* タブの内容 */}
                            {activeTab === 'tab1' &&
                                <SectionTabContent>
                                    <ProcessProductSearch
                                        selectted_database_pack_id={selectted_database_pack_id}
                                        setInputCategoryId={setInputCategoryId}
                                        setInputCategoryValueId={setInputCategoryValueId}
                                        setCategoryIds={setCategoryIds}
                                        setCategoryValueIds={setCategoryValueIds}
                                        setInputProductId={setInputProductId}
                                        setInputProductName={setInputProductName}
                                        setInputUnitName={setInputUnitName}
                                        setInputUnitId={setInputUnitId}
                                        setIsModalOpen={setIsModalOpen}
                                    />
                                </SectionTabContent>
                            }
                            {activeTab === 'tab2' &&
                                <SectionTabContent>
                                    <ProductTextTreeCategories
                                        modal_type={""}
                                        type="select"
                                        setIsSearchModalOpen={setIsModalOpen}
                                        onSelectProduct={onSelectProduct}
                                    />
                                </SectionTabContent>
                            }
                        </SectionTab>
                    </SectionBody>
                </Inner>
            </Modal>
        </>
    )
}

export default ProcessProductSearchModal

const TabButton = styled.button.withConfig({
    shouldForwardProp: (prop) => prop !== 'isActive'
}) <{ isActive: boolean }>`
  padding: 5px 15px;
  background-color: #e9ecef;
  color: black;
  border: none;
  margin-right: 10px;
  cursor: pointer;
  border-radius: 20px;

  ${(props) =>
        props.isActive &&
        css`
      background-color: #46A3B3;
      color: white;
    `
    }

  &:hover {
    background-color: #cfd8dc;
    ${(props) =>
        props.isActive &&
        css`
        background-color: #007bff;
      `
    }
  }
`;

const TabPanel = styled.div`
  padding: 20px;
  margin-top: 10px;
`;

const CreateButton = styled.button`
    background-color: #46A3B3;
    color: white;
    border: none ;
    margin-bottom: 5px ;
    cursor: pointer;
`

const Inner = styled.div`
    width: 100%;
    padding: 120px 10%;
    margin: 0 auto;
`

const SectionResult = styled.div`
    padding: 0 0 20px;
    margin-bottom: 20px;
    border-bottom: 1px solid var(--color-line-primary);
    display: flex;
    justify-content: space-between;
`

const SectionResultTitle = styled.h3`
    font-size: 18px;
    font-weight: 500;
`

const SectionBody = styled.div`

`

const SectionTab = styled.div`
    gap: 24px 12px;
    display: flex;
    align-items: flex-start;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: flex-start;
`

const SectionResultTitleText = styled.span`
    color: #fff;
    font-size: 12px;
    font-weight: 500;
    display: flex;
    align-items: center;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: flex-start;
    gap: 0 20px;
    white-space: nowrap;
`

const SectionTabContent = styled.div`
    display: block;
    z-index: 5;
    width: 100%;
    order: 2;
    position: relative;    
`

const StyledButton = styled.button`
    background-color: var(--color-site-secondary);
    color: #fff;

    padding: 14px 30px;
    border-radius: 4px;
    white-space: nowrap;

    display: inline-flex;
    align-items: center;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: center;
    cursor: pointer;
    white-space: nowrap;
    gap: 0 4px;

    appearance: none;
    border: none;
    font: inherit;
    outline: none;      

  &:hover {
    background-color: var(--color-gray--01);
    color: inherit;

  }
`;