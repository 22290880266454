import React, { useRef } from 'react';
import { useDrag, useDrop } from 'react-dnd';
import styled from 'styled-components';
import { flexRender } from '@tanstack/react-table';

interface DragItem {
    index: number;
    id: string;
    type: string;
}

const DraggableRow: React.FC<{
    row: any;
    index: number;
    moveRow: (dragIndex: number, hoverIndex: number) => void;
}> = ({ row, index, moveRow }) => {
    const ref = useRef<HTMLTableRowElement>(null);

    const [, drop] = useDrop({
        accept: 'ROW',
        hover: (item: DragItem, monitor) => {
            if (!ref.current) {
                return;
            }
            const dragIndex = item.index;
            const hoverIndex = index;

            if (dragIndex === hoverIndex) {
                return;
            }

            const hoverBoundingRect = ref.current?.getBoundingClientRect();
            const hoverMiddleY = (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2;
            const clientOffset = monitor.getClientOffset();
            const hoverClientY = clientOffset!.y - hoverBoundingRect.top;

            if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) {
                return;
            }

            if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) {
                return;
            }

            moveRow(dragIndex, hoverIndex);
            item.index = hoverIndex;
        },
    });

    const [{ isDragging }, drag] = useDrag({
        type: 'ROW',
        item: { index },
        collect: (monitor) => ({
            isDragging: monitor.isDragging(),
        }),
    });

    drag(drop(ref));

    return (
        <Tr
            ref={ref}
            style={{ opacity: isDragging ? 0.5 : 1, background: index % 2 === 0 ? '#fafafa' : '#fff' }}
        >
            {row.getVisibleCells().map((cell: any) => (
                <Td key={cell.id}>
                    <CellContent>
                        {flexRender(cell.column.columnDef.cell, cell.getContext())}
                    </CellContent>
                </Td>
            ))}
        </Tr>
    );
};


export default DraggableRow;

const CellContent = styled.div`
overflow: hidden;
text-overflow: ellipsis;
white-space: nowrap;
`;

const Tr = styled.tr`
cursor: grab;

&:active {
  cursor: grabbing;
}
border-top: 1px solid var(--color-line-primary);
border-bottom: 1px solid var(--color-line-primary);
`

const Td = styled.td`
font-size: 14px;
font-weight: 500;
line-height: 1.25;
padding: 12px;
align-content: center;
overflow: hidden;
text-overflow: ellipsis;
white-space: nowrap;
min-height: 40px;
`;
