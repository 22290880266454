import React, { useState, useCallback, useRef, useEffect, useContext, Dispatch, SetStateAction } from 'react';
import {
    flexRender,
    getCoreRowModel,
    useReactTable,
    getPaginationRowModel,
    getSortedRowModel,
    ColumnDef,
    SortingState,
    ColumnMeta,
} from '@tanstack/react-table';
import { useDrag, useDrop } from 'react-dnd';
import { useTranslation } from 'react-i18next';
import { ProcessViewContext } from '@pages/process/ProcessView';
import styled from 'styled-components';

import iconArrowDown from '@images/table/icon_arrow_down.svg';
import iconArrowUp from '@images/table/icon_arrow_up.svg';
import iconArrowBack from '@images/table/icon_arrow_back.svg'

interface TableProps {
    columns: ColumnDef<any, any>[];
    data: any[];
    paging_flag: boolean;
    inputAmountFormula: string | undefined;
    setInputAmountFormula: Dispatch<SetStateAction<string | undefined>>
    setIsSearchModalOpen: Dispatch<SetStateAction<boolean>>
}

const initialPageIndex = 0;
const initialPageSize = 1000;

interface DragItem {
    index: number;
    id: string;
    type: string;
}

interface ExtendedColumnMeta extends ColumnMeta<any, unknown> {
    editable?: boolean;
}

const DraggableHeader: React.FC<{
    header: any;
    index: number;
    moveColumn: (dragIndex: number, hoverIndex: number) => void;
}> = ({ header, index, moveColumn }) => {
    const ref = useRef<HTMLTableCellElement>(null);

    const [, drop] = useDrop({
        accept: 'COLUMN',
        hover: (item: DragItem, monitor) => {
            if (!ref.current) {
                return;
            }
            const dragIndex = item.index;
            const hoverIndex = index;

            if (dragIndex === hoverIndex) {
                return;
            }

            const hoverBoundingRect = ref.current?.getBoundingClientRect();
            const hoverMiddleX = (hoverBoundingRect.right - hoverBoundingRect.left) / 2;
            const clientOffset = monitor.getClientOffset();
            const hoverClientX = clientOffset!.x - hoverBoundingRect.left;

            if (dragIndex < hoverIndex && hoverClientX < hoverMiddleX) {
                return;
            }

            moveColumn(dragIndex, hoverIndex);
            item.index = hoverIndex;
        },
    });

    const [{ isDragging }, drag] = useDrag({
        type: 'COLUMN',
        item: { index },
        collect: (monitor) => ({
            isDragging: monitor.isDragging(),
        }),
    });

    drag(drop(ref));

    return (
        <Th
            ref={ref}
            style={{
                opacity: isDragging ? 0.5 : 1,
                cursor: isDragging ? 'grabbing' : 'grab',
            }}
            onClick={header.column.getToggleSortingHandler()}
        >
            <ThInner>
                {flexRender(header.column.columnDef.header, header.getContext())}
                {header.column.getIsSorted() ? (header.column.getIsSorted() === 'desc' ? <IconArrowDown /> : <IconArrowUp />) : ''}
            </ThInner>
        </Th>
    );
};

const ProsessParameterSettingSelectTable: React.FC<TableProps> = ({
    columns: initialColumns,
    data,
    paging_flag,
    inputAmountFormula,
    setInputAmountFormula,
    setIsSearchModalOpen
}) => {
    const { t } = useTranslation();
    const [columns, setColumns] = useState(initialColumns);
    const context = useContext(ProcessViewContext);
    const { setUpdateProcessParameters } = context;
    const [tableData, setTableData] = useState(data);
    const [sorting, setSorting] = useState<SortingState>([]);


    const moveColumn = useCallback(
        (dragIndex: number, hoverIndex: number) => {
            const newColumns = [...columns];
            const [movedColumn] = newColumns.splice(dragIndex, 1);
            newColumns.splice(hoverIndex, 0, movedColumn);
            setColumns(newColumns);
        },
        [columns]
    );

    const table = useReactTable({
        data: tableData ?? [],
        columns,
        state: { sorting },
        onSortingChange: setSorting,
        initialState: {
            pagination: {
                pageIndex: initialPageIndex,
                pageSize: initialPageSize,
            },
        },
        getCoreRowModel: getCoreRowModel(),
        getPaginationRowModel: getPaginationRowModel(),
        getSortedRowModel: getSortedRowModel(),
    });

    return (
        <div className="p-2">
            {paging_flag && (
                <div style={{ margin: '5px' }}>
                    <select
                        style={{ margin: '5px' }}
                        value={table.getState().pagination.pageSize}
                        onChange={(e) => {
                            table.setPageSize(Number(e.target.value));
                        }}
                    >
                        {[10, 20, 30].map((pageSize) => (
                            <option key={pageSize} value={pageSize}>
                                {t('表示数')} {pageSize}
                            </option>
                        ))}
                    </select>
                    <button onClick={() => table.previousPage()} disabled={!table.getCanPreviousPage()}>
                        {'<'}
                    </button>
                    {table.getPageOptions().map((page) => (
                        <button
                            key={page}
                            onClick={() => table.setPageIndex(page)}
                            disabled={table.getState().pagination.pageIndex === page}
                        >
                            {page + 1}
                        </button>
                    ))}
                    <button onClick={() => table.nextPage()} disabled={!table.getCanNextPage()}>
                        {'>'}
                    </button>
                </div>
            )}

            <Table>
                <Thead>
                    {table.getHeaderGroups().map(headerGroup => (
                        <Tr key={headerGroup.id}>
                            <Th>{t('選択')}</Th> {/* 空のヘッダセルを追加 */}
                            {headerGroup.headers.map((header, index) => (
                                <DraggableHeader
                                    key={header.id}
                                    header={header}
                                    index={index}
                                    moveColumn={moveColumn}
                                />
                            ))}
                        </Tr>
                    ))}
                </Thead>
                <Tbody>
                    {table.getRowModel().rows.map((row, rowIndex) => (
                        <Tr>
                            <Td>
                                {/* <button onClick={() => removeRow(rowIndex)}>{t('削除')}</button> */}
                                <StyledButton
                                    type='button'
                                    onClick={() => {
                                        setInputAmountFormula((inputAmountFormula ?? "") + String(row.original.name)); 
                                        setIsSearchModalOpen(false);
                                    }}
                                >
                                    <StyledSpan></StyledSpan>
                                </StyledButton>
                            </Td>
                            {row.getVisibleCells().map(cell => (
                                <Td key={cell.id}>
                                    {(cell.column.columnDef.meta as ExtendedColumnMeta).editable ? (
                                        cell.getValue()?.toString()
                                    ) : (
                                        flexRender(cell.column.columnDef.cell, cell.getContext())
                                    )}
                                </Td>
                            ))}
                        </Tr>
                    ))}
                </Tbody>
            </Table>
            <div className="h-4" />
        </div>
    );
};

export default ProsessParameterSettingSelectTable;


const Table = styled.table`
min-width: 100%;
border-collapse: collapse;
margin-bottom: 8px;
background-color: #fff;
border-spacing: 0;
`

const Thead = styled.thead`
border-right: 1px solid var(--color-line-primary);
border-left: 1px solid var(--color-line-primary);
`

const Tr = styled.tr`
border-top: 1px solid var(--color-line-primary);
border-bottom: 1px solid var(--color-line-primary);
`

const Th = styled.th`
font-size: 13px;
font-weight: 700;
line-height: 1.25;
color: var(--color-site-primary);
padding: 12px;
align-content: center;
overflow: hidden;
text-overflow: ellipsis;
white-space: nowrap;
min-height: 40px;
 text-align: left;
`

const ThInner = styled.div`
width: fit-content;
display: flex;
align-items: left;
flex-direction: row;
flex-wrap: nowrap;
justify-content: flex-start;
gap: 4px;
white-space: nowrap;
`;

const Tbody= styled.tbody`
border-right: 1px solid var(--color-line-primary);
border-left: 1px solid var(--color-line-primary);
`

const Td= styled.td`
font-size: 14px;
font-weight: 500;
line-height: 1.25;
padding: 12px;
align-content: center;
overflow: hidden;
text-overflow: ellipsis;
white-space: nowrap;
min-height: 40px;
}
`

const IconArrowDown = styled.span`
mask: url(${iconArrowDown}) no-repeat center center / contain;
-webkit-mask: url(${iconArrowDown}) no-repeat center center / contain;
display: block;
width: 100%;
height: 100%;
width: 20px;
height: 20px;
background: var(--color-txt-primary);
}
`

const IconArrowUp = styled.span`
mask: url(${iconArrowUp}) no-repeat center center / contain;
-webkit-mask: url(${iconArrowUp}) no-repeat center center / contain;
display: block;
width: 100%;
height: 100%;
width: 20px;
height: 20px;
background: var(--color-txt-primary);
}
`

const Input = styled.input`
width: 100%;
padding: 12px;
color: var(--color-txt-primary);
border: 1px solid var(--color-line-primary);
border-radius: 4px;
background-color: #fff;
font-size: 16px;
font-weight: 400;
line-height: 1.25;

appearance: none;
background: transparent;
font: inherit;
outline: none;
}
`


const ButtonWrap = styled.div`
text-align: end;
}
`

const StyledButton = styled.button`
cursor: pointer;

    appearance: none;
    background: transparent;
    border: none;
    border-radius: 0;
    font: inherit;
    outline: none;
`

const StyledSpan = styled.span`
    mask: url(${iconArrowBack}) no-repeat center center / contain;
    -webkit-mask: url(${iconArrowBack}) no-repeat center center / contain;
    display: block;
    width: 16px;
    height: 16px;
    background: var(--color-txt-primary);
`