import React from 'react';
import Select, { SingleValue, ActionMeta } from 'react-select';

// オプションの型
interface OptionType {
    value: string | number;
    label: string;
}

// 汎用的なプロパティ
interface SearchSelectProps<T> {
    value: T | null;
    options: T[];
    onChange: (newValue: SingleValue<T> | null, actionMeta: ActionMeta<T>) => void; // null を許可
    placeholder?: string;
    isClearable?: boolean;
    isDisabled?: boolean;
}

// SearchSelect コンポーネント
const SearchSelect = <T extends OptionType>({
    value,
    options,
    onChange,
    placeholder = 'Select an option',
    isClearable = false,
    isDisabled = false,
}: SearchSelectProps<T>) => {
    return (
        <Select
            value={value}
            options={options}
            onChange={(newValue, actionMeta) => {
                if (newValue === null || !Array.isArray(newValue)) {
                    // null（クリア）も許可
                    onChange(newValue, actionMeta);
                }
            }}
            placeholder={placeholder}
            isClearable={isClearable}
            isDisabled={isDisabled}
            menuPortalTarget={document.body}
            styles={{
                menuPortal: (base) => ({
                    ...base,
                    zIndex: 9999, // メニューのz-indexを高く設定
                }),
                menu: (base) => ({
                    ...base,
                    position: 'absolute', // 絶対位置で描画
                }),
            }}
        />
    );
};

export default SearchSelect;
