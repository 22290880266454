
//　汎用的な関数

import { ProcessIosType } from "@typeList/types";

/**
 * 配列の中のオブジェクトからキーを指定して取得
 * 
 * @param arr 
 * @param key 
 * @returns 
 */
export const getValuesByKey = <T, K extends keyof T>(arr: T[], key: K): T[K][] =>
    arr.map(item => item[key]);

/**
 * 配列の中のオブジェクトから任意のキーと値を指定してとくていの値を取得
 * 
 * @param arr 
 * @param searchKey 
 * @param searchValue 
 * @param returnKey 
 * @returns 
 */
export const getValueByKey = <T, K extends keyof T, V extends keyof T>(
    arr: T[],
    searchKey: K,
    searchValue: T[K],
    returnKey: V
): T[V] | undefined => {
    const item = arr.find(obj => obj[searchKey] === searchValue);
    return item ? item[returnKey] : undefined;
};


/**
 * 配列の各要素を再帰的に比較
 * 
 * @param obj1 
 * @param obj2 
 * @returns 
 */
export const deepEqualStrict = (obj1: any, obj2: any) => {
    if (obj1 === obj2) {
        return true;
    }
    if (typeof obj1 !== 'object' || obj1 === null || typeof obj2 !== 'object' || obj2 === null) {
        return false;
    }
    let keys1 = Object.keys(obj1);
    let keys2 = Object.keys(obj2);
    if (keys1.length !== keys2.length) {
        return false;
    }
    for (let key of keys1) {
        if (!keys2.includes(key) || !deepEqualStrict(obj1[key], obj2[key])) {
            return false;
        }
    }
    return true;
}

/**
 * 配列の各要素を再帰的に比較(文字列に変換して比較)
 * 
 * @param obj1 
 * @param obj2 
 * @returns 
 */
export const deepEqual = (obj1: any, obj2: any) => {
    if (obj1 === obj2) {
        return true;
    }

    if (typeof obj1 !== 'object' || obj1 === null || typeof obj2 !== 'object' || obj2 === null) {
        return false;
    }

    let keys1 = Object.keys(obj1);
    let keys2 = Object.keys(obj2);

    if (keys1.length !== keys2.length) {
        return false;
    }

    for (let key of keys1) {
        if (!keys2.includes(key)) {
            return false;
        }

        let val1 = obj1[key].toString();
        let val2 = obj2[key].toString();

        if (!deepEqual(val1, val2)) {
            return false;
        }
    }

    return true;
}

/**
 * 配列同士の比較
 * 
 * @param arr1 
 * @param arr2 
 * @returns 
 */
export const arraysEqual = (arr1: any, arr2: any) => {
    if (arr1.length !== arr2.length) {
        return false;
    }
    for (let i = 0; i < arr1.length; i++) {
        if (!deepEqual(arr1[i], arr2[i])) {
            return false;
        }
    }
    return true;
}


/**
 * 数値を抽出する関数
 * 
 * @param handle 
 * @returns 
 */
export const extractNumberFromHandle = (handle: string): number | null => {
    // 正規表現で数値部分を抽出
    const match = handle.match(/\d+/);
    return match ? parseInt(match[0], 10) : null; // 数値があれば数値に変換して返す
};


/**
 * ブラウザのタイムゾーンを取得する関数
 * @returns 
 */
export const getBrowserTimeZone = () => {
    // Intl.DateTimeFormatを使用してタイムゾーンを取得
    return Intl.DateTimeFormat().resolvedOptions().timeZone;
}


/**
 * ISO 8601の日付をブラウザのタイムゾーンでフォーマットする関数
 * @param {string} isoDate - ISO 8601形式の日付文字列
 * @returns {string} - ブラウザのタイムゾーンでフォーマットされた日付
 */
export const formatToLocalTime = (isoDate: string): string => {
    // ISO 8601文字列をDateオブジェクトに変換
    if (!isoDate || isoDate === "") {
        return "";
    }
    const date = new Date(isoDate + 'Z');
    const browserTimeZone = getBrowserTimeZone();

    // DateTimeFormatを使用してブラウザのタイムゾーンでフォーマット
    const formattedDate = new Intl.DateTimeFormat('ja-JP', {
        year: 'numeric',
        month: '2-digit',
        day: '2-digit',
        hour: '2-digit',
        minute: '2-digit',
        second: '2-digit',
        timeZone: browserTimeZone,
        timeZoneName: 'short'
    }).format(date);

    return formattedDate;
}


/**
* 数値を指数表記または小数表記に変換する関数
* @param {number} value - 変換する数値
* @param {number} precision - 指数表記時の桁数
* @param {boolean} useDecimal - 小数表記を使用するかのフラグ
* @param {number} decimalThreshold - 小数表記を行う下限桁数
* @returns {string} - 変換後の数値の文字列
*/
export const formatNumber = (value: number, precision: number, useDecimal: boolean, decimalThreshold: number) => {
    // 絶対値を取得して、指数表記の判定に利用
    const absValue = Math.abs(value);
    
    // 小数点表記が必要かつ、値が10^-decimalThreshold以上である場合に小数表記
    if (useDecimal && absValue >= 10 ** -decimalThreshold && absValue <= 10 ** precision) {
        return value.toFixed(decimalThreshold);
    }

    // 整数かどうかを判定し、整数の場合は指数表記を適用
    if (Number.isInteger(value)) {
        return value.toExponential(precision);
    }

    // 指数表記を適用
    return value.toExponential(precision);
}

/**
* 数式に使用できる文字をチェックするする関数
* @param {number} value - チェックする数式
* @returns {boolean} - 使用できない文字が入っていた場合はfalse
*/
export const checkFormula = (value: string | undefined) => {
    let result = true; 
    if( value !== undefined && value !== ""){
            const chckPattern = /^[a-zA-Z\d_+*-./()]+$/;
            result = chckPattern.test(value)
    }
    return result
}


// 日付形式フォーマット yyyy-mm-dd hh:mm:ss
export const formatDateTimeForInput = (dateString: string) => {
    if (!dateString) return null;

    const date = new Date(dateString);

    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0'); // 月は0から始まるため +1
    const day = String(date.getDate()).padStart(2, '0');
    const hours = String(date.getHours()).padStart(2, '0');
    const minutes = String(date.getMinutes()).padStart(2, '0');
    const seconds = String(date.getSeconds()).padStart(2, '0'); // 秒を追加

    // 'YYYY-MM-DDTHH:mm:ss' の形式で返す
    return `${year}-${month}-${day}T${hours}:${minutes}:${seconds}`;
};


/**
* パラメーター名に使用できる文字をチェックするする関数
* @param {number} value - チェックする数式
* @returns {boolean} - 使用できない文字が入っていた場合はfalse
*/
export const checkPrameterName = (value: string | undefined) => {
    let result = true;
    if (value !== undefined && value !== "") {
        const chckPattern = /^[a-zA-Z0-9_-]*$/;
        result = chckPattern.test(value)
    }
    return result
}

/**
 * Nullと空を同じ扱いで評価
 * @param a 
 * @param b 
 * @returns 
 */
export const isEqualWithNullEmpty = (a: any, b: any) => {
    return ((a === null || a === '') && (b === null || b === '')) || a === b;
};


/**
 * プロセス　process_io_noの最大値を取得
 * 
 * @param inProduct 
 * @param inElementaryFlow 
 * @param outProduct 
 * @param outElementaryFlow 
 * @returns 
 */
export const getMaxProcessIoNo = (
    inProduct: ProcessIosType[] | undefined,
    inElementaryFlow: ProcessIosType[] | undefined,
    outProduct: ProcessIosType[] | undefined,
    outElementaryFlow: ProcessIosType[] | undefined,
) => {
    const allProcessIoNos: number[] = [];

    // データが存在する場合のみ、process_io_noを取得する
    if (inProduct) {
        allProcessIoNos.push(...inProduct.map(item => item.process_io_no ?? 0));
    }
    if (inElementaryFlow) {
        allProcessIoNos.push(...inElementaryFlow.map(item => item.process_io_no ?? 0));
    }
    if (outProduct) {
        allProcessIoNos.push(...outProduct.map(item => item.process_io_no ?? 0));
    }
    if (outElementaryFlow) {
        allProcessIoNos.push(...outElementaryFlow.map(item => item.process_io_no ?? 0));
    }

    // 空配列の場合は 0 を返す
    if (allProcessIoNos.length === 0) return 0;

    return Math.max(...allProcessIoNos);
};
