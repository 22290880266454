// AlertDialog.tsx
import React, { useEffect, useState } from 'react';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogActions from '@mui/material/DialogActions';
import { FadeLoader } from 'react-spinners';
import styled from 'styled-components';
import ModalButton from '@common/button/ModalButton';
import { useTranslation } from 'react-i18next';

type LoadingDialogProps = {
    open: boolean;
    onClose: () => void;
    loading:boolean;
    loading_message: string;
    result_message: string | JSX.Element;

};

const LoadingDialog: React.FC<LoadingDialogProps> = ({ open, onClose, loading, loading_message, result_message }) => {
    const {t} = useTranslation();

    return (
        <Dialog
            open={open}
            onClose={()=>{}}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
        >
            {loading &&
                <DialogContent>
                    {/* <DialogContentText id="alert-dialog-description"> */}
                    <DialogContentInner>
                        <FadeLoader
                            color="#48bdbb"
                            height={10}
                            radius={2}
                            width={5}
                        />
                        <DialogContentInnerText>{loading_message}</DialogContentInnerText>
                        {/* </DialogContentText> */}
                    </DialogContentInner>
                </DialogContent>
            }

            {!loading && result_message &&
                <DialogContent>
                    {/* <DialogContentText id="alert-dialog-description"> */}
                    <DialogContentInner>
                        <DialogContentInnerText>{result_message}</DialogContentInnerText>
                    </DialogContentInner>
                    <DialogActions>
                        <ModalButton onClick={onClose} text={t('閉じる')} />
                    </DialogActions>
                </DialogContent>
            }

        </Dialog>
    );
};

export default LoadingDialog;

const DialogContentInner = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100px; /* ダイアログの高さに応じて調整 */
    font-size: 16px;
    text-align: center;
    margin-left: 25px; 
    margin-right: 20px; 
`

const DialogContentInnerText = styled.span`
    font-size: 16px;
    margin-top: 10px; /* ローダーとテキストの間の余白 */
`