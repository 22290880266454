import { toPng, toSvg } from "html-to-image";
import { Dispatch, SetStateAction, useCallback, useRef } from "react";
import { ControlButton, getNodesBounds, getViewportForBounds, useReactFlow } from "reactflow";
import { ContextMenuState } from "./ContextMenu";

import iconDownload from '@images/product_system/icon_download.svg'
import styled from "styled-components";

interface DownloadSvgAndPngButtonProps {
    setContextMenu: Dispatch<SetStateAction<ContextMenuState>>
}

const DownloadSvgAndPngButton: React.FC<DownloadSvgAndPngButtonProps> = ({ setContextMenu }) => {
    const { getNodes } = useReactFlow();
    const controlButtonRef = useRef<HTMLDivElement>(null);
    const handleDownload = useCallback(async (format: 'png' | 'svg') => {
        const element = document.querySelector('.react-flow__viewport') as HTMLElement;
        if (!element) return;

        const width = 1024;
        const height = 768;

        const nodesBounds = getNodesBounds(getNodes());
        const viewport = getViewportForBounds(
            nodesBounds,
            width,
            height,
            0.1, // minZoom
            1.5, // maxZoom
            0.1  // padding
        );

        // スクリーンショットを撮る前にコントロールボタンを隠す
        if (controlButtonRef.current) {
            controlButtonRef.current.style.display = 'none';
        }

        try {
            const style = {
                transform: `translate(${-viewport.x}px, ${-viewport.y}px scale(${viewport.zoom})`,
                width: `${element.clientWidth}px`,
                height: `${element.clientHeight}px`
            };

            let dataUrl = '';
            if (format === 'png') {
                dataUrl = await toPng(element, {
                    backgroundColor: '#FFFFFF',
                    width: width,
                    height: height,
                    style: style,
                });
            } else if (format === 'svg') {
                dataUrl = await toSvg(element, {
                    backgroundColor: '#FFFFFF',
                    width: width,
                    height: height,
                    style: style,
                });
            }

            // 画像をダウンロード
            const link = document.createElement('a');
            link.href = dataUrl;
            link.download = `diagram.${format}`;
            link.click();
        } catch (error) {
            console.error('Error generating image:', error);
        } finally {
            // スクリーンショットを撮った後にコントロールボタンを再表示
            if (controlButtonRef.current) {
                controlButtonRef.current.style.display = 'flex';
            }
        }
    }, []);

    const onDownloadButtonClick = useCallback((event: React.MouseEvent) => {
        event.preventDefault();
        setContextMenu({
            x: event.clientX,
            y: event.clientY,
            visible: true,
            items: [
                { label: 'PNG', onClick: () => handleDownload('png') },
                { label: 'SVG', onClick: () => handleDownload('svg') },
            ],
        });
    }, [handleDownload]);

    return (
        <>
            <div ref={controlButtonRef} style={{ position: 'absolute', top: 10, right: 10, zIndex: 4 }}>
                <CustumControlButton onClick={onDownloadButtonClick}>
                    <DownloadDiv>
                        <DownloadSpan></DownloadSpan>
                    </DownloadDiv>
                </CustumControlButton>
            </div>
        </>
    );
}

export default DownloadSvgAndPngButton;


const CustumControlButton = styled(ControlButton)`
width: 24px !important;
height: 24px !important;
background-color: var(--color-site-secondary) !important;
`

const DownloadDiv = styled.div`
display: grid;
place-items: center;
width: 32px;
height: 32px;
`

const DownloadSpan = styled.span`
background-color: #fff;
mask: url(${iconDownload}) no-repeat center center / contain;
-webkit-mask: url(${iconDownload}) no-repeat center center / contain;
display: block;
width: 16px;
height: 16px;
`
