import React, { Dispatch, SetStateAction, useContext, useEffect, useState } from 'react';

import styled, { css } from 'styled-components';
import { createColumnHelper } from '@tanstack/react-table';
import { SearchProducts } from '@typeList/types';
import { useGetUserDetail, useSearchProductsByQuery } from '@hooks/useBackendApi';
import { getCurrentUserInfo } from '@services/Cookies';
import { getValueByKey } from '@utils/utils';
import ProductEditModal from '@specific/process/product_edit/ProductEditModal';
import SubsystemInputProductSelectTable from '@common/table/SubsystemInputProductSelectTable';
import TablePageNation from '@common/pagenation/TablePageNation';
import { CaseStudyViewContext } from '@pages/case_study/CaseStudyView';
import { t } from 'i18next';
import { FadeLoader } from 'react-spinners';

interface SubsystemInputProductSearchType {
    // プロセスのデータベースパック
    selectted_database_pack_id: number | undefined;
    setIsSearchModalOpen: Dispatch<SetStateAction<boolean>>
    currentNodeData: any;
}


const SubsystemInputProductSearch: React.FC<SubsystemInputProductSearchType> = ({
    selectted_database_pack_id,
    setIsSearchModalOpen,
    currentNodeData,
}) => {
    const context = useContext(CaseStudyViewContext)
    const { caseStudyData, userData } = context;

    // 入力
    const [inputSearchText, setInputSearchText] = useState<string>("");
    const [inputFulltextSearch, setInputFulltextSearch] = useState<boolean>(false);
    // ラジオボタン用
    const [inputFulltextSearchRadio, setInputFulltextSearchRadio] = useState<string>("1");
    const [inputCurrentUserUpdatedCheck, setInputCurrentUserUpdatedCheck] = useState<boolean>(false);
    const [inputProductTypeCheck, setInputProductTypeCheck] = useState<number>(0);



    const { searchProductsByQuery, searchProductsByQueryData, searchProductsByQueryLoading, searchProductsByQueryError } = useSearchProductsByQuery();

    const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>, key: string) => {
        switch (key) {
            case "search_text":
                setInputSearchText(event.target.value);
                break;
            case "is_fulltext_search":
                if (event.target.value === "1") {
                    setInputFulltextSearch(false);
                    setInputFulltextSearchRadio("1");
                }
                if (event.target.value === "2") {
                    setInputFulltextSearch(true);
                    setInputFulltextSearchRadio("2");
                }
                break;
            case "is_default_language":
                // 処理追加
                break;
            case "is_current_user_updated":
                setInputCurrentUserUpdatedCheck(event.target.checked);
                break;
            case "product_type":
                setInputCurrentUserUpdatedCheck(event.target.checked);
                break;
        }
    };
    // ページング
    const [page, setPage] = useState<number>(1)
    const [pageSize, setPageSize] = useState<number>(10)

    const [tableData, setTableData] = useState<any | null>(null);
    const [tableDataLoading, setTableDataLoading] = useState<boolean>(false);
    const [tableDataCount, setTableDataCount] = useState<number>(0);

    const [isDisabled, setIsDisabled] = useState(false);
    useEffect(() => {
        if(tableDataLoading){
            setIsDisabled(true);
        } else {
            setIsDisabled(false);
        }
    },[tableDataLoading])

    // 検索
    const handleSearchButtonClick = async () => {
        if(page !== 1){
            setPage(1)
        } else {
            search();
        }
    };

    const search = async() =>{
        setTableDataLoading(true);

        if (isDisabled) return;

        try {
            const searchResults = await searchProductsByQuery(
                caseStudyData?.database_pack.id,
                inputSearchText,
                page,
                pageSize,
                inputFulltextSearch,
                true,
                inputCurrentUserUpdatedCheck,
                0
            );
            await flattenedData(searchResults);
        } catch (error) {
            console.log(error);
        } finally {
            setTableDataLoading(false);
        }
    }

    // ダイアログオープン時はAPIを呼ばないよう制御する
    const [openPageApiFlg, setOpenPageApiFlg] = useState<boolean>(false);
    const [openPageSizeApiFlg, setOpenPageSizeApiFlg] = useState<boolean>(false);

    // API呼び出し
    useEffect(() => {
        if (openPageApiFlg) {
            search();
        }
        if(!openPageApiFlg){
            setOpenPageApiFlg(true)
        }
    }, [page])

    // 表示切替時はページをリセット
    useEffect(() => {
        if (page === 1　&& openPageSizeApiFlg) {
            search();
        }
        if(!openPageSizeApiFlg){
            setOpenPageSizeApiFlg(true);
        }
        setPage(1)
    }, [pageSize])

    const flattenedData = async (jsonData: SearchProducts) => {
        if (jsonData === null) {
            return;
        }

        const flatData = jsonData.results.flatMap((item: any) => {
            return [{
                locale: item.locale,
                name: item.name,
                synonyms_text: item.synonyms_text,
                include_items_text: item.include_items_text,
                related_items_text: item.related_items_text,
                public_comment: item.public_comment,
                private_comment: item.private_comment,
                cas_number: item.cas_number,
                id: item.id,
                global_id: item.global_id,
                unitLocale: item.unit.locale,
                unitName: item.unit.name,
                unitPrivateComment: item.unit.private_comment,
                unitPublicComment: item.unit.public_comment,
                unitCode: item.unit.code,
                unitId: item.unit.id,
                unit_group_id: item.unit.unit_group_id,
                identifierScValue: item.identifier_scheme_values.find((val: any) => val.identifier_scheme.id === 2)?.value,
                identifierScheme: null,
                created_username: item.created_username,
                category_values: item.category_values,
            }];
        });

        setTableData(flatData);
        setTableDataCount(jsonData.count);
    };

    useEffect(() => {
        if (searchProductsByQueryData) {
            flattenedData(searchProductsByQueryData);
        }
    }, [searchProductsByQueryData]);

    const columnHelper = createColumnHelper<any>();
    const columns = [
        columnHelper.accessor('name', {
            header: () =>t('名前'),
            cell: info => info.renderValue(),
        }),
        columnHelper.accessor('unitCode', {
            header: () => t('主単位'),
            cell: info => info.renderValue(),
        }),
        columnHelper.accessor('synonyms_text', {
            header: () => t('同意語'),
            cell: info => info.renderValue(),
        }),
        columnHelper.accessor('include_items_text', {
            header: () => t('包含品目'),
            cell: info => info.renderValue(),
        }),
        columnHelper.accessor('related_items_text', {
            header: () => t('関連項目'),
            cell: info => info.renderValue(),
        }),
        columnHelper.accessor('public_comment', {
            header: () => t('説明'),
            cell: info => info.renderValue(),
        }),
        columnHelper.accessor('private_comment', {
            header: () => t('プライベートコメント'),
            cell: info => info.renderValue(),
        }),
        columnHelper.accessor('created_username', {
            header: () => t('作成者'),
            cell: info => info.renderValue(),
        }),
        // columnHelper.accessor('identifierScValue', {
        //     header: () => t('IDEA'),
        //     cell: info => info.renderValue(),
        // }),
    ];

    return (
        <>
            <SectionBody>
                <SearchBox>
                    <SearchWrap>
                        <InputSerch
                            type="search"
                            value={inputSearchText ?? ""}
                            onChange={(event) => handleInputChange(event, "search_text")}
                            onKeyDown={(event) => event.key === 'Enter' && handleSearchButtonClick()}
                        />
                    </SearchWrap>
                    <StyledButton onClick={handleSearchButtonClick} type='button' disabled={isDisabled}>{t('検索')}</StyledButton>
                </SearchBox>

                <Radio>
                    <RadioLabel>
                        <RadioInput
                            type="radio"
                            name="productNameSearch"
                            id="1"
                            value="1"
                            checked={inputFulltextSearchRadio === "1"}
                            onChange={(event) => handleInputChange(event, "is_fulltext_search")}
                        />
                        <RadioText>{t('製品名で検索')}</RadioText>
                    </RadioLabel>
                    <RadioLabel>
                        <RadioInput
                            type="radio"
                            name="fulltextSearch"
                            id="2"
                            value="2"
                            checked={inputFulltextSearchRadio === "2"}
                            onChange={(event) => handleInputChange(event, "is_fulltext_search")}
                        />
                        <RadioText>{t('製品に含まれる文言で検索')}</RadioText>
                    </RadioLabel>
                </Radio>

                <SectionAcordionContent>
                    <SectionColumn>
                        <SectionColumnContent>
                            <SectionColumnTitle>
                                <CheckBoxText>{t('データベースパック')}</CheckBoxText>
                            </SectionColumnTitle>
                        </SectionColumnContent>
                        <SectionColumnContent>
                            <SelectPrimary>
                                <InputPrimary type="text" disabled value={caseStudyData?.database_pack.name} />
                            </SelectPrimary>
                            <CheckBox>
                                <CheckBoxLabel>
                                    <CheckBoxInput
                                        type="checkbox"
                                        checked={inputCurrentUserUpdatedCheck}
                                        onChange={(event) => handleInputChange(event, "is_current_user_updated")}
                                    />
                                    <CheckBoxText>{t('自分で更新した製品のみ検索')}</CheckBoxText>
                                </CheckBoxLabel>
                            </CheckBox>
                        </SectionColumnContent>
                    </SectionColumn>
                </SectionAcordionContent>
            </SectionBody>
            <SectionBodyLast>

                {searchProductsByQueryLoading &&
                    <FadeLoader
                        color="#48bdbb"
                        height={10}
                        radius={2}
                        width={5}
                    />
                }
                {tableData && !searchProductsByQueryLoading &&
                    <>
                        <TablePageNation
                            count={tableDataCount || 0}
                            page={page}
                            setPage={setPage}
                            pageSize={pageSize}
                            setPageSize={setPageSize}
                        />
                        <SubsystemInputProductSelectTable
                            columns={columns}
                            data={tableData}
                            EditModalComponent={ProductEditModal}
                            setIsSearchModalOpen={setIsSearchModalOpen}
                            currentNodeData={currentNodeData}
                        />
                    </>
                }
            </SectionBodyLast>
        </>
    );
};

export default SubsystemInputProductSearch;

const InputPrimary = styled.input`
    width: min(100%, 340px);

    padding: 12px;
    color: var(--color-txt-primary);
    border: 1px solid var(--color-line-primary);
    border-radius: 4px;
    background-color: #fff;
    font-size: 16px;
    font-weight: 400;
    line-height: 1.25;
`

const InputSerch = styled.input`
    width: 100%;
    padding-left: 44px;

    padding: 12px;
    color: var(--color-txt-primary);
    border: 1px solid var(--color-line-primary);
    border-radius: 4px;
    background-color: #fff;
    font-size: 16px;
    font-weight: 400;
    line-height: 1.25;
`

const StyledButton = styled.button`
    background-color: var(--color-site-secondary);
    color: #fff;

    padding: 14px 30px;
    border-radius: 4px;
    white-space: nowrap;

    display: inline-flex;
    align-items: center;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: center;
    cursor: pointer;
    white-space: nowrap;
    gap: 0 4px;

    appearance: none;
    border: none;
    font: inherit;
    outline: none;      

  &:hover {
    background-color: var(--color-gray--01);
    color: inherit;

  }
`

const SectionBody = styled.div`
    margin-bottom: 60px;
`

const SectionBodyLast = styled.div`
`

const SearchBox = styled.div`
    display: flex;
    align-items: center;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: flex-start;
    gap: 16px;    
`

const SearchWrap = styled.div`
    width: 100%;
    position: relative;
`

const Radio = styled.div`
    margin: 24px 0 40px;
    display: flex;
    align-items: center;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: flex-start;
    gap: 54px;    
`

const RadioLabel = styled.label`
    isplay: flex;
    align-items: center;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: flex-start;
    gap: 6px;
    width: fit-content;
    cursor: pointer;
    position: relative;    
`
const RadioText = styled.span`
    align-items: center;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: flex-start;
    gap: 0 20px;
    font-size: 16px;
    white-space: nowrap;
    font-weight: 500; 
`

const SectionAcordionContent = styled.div`
    padding-top: 24px;
`

const SectionColumn = styled.div`
`

const SectionColumnContent = styled.div`
    display: flex;
    align-items: center;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: flex-start;
    gap: 16px;
`

const SectionColumnTitle = styled.div`
    margin-bottom: 12px;
    font-size: 16px;
    font-weight: 500;
    line-height: 1.25;
`


const SelectPrimary = styled.label`
    width: min(100%, 340px);
    position: relative;
    display: block;
    cursor: pointer;
`

const SelectInner = styled.select`
    color: rgb(85, 85, 85);
    position: relative;
    width: 100%;
    padding: 12px 32px 12px 12px;
    cursor: pointer !important;
    border: 1px solid var(--color-line-primary);
    border-radius: 4px;
    background-color: #fff;
    font-size: 16px;
    font-weight: 400;
    line-height: 1.25;
`

const CheckBox = styled.div`
    display: flex;
    align-items: center;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: flex-start;
    gap: 54px;
`

const CheckBoxLabel = styled.label`
    display: flex;
    align-items: center;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: flex-start;
    gap: 6px;
    cursor: pointer;
 `

const CheckBoxText = styled.span`
    font-size: 16px;
    font-weight: 500;
`

const CheckBoxInput = styled.input`
    accent-color: var(--color-site-secondary);
`

const RadioInput = styled.input`
    accent-color: var(--color-site-secondary);
`

const SectionResult = styled.div`
    padding: 0 0 20px;
    margin-bottom: 20px;
    border-bottom: 1px solid var(--color-line-primary);
    display: flex;
    justify-content: space-between;
`

const SectionResultTitle = styled.h3`
    font-size: 18px;
    font-weight: 500;
`

const SectionResultTitleText = styled.p`
    font-size: 18px;
    font-weight: 500;
`