import React, { Dispatch, useEffect, useState } from 'react';
import Modal from '@common/modal/Modal';
import { useGetUnitGroups } from '@hooks/useBackendApi';
import { UnitConversions, UnitGroupList, UnitGroupUnit } from '@typeList/types';
import styled from 'styled-components';
import ReloadButton from '@common/button/ReloadButton';

import iconDelete from '@images/table/icon_delete.svg';
import iconAdd from '@images/product_system/icon_add.svg'
import { getCurrentUserInfo } from '@services/Cookies';
import { useTranslation } from 'react-i18next';

interface UnitConvertingSettingModalProps {
    inputDatabasePackId: number | undefined;
    inputUnitId: number;
    inputUnitName: string;
    setInputUnitId: Dispatch<React.SetStateAction<number>>;
    setInputUnitName: Dispatch<React.SetStateAction<string>>;
    unitConversions: UnitConversions[];
    setUnitConversions: Dispatch<React.SetStateAction<UnitConversions[]>>;
    setUnitConversionsCode: Dispatch<React.SetStateAction<string>>;
}

interface FlattenedData {
    name: string;
    unitName: string;
    unitCode: string;
    to_unit_id: number;
    factor: number;
    ConvertingType: string;
    units: UnitGroupUnit[];
}

interface UnitList {
    id: number;
    name: string;
}

interface UnitCodes {
    id: number,
    unit_id: number,
    unit_name: string,
    unit_code: string
}

interface ConversionRow {
    unitGroupId: number;
    to_unit_id: number;
    factor: number;
    public_comment: string;
    private_comment: string;
    locale: string;
}

/**
 * 単位換算設定
 * @param param0 
 * @returns 
 */
const UnitConvertingSettingModal: React.FC<UnitConvertingSettingModalProps> = ({
    inputDatabasePackId,
    inputUnitId,
    inputUnitName,
    setInputUnitId,
    setInputUnitName,
    unitConversions,
    setUnitConversions,
    setUnitConversionsCode
}) => {
    const { t } = useTranslation();
    const [isModalOpen, setIsModalOpen] = useState(false);
    const openModal = () => setIsModalOpen(true);
    const closeModal = () => setIsModalOpen(false);

    // 単位マスタ取得
    const { getUnitGroups, unitGroupsData, unitGroupsError, unitGroupsLoading } = useGetUnitGroups();

    const [unitList, setUnitList] = useState<UnitList[]>([]);
    const [unitCodesList, setUnitCodesList] = useState<UnitCodes[]>([]);
    const [conversionRows, setConversionRows] = useState<ConversionRow[]>([]);
    const [selectUnitCodeMap, setSelectUnitCodeMap] = useState<{ [key: number]: UnitCodes[] }>({});

    useEffect(() => {
        if (inputDatabasePackId) {
            getUnitGroups(inputDatabasePackId);
        }
    }, [inputDatabasePackId]);

    useEffect(() => {
        if (unitGroupsData) {
            flattenData(unitGroupsData);
        }
    }, [unitGroupsData]);

    const flattenData = (jsonData: UnitGroupList[] | null) => {
        if (!jsonData) {
            return;
        }
        const unit = jsonData.map(data => ({
            id: data.id,
            name: data.name
        }));
        const unitCodes = jsonData.flatMap(data => (
            data.units.map(unit => ({
                id: data.id,
                unit_id: unit.id,
                unit_name: unit.name,
                unit_code: unit.code
            }))
        ));
        setUnitList(unit);
        setUnitCodesList(unitCodes);
    };

    // unitConversionsのデータをconversionRowsにセット
    useEffect(() => {
        if (unitConversions.length > 0) {
            // conversionRowsをunitConversionsから初期化
            const rows = unitConversions.map(conversion => {
                const unitGroupId = unitCodesList.find(code => code.unit_id === conversion.to_unit_id)?.id || 0;
                return {
                    unitGroupId,
                    to_unit_id: conversion.to_unit_id,
                    factor: conversion.factor,
                    public_comment: conversion.public_comment || '',
                    private_comment: conversion.private_comment || '',
                    locale: conversion.locale || 'ja',
                };
            });
            setConversionRows(rows);

            // selectUnitCodeMapを初期化
            const initialSelectUnitCodeMap: { [key: number]: UnitCodes[] } = {};
            rows.forEach((row, index) => {
                const filteredUnitCodes = unitCodesList.filter(units => units.id === row.unitGroupId);
                initialSelectUnitCodeMap[index] = filteredUnitCodes;
            });
            setSelectUnitCodeMap(initialSelectUnitCodeMap);
        }
    }, [unitConversions, unitCodesList]);


    const handleUnitChange = (index: number, unitGroupId: number) => {
        const newRows = [...conversionRows];
        newRows[index].unitGroupId = unitGroupId;
        newRows[index].to_unit_id = 0;
        setConversionRows(newRows);

        const filteredUnitCodes = unitCodesList.filter(units => units.id === unitGroupId);
        setSelectUnitCodeMap(prev => ({ ...prev, [index]: filteredUnitCodes }));
    };

    const handleRowChange = <K extends keyof ConversionRow>(index: number, field: K, value: ConversionRow[K]) => {
        const newRows = [...conversionRows];
        newRows[index][field] = value;
        setConversionRows(newRows);
    };

    const handleUnitIdChange = (index: number, to_unit_id: number) => {
        const newRows = [...conversionRows];
        newRows[index].to_unit_id = to_unit_id;
        setConversionRows(newRows);
    };

    // 行追加
    const handleAddRow = () => {
        const newUnitGroupId = unitList[0]?.id || 0;
        const newUnitCodes = unitCodesList.filter(units => units.id === newUnitGroupId);
        setSelectUnitCodeMap(prev => ({
            ...prev,
            [conversionRows.length]: newUnitCodes
        }));
        setConversionRows([...conversionRows, {
            unitGroupId: newUnitGroupId,
            to_unit_id: 0,
            factor: 1,
            private_comment: '',
            public_comment: '',
            locale: getCurrentUserInfo()?.default_locale_code || 'ja'
        }]); // Initialize public_comment as empty string
    };

    // 行削除
    const handleDeleteRow = (index: number) => {
        const newRows = conversionRows.filter((_, rowIndex) => rowIndex !== index);
        setConversionRows(newRows);

        const newSelectUnitCodeMap: { [key: number]: UnitCodes[] } = {};
        Object.keys(selectUnitCodeMap).forEach((key) => {
            const keyNum = Number(key);
            if (keyNum !== index) {
                newSelectUnitCodeMap[keyNum > index ? keyNum - 1 : keyNum] = selectUnitCodeMap[keyNum];
            }
        });
        setSelectUnitCodeMap(newSelectUnitCodeMap);
    };

    const [errorMsg, setErrorMsg] = useState<any>("");
    const handleOkClick = () => {
        // エラー初期化
        setErrorMsg(null);
        const result = conversionRows.map(({
            to_unit_id,
            factor,
            locale,
            public_comment,
            private_comment
        }) => ({
            to_unit_id,
            factor,
            locale,
            public_comment,
            private_comment
        }));
        const hasInputUnitId = conversionRows.some(row => row.to_unit_id === inputUnitId);

        if (hasInputUnitId) {
            setErrorMsg("変換行の中に変換元単位が含まれています。")
            return;
        }

        const noSelectUnit = conversionRows.some(row => row.to_unit_id === 0);
        if (noSelectUnit) {
            setErrorMsg("変換先単位が選択されていません。")
            return;
        }

        const unitIdSet = new Set();
        for (const row of conversionRows) {
            if (unitIdSet.has(row.to_unit_id)) {
                setErrorMsg("重複する変換先単位が存在します。")
                return;
            }
            unitIdSet.add(row.to_unit_id);
        }

        const matchingUnitCodes = conversionRows
            .map(row => unitCodesList.find(code => code.unit_id === row.to_unit_id)?.unit_code)
            .filter(code => code !== undefined);

        setUnitConversionsCode(matchingUnitCodes.join(','));
        setUnitConversions(result);
        closeModal();
    };

    const formula = (row: ConversionRow) => {
        const toUnit = unitCodesList.find(code => code.unit_id === row.to_unit_id);
        if (!toUnit) return '';
        return `1 ${inputUnitName} = ${row.factor} ${toUnit.unit_code}`;
    };


    return (
        <>
            <StyledButton type='button' onClick={openModal}>
                {t('単位換算')}
            </StyledButton>
            <Modal isOpen={isModalOpen} onClose={closeModal}>
                <Inner>
                    <Title>{t('単位分類ごとに単位変換を設定します')}</Title>
                    <SectionHead>
                        {errorMsg && <ErrorMsg>{errorMsg}</ErrorMsg>}
                        <SectionColumnWrap>
                            <SectionColumnWrapTitle>{t('変換元単位')}</SectionColumnWrapTitle>
                            <SectionColumnContent>
                                <input type="text" hidden value={inputUnitId} />
                                <InputPrimary type="text" disabled value={inputUnitName} />
                            </SectionColumnContent>
                        </SectionColumnWrap>
                    </SectionHead>
                    <SectionBody>
                        <TableSection>
                            <TableWrap>

                                <Table>
                                    <Thead>
                                        <Tr>
                                            <Th>{t('単位分類')}</Th>
                                            <Th>{t('変換先単位')}</Th>
                                            <Th>{t('係数')}</Th>
                                            <Th>{t('計算式')}</Th>
                                            <Th>{t('公開コメント')}</Th>
                                            <Th>{t('操作')}</Th>
                                        </Tr>
                                    </Thead>
                                    <Tbody>
                                        {conversionRows.map((row, index) => (
                                            <Tr key={index}>
                                                <Td>
                                                    {unitList &&
                                                        <SelectLabel>
                                                            <Select
                                                                value={row.unitGroupId}
                                                                onChange={(e) => handleUnitChange(index, Number(e.target.value))}
                                                            >
                                                                {unitList.map(unit => (
                                                                    <option key={unit.name} value={unit.id}>
                                                                        {unit.name}
                                                                    </option>
                                                                ))}
                                                            </Select>
                                                        </SelectLabel>
                                                    }
                                                </Td>
                                                <Td>
                                                    {selectUnitCodeMap[index] ? (
                                                        <SelectLabel>
                                                            <Select
                                                                value={row.to_unit_id}
                                                                onChange={(e) => handleUnitIdChange(index, Number(e.target.value))}
                                                            >
                                                                <option value={0}>{t('選択してください')}</option>
                                                                {selectUnitCodeMap[index].map((units: any) => (
                                                                    <option key={units.unit_name} value={units.unit_id}>
                                                                        {units.unit_code}
                                                                    </option>
                                                                ))}
                                                            </Select>
                                                        </SelectLabel>
                                                    ) : (
                                                        <SelectLabel>
                                                            <Select disabled>
                                                                <option value={0}>{t('選択してください')}</option>
                                                            </Select>
                                                        </SelectLabel>
                                                    )}
                                                </Td>
                                                <Td>
                                                    <Input
                                                        type="number"
                                                        name="factor"
                                                        value={row.factor}
                                                        onChange={(e) => handleRowChange(index, 'factor', Number(e.target.value))}
                                                    />
                                                </Td>
                                                <FormulaTd>
                                                    <span>{formula(row)}</span>
                                                </FormulaTd>
                                                <Td>
                                                    <Input
                                                        type='text'
                                                        name='public_comment'
                                                        value={row.public_comment} // Bind to the public_comment field
                                                        onChange={(e) => handleRowChange(index, 'public_comment', e.target.value)}
                                                    />
                                                </Td>
                                                <Td>
                                                    <DeleteButton type='button' onClick={() => handleDeleteRow(index)}>
                                                        <ButtonInner>
                                                            <DeleteButtonIcon></DeleteButtonIcon>
                                                        </ButtonInner>
                                                    </DeleteButton>
                                                    {/* <button onClick={() => handleDeleteRow(index)}>削除</button> */}
                                                </Td>
                                            </Tr>
                                        ))}
                                    </Tbody>
                                </Table>
                                <ButtonWrap>
                                    <AddButton type='button' onClick={handleAddRow}>
                                        <AddButtonIconWrap>
                                            <AddButtonIcon></AddButtonIcon>
                                        </AddButtonIconWrap>
                                        <ButtonText>{t('行を追加')}</ButtonText>
                                    </AddButton>
                                </ButtonWrap>
                            </TableWrap>
                        </TableSection>


                    </SectionBody>
                    <ButtonReloadSectionTabel>
                        <ReloadButton onClick={handleOkClick} text={t('更新する')} />
                    </ButtonReloadSectionTabel>
                </Inner>
            </Modal>
        </>
    );
}

export default UnitConvertingSettingModal;

const ErrorMsg = styled.p`
color:red;
`

const StyledButton = styled.button`
    background-color: var(--color-site-secondary);
    color: #fff;

    padding: 8px 24px;
    border-radius: 20px;
    display: inline-flex;
    align-items: center;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: center;
    cursor: pointer;
    white-space: nowrap;
    gap: 0 4px;    

    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    border: none;
    font: inherit;
    outline: none;     

  &:hover {
    background-color: var(--color-gray--01);
    color: inherit;

  }
`

const Inner = styled.div`
    width: 100%;
    padding: 120px 10%;
    margin: 0 auto;
`

const Title = styled.h2`
    font-size: 18px;
    padding: 0 0 20px;
    margin-bottom: 20px;
    border-bottom: 1px solid var(--color-line-primary);
    font-size: 16px;
    font-weight: 500;    
    line-height: 1.25;    
`
const SectionHead = styled.div`
`
const SectionBody = styled.div`
`

const SectionColumnWrap = styled.div`
    display: flex;
    gap: 0 20px;
    margin-bottom: 20px;
    align-items: center;
    grid-template-columns: 27.0833333333% minmax(0, 1fr);
`
const SectionColumnWrapTitle = styled.dt`
    margin-bottom: 0;   
    font-size: 16px;
    font-weight: 500;
    line-height: 1.25;
`

const SectionColumnContent = styled.dd`
    display: flex;
    align-items: center;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: flex-start;
    gap: 16px;
`

const InputPrimary = styled.input`
    width: 100%;

    padding: 12px;
    color: var(--color-txt-primary);
    border: 1px solid var(--color-line-primary);
    border-radius: 4px;
    background-color: #fff;
    font-size: 16px;
    font-weight: 400;
    line-height: 1.25;
`

const TableSection = styled.section`
    background-color: #fff;
    padding: 20px;
    margin-bottom: 32px;
`

const TableWrap = styled.div`
    height: 582px;
    overflow-y: scroll;
    overflow-x: auto;
`

const Table = styled.table`
    min-width: 100%;
    border-collapse: collapse;
    margin-bottom: 8px;
    background-color: #fff;
    border-spacing: 0;
`

const Thead = styled.thead`
border-right: 1px solid var(--color-line-primary);
border-left: 1px solid var(--color-line-primary);
`

const Tr = styled.tr`
border-top: 1px solid var(--color-line-primary);
border-bottom: 1px solid var(--color-line-primary);
`

const Th = styled.th`
font-size: 13px;
    font-weight: 700;
    line-height: 1.25;
    color: var(--color-site-primary);
    padding: 12px;
    align-content: center;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    min-height: 40px;
`

const ThInner = styled.div`
width: fit-content;
    display: flex;
    align-items: center;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: flex-start;
    gap: 4px;
    white-space: nowrap;
`

const Tbody = styled.tbody`
border-right: 1px solid var(--color-line-primary);
border-left: 1px solid var(--color-line-primary);
`

const Td = styled.td`
padding: 5px 8px;
`

const FormulaTd = styled.td`
font-size: 14px;
    font-weight: 500;
    line-height: 1.25;
    padding: 12px;
    align-content: center;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    min-height: 40px;
`

const SelectLabel = styled.label`
width: min(100%, 340px);
position: relative;
    display: block;
`

const Select = styled.select`
color: rgb(85, 85, 85);
padding: 6.5px 32px 6.5px 8px;
font-size: 12px;

position: relative;
width: 100%;

border: 1px solid var(--color-line-primary);
border-radius: 4px;
background-color: #fff;
font-weight: 400;
line-height: 1.25;
font: inherit;
outline: none;
`

const Input = styled.input`
padding: 6.5px 32px 6.5px 8px;
font-size: 12px;
width: min(100%, 340px);
color: var(--color-txt-primary);
border: 1px solid var(--color-line-primary);
border-radius: 4px;
background-color: #fff;
font-weight: 400;
line-height: 1.25;

-webkit-appearance: none;
-moz-appearance: none;
background: transparent;
font: inherit;
outline: none;
`


const DeleteButtonIcon = styled.span`
mask: url(${iconDelete}) no-repeat center center / contain;
-webkit-mask: url(${iconDelete}) no-repeat center center / contain;
display: block;
width: 24px;
height: 24px;
background: var(--color-txt-primary);
`;

const ButtonInner = styled.div`
`;

const DeleteButton = styled.button`
cursor: pointer;
appearance: none;
background: transparent;
border: none;
border-radius: 0;
font: inherit;
outline: none;
`;

const ButtonWrap = styled.div`
    margin-top: 20px;
    justify-content: flex-start;
    display: flex;
    align-items: center;
    flex-direction: row;
    flex-wrap: wrap;
`

const AddButton = styled.button`
    padding: 6px 18px;
    border-radius: 20px;
    display: inline-flex;
    align-items: center;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: center;
    cursor: pointer;
    white-space: nowrap;
    background-color: var(--color-gray--01);
    gap: 0 4px;

    appearance: none;
    border: none;
    font: inherit;
    outline: none;

    &:hover {
        background-color: var(--color-site-secondary);
        text-decoration: none !important;

        span {
            color: #fff;
        }
    }
`

const AddButtonIconWrap = styled.div`
    width: 16px;
    height: 16px;
    border: none;
    background-color: var(--color-site-secondary);
    display: grid;
    place-content: center;
    border-radius: 50%;
`

const AddButtonIcon = styled.span`
    width: 7.44px;
    height: 7.44px;
    background-color: #fff;
    position: relative;
    mask: url(${iconAdd}) no-repeat center center / contain;
    -webkit-mask: url(${iconAdd}) no-repeat center center / contain;
    display: block;
`

const ButtonText = styled.span`
    font-size: 12px;
    font-weight: 500;
`

const ButtonReloadSectionTabel = styled.div`
    margin-top: 40px;
    text-align: right;

    margin: 0;
    padding: 0;
    border: 0;
    font: inherit;
    vertical-align: baseline;
    box-sizing: border-box;    
`