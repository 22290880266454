import Modal from '@common/modal/Modal';
import React, { Dispatch, SetStateAction, useContext, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next';
import { ProcessViewContext } from '@pages/process/ProcessView';
import { arraysEqual } from '@utils/utils';
import { createColumnHelper } from '@tanstack/react-table';
import { ProcessParametersType } from '@typeList/types';
import ProsessParameterSettingSelectTable from '@common/table/ProsessParameterSettingSelectTable';
import ModalButton from '@common/button/ModalButton';
import styled from 'styled-components';
import { CaseStudyViewContext } from '@pages/case_study/CaseStudyView';

interface Parameter extends ProcessParametersType {
    division: string | null;
}

interface FlowParameterSettingType {
    inputAmountFormula: string | undefined;
    setInputAmountFormula: Dispatch<SetStateAction<string | undefined>>
}

/**
 * 065 フローのパラメーター設定一覧
 * @returns 
 */
const FlowParameterSettingListModal: React.FC<FlowParameterSettingType> = ({inputAmountFormula, setInputAmountFormula}) => {
    const [isModalOpen, setIsModalOpen] = useState(false);
    const openModal = () => setIsModalOpen(true);
    const closeModal = () => setIsModalOpen(false);

    const { t } = useTranslation();
    const context = useContext(ProcessViewContext);
    const cacseStudyContext = useContext(CaseStudyViewContext);
    const { processParameters, setProcessParameters, updateProcessParameters, setUpdateProcessParameters, setUnsaved } = context;
    const { caseStudyParameters, updateCaseStudyParameters} = cacseStudyContext;
    useEffect(() => {
        if (
            !arraysEqual(processParameters, updateProcessParameters)
        ) {
            setUnsaved(true);
        } else {
            setUnsaved(false);
        }
    }, [updateProcessParameters])

    const columnHelper = createColumnHelper<Parameter[]>();
    const columns = [
        columnHelper.accessor('name', {
            header: () => t('名前'),
            cell: info => info.renderValue(),
            meta: { editable: true },
        }),
        columnHelper.accessor('division', {
            header: () => t('区分'),
            cell: info => {
                if(processParameters && processParameters.length > 0){
                    return t('プロセス')
                } 
                if(caseStudyParameters && caseStudyParameters.length > 0){
                    return t('ケーススタディ')
                } 
            },
            meta: { editable: false },
        }),
        columnHelper.accessor('value', {
            header: () => t('値'),
            cell: info => info.renderValue(),
            meta: { editable: true },
        }),
    ];

    return (
        <>
            <ModalButton type='button' onClick={openModal} text={t('パラメーター')}/>
            {isModalOpen && (
                <Modal isOpen={isModalOpen} onClose={closeModal}>
                    <Inner>
                    <Title>{t('パラメーター一覧')}</Title>
                    {updateProcessParameters &&　updateProcessParameters.length > 0 &&
                        <>
                            <ProsessParameterSettingSelectTable 
                                columns={columns} 
                                data={updateProcessParameters} 
                                paging_flag={false} 
                                inputAmountFormula={inputAmountFormula}
                                setInputAmountFormula={setInputAmountFormula} 
                                setIsSearchModalOpen={setIsModalOpen}
                            />
                        </>
                    }
                   {updateCaseStudyParameters &&updateCaseStudyParameters.length > 0 &&
                        <>
                            <ProsessParameterSettingSelectTable 
                                columns={columns} 
                                data={updateCaseStudyParameters} 
                                paging_flag={false} 
                                inputAmountFormula={inputAmountFormula}
                                setInputAmountFormula={setInputAmountFormula} 
                                setIsSearchModalOpen={setIsModalOpen}
                            />
                        </>
                    }
                    </Inner>
                </Modal>
            )}
        </>
    )
}

export default FlowParameterSettingListModal;

const Inner = styled.div`
    width: 100%;
    padding: 120px 10%;
    margin: 0 auto;
`

const Title = styled.h2`
    font-size: 18px;
    padding: 0 0 20px;
    margin-bottom: 20px;
    border-bottom: 1px solid var(--color-line-primary);
    font-size: 16px;
    font-weight: 500;    
    line-height: 1.25;    
`
