import Modal from '@common/modal/Modal';
import { LinkMenu } from '@styles/Common';
import React, { useEffect, useState, createContext } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { useForm, SubmitHandler } from 'react-hook-form';
import { GetElementaryFlowsList, GetUserDetail, SearchElementaryFlows } from '@typeList/types';
import { useGetUserDetail, useSearchCaseStudies } from '@hooks/useBackendApi';
import { getCurrentDefaultDatabasePackId, getCurrentUserInfo } from '@services/Cookies';
import { createColumnHelper } from '@tanstack/react-table';
import { formatToLocalTime, getValueByKey } from '@utils/utils';
import iconAdd from '@images/product_system/icon_add.svg';
import iconSub from '@images/table/icon_arrow_up.svg';
import iconCheck from '@images/table/icon_check.svg';
// import ModalButton from '@common/button/ModalButton';
import TablePageNation from '@common/pagenation/TablePageNationExport';
// import CaseStudyEditDataTable from '@common/table/CaseStudyEditDataTable';
import DataTable from '@common/table/DataTableExport';
import { FadeLoader } from 'react-spinners';
import DateInput from '@common/date_picker/DateInput';

export interface CaseStudyExportContextType {
    inputDatabasePackId: number | undefined;
    inputDatabasePackName: string | undefined;
}

const defaulutContextvalue: CaseStudyExportContextType = {
    inputDatabasePackId: 0,
    inputDatabasePackName: ""
}

export const CaseStudySearch = createContext<CaseStudyExportContextType>(defaulutContextvalue);

interface CaseStudyExportModalProps {
    userData: GetUserDetail | null;
}

/**
 * 032 ケーススタディのエクスポート
 * @returns 
 */
// const CaseStudyExportModal = () => {
const CaseStudyExportModal: React.FC<CaseStudyExportModalProps> = ({ userData }) => {
    const { t } = useTranslation();
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [isCollapseOpen, setIsCollapseOpen] = useState(false)
    const openModal = () => setIsModalOpen(true);
    const closeModal = () => {
        setSelectedRows([]); // モーダルを閉じる際に選択をクリア
        setIsModalOpen(false);
    };
    const { register, handleSubmit } = useForm<SearchElementaryFlows>();
    const [inpuProcessExportRadioSearch, setInpuProcessExportRadioSearch] = useState<string>("1");
    const onIsClickcollapseOpen = () => setIsCollapseOpen((prev) => !prev);

    // const [inputDataRetrievalStartAt] = useState<string>("");
    // const [inputDataRetrievalEndAt] = useState<string>("");

    // データベースパック
    const [inputDatabasePackId, setInputDatabasePackId] = useState<number | undefined>(userData?.default_database_pack_id);
    const [databasePacks, setDatabasePacks] = useState<any[]>([]);
    const [inputDatabasePackName, setInputDatabasePackName] = useState("");
    useEffect(() => {
        if (userData?.licenses) {
            const filteredDatabasePacks = userData?.licenses
                .filter(license => !license.is_inactive) // is_inactive = false のみを対象
                .map(license => ({
                    id: license.role.database_pack.id,
                    name: license.role.database_pack.name,
                }));

            // id の重複を Set で除去し、再度配列に戻す
            const uniqueDatabasePacks = Array.from(new Map(
                filteredDatabasePacks.map(pack => [pack.id, pack]) // id をキーにした Map を生成
            ).values());

            setDatabasePacks(uniqueDatabasePacks);
            setInputDatabasePackId(getCurrentDefaultDatabasePackId() ?? uniqueDatabasePacks[0].id);
            setInputDatabasePackName(getValueByKey(databasePacks, 'id', Number(inputDatabasePackId), 'name'));
        }
    }, [userData]);

    useEffect(() => {
        if (getCurrentDefaultDatabasePackId()) {
            setInputDatabasePackId(getCurrentDefaultDatabasePackId())
        }
    }, [getCurrentDefaultDatabasePackId()])

    const handleInputChangeSelect = (event: React.ChangeEvent<HTMLSelectElement>, key: string) => {
        switch (key) {
            case 'database_pack_id':
                setInputDatabasePackId(Number(event.target.value));
                setInputDatabasePackName(getValueByKey(databasePacks, 'id', Number(event.target.value), 'name'));
                break;
        }
    };

    // 検索テキスト
    const [inputSearchText, setInputSearchText] = useState<string>("");

    //　ケーススタディ名、ケーススタディ情報全体検索ラジオボタン 
    const [inputFulltextSearch, setInputFulltextSearch] = useState<boolean>(false);
    const [inputFulltextSearchRadio, setInputFulltextSearchRadio] = useState<string>("1");

    // 自分の更新したケーススタディのみ検索
    const [inputCurrentUserUpdatedCheck, setInputCurrentUserUpdatedCheck] = useState<boolean>(false);

    // 作成日
    const [inputIsCreatedDateCheck, setInputIsCreatedDateCheck] = useState<boolean>(false);
    const [inputCreatedDateFrom, setInputCreatedDateFrom] = useState<string | null>(null);
    const [inputCreatedDateTo, setInputCreatedDateTo] = useState<string | null>(null);

    // 更新日
    const [inputIsUpdatedDateCheck, setInputIsUpdatedDateCheck] = useState<boolean>(false);
    const [inputUpdatedDateFrom, setInputUpdatedDateFrom] = useState<string | null>(null);
    const [inputUpdatedDateTo, setInputUpdatedDateTo] = useState<string | null>(null);



    const handleClickExport = async () => {
        closeModal();
    };

    const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>, key: string) => {
        switch (key) {
            case "search_text":
                setInputSearchText(event.target.value);
                break;
            case "is_fulltext_search":
                if (event.target.value === "1") {
                    setInputFulltextSearch(false);
                    setInputFulltextSearchRadio("1");
                }
                if (event.target.value === "2") {
                    setInputFulltextSearch(true);
                    setInputFulltextSearchRadio("2");
                }
                break;
            case "is_default_language":
                // 処理追加
                break;
            case "is_current_user_updated":
                setInputCurrentUserUpdatedCheck(event.target.checked);
                break;
            case "product_type":
                setInputCurrentUserUpdatedCheck(event.target.checked);
                break;
            case "is_created_date":
                setInputIsCreatedDateCheck(event.target.checked);
                break;
            case "created_date_from":
                setInputCreatedDateFrom(event.target.value);
                break;
            case "created_date_to":
                setInputCreatedDateTo(event.target.value);
                break;
            case "is_updated_date":
                setInputIsUpdatedDateCheck(event.target.checked);
                break;
            case "updated_date_from":
                setInputUpdatedDateFrom(event.target.value);
                break;
            case "updated_date_to":
                setInputUpdatedDateTo(event.target.value);
                break;
        }
    };

    // ページング
    const [page, setPage] = useState<number>(1)
    const [pageSize, setPageSize] = useState<number>(100)

    // テーブル表示用
    const [tableData, setTableData] = useState<any | null>(null);
    const [tableDataLoading, setTableDataLoading] = useState<boolean>(false);

    // ケーススタディ検索APIのカスタムフック
    const { searchCaseStudies, searchCaseStudiesData, searchCaseStudiesLoading, searchCaseStudiesError } = useSearchCaseStudies();

    // ボタン連打制御
    const [isDisabled, setIsDisabled] = useState(false);
    useEffect(() => {
        if (tableDataLoading) {
            setIsDisabled(true);
        } else {
            setIsDisabled(false);
        }
    }, [tableDataLoading])

    // ケーススタディ検索
    const handleSearchButtonClick = async () => {
        if(page !== 1){
            setPage(1)
        } else {
            search();
        }
    };

    // 検索
    const search = async() =>{
        if (isDisabled) return;
        setTableDataLoading(true);
        // console.log("searchResults",inputSearchText);
        const formatDate = (date: Date, isEndOfDay: boolean = false) => {
            if (isEndOfDay) {
                date.setHours(23, 59, 59, 999); // 23:59:59.999 に設定
            } else {
                date.setHours(0, 0, 0, 0); // 00:00:00.000 に設定
            }

            const year = date.getFullYear();
            const month = String(date.getMonth() + 1).padStart(2, '0'); // 月は0から始まるため +1
            const day = String(date.getDate()).padStart(2, '0');
            const hours = String(date.getHours()).padStart(2, '0');
            const minutes = String(date.getMinutes()).padStart(2, '0');
            const seconds = String(date.getSeconds()).padStart(2, '0');

            return `${year}-${month}-${day}T${hours}:${minutes}:${seconds}`;
        };

        const formattedCreatedDateFrom = inputCreatedDateFrom
            ? formatDate(new Date(inputCreatedDateFrom))
            : null;

        const formattedCreatedDateTo = inputCreatedDateTo
            ? formatDate(new Date(inputCreatedDateTo), true)
            : null;

        const formattedUpdatedDateFrom = inputUpdatedDateFrom
            ? formatDate(new Date(inputUpdatedDateFrom))
            : null;

        const formattedUpdatedDateTo = inputUpdatedDateTo
            ? formatDate(new Date(inputUpdatedDateTo), true)
            : null;

        try {
            const searchResults = await searchCaseStudies(
                inputDatabasePackId,
                inputSearchText,
                page,
                pageSize,
                inputFulltextSearch,
                inputCurrentUserUpdatedCheck,
                inputIsCreatedDateCheck,
                formattedCreatedDateFrom,
                formattedCreatedDateTo,
                inputIsUpdatedDateCheck,
                formattedUpdatedDateFrom,
                formattedUpdatedDateTo
            );
            await flattenedData(searchResults);
        } catch (error) {
            console.log(error);
        } finally {
            setTableDataLoading(false);
        }
    }

    // API呼び出し
    useEffect(() => {
        if (inputDatabasePackId && isModalOpen) {
            search();
        }
    }, [page])

    // 表示切替時はページをリセット
    useEffect(() => {
        if (inputDatabasePackId && page === 1 && isModalOpen) {
            search();
        }
        setPage(1)
    }, [pageSize])


    const flattenedData = async (jsonData: any) => {
        if (jsonData === null) {
            return;
        }
        if (jsonData === undefined) {
            return;
        }

        const flatData = jsonData.results.flatMap((item: any) => {
            return [{
                id: item.id,
                name: item.name,
                conduct_date_at: item.conduct_date_at,
                conductor_name: item.conductor_name,
                updated_at: item.updated_at,
                goal: item.goal,
                functional_unit: item.functional_unit,
                summary: item.summary,
                case_study_review_level_ids: item.case_study_review_level_ids,
            }];
        });

        setTableData(flatData);
    };

    useEffect(() => {
        if (searchCaseStudiesData) {
            flattenedData(searchCaseStudiesData);
        }
    }, [searchCaseStudiesData]);

    // 選択された行のIDを追跡
    // const [selectedRows, setSelectedRows] = useState<string[]>([]);
    const [selectedRows, setSelectedRows] = useState<{ [key: number]: boolean }>({});

    const handleRowSelect = (rowId: number, isChecked: boolean) => {
        setSelectedRows(prev => ({ ...prev, [rowId]: isChecked }));
    };


    // テーブルの行選択・解除時（全て）
    const handleSelectAll = (event: React.ChangeEvent<HTMLInputElement>) => {
        if (event.target.checked) {
            // すべて選択：すべてのrowIdをtrueに設定
            const allSelected = tableData.reduce((acc: { [key: number]: boolean }, row: any) => {
                acc[row.id] = true;
                return acc;
            }, {});
            setSelectedRows(allSelected);
        } else {
            // すべて解除：空のオブジェクトをセット
            setSelectedRows({});
        }
    };

    const columnHelper = createColumnHelper<any>();
    const columns = [
        {
            id: 'checkbox',
            header: () => (
                <CheckBoxInput
                    type="checkbox"
                    onChange={handleSelectAll}
                    checked={Object.keys(selectedRows).length === tableData?.length && tableData?.length > 0}
                />
            ),
            cell: (info: { row: { original: any } }) => {
                const rowId = info.row.original.id;
                console.log('Row ID:', rowId, 'Checked:', selectedRows[rowId]);
                return (
                    <CheckBoxInput
                        type="checkbox"
                        // checked={selectedRows.includes(rowId)}
                        // checked={selectedRows[rowId] || false}
                        checked={selectedRows.hasOwnProperty(rowId) ? selectedRows[rowId] : false}
                        // onChange={() => handleRowSelect(info.row.original.id)}
                        onChange={(e) => handleRowSelect(rowId, e.target.checked)}
                    />
                );
            },
            // }),
        },
        columnHelper.accessor('name', {
            header: () => '名前',
            cell: info => info.renderValue(),
        }),
        columnHelper.accessor('conduct_date_at', {
            header: () => '実施日',
            cell: info => info.renderValue(),
        }),
        columnHelper.accessor('conductor_name', {
            header: () => '実施者',
            cell: info => info.renderValue(),
        }),
        columnHelper.accessor('updated_at', {
            header: () => '最終更新日時',
            cell: info => formatToLocalTime(String(info.renderValue())),
        }),
        columnHelper.accessor('goal', {
            header: () => '目的',
            cell: info => info.renderValue(),
        }),
        columnHelper.accessor('functional_unit', {
            header: () => '機能単位',
            cell: info => info.renderValue(),
        }),
        columnHelper.accessor('summary', {
            header: () => '備考',
            cell: info => info.renderValue(),
        }),
        columnHelper.accessor('case_study_review_level_ids', {
            header: () => 'レビューレベル',
            cell: info => info.renderValue(),
        }),
    ];

    // 選択された行の数を計算するための変数
    // const selectedCount = selectedRows.length;
    const selectedCount = Object.values(selectedRows).filter(Boolean).length;
    const handleDeselectAll = () => {
        setSelectedRows([]); // すべての選択を解除
        setSelectedRows({}); // 全選択解除
    };

    return (
        <>
            <LinkMenu onClick={openModal}>
                [SystemTest]{t('エクスポート')}
            </LinkMenu>
            <Modal isOpen={isModalOpen} onClose={closeModal}>
                <Inner>
                    <Title>{t('出力条件')}</Title>
                    <SectionBody>
                        <SectionContent>
                            <CheckBoxLabel>
                                <CheckBoxInput
                                    type="checkbox"
                                // {...register("is_lcia_result_flow")}
                                />
                                <CheckBoxText>{t('内部情報を除外してエクスポート(プライベートコメント・拡張情報を除外します)')}</CheckBoxText>
                            </CheckBoxLabel>
                        </SectionContent>
                        <SectionContent>
                            <CheckBoxLabel>
                                <CheckBoxInput
                                    type="checkbox"
                                // {...register("is_lcia_result_flow")}
                                />
                                <CheckBoxText>{t('参照プロセスリストからデータセットのマスタとなるデータを除外する')}</CheckBoxText>
                            </CheckBoxLabel>
                        </SectionContent>
                    </SectionBody>
                    <CaseStudySearch.Provider value={{ inputDatabasePackId, inputDatabasePackName }}></CaseStudySearch.Provider>
                    <Title>{t('検索条件')}</Title>
                    <SectionBody>
                        <SectionContent>
                            <InputPrimary
                                // type="search" placeholder={t('キーワードを入力')} {...register("search_text")}
                                type="search" value={inputSearchText ?? ""} placeholder={t('キーワードを入力')} onChange={(event) => handleInputChange(event, "search_text")}
                            />
                            {/* <StyledButton type='submit' >{t('検索')}</StyledButton> */}
                            <StyledButton type='button' onClick={handleSearchButtonClick} disabled={isDisabled}>{t('検索')}</StyledButton>
                        </SectionContent>
                        <SectionContent>
                            <Radio>
                                <RadioLabel>
                                    <RadioInput
                                        type="radio"
                                        name="radio1"
                                        id="1"
                                        value="1"
                                        checked={inputFulltextSearchRadio === "1"}
                                        onChange={(event) => handleInputChange(event, "is_fulltext_search")}
                                    />
                                    <RadioText>{t('ケーススタディ名で検索')}</RadioText>
                                </RadioLabel>
                                <RadioLabel>
                                    <RadioInput
                                        type="radio"
                                        name="radio2"
                                        id="2"
                                        value="2"
                                        checked={inputFulltextSearchRadio === "2"}
                                        onChange={(event) => handleInputChange(event, "is_fulltext_search")}
                                    />
                                    <RadioText>{t('ケーススタディに含まれる文言で検索')}</RadioText>
                                </RadioLabel>
                            </Radio>
                        </SectionContent>
                        {/* </SectionBody> */}
                        {/* <SectionBody> */}
                        <SectionAcordionHeader onClick={onIsClickcollapseOpen}>
                            <AddIcon>
                                <AddIconImage open={isCollapseOpen} />
                            </AddIcon>
                            <SectionAcordionTitle>追加情報</SectionAcordionTitle>
                        </SectionAcordionHeader>
                        <SectionAcordion open={isCollapseOpen}>
                            <SectionTab>
                                <SectionTabContent>
                                    <SectionColumn>
                                        <SectionColumnTitle>
                                            <CheckBox>
                                                <CheckBoxLabel>
                                                    {/* <CheckBoxInput type="checkbox" /> */}
                                                    <CheckBoxText>{t('データベースパック')}</CheckBoxText>
                                                </CheckBoxLabel>
                                            </CheckBox>

                                        </SectionColumnTitle>

                                        <SectionColumnContent>
                                            <SelectPrimary>
                                                <SelectInner
                                                    name="datadatabase-packset"
                                                    id="database-pack-select"
                                                    onChange={(event) => handleInputChangeSelect(event, 'database_pack_id')}
                                                    value={inputDatabasePackId ?? ''}
                                                >
                                                    {databasePacks.map((database_pack) => (
                                                        <option key={database_pack.id} value={database_pack.id}>
                                                            {database_pack.name}
                                                        </option>
                                                    ))}
                                                </SelectInner>

                                            </SelectPrimary>

                                            {/* <SectionColumnContent>  */}
                                            <SectionColumnTitle>
                                                <CheckBox>
                                                    <CheckBoxLabel>
                                                        {/* <CheckBoxInput
                                                        type="checkbox"
                                                        checked={inputCurrentUserUpdatedCheck}
                                                        onChange={(event) => handleInputChange(event, "is_current_user_updated")}
                                                    />
                                                    <CheckBoxText>{t('自分の更新したケーススタディのみ検索')}</CheckBoxText> */}
                                                    </CheckBoxLabel>
                                                </CheckBox>
                                            </SectionColumnTitle>
                                        </SectionColumnContent>

                                        <SectionTabContent>
                                            <SectionColumn>

                                                <SectionColumnTitle>
                                                    <CheckBox>
                                                        <CheckBoxLabel>
                                                            <CheckBoxInput
                                                                type="checkbox"
                                                                checked={inputIsCreatedDateCheck}
                                                                onChange={(event) => handleInputChange(event, "is_created_date")}
                                                            />
                                                            <CheckBoxText>{t('作成日')}</CheckBoxText>
                                                        </CheckBoxLabel>
                                                    </CheckBox>
                                                </SectionColumnTitle>
                                                <SectionColumnContent>

                                                    <DateInput
                                                        // type='date'
                                                        value={inputCreatedDateFrom || ""}
                                                        onChange={(event) => handleInputChange(event, "created_date_from")}
                                                    />
                                                    <DateSpan>〜</DateSpan>
                                                    <DateInput
                                                        // type='date'
                                                        value={inputCreatedDateTo || ""}
                                                        onChange={(event) => handleInputChange(event, "created_date_to")}
                                                    />
                                                </SectionColumnContent>
                                            </SectionColumn>
                                        </SectionTabContent>

                                        <SectionTabContent>
                                            <SectionColumn>

                                                <SectionColumnTitle>
                                                    <CheckBox>
                                                        <CheckBoxLabel>
                                                            <CheckBoxInput
                                                                type="checkbox"
                                                                checked={inputIsUpdatedDateCheck}
                                                                onChange={(event) => handleInputChange(event, "is_updated_date")}
                                                            />
                                                            <CheckBoxText>{t('更新日')}</CheckBoxText>
                                                        </CheckBoxLabel>
                                                    </CheckBox>
                                                </SectionColumnTitle>
                                                <SectionColumnContent>

                                                    <DateInput
                                                        // type='date'
                                                        value={inputUpdatedDateFrom || ""}
                                                        onChange={(event) => handleInputChange(event, "updated_date_from")}
                                                    />
                                                    <DateSpan>〜</DateSpan>
                                                    <DateInput
                                                        // type='date'
                                                        value={inputUpdatedDateTo || ""}
                                                        onChange={(event) => handleInputChange(event, "updated_date_to")}
                                                    />
                                                </SectionColumnContent>
                                            </SectionColumn>
                                        </SectionTabContent>

                                    </SectionColumn>
                                </SectionTabContent>
                            </SectionTab>
                        </SectionAcordion>
                    </SectionBody>

                    <SectionBody>
                        <SectionResult>
                            <SectionResultTitle>{t('検索結果')}</SectionResultTitle>
                            {/* {t('検索結果')} */}
                            {/* <StyledCreateButton onClick={openModal}>
                            {t('エクスポート')}
                        </StyledCreateButton> */}
                            <ButtonCreateSectionTabel>
                                <StyledButtonExport onClick={handleClickExport} >{t('エクスポート')}</StyledButtonExport>
                            </ButtonCreateSectionTabel>
                        </SectionResult>
                        <SectionTable>
                            {searchCaseStudiesError && <div>{searchCaseStudiesError}</div>}

                            {searchCaseStudiesLoading &&
                                <FadeLoader
                                    color="#48bdbb"
                                    height={10}
                                    radius={2}
                                    width={5}
                                />
                            }
                            {tableData && !searchCaseStudiesLoading && (
                                <>
                                    <TablePageNation
                                        count={searchCaseStudiesData?.count || 0}
                                        page={page}
                                        setPage={setPage}
                                        pageSize={pageSize}
                                        setPageSize={setPageSize}
                                    />
                                    {/* <CaseStudyEditDataTable */}
                                    <DataTable
                                        columns={columns}
                                        data={tableData}
                                        count={searchCaseStudiesData?.count || 0}
                                        paging_flag={false}
                                    // setIsModalOpen={setIsModalOpen}
                                    />
                                    <div style={{ marginTop: '1rem', display: 'flex', justifyContent: 'space-between' }}>
                                        <span>選択件数: {selectedCount} 件</span>
                                        <StyledButtonExport onClick={handleDeselectAll} disabled={selectedCount === 0} >{t('チェックをすべて解除する')}</StyledButtonExport>
                                    </div>
                                </>
                            )}
                        </SectionTable>
                    </SectionBody>
                </Inner>
            </Modal>
        </>
    );
}

export default CaseStudyExportModal;

const Title = styled.h2`
    font-size: 18px;
    padding: 0 0 20px;
    margin-bottom: 20px;
    border-bottom: 1px solid var(--color-line-primary);
    font-weight: 500;
    line-height: 1.25;
`
const Inner = styled.div`
    width: 100%;
    padding: 120px 10%;
    margin: 0 auto;
`
const SectionBody = styled.div`
    margin-bottom: 30px;
`
const SectionContent = styled.dd`
    margin-bottom: 32px;
    display: flex;
    align-items: center;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: flex-start;
    gap: 16px;    
`
const SectionTab = styled.div`
    display: flex;
    align-items: flex-start;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: flex-start;
    gap: 32px 54px;
`
const SectionTabContent = styled.div`
    display: block;
    z-index: 5;
    width: 100%;
    order: 2;
    position: relative;    
`
const CheckBox = styled.div`
    display: flex;
    align-items: center;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: flex-start;
    gap: 54px;
`
const CheckBoxLabel = styled.label`
    display: flex;
    align-items: center;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: flex-start;
    gap: 6px;
    cursor: pointer;
  
`
const CheckBoxText = styled.span`
    font-size: 16px;
    font-weight: 500;
`

// const CheckBoxInput = styled.input`
//     margin: 0;
//     padding: 0;
//     border: 0;
//     font-size: 100%;
//     font: inherit;
//     vertical-align: baseline;
//     box-sizing: border-box;
// `
const CheckBoxInput = styled.input`
    accent-color: var(--color-site-secondary);
`
const InputPrimary = styled.input`
    width: min(100%);
    
    padding: 12px;
    color: var(--color-txt-primary);
    border: 1px solid var(--color-line-primary);
    border-radius: 4px;
    background-color: #fff;
    font-size: 16px;
    font-weight: 400;
    line-height: 1.25;
`
const StyledButton = styled.button`
    background-color: var(--color-site-secondary);
    color: #fff;

    padding: 14px 30px;
    border-radius: 4px;
    white-space: nowrap;

    display: inline-flex;
    align-items: center;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: center;
    cursor: pointer;
    white-space: nowrap;
    gap: 0 4px;

    appearance: none;
    border: none;
    font: inherit;
    outline: none;   
  &:hover {
    background-color: var(--color-gray--01);
    color: inherit;
  }
`
const Radio = styled.div`
    display: flex;
    align-items: center;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: flex-start;
    gap: 54px; 
`

const RadioLabel = styled.label`
    order: 1;
    
    display: flex;
    align-items: center;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: flex-start;
    gap: 6px;
    width: fit-content;
    cursor: pointer;
    position: relative;    
`
const RadioInput = styled.input`
    accent-color: var(--color-site-secondary);
`
const RadioText = styled.span`
    display: flex;
    align-items: center;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: flex-start;
    gap: 0 20px;
    font-size: 16px;
    white-space: nowrap;
    font-weight: 500; 
`
const SectionAcordionHeader = styled.summary`
    font-size: 16px;
    font-weight: 500;
    cursor: pointer;
    list-style-type: none;
    display: flex;
    align-items: center;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: flex-start;
    gap: 0 8px;
`
const AddIcon = styled.div`   
    width: 20px;
    height: 20px;
    border: none;
    background-color: #ffffff;
    display: grid;
    place-content: center;
    border-radius: 50%;
    border: 2px solid var(--color-line-primary); 
`
const AddIconImage = styled.span<{ open: boolean }>`
    width: 12px;
    height: 12px;
    background-color: #ffffff;
    position: relative;
    display: block;
    background: var(--color-txt-primary);    
    mask: url(${props => (props.open ? iconSub : iconAdd)}) no-repeat center center / contain;
    -webkit-mask: url(${props => (props.open ? iconSub : iconAdd)}) no-repeat center center / contain;

`
const SectionAcordionTitle = styled.label`
    margin-bottom: 0;
    font-size: 16px;
    font-weight: 500;
    line-height: 1.25;
`
const SectionAcordion = styled.div<{ open: boolean }>`
  max-height: ${props => (props.open ? "100%" : "0")};
  overflow: hidden;
  padding: ${props => (props.open ? "15px" : "0 15px")};
`
const SectionAcordionContent = styled.dd`
    padding-top: 24px;
`
const SectionColumn = styled.dl`
`
const SectionColumnWrap = styled.div`
    margin-bottom: 8px;
    display: grid;
    align-items: center;
    grid-template-columns: 27.0833333333% minmax(0, 1fr);
    gap: 6px 40px;    
`
const SectionColumnTitle = styled.div`
    margin-bottom: 12px;
    font-size: 16px;
    font-weight: 500;
    line-height: 1.25;

`
// const SectionColumnContent = styled.dd`
//     display: flex;
//     align-items: center;
//     flex-direction: row;
//     flex-wrap: nowrap;
//     justify-content: flex-start;
//     gap: 16px;
// `
const SectionColumnContent = styled.div`
    margin-bottom: 32px;
    display: flex;
    align-items: center;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: flex-start;
    gap: 16px;
`
const SelectPrimary = styled.label`
    width: min(100%, 340px);
    position: relative;
    display: block;
    cursor: pointer;
`
const SelectInner = styled.select`
    position: relative;
    width: 100%;
    padding: 12px 32px 12px 12px;
    cursor: pointer !important;
    border: 1px solid var(--color-line-primary);
    border-radius: 4px;
    background-color: #fff;
    font-size: 16px;
    font-weight: 400;
    line-height: 1.25;
`
const SectionContentTerm = styled.dd`
    margin-bottom: 32px;
    display: flex;
    align-items: center;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: flex-start;
    gap: 12px;
`
const SectionTable = styled.section`
    margin-bottom: 32px;
    display: block;
  
`
const ButtonCreateSectionTabel = styled.div`
    margin-top: 40px;
    text-align: right;

    margin: 0;
    padding: 0;
    border: 0;
    font-size: 100%;
    vertical-align: baseline;
    box-sizing: border-box;
    border-radius: 1px;    
`
const SectionResult = styled.div`
    padding: 0 0 20px;
    margin-bottom: 20px;
    border-bottom: 1px solid var(--color-line-primary);
    display: flex;
    justify-content: space-between;
`
const SectionResultTitle = styled.h3`
    font-size: 18px;
    font-weight: 500;
`

const DateSpan = styled.span`
    font-size: 12px;
    white-space: nowrap;
    font-weight: 500;
    line-height: 1.25;
`
const StyledButtonExport = styled.button`
    background-color: var(--color-site-secondary);
    color: #fff;

    padding: 7px 16px;
    border-radius: 4px;

    display: inline-flex;
    align-items: center;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: center;
    cursor: pointer;
    white-space: nowrap;
    gap: 0 4px;

    appearance: none;
    border: none;
    font: inherit;
    outline: none;   
  &:hover {
    background-color: var(--color-gray--01);
    color: inherit;
  }
`