import React from 'react';
import styled from 'styled-components';
import { ButtonProps as MUIButtonProps } from '@mui/material';
import iconCross from '@images/table/icon_cross.svg';

interface CustomButtonProps extends MUIButtonProps {
  onClick: () => void;
}

const ReleaseButton: React.FC<CustomButtonProps> = ({ onClick }) => {
  return (
    <>
        <Button type='button' onClick={onClick}>
            <ButtonInner>
                <ReleaseButtonIcon />
            </ButtonInner>
        </Button>
    </>
  );
};

export default ReleaseButton;

const Button = styled.button`
cursor: pointer;
appearance: none;
background: transparent;
border: none;
border-radius: 0;
font: inherit;
outline: none;
`;
const ButtonInner = styled.div`
`;
const ReleaseButtonIcon = styled.span`
mask: url(${iconCross}) no-repeat center center / contain;
-webkit-mask: url(${iconCross}) no-repeat center center / contain;
display: block;
width: 30px;
height: 30px;
background: var(--color-txt-primary);
`