import SankeyDiagram from '@common/graph/SankeyDiagram';
import LoadingDialog from '@common/modal/LoadingDialog';
import Modal from '@common/modal/Modal';
import SearchSelect from '@common/select/SearchSelect';
import { useProcessContributionAnalyze } from '@hooks/useBackendApi';
import { ProcessViewContext } from '@pages/process/ProcessView';
import { ContributionAnalyze, UpperContributions } from '@typeList/types';
import { getRandomColor } from '@utils/colorUtils';
import React, { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { SingleValue } from 'react-select';
import styled from 'styled-components';

interface ContributionAnalysisProps {
	calculateData: any | null;
	result_type: string;
	selectedProcessId: number | null;
}

interface FlowData {
	from: string;
	to: string;
	flow: number;
	colorFrom: any;
	colorTo: any;
}

interface DatabasePack {
	label: string;
	data: FlowData[];
}

const ProessContributionAnalysisModal: React.FC<ContributionAnalysisProps> = ({ calculateData, selectedProcessId, result_type }) => {
	const { t } = useTranslation();
	const [resultData, setResultData] = useState<any[]>([]);
	useEffect(() => {
		setResultData(calculateData);
	}, [calculateData])
	const { processData, userData } = useContext(ProcessViewContext);
	const [isModalOpen, setIsModalOpen] = useState(false);
	const [selectedExchangeIndex, setSelectedExchangeIndex] = useState(0);
	const [maxPercentage, setMaxPercentage] = useState(10);
	const [sankeyDatabasePacks, setSankeyDatabasePacks] = useState<DatabasePack[]>([]);

	const openModal = () => setIsModalOpen(true);
	const closeModal = () => setIsModalOpen(false);

	// const handleExchangeChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
	// 	setSelectedExchangeIndex(Number(event.target.value));
	// };

	const handleExchangeChange = (newValue: SingleValue<any> | null) => {
		if (newValue && result_type === "lci") {
			setSelectedExchangeIndex(exchangeNames.findIndex(option => option.value === newValue.value));
		} 

		if (newValue && result_type === "lcia") {
			setSelectedExchangeIndex(lciaModelNames.findIndex(option => option.value === newValue.value));
		} 
	};

	const handleMaxPercentageChange = (event: React.ChangeEvent<HTMLInputElement>) => {
		const value = Math.min(Math.max(Number(event.target.value), 1), 100); // Ensure value is between 0 and 100
		setMaxPercentage(value);
	};

	const {
		excuteProcessContributionAnalyze,
		contributionAnalyzeData,
		contributionAnalyzeLoading,
		contributionAnalyzeError
	} = useProcessContributionAnalyze();

	const [analyzeData, setAnalyzeData] = useState<ContributionAnalyze[]>([]);

	// 分析ボタン
	const hundleClickAnalys = async () => {
		if (!resultData || resultData.length === 0 || !isModalOpen) {
			return;
		}

		setDaialogMessage('')
		openLoadingDaialog();

		try {
			// LCI インベントリ計算
			if (result_type === "lci") {
				const selectedExchange = resultData?.[0]?.result_items?.[selectedExchangeIndex]?.exchange;
				if (selectedExchange) {
					excuteProcessContributionAnalyze(processData?.id || 0, {
						result_key: {
							result_type: result_type,
							direction: resultData?.[0]?.result_items?.[selectedExchangeIndex]?.direction || "in",
							exchange_id: selectedExchange.id
						},
						ratio_border: maxPercentage / 100
					});
				}
			}

			// LCIA　特性化　被害評価　統合化
			if (result_type === "lcia") {
				const selectedLciaModel = resultData?.[0]?.result_items?.[selectedExchangeIndex]?.lcia_model;
				if (selectedLciaModel) {
					excuteProcessContributionAnalyze(processData?.id || 0, {
						result_key: {
							result_type: result_type,
							lcia_model_id: selectedLciaModel.id
						},
						ratio_border: maxPercentage / 100
					});
				}
			}
			setDaialogMessage(t('計算が完了しました'))
		} catch (error) {
			console.log(error);
		}
	};

	useEffect(() => {
		if (contributionAnalyzeData && contributionAnalyzeData.length > 0) {
			if (result_type === "lci") {
				const selectAnalyzeData = contributionAnalyzeData.filter(data => data.process_output_id === calculateData[0].process_output.id)
				setAnalyzeData(selectAnalyzeData);

			} else {
				setAnalyzeData(contributionAnalyzeData);
			}
		}
	}, [contributionAnalyzeData]);

	useEffect(() => {
		if (analyzeData.length > 0) {
			const sankeyData = analyzeData.map(data => ({
				label: data.process_output_id.toString(),
				data: traverseContributions(data.upper_contributions, data.process_output_exchange_name),
			}));
			setSankeyDatabasePacks(sankeyData);
		} else {
			setSankeyDatabasePacks([]);
		}
	}, [analyzeData]);

	const traverseContributions = (contributions: UpperContributions[], parentName: string | null = null): FlowData[] => {
		const results: FlowData[] = [];
		for (const contribution of contributions) {
			if (parentName) {
				results.push({
					from: parentName,
					to: contribution.name,
					flow: contribution.ratio * contribution.amount, // Calculate flow based on ratio and amount
					colorFrom: getRandomColor(), // ランダムなカラーを生成
					colorTo: getRandomColor(),
				});
			}

			// Recursively process nested upper_contributions
			if (contribution.upper_contributions.length > 0) {
				results.push(...traverseContributions(contribution.upper_contributions, contribution.name));
			}
		}

		return results;
	};


	// プルダウンLCI
	const [exchangeNames, setExchangeNames] = useState<any[]>([])
	useEffect(() => {
		if (resultData && isModalOpen && result_type === "lci") {
			const names = resultData?.[0]?.result_items?.map((item: any, index: number) => {
				const category2 = item.exchange.category2_name ? `/${item.exchange.category2_name}` : "";
				const category3 = item.exchange.category3_name ? `/${item.exchange.category3_name}` : "";
				return {
					label: `${item.exchange.name}${category2}${category3}`,
					value: item.exchange.id,
				};
			}) || [];

			setExchangeNames(names)
		}
	}, [resultData, isModalOpen])

	// プルダウンLCIA
	const [lciaModelNames, setLciaModelNames] = useState<any[]>([])
	useEffect(() => {
		if (resultData && isModalOpen && result_type === "lcia") {
			// console.log("lcia resultData", resultData)
			const names = resultData?.[0]?.result_items?.map((item: any, index: number) => ({
				label: `${item.lcia_model?.impact_assessment_method?.name}/${item.lcia_model?.impact_category?.name}/${item.lcia_model?.lcia_indicator?.name}`,
				value: item.lcia_model?.id
			})) || [];
			setLciaModelNames(names)
		}
	}, [resultData, isModalOpen])

	const options = {
		responsive: true,
		plugins: {
			legend: {
				display: false,
			},
			tooltip: {
				enabled: true,
				callbacks: {
					label: (context: any) => {
						const { from, to, flow } = context.raw;
						return `${from} -> ${to}: ${flow}`;
					}
				}
			},
		},
		interaction: {
			mode: 'nearest',
			intersect: true,
		},
		hover: {
			mode: 'nearest',
			intersect: true,
		},
	};

	const [data, setData] = useState<any>(null);
	useEffect(() => {
		// マージされたデータベースパックを作成
		const combinedData = sankeyDatabasePacks.reduce<FlowData[]>((acc, current) => {
			return [...acc, ...current.data];
		}, []);

		// SankeyDiagramに渡すデータをセット
		setData({
			labels: combinedData.map(d => d.from),
			databasePacks: [
				{
					label: '',
					data: combinedData,
					colorMode: 'from',
				},
			],
		});
	}, [sankeyDatabasePacks]);

	// ローディングダイアログ
	const [isLoadingDaialogOpen, setIsLoadingDaialogOpen] = useState(false);
	const openLoadingDaialog = () => setIsLoadingDaialogOpen(true);
	const closeLoadingDaialog = () => setIsLoadingDaialogOpen(false);
	const [daialogMessage, setDaialogMessage] = useState<string>("");
	const daialogLoadingMessage = t('計算しています');

	return (
		<span>
			<Button type='button' onClick={openModal}>
				<ButtonInnerText>
					{userData?.is_admin && result_type === "lcia" &&
						<>[SystemTest]</>
					}
					{t('寄与率分析')}
				</ButtonInnerText>
			</Button>

			<LoadingDialog
				open={isLoadingDaialogOpen}
				onClose={closeLoadingDaialog}
				loading={contributionAnalyzeLoading}
				loading_message={daialogLoadingMessage}
				result_message={daialogMessage}
			/>

			<Modal isOpen={isModalOpen} onClose={closeModal}>
				<Inner>
					<SectionTitle>{t('寄与率分析')}</SectionTitle>
					<Label>
						{result_type === "lci" &&
							// <Select onChange={handleExchangeChange} value={selectedExchangeIndex}>
							// 	{exchangeNames.map((exchange: any, index: any) => (
							// 		<option key={index} value={index} title={exchange.label} >
							// 			{/* {exchange.label} */}
							// 			{exchange.label.length > 50 ? exchange.label.slice(0, 50) + '...' : exchange.label}
							// 		</option>
							// 	))}
							// </Select>
							<SearchSelect
								value={exchangeNames[selectedExchangeIndex] || null} // 選択中の値
								options={exchangeNames} // プルダウンオプション
								onChange={handleExchangeChange} // 値変更時の処理
								placeholder={t('Select an exchange')} // プレースホルダー
								isClearable={false} // 選択をクリアできる
							/>
						}
						{result_type === "lcia" &&
							// <Select onChange={handleExchangeChange} value={selectedExchangeIndex}>
							// 	{lciaModelNames.map((model: any, index: any) => (
							// 		<option key={index} value={index} title={model.label}>
							// 			{/* {model.label} */}
							// 			{model.label.length > 50 ? model.label.slice(0, 50) + '...' : model.label}
							// 		</option>
							// 	))}
							// </Select>
							<SearchSelect
								value={lciaModelNames[selectedExchangeIndex] || null} // 選択中の値
								options={lciaModelNames} // プルダウンオプション
								onChange={handleExchangeChange} // 値変更時の処理
								placeholder={t('Select an LCIA model')} // プレースホルダー
								isClearable={false} // 選択をクリアできる
							/>
						}
					</Label>
					<Dl>
						<Dt>{t('表示する最小の寄与率')}</Dt>
						<Dd>
							<Input type="number" max="100" min="1" value={maxPercentage} onChange={handleMaxPercentageChange} />
							<span>%</span>
						</Dd>
					</Dl>
					<Button onClick={hundleClickAnalys}><ButtonInnerText>{t('分析')}</ButtonInnerText></Button>
					{analyzeData.length > 0 &&
						<SankeyDiagram data={data} options={options} width={100} height={100} />
					}
				</Inner>
			</Modal>
		</span>
	);
};

export default ProessContributionAnalysisModal;

const Inner = styled.div`
	width: 100%;
	padding: 120px 10%;
	margin: 0 auto;
`

const SectionTitle = styled.h2`
	padding: 0 0 20px;
	margin-bottom: 20px;
	border-bottom: 1px solid var(--color-line-primary);
	font-size: 18px;
	font-weight: 500;
	line-height: 1.25;
`

const Label = styled.label`
	width: min(100%, 470px);
	position: relative;
	display: block;
	cursor: pointer;
`
const Select = styled.select`
	color: rgb(85, 85, 85);
	position: relative;
	width: 100%;
	padding: 12px 32px 12px 12px;
	cursor: pointer !important;
	border: 1px solid var(--color-line-primary);
	border-radius: 4px;
	background-color: #fff;
	font-size: 16px;
	font-weight: 400;
	line-height: 1.25;
	font-size: 12px;
`

const Dl = styled.dl`
	display: grid;
	gap: 8px 40px;
	grid-template-columns: 25% 70%;
	margin-top: 10px ;
`

const Dt = styled.dt`
	font-size: 16px;
	font-weight: 500;
	padding: 12px 0;
`

const Dd = styled.dd`
	font-size: 12px;
	font-weight: 500;
`

const Input = styled.input`
	background-color: #fff;
	width: 15%;
	padding: 12px;
	color: var(--color-txt-primary);
	border: 1px solid var(--color-line-primary);
	border-radius: 4px;
	font-size: 16px;
	font-weight: 400;
	line-height: 1.25;
	}
`

const Button = styled.button`
	background-color: var(--color-site-secondary);
	border-radius: 4px;
	border: 1px solid var(--color-site-secondary);
	padding: 7px 16px;

	display: inline-flex;
	align-items: center;
	flex-direction: row;
	flex-wrap: nowrap;
	justify-content: center;
	cursor: pointer;
	white-space: nowrap;
	gap: 0 4px;
	-webkit-appearance: none;
	-moz-appearance: none;
	appearance: none;
	font: inherit;
	outline: none;

	&:hover {
	background-color: #fff;
	text-decoration: none;

	span {
		color: var(--color-site-secondary);
	}
	}
`

const ButtonInnerText = styled.span`
	color: #fff;
	font-size: 13px;
	font-weight: 500;
`