import React from 'react';
import styled from 'styled-components';

import iconDownload from '@images/product_system/icon_download.svg'
import { useTranslation } from 'react-i18next';

interface ContextMenuProps {
  x: number;
  y: number;
  visible: boolean;
  items: { label: string; onClick: () => void }[];
  onClose: () => void;
}

export interface ContextMenuState {
  x: number;
  y: number;
  visible: boolean;
  items: { label: string; onClick: () => void }[];
}

const DownloadContextMenu: React.FC<ContextMenuProps> = ({ x, y, visible, items, onClose }) => {
  const {t} = useTranslation();
  if (!visible) return null;

  const handleClick = (event: React.MouseEvent, onClick: () => void) => {
    event.stopPropagation();
    onClick();
    onClose();
  };

  return (
    <MenuContainer x={x} y={y} onClick={(e) => e.stopPropagation()} className="context-menu">
      <Dl>
        <Dt>{t('ダウンロード')}</Dt>
        {items.map((item, index) => (
          <Dd key={index} >
            <DownloadLink onClick={(e) => handleClick(e, item.onClick)}>
              <DownloadText>{item.label}</DownloadText>
              <DownloadDesc>{t('高品質の画像を保存できます')}</DownloadDesc>
              <DownloadIconDiv>
                <DownloadIcon></DownloadIcon>
              </DownloadIconDiv>
            </DownloadLink>
          </Dd>
        ))}
      </Dl>
    </MenuContainer>
  );
};

export default DownloadContextMenu;

const MenuContainer = styled.dl<{ x: number; y: number }>`
  position: absolute;
  right: 34px;
  top: 250px;
  background-color: white;
  border: 1px solid #ccc;
  z-index: 1000;

  border: 2px solid var(--color-line-primary);
  border-radius: 4px;
  padding: 1px;
`;

const Dl = styled.dl`
  list-style: none;
  padding: 0;
  margin: 0;
`;

const Dt = styled.dt`
font-size: 12px;
line-height: 1;
font-weight: 500;
padding: 12px;
border-bottom: 2px solid var(--color-line-primary);
`;

const Dd = styled.dt`
border-bottom: 2px solid var(--color-line-primary);
`;

const DownloadText = styled.p`
font-size: 12px;
font-weight: 500;
line-height: 1;
margin-bottom: 8px;
grid-column: 1 / 2;
grid-row: 1 / 2;
`;

const DownloadDesc = styled.p`
font-size: 10px;
font-weight: 500;
line-height: 1;
color: var(--color-txt-placeholder);
grid-column: 1 / 2;
grid-row: 2 / 3;
`;

const DownloadIconDiv = styled.div`
grid-column: 2 / 3;
grid-row: 1 / 3;
align-self: center;
justify-self: end;
`;

const DownloadIcon = styled.span`
mask: url(${iconDownload}) no-repeat center center / contain;
-webkit-mask: url(${iconDownload}) no-repeat center center / contain;
display: block;
width: 100%;
height: 100%;
width: 16px;
height: 16px;
background: var(--color-txt-primary);
`;

const DownloadLink= styled.a`
padding: 12px 8px 12px 12px;
display: grid;
gap: 8px 30px;
text-decoration: none !important;
`;