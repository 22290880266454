import React, { useContext, useEffect, useState } from 'react';
import styled from 'styled-components';
import Modal from '@common/modal/Modal';
import SubsystemDivisionEditTable from '@common/table/SubsystemDivisionEditTable';
import { SubsystemCategories, SubsystemCategorySet } from '@typeList/types';
import AddButton from '@common/button/AddButton'
import { CaseStudyViewContext } from '@pages/case_study/CaseStudyView';
import { useTranslation } from 'react-i18next';
import ReloadButton from '@common/button/ReloadButton';
import SubsystemDivisionCreateTable from '@common/table/SubsystemDivisionCerateTable';
import { useCopySubsystemCategorySet, useCreateSubsystemCategorySet, useUpdateSubsystemCategorySet } from '@hooks/useBackendApi';
import AlertDialogDisable from '@common/modal/AlertDialogDisable';
import Select, { SingleValue, ActionMeta } from 'react-select';

interface CustomTreeNode {
    key: string;
    title: string;
    children: CustomTreeNode[];
}

interface OptionType {
    value: number;
    label: string;
  }

/**
 * 068 サブシステムカテゴリセット作成
 * @returns 
 */
const SubsystemDivisionCreateModal = () => {
    const { t } = useTranslation();
    const context = useContext(CaseStudyViewContext);
    const { 
        caseStudyData,
        categorySetRows,
        setCategorySetRows,
        resetCaseStudyData,
        subsystemCategorySetData
    } = context;

    const [inputCategoryValueId, setInputCategoryValueId] = useState<number | null>(null);
    
    // カテゴリセットID
    const [inputCategorySetId, setInputCategorySetId] = useState<number | null>(null);
    
    // カテゴリセット名
    const [inputCategorySetName, setInputCategorySetName] = useState<string | null>(null);
    // カテゴリー
    const [inputsubsystemCategories, setInputSubsystemCategories] = useState<SubsystemCategories[]>([]);

    const handleInputCategorySetNameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setInputCategorySetName(event.target.value);
    }

    const handleSelectCategoryValue = (categoryValueId: number, categoryValueName: string) => {
        setInputCategoryValueId(categoryValueId);
        // onSelectCategoryValue(categoryValueId, categoryValueName);
        closeModal();
    };

    const [isModalOpen, setIsModalOpen] = useState(false);
    const openModal = () => setIsModalOpen(true);
    const closeModal = () => setIsModalOpen(false);

    const [inputCategorySetNameError, setInputCategorySetNameError] = useState<any>(null);
    const [inputCategoryNameError, setInputCategoryNameError] = useState<any>(null);

    const validateCheck = (): boolean => {
        setInputCategorySetNameError(null);
        if (!inputCategorySetName) {
            setInputCategorySetNameError(t('カテゴリセット名は必須です'));
            return false;
        }
        return true;
    }

    // ボタン連打制御
    const [isDisabled, setIsDisabled] = useState(false);
    useEffect(() =>{
        if(isModalOpen){
            setIsDisabled(false)
        }
    },[isModalOpen])


    // 更新用
    const { updateSubsystemCategorySet } = useUpdateSubsystemCategorySet();
    const handleUpdate = async (tableData: any[]) => {
        // rowIdに対応するデータを見つける
        const updatedData = subsystemCategorySetData?.filter(item => item.id === inputCategorySetId).map((item) => {
            return {
                ...item,
                name: inputCategorySetName,
                case_study_id: caseStudyData?.id,
                subsystem_categories: tableData // ここでsubsystem_categoriesを更新
            };
        });

        if (!validateCheck()) {
            return
        }

        setInputCategoryNameError(null)
        const invalidEntries = tableData.filter(item => !item.name || item.name.trim() === "");
        if (invalidEntries.length > 0) {
            setInputCategoryNameError(t('カテゴリ名が空欄のデータがあります。'));
            return;
        }
        
        if (updatedData && updatedData.length > 0) {
           
            try {
                // rowIdと一致するデータのみをサーバーに送信
                await updateSubsystemCategorySet(caseStudyData?.id || 0, updatedData[0])
                setIsModalOpen(false)
                await resetCaseStudyData();
            } catch (error) {
                console.log(error);
            }
        }
    };

    const {copySubsystemCategorySet, copySubsystemCategorySetData, copySubsystemCategorySetLoading} = useCopySubsystemCategorySet()
    
    // 選択中のコピー元
    const [selectedOption, setSelectedOption] = useState<number>(0);
    useEffect(() => {
        if(subsystemCategorySetData && subsystemCategorySetData.length > 0){
            setSelectedOption(subsystemCategorySetData.filter((row) => (row.scope === "system"))[0].id)
        }
    },[subsystemCategorySetData])

    // select　変更時
    const handleSelectChange = (event: React.ChangeEvent<HTMLSelectElement> ) => {
        const value = Number(event.target.value);
        setSelectedOption(value);
    };

    const [alertDialogOpen, setAlertDialogOpen] = useState(false);
    const openalertDialog = () => setAlertDialogOpen(true);

    const handleAlertDialogClose = async() =>{
		setAlertDialogOpen(false)
	}

    // OK
	const handleUnitProcessConfirm = async () => {
        if(!caseStudyData?.id){
            return
        }
        try {
            const response = await copySubsystemCategorySet(caseStudyData?.id, selectedOption);
            setAlertDialogOpen(false);

            setInputCategorySetId(response?.id ?? 0)
            setInputCategorySetName(response?.name ?? '');
            setInputSubsystemCategories(response?.subsystem_categories ?? []);

            // 編集画面
            openModal();
            await resetCaseStudyData();
        } catch (error) {
            console.log(error);
        }
	};

	const AlertDialogTitele = t("サブシステムカテゴリ追加")

	const AlertDialogMessage = (): JSX.Element => {
		return (
            <>
                <StyledDiv>
                    {t('このケーススタディのみ有効なユーザ定義のサブシステムカテゴリセットを作成します。元となるサブシステムカテゴリセットを選択してください')}
                </StyledDiv>
                <HorizontalContainer>
                    <StyledSpan>{t('コピー元')}</StyledSpan>
                    <StyledSelect
                        value={selectedOption}
                        onChange={(e) => { handleSelectChange(e) }}
                    >
                        {subsystemCategorySetData?.filter((row) => (row.scope === "system")).map((row, index)  => (
                            <option value={row.id} key={`${row.id}-${index}`}>
                                {row.name}
                            </option>
                        ))}

                    </StyledSelect>
                </HorizontalContainer>
            </>
		);
	};

    return (
        <>
            <ButtonWrap>
                {/* <AddButton type='button' onClick={openModal} text={t('サブシステムカテゴリを追加')} /> */}
                <AddButton type='button' onClick={openalertDialog} text={t('サブシステムカテゴリを追加')} />
            </ButtonWrap>
            {/* <Button type='button' onClick={openModal} key={`category-value-select-${rowId}`}><ButtonText>変更</ButtonText></Button> */}

            <AlertDialogDisable
                open={alertDialogOpen}
                onClose={handleAlertDialogClose}
                onConfirm={handleUnitProcessConfirm}
                title={AlertDialogTitele}
                message={AlertDialogMessage()}
                disableConfirmButton={copySubsystemCategorySetLoading} // OKボタンの無効化をフラグで制御
            />

            {isModalOpen && (
                <Modal isOpen={isModalOpen} onClose={closeModal}>
                    <Inner>
                        <Span>{t('カテゴリセット名')}<SectionRequired>※</SectionRequired></Span>
                        <Input 
                            type="text"
                            value={inputCategorySetName ?? ""}
                            onChange={handleInputCategorySetNameChange}
                        />
                        {inputCategorySetNameError && <ErrorMsg>{inputCategorySetNameError}</ErrorMsg>}
                        {inputCategoryNameError && <ErrorMsg>{inputCategoryNameError}</ErrorMsg>}
                        <SubsystemDivisionEditTable data={inputsubsystemCategories} onUpdate={handleUpdate}/>
                    </Inner>
                </Modal>
            )}
        </>
    );
};

export default SubsystemDivisionCreateModal;

const Inner = styled.div`
    width: 100%;
    padding: 120px 10%;
    margin: 0 auto;
`

const StyledDiv = styled.div`
    margin-bottom: 10px;
`;

const HorizontalContainer = styled.div`
    display: flex; /* 横並びにする */
    align-items: center; /* 垂直方向を中央揃え */
    gap: 12px; /* 子要素間の余白 */
`;

const StyledSpan = styled.span`
    width: 25%;
    font-size: 16px;
    margin-right: 10px;
`;

const StyledSelect = styled.select`
    position: relative;
    width: 100%;
    padding: 12px 32px 12px 12px;
    cursor: pointer !important;
    border: 1px solid var(--color-line-primary);
    border-radius: 4px;
    font-size: 16px;
    font-weight: 400;
    line-height: 1.25;
`

const ButtonWrap = styled.div`
text-align: right;
`

const Input = styled.input`
    padding: 12px;
    color: var(--color-txt-primary);
    border: 1px solid var(--color-line-primary);
    border-radius: 4px;
    background-color: #fff;
    font-size: 16px;
    font-weight: 400;
    line-height: 1.25;
    margin-bottom: 10px;
    appearance: none;
    font: inherit;
    outline: none;
`

const Span = styled.span`
    font-size: 16px;
    margin-right: 10px;
`

const ErrorMsg = styled.span`
    margin-left: 10px;
    font-size: 14px;
    font-weight: 500;
    color: var(--color-error-primary);
    gap: 5px;
`

const SectionRequired = styled.span`
    font-size: 0.7em;
    vertical-align: top;
    position: relative;
    top: 0.1em;
    color: var(--color-red--01);
`