import Modal from '@common/modal/Modal';
import React, { useContext, useState, useMemo, useEffect } from 'react';
import styled from 'styled-components';
import AddButton from '@common/button/AddButton';
import { useTranslation } from 'react-i18next';
import { CaseStudyViewContext } from '@pages/case_study/CaseStudyView';
import { RequiredSpan } from '@styles/Common';

interface SubsystemCreateProps {
    isOpen: boolean;
    onClose: () => void;
    onCreate: (
        name: string,
        sampling_procedure: string,
        criteria_for_excluding_intermediate_flow: string,
        selectedCategoryColor: string,
        selectedCategoryIds: Array<number>,
        publicComment: string,
        privateComment: string,
    ) => void;
}

const SubsystemCreate: React.FC<SubsystemCreateProps> = ({ isOpen, onClose, onCreate }) => {
    const { t } = useTranslation();
    const context = useContext(CaseStudyViewContext);
    const { subsystemCategorySetData, selectCategorySet } = context;

    const [name, setName] = useState('');
    const [samplingProcedure, setSamplingProcedure] = useState('');
    const [criteriaForExcludingIntermediateFlow, setCriteriaForExcludingIntermediateFlow] = useState('');
    const [publicComment, setPublicCommet] = useState('');
    const [privateComment, setPrivateComment] = useState('');
    const [error, setError] = useState(false);


    // 各カテゴリセットの選択中のカテゴリ
    const [selectedCategoryIds, setSelectedCategoryIds] = useState<Array<number>>([]);

    // 選択されたカテゴリIDに基づくサブシステムカテゴリの色を設定
    const selectedCategoryColor = useMemo(() => {
        // selectCategorySetと一致するサブシステムカテゴリセットを取得
        const matchingCategorySet = subsystemCategorySetData?.find(
            (categorySet) => categorySet.id === selectCategorySet
        );
        // matchingCategorySetのsubsystem_categoriesの中で、selectedCategoryIdsのそれぞれの色を取得
        const selectedColors = selectedCategoryIds.map((id, index) => {
            const category = matchingCategorySet?.subsystem_categories.find(cat => cat.id === id);
            return category?.color || 'defaultColor'; // デフォルトのカラーを指定
        });

        // 必要に応じて一つの色を返す（最初の選択中の色、もしくは他のロジックに基づく）
        return selectedColors[0] || 'defaultColor'; // 適宜変更可能

    }, [selectedCategoryIds, subsystemCategorySetData, selectCategorySet]);

    // subsystemCategorySetDataの初期化時に最初のcategory.idを選択する
    useEffect(() => {
        if (subsystemCategorySetData && subsystemCategorySetData.length > 0) {
            // subsystemCategorySetDataの各categorySetから最初のsubsystem_categoriesのIDを取得
            const initialIds = subsystemCategorySetData
                .map((categorySet) => categorySet.subsystem_categories[0]?.id)
                .filter((id): id is number => id !== undefined); // undefinedを除外
            if (initialIds.length > 0) {
                setSelectedCategoryIds(initialIds);
                // setSelectedCategoryId(initialIds[0]);
            }
        }
    }, [subsystemCategorySetData]);

    const handleCategoryChange = (categoryId: number, index: number) => {
        // 指定されたindexの配列の値を書き換える
        setSelectedCategoryIds((prevIds) => {
            const newIds = [...prevIds]; // 現在の配列をコピー
            newIds[index] = categoryId; // 指定されたインデックスの値を更新
            return newIds;
        });
    };

    const handleCreate = () => {
        //nameが空の場合はエラーにする
        if(!name){
            setError(true)
            return
        }

        onCreate(name, 
            samplingProcedure, 
            criteriaForExcludingIntermediateFlow, 
            selectedCategoryColor, 
            selectedCategoryIds,
            publicComment,
            privateComment
        );
        setName('');
        setSamplingProcedure('');
        setCriteriaForExcludingIntermediateFlow('');
        // setSelectedCategoryId(undefined);
        setPublicCommet('')
        setPrivateComment('')
        
    };

    return (
        <Modal isOpen={isOpen} onClose={onClose}>
            <Inner>
                <Title>{t('サブシステム新規作成')}</Title>
                <SectionBody>
                    <SectionColumn>
                        <SectionColumnWrap>
                            <SectionColumnWrapTitle>{t('名前')}<RequiredSpan>※</RequiredSpan></SectionColumnWrapTitle>
                            <InputPrimary
                                type="text"
                                value={name}
                                onChange={(e) => setName(e.target.value)}
                                placeholder="名前を入力"
                            />
                             <SectionColumnWrapTitle></SectionColumnWrapTitle>
                             {error && <ErrorMsg>{t('名前は必須です')}</ErrorMsg>}
                        </SectionColumnWrap>
                        <SectionColumnWrapForCategory>
                        <SectionColumnWrapTitle>{t('サブシステムカテゴリ')}</SectionColumnWrapTitle>
                            <SectionColumnWrapContent>
                                {subsystemCategorySetData?.map((categorySet, index) => (
                                    <React.Fragment key={categorySet.id || index}>
                                        <Input
                                            type="text"
                                            value={categorySet.name || ''}
                                            disabled
                                        />
                                        <SelectLabel>
                                            <Select
                                                value={selectedCategoryIds[index] || ''}
                                                onChange={(e) => handleCategoryChange(Number(e.target.value), index)}
                                            >
                                                {/* <option value="" disabled>カテゴリを選択</option> */}
                                                {categorySet.subsystem_categories.map(category => (
                                                    <option key={category.id} value={category.id}>
                                                        {category.name}
                                                    </option>
                                                ))}
                                            </Select>
                                        </SelectLabel>
                                    </React.Fragment>
                                ))}
                            </SectionColumnWrapContent>

                        </SectionColumnWrapForCategory>
                        <SectionColumnWrap>
                            <SectionColumnWrapTitle>{t('収集方法')}</SectionColumnWrapTitle>
                            <CommentTextArea
                                cols={100}
                                rows={3}
                                value={samplingProcedure || ''}
                                onChange={(event) => setSamplingProcedure(event.target.value)}
                            />
                        </SectionColumnWrap>
                        <SectionColumnWrap>
                            <SectionColumnWrapTitle>{t('カットオフした項目')}</SectionColumnWrapTitle>
                            <CommentTextArea
                                cols={100}
                                rows={3}
                                value={criteriaForExcludingIntermediateFlow || ''}
                                onChange={(event) => setCriteriaForExcludingIntermediateFlow(event.target.value)}
                            />
                        </SectionColumnWrap>
                        <SectionColumnWrap>
                            <SectionColumnWrapTitle>{t('その他（公開コメント）')}</SectionColumnWrapTitle>
                            <CommentTextArea
                                cols={100}
                                rows={3}
                                value={publicComment || ''}
                                onChange={(event) => setPublicCommet(event.target.value)}
                            />
                        </SectionColumnWrap>
                        <SectionColumnWrap>
                            <SectionColumnWrapTitle>{t('その他（プライベートコメント）')}</SectionColumnWrapTitle>
                            <CommentTextArea
                                cols={100}
                                rows={3}
                                value={privateComment || ''}
                                onChange={(event) => setPrivateComment(event.target.value)}
                            />
                        </SectionColumnWrap>
                    </SectionColumn>
                </SectionBody>
                <ButtonReloadSectionTabel>
                    <AddButton onClick={handleCreate} text={t('作成する')} />
                </ButtonReloadSectionTabel>
            </Inner>
        </Modal>
    );
};

export default SubsystemCreate;

const ButtonReloadSectionTabel = styled.div`
    margin-top: 40px;
    text-align: right;
`;

const Inner = styled.div`
    width: 100%;
    padding: 120px 10%;
    margin: 0 auto;
`;

const Title = styled.h2`
    font-size: 16px;
    padding: 0 0 20px;
    margin-bottom: 10px;
    border-bottom: 1px solid var(--color-line-primary);
    font-weight: 500;    
`;

const SectionBody = styled.div`
    margin-bottom: 60px;
`;

const SectionColumn = styled.dl`
`;

const SectionColumnWrap = styled.div`
    margin-bottom: 8px;
    display: grid;
    align-items: center;
    grid-template-columns: 27.0833333333% minmax(0, 1fr);
    gap: 6px 40px;      
`;

const SectionColumnWrapForCategory = styled.div`
    margin-bottom: 8px;
    display: grid;
    // align-items: center;
    grid-template-columns: 27.0833333333% minmax(0, 1fr);
    gap: 6px 40px;      
`;

const SectionColumnWrapTitle = styled.dt`
    margin-bottom: 0;   
    font-size: 16px;
    font-weight: 500;
    line-height: 1.25;
`;

const SectionColumnWrapContent = styled.dd`
    display: grid;
    gap: 8px 12px;
    grid-template-columns: 42.6470588235% 1fr;
    justify-content: flex-start;
    align-items: center;
`;

const InputPrimary = styled.input`
    width: 100%;
    padding: 12px;
    color: var(--color-txt-primary);
    border: 1px solid var(--color-line-primary);
    border-radius: 4px;
    background-color: #fff;
    font-size: 16px;
    font-weight: 400;
    line-height: 1.25;
`;

const Input = styled.input`
    padding: 12px;
    color: var(--color-txt-primary);
    border: 1px solid var(--color-line-primary);
    border-radius: 4px;
    background-color: #fff;
    font-size: 16px;
    font-weight: 400;
    line-height: 1.25;
`;

const SelectLabel = styled.label`
    width: 100%;
    position: relative;
    display: block;
`;

const Select = styled.select`
    position: relative;
    width: 100%;
    padding: 12px 32px 12px 12px;
    cursor: pointer !important;
    color: rgb(85, 85, 85);
    border: 1px solid var(--color-line-primary);
    border-radius: 4px;
    background-color: #fff;
    font-size: 16px;
    font-weight: 400;
    line-height: 1.25;
`

const ErrorMsg = styled.span`
    font-size: 14px;
    font-weight: 500;
    color: var(--color-error-primary);
    display: flex;
    align-items: center;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: flex-start;
    gap: 5px;
`

const CommentTextArea = styled.textarea`
    padding: 12px;
    color: var(--color-txt-primary);
    border: 1px solid var(--color-line-primary);
    border-radius: 4px;
    background-color: #fff;
    font-size: 16px;
    font-weight: 400;
    line-height: 1.25;
    width: 100%;
`