import { useFileUpload, useGetAttachedFileBase64Image, useGetProductById, useGetUnitGroupsCurrency, useGetUserDetail, useUpdateProduct } from '@hooks/useBackendApi';
import { CreateProductIdentifierSchemeValues, UnitConversions, UnitGroupList, UpdateProduct } from '@typeList/types';
import React, { Dispatch, SetStateAction, createContext, useContext, useEffect, useRef, useState } from 'react';
import { useForm, SubmitHandler } from 'react-hook-form';
import UnitConvertingSettingModal from '../unit_converting_setting/UnitConvertingSettingModal';
import UnitSelectModal from '@common/UnitSelectModal';
import { ProductSearchContext } from '../product_search/ProductSearch';
import ProductEditCategrySelect from './ProductEditCategrySelect';
import styled from 'styled-components';
import ModalButton from '@common/button/ModalButton';

import iconAdd from '@images/product_system/icon_add.svg'
import iconSub from '@images/table/icon_arrow_up.svg'
import iconImg from '@images/file/icon_file_upload.svg'
import iconCross from '@images/table/icon_cross.svg';

import { useTranslation } from 'react-i18next';
import { getCurrentUserInfo } from '@services/Cookies';
import { formatToLocalTime, getValueByKey } from '@utils/utils';

// 製品編集コンテキスト
export interface EditProductContext {
    inputDatabasePackId: number | undefined;
    categoryData: Array<any>,
    setCategoryData: Dispatch<SetStateAction<Array<any>>>;
}

interface ProductEditType {
    Id: number;
    onClose: () => void;
}

const defaulutContextvalue: EditProductContext = {
    inputDatabasePackId: 1,
    categoryData: [],
    setCategoryData: () =>{}
}

export const productEditContext = createContext<EditProductContext>(defaulutContextvalue);

/**
 * 016 製品の編集
 * @param param0 
 * @returns 
 */
const ProductEditModal: React.FC<ProductEditType> = ({ Id, onClose }) => {
    const { t } = useTranslation();
    const context = useContext(ProductSearchContext)
    const userId = getCurrentUserInfo()?.id;
    const { userData } = useGetUserDetail(userId);

    // 追加情報アコーディオン
    const [isCollapseOpen, setIsCollapseOpen] = useState(false)
    const onIsClickcollapseOpen = () => setIsCollapseOpen((prev) => !prev);

    // 追加情報アコーディオン
    const [isSystemInfoCollapseOpen, setIsSystemInfoCollapseOpen] = useState(false)
    const onIsClickSystemInfoCollapseOpen = () => setIsSystemInfoCollapseOpen((prev) => !prev);

    // データベースパック
    const [inputDatabasePackName, setInputDatabasePackName] = useState(context.inputDatabasePackName);
    const [inputDatabasePackId, setInputDatabasePackId] = useState<number | undefined>(context.inputDatabasePackId);
    const [databasePacks, setDatabasePacks] = useState<any[]>([]);
    const { getProductByIdData } = useGetProductById(Id);

    useEffect(() => {
        if ((inputDatabasePackId === 0 || !inputDatabasePackId) && getProductByIdData) {
            setInputDatabasePackId(getProductByIdData?.database_pack_id);
        }
        if (inputDatabasePackId && userData?.licenses) {
            const filteredDatabasePacks = userData?.licenses
                .filter(license => !license.is_inactive) // is_inactive = false のみを対象
                .map(license => ({
                    id: license.role.database_pack.id,
                    name: license.role.database_pack.name,
                }));

            // id の重複を Set で除去し、再度配列に戻す
            const uniqueDatabasePacks = Array.from(new Map(
                filteredDatabasePacks.map(pack => [pack.id, pack]) // id をキーにした Map を生成
            ).values());

            setDatabasePacks(uniqueDatabasePacks);
            setInputDatabasePackId(userData.default_database_pack_id || uniqueDatabasePacks[0].id);
        }
    }, [getProductByIdData, userData]);

    useEffect(() => {
        if (inputDatabasePackId) {
            setInputDatabasePackName(getValueByKey(databasePacks, 'id', Number(inputDatabasePackId), 'name'))
        }
    }, [databasePacks, inputDatabasePackId])

    const { UpdateProduct } = useUpdateProduct();

    const { register, handleSubmit, setValue, formState: { errors } } = useForm<UpdateProduct>();

    // 単位
    const [inputUnitId, setInputUnitId] = useState<number>(getProductByIdData?.unit.id ?? 0);
    const [inputUnitName, setInputUnitName] = useState<string>(getProductByIdData?.unit.code ?? '');
    useEffect(() => {
        setInputUnitId(getProductByIdData?.unit.id ?? 0)
        setInputUnitName(getProductByIdData?.unit.code ?? '')
    }, [getProductByIdData])
    // 単位換算をセット
    const [unitConversions, setUnitConversions] = useState<UnitConversions[]>([]);
    const [unitConversionsCode, setUnitConversionsCode] = useState<string>("");
    useEffect(() => {
        if (getProductByIdData) {
            // unit_conversionsを変換してセット
            const transformedUnitConversions = getProductByIdData.unit_conversions.map((conversion) => ({
                locale: conversion.locale,
                public_comment: conversion.public_comment || "", // デフォルトの公開コメント
                private_comment: conversion.private_comment || "", // デフォルトの非公開コメント
                to_unit_id: conversion.to_unit.id, // to_unitのID
                factor: conversion.factor, // factorの値
            }));

            setUnitConversions(transformedUnitConversions);

            // unit_conversionsのコードをカンマ区切りで結合してセット
            const codes = getProductByIdData.unit_conversions
                .map((conversion) => conversion.to_unit.code)
                .join(',');
            setUnitConversionsCode(codes);

            // フォームの値をセット
            setValue('unit_conversions', transformedUnitConversions);
        }
    }, [getProductByIdData, setValue]);


    // カテゴリ
    const [categoryData, setCategoryData] = useState<Array<any>>([]);

    useEffect(() => {
        if (getProductByIdData) {
            setValue('id', getProductByIdData.id);
            setValue('name', getProductByIdData.name);
            setValue('public_comment', getProductByIdData.public_comment || '');
            setValue('private_comment', getProductByIdData.private_comment || '');
            setValue('unit_id', getProductByIdData.unit.id);
            setInputUnitName(getProductByIdData.unit.code);
            setValue('cas_number', getProductByIdData.cas_number);
            setValue('synonyms_text', getProductByIdData.synonyms_text);
            setValue('include_items_text', getProductByIdData.include_items_text);
            setValue('related_items_text', getProductByIdData.related_items_text);
            setValue('unit_price', getProductByIdData.unit_price);
        }
    }, [getProductByIdData, setValue]);

    const [ilcdIdvalue, setIlcdIdvalue] = useState<string>(
        getProductByIdData?.identifier_scheme_values?.find(
            (value) => value.identifier_scheme.id === 1
        )?.value || ''
    );

    useEffect(() => {
        const value = getProductByIdData?.identifier_scheme_values?.find(
            (value) => value.identifier_scheme.id === 1
        )?.value || ''
        setIlcdIdvalue(value);
    }, [getProductByIdData])

    const [ideaCodeValue, setIdeaCodeValue] = useState<string>(
        getProductByIdData?.identifier_scheme_values?.find(
            (value) => value.identifier_scheme.id === 2
        )?.value || ''
    );

    useEffect(() => {
        const value = getProductByIdData?.identifier_scheme_values?.find(
            (value) => value.identifier_scheme.id === 2
        )?.value || ''
        setIdeaCodeValue(value);
    }, [getProductByIdData])

    // ILCD ID体系をしめすID=1、IDEAコード体系をしめすID=2
    const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>, key: string) => {
        if (key === 'ilcd_id_value') {
            setIlcdIdvalue(event.target.value);
        }
        if (key === 'idea_code') {
            setIdeaCodeValue(event.target.value);
        }
    };

    //　画像アップロード
    const { fileUpload, fileUploadData } = useFileUpload();

    const onSubmit: SubmitHandler<UpdateProduct> = async data => {
        // 画像
        let Files = [];
        // 初期データ
        if (getProductByIdData?.product_attached_files && getProductByIdData.product_attached_files.length > 0) {
            // setImageSrc が存在する場合
            if (imageSrc) {
                const productSystemSummaryFile = getProductByIdData.product_attached_files[0];
                if (productSystemSummaryFile) {
                    // Files に product_system_summary のファイルをセット
                    Files.push({
                        id: productSystemSummaryFile.id,
                    });
                }
            }
        }

        if (imageFile) {
            Files = []
            const response = await fileUpload(imageFile);
            Files.push(response.id);
        }

        console.log("Files", Files);
        // ID体系
        let identifierSchemeValues: CreateProductIdentifierSchemeValues[] | null = [];
        if (ilcdIdvalue) {
            // ILCD ID
            identifierSchemeValues.push({ identifier_scheme_id: 1, value: ilcdIdvalue })
        }
        if (ideaCodeValue) {
            // IDEAコード
            identifierSchemeValues.push({ identifier_scheme_id: 2, value: ideaCodeValue })
        }
        if (identifierSchemeValues.length > 0) {
            setValue('identifier_scheme_values', identifierSchemeValues);
        }

        const updateParam = {
            locale: getProductByIdData?.locale,
            name: data.name,
            synonyms_text: data.synonyms_text,
            include_items_text: data.include_items_text,
            related_items_text: data.related_items_text,
            public_comment: data.public_comment,
            private_comment: data.private_comment,
            cas_number: data.cas_number,
            unit_id: inputUnitId,
            unit_price: Number(data.unit_price),
            unit_price_currency_unit_id: selectCurrencyData,
            category_value_ids: categoryData.map(item => item.categoryValueId),
            identifier_scheme_values: identifierSchemeValues,
            unit_conversions: unitConversions,
            attached_file_ids: Files
        }

        // 更新処理
        try {
            await UpdateProduct(data.id, updateParam);
            onClose();
        } catch (error: any) {
            console.log("Update Product Error", error)
        }
    };

    const [previewImage, setPreviewImage] = useState<string | null>(null);
    const [imageSrc, setImageSrc] = useState<string | null>(null);
    const [imageName, setImageName] = useState<string | null>(null);

    const [imageFile, setImageFile] = useState<File>();
    const { getAttachedFileBase64Image, attachedFileBase64ImageData } = useGetAttachedFileBase64Image();
    useEffect(() => {
        const fetchImage = async () => {
            try {
                if (getProductByIdData?.product_attached_files && getProductByIdData.product_attached_files.length > 0) {
                    const technologyImageFile = getProductByIdData.product_attached_files[0];
                    const base64Data = await getAttachedFileBase64Image(technologyImageFile.id || 0);
                    setImageSrc(base64Data?.base64_data);
                    setImageName(base64Data.filename);
                }
            } catch (error) {
                console.error(error);
            }
        };
        fetchImage();
    }, [getProductByIdData]);

    const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const file = event.target.files?.[0];
        if (file) {
            setImageName(file.name);
            const reader = new FileReader();
            reader.onloadend = () => {
                setPreviewImage(reader.result as string);
                setImageFile(file);
            };
            reader.readAsDataURL(file);
        }
    };

    // 画像の削除ハンドラー
    const handleImageDelete = (event: React.MouseEvent<HTMLButtonElement>) => {
        event.preventDefault(); // デフォルト動作を防止
        event.stopPropagation(); // イベントの伝播を防止
        setImageSrc('');
        setPreviewImage('');
        setImageName('');
        setImageFile(undefined);
        // ファイル入力フィールドをリセット
        const fileInput = document.getElementById('fileImg_product_edit') as HTMLInputElement;
        if (fileInput) {
            fileInput.value = ''; // ファイル選択のリセット
        }
    };

    const triggerFileInput = () => {
        document.getElementById('fileImg')?.click();
    };

    // 単位プルダウン
    const { getUnitGroupsCurrency, unitGroupsCurrencyData } = useGetUnitGroupsCurrency();
    const [currencyDataList, setCurrencyDataList] = useState<UnitGroupList | null>(null);
    const [selectCurrencyData, setSelectCurrencyData] = useState<number | null>(null);
    useEffect(() => {
        if (getProductByIdData) {
            setSelectCurrencyData(getProductByIdData.unit_price_currency_unit?.id || null)
        }
    }, [getProductByIdData])

    useEffect(() => {
        if (inputDatabasePackId) {
            try {
                getUnitGroupsCurrency(inputDatabasePackId);
            } catch (error) {
                console.log(error);
            }
        }
    }, [inputDatabasePackId])
    useEffect(() => {
        setCurrencyDataList(unitGroupsCurrencyData);
    }, [unitGroupsCurrencyData])

    const handleInputChangeSelect = (event: React.ChangeEvent<HTMLSelectElement>, key: string) => {
        if (key === 'currency') {
            setSelectCurrencyData(Number(event.target.value));
        }
    };

    return (
        <productEditContext.Provider value={{
            inputDatabasePackId,
            categoryData, 
            setCategoryData
        }}>
            {getProductByIdData &&
                <Inner>
                    <form>
                        <Title>{t('製品の編集')}</Title>
                        <SectionBody>
                            <SectionColumn>
                                <input hidden {...register("id")} />
                                <SectionColumnWrap>
                                    <SectionColumnWrapTitle htmlFor="name">{t('名前')}</SectionColumnWrapTitle>
                                    <SectionColumnContent>
                                        <InputPrimary id="name" {...register("name", { required: t('名前は必須です') })} />
                                        {errors.name && <ErrorMsg>{errors.name.message}</ErrorMsg>}
                                    </SectionColumnContent>
                                </SectionColumnWrap>
                                <SectionColumnWrap>
                                    <SectionColumnWrapTitle htmlFor="public_comment">{t('説明')}</SectionColumnWrapTitle>
                                    <SectionColumnContent>
                                        <InputPrimary id="public_comment" {...register("public_comment")} />
                                    </SectionColumnContent>
                                </SectionColumnWrap>
                                <SectionColumnWrap>
                                    <SectionColumnWrapTitle htmlFor="private_comment">{t('プライベートコメント')}</SectionColumnWrapTitle>
                                    <SectionColumnContent>
                                        <InputPrimary id="private_comment" {...register("private_comment")} />
                                    </SectionColumnContent>
                                </SectionColumnWrap>
                                <SectionColumnWrap>
                                    <SectionColumnWrapTitle htmlFor="unit_id">{t('主単位')}</SectionColumnWrapTitle>
                                    <input id="unit_id" type="hidden" {...register("unit_id", { required: true })} />
                                    <SectionColumnContent>
                                        <InputPrimary type="text" disabled value={inputUnitName} />
                                        <UnitSelectModal link_text={t('参照')}
                                            inputDatabasePackName={inputDatabasePackName ?? ''}
                                            setInputUnitId={setInputUnitId}
                                            inputDatabasePackId={inputDatabasePackId}
                                            setInputUnitName={setInputUnitName}
                                            editMode={true}
                                        />
                                    </SectionColumnContent>
                                </SectionColumnWrap>
                                <SectionColumnWrap>
                                    <SectionColumnWrapTitle htmlFor="unit_price">{t('単価')}</SectionColumnWrapTitle>
                                    <SectionColumnContent>
                                        <SectionColumnContentText>{t('1㎏あたりの金額')}</SectionColumnContentText>
                                        <InputPrimary id="unit_price" type="number" {...register("unit_price")} />
                                        <Select
                                            value={selectCurrencyData || 0}
                                            onChange={(event) => handleInputChangeSelect(event, 'currency')}
                                        >
                                            <option value="">{t('選択しない')}</option>
                                            {currencyDataList?.units.map((unit) => (
                                                <option key={unit.id} value={unit.id}>
                                                    {unit.code}
                                                </option>
                                            ))}
                                        </Select>
                                        {/* <InputPrimary id="unit_price_currency_unit_id" {...register("unit_price_currency_unit_id")}/> */}
                                    </SectionColumnContent>
                                </SectionColumnWrap>
                                <SectionColumnWrap>
                                    <SectionColumnWrapTitle htmlFor="category">{t('カテゴリ')}</SectionColumnWrapTitle>
                                    <SectionColumnContent>
                                        <ProductEditCategrySelect categoryValues={getProductByIdData.category_values} />
                                    </SectionColumnContent>
                                </SectionColumnWrap>
                                <SectionColumnWrap>
                                    <SectionColumnWrapTitle htmlFor="ilcd_id">{t('ID体系 (ILCD ID)')}</SectionColumnWrapTitle>
                                    <SectionColumnContent>
                                        <InputPrimary
                                            id="ilcd_id"
                                            onChange={(event) => handleInputChange(event, 'ilcd_id_value')}
                                            value={ilcdIdvalue}
                                        />
                                    </SectionColumnContent>
                                </SectionColumnWrap>
                                <SectionColumnWrap>
                                    <SectionColumnWrapTitle htmlFor="idea_code">{t('IDEAコード')}</SectionColumnWrapTitle>
                                    <SectionColumnContent>
                                        <InputPrimary
                                            id="idea_code"
                                            onChange={(event) => handleInputChange(event, 'idea_code')}
                                            value={ideaCodeValue}
                                        />
                                    </SectionColumnContent>
                                </SectionColumnWrap>
                                <SectionColumnWrap>
                                    <SectionColumnWrapTitle htmlFor="unit_conversions">{t('別単位(換算)')}</SectionColumnWrapTitle>
                                    <input id="unit_conversions" value={JSON.stringify(unitConversions)} hidden {...register("unit_conversions")} />
                                    <SectionColumnContent>
                                        <InputPrimary type="text" value={unitConversionsCode} disabled />
                                        <UnitConvertingSettingModal
                                            inputDatabasePackId={inputDatabasePackId}
                                            inputUnitId={inputUnitId}
                                            inputUnitName={inputUnitName}
                                            setInputUnitId={setInputUnitId}
                                            setInputUnitName={setInputUnitName}
                                            unitConversions={unitConversions}
                                            setUnitConversions={setUnitConversions}
                                            setUnitConversionsCode={setUnitConversionsCode}
                                        />
                                    </SectionColumnContent>
                                </SectionColumnWrap>
                            </SectionColumn>
                        </SectionBody>

                        <SectionBody>
                            <SectionAcordionHeader onClick={onIsClickcollapseOpen}>
                                <AddIcon>
                                    <AddIconImage open={isCollapseOpen} />
                                </AddIcon>
                                <SectionAcordionTitle>{t('追加情報')}</SectionAcordionTitle>
                            </SectionAcordionHeader>
                            {/* <Title>追加情報</Title> */}

                            <SectionAcordion open={isCollapseOpen}>
                                <SectionAcordionContent>
                                    <SectionColumnWrap>
                                        <SectionColumnWrapTitle htmlFor="cas_number">{t('CAS番号')}</SectionColumnWrapTitle>
                                        <SectionColumnContent>
                                            <InputPrimary id="cas_number" {...register("cas_number")} defaultValue={getProductByIdData?.synonyms_text ?? ""} />
                                        </SectionColumnContent>
                                    </SectionColumnWrap>
                                    <SectionColumnWrap>
                                        <SectionColumnWrapTitle htmlFor="synonym">{t('同義語')}</SectionColumnWrapTitle>
                                        <SectionColumnContent>
                                            <InputPrimary id="synonym" {...register("synonyms_text")} defaultValue={getProductByIdData?.synonyms_text ?? ""}/>
                                        </SectionColumnContent>
                                    </SectionColumnWrap>
                                    <SectionColumnWrap>
                                        <SectionColumnWrapTitle htmlFor="inclusion_item">{t('包含品目')}</SectionColumnWrapTitle>
                                        <SectionColumnContent>
                                            <InputPrimary id="inclusion_item" {...register("include_items_text")} defaultValue={getProductByIdData?.include_items_text ?? ""}/>
                                        </SectionColumnContent>
                                    </SectionColumnWrap>
                                    <SectionColumnWrap>
                                        <SectionColumnWrapTitle htmlFor="related_item">{t('関連項目')}</SectionColumnWrapTitle>
                                        <SectionColumnContent>
                                            <InputPrimary id="related_item" {...register("related_items_text")} defaultValue={getProductByIdData?.related_items_text ?? ""}/>
                                        </SectionColumnContent>

                                    </SectionColumnWrap>

                                    <SectionColumnWrap>
                                        <SectionColumnWrapTitle>{t('画像名称')}</SectionColumnWrapTitle>
                                        <SectionColumnContent>
                                            <DisableInput disabled type="text" value={imageName || ''} />
                                        </SectionColumnContent>

                                    </SectionColumnWrap>

                                    <FileDiv>
                                        <SectionColumnWrapTitle>{t('技術の図')}</SectionColumnWrapTitle>
                                        <SectionColumnContent>
                                            <FileWrap>
                                                <FileInput type="file" id="fileImg_product_edit" onChange={handleFileChange} />
                                                <FileLabel htmlFor="fileImg_product_edit" onClick={(e) => e.stopPropagation()}>
                                                    {previewImage ? (
                                                        <ImgWrapper>
                                                            <Img src={previewImage} width="100%" height="382px" alt={t('技術の図')} />
                                                            <DeleteButton onClick={handleImageDelete}>
                                                                <FileDeleteIcon />
                                                            </DeleteButton>
                                                        </ImgWrapper>
                                                    ) : imageSrc ? (
                                                        <ImgWrapper>
                                                            <Img src={`data:image/png;base64,${imageSrc}`} width="100%" height="380px" alt={t('技術の図')} />
                                                            <DeleteButton onClick={handleImageDelete}>
                                                                <FileDeleteIcon />
                                                            </DeleteButton>
                                                        </ImgWrapper>
                                                    ) : (
                                                        <Img src={iconImg} alt={t('技術の図')} />
                                                    )}
                                                </FileLabel>
                                            </FileWrap>
                                        </SectionColumnContent>
                                    </FileDiv>
                                </SectionAcordionContent>
                            </SectionAcordion>
                        </SectionBody>

                        {/* <Title>{t('システム情報')}</Title> */}
                        <SectionBody>
                            <SectionAcordionHeader onClick={onIsClickSystemInfoCollapseOpen}>
                                <AddIcon>
                                    <AddIconImage open={isSystemInfoCollapseOpen} />
                                </AddIcon>
                                <SectionAcordionTitle>{t('システム情報')}</SectionAcordionTitle>
                            </SectionAcordionHeader>

                            <SectionAcordion open={isSystemInfoCollapseOpen}>
                                <SectionAcordionContent>
                                    <SectionColumnWrap>
                                        <SectionColumnWrapTitle htmlFor="created_at">{t('作成日時')}</SectionColumnWrapTitle>
                                        <SectionColumnContent>
                                            <DisableInput id="created_at" value={formatToLocalTime(getProductByIdData.created_at)} disabled />
                                        </SectionColumnContent>
                                    </SectionColumnWrap>
                                    <SectionColumnWrap>
                                        <SectionColumnWrapTitle htmlFor="updated_at">{t('更新日時')}</SectionColumnWrapTitle>
                                        <SectionColumnContent>
                                            <DisableInput id="updated_at" value={formatToLocalTime(getProductByIdData.updated_at)} disabled />
                                        </SectionColumnContent>
                                    </SectionColumnWrap>
                                    <SectionColumnWrap>
                                        <SectionColumnWrapTitle htmlFor="uuid">{t('UUID')}</SectionColumnWrapTitle>
                                        <SectionColumnContent>
                                            <DisableInput id="uuid" disabled value={getProductByIdData.global_id} />
                                        </SectionColumnContent>
                                    </SectionColumnWrap>
                                    <SectionColumnWrap>
                                        <SectionColumnWrapTitle htmlFor="team_uuid">{t('チームUUID')}</SectionColumnWrapTitle>
                                        <SectionColumnContent>
                                            <DisableInput id="team_uuid" disabled />
                                        </SectionColumnContent>
                                    </SectionColumnWrap>
                                </SectionAcordionContent>
                            </SectionAcordion>

                        </SectionBody>
                        {!getProductByIdData.is_database_pack_master &&
                            <ButtonCreateSectionTabel>
                                <ModalButton onClick={handleSubmit(onSubmit)} text={t('更新する')} />
                            </ButtonCreateSectionTabel>
                        }
                    </form>
                </Inner>
            }
        </productEditContext.Provider>
    );
};

export default ProductEditModal;

const ErrorMsg = styled.span`
    font-size: 14px;
    font-weight: 500;
    color: var(--color-error-primary);
    display: flex;
    align-items: center;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: flex-start;
    gap: 5px;
`

const Inner = styled.div`
    width: 100%;
    padding: 120px 10%;
    margin: 0 auto;
`

const Title = styled.h2`
    font-size: 18px;
    padding: 0 0 20px;
    margin-bottom: 20px;
    border-bottom: 1px solid var(--color-line-primary);
    font-size: 16px;
    font-weight: 500;    
    line-height: 1.25;    
`

const SectionBody = styled.div`
    margin-bottom: 60px;
`

const SectionBodyLast = styled.div`
`

const SectionColumn = styled.dl`
`

const SectionColumnContent = styled.dd`
    display: flex;
    align-items: center;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: flex-start;
    gap: 16px;
`

const SectionColumnContentText = styled.p`
    font-size: 12px;
    white-space: nowrap;
    font-weight: 500;
    line-height: 1.25;
`

const SectionColumnContentShort = styled.dd`
    width: min(100%, 340px);
    display: flex;
    align-items: center;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: flex-start;
    gap: 16px;
`


const SectionColumnWrap = styled.div`
    margin-bottom: 8px;
    display: grid;
    align-items: center;
    grid-template-columns: 27.0833333333% minmax(0, 1fr);
    gap: 6px 40px;      
`

const SectionColumnWrapTitle = styled.label`
    margin-bottom: 0;   
    font-size: 16px;
    font-weight: 500;
    line-height: 1.25;
`

const InputPrimary = styled.input`
    width: 100%;

    padding: 12px;
    color: var(--color-txt-primary);
    border: 1px solid var(--color-line-primary);
    border-radius: 4px;
    background-color: #fff;
    font-size: 16px;
    font-weight: 400;
    line-height: 1.25;
`

const ButtonCreateSectionTabel = styled.div`
    margin-top: 40px;
    text-align: right;

    margin: 0;
    padding: 0;
    border: 0;
    font: inherit;
    vertical-align: baseline;
    box-sizing: border-box;    
`

const StyledButton = styled.button`
    background-color: var(--color-site-secondary);
    color: #fff;

    padding: 8px 24px;
    border-radius: 20px;
    display: inline-flex;
    align-items: center;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: center;
    cursor: pointer;
    white-space: nowrap;
    gap: 0 4px;    

    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    border: none;
    font: inherit;
    outline: none;

  &:hover {
    background-color: var(--color-gray--01);
    color: inherit;
  }
`

const DisableInput = styled.input`
    background-color: var(--color-bg-primary);
    width: 100%;
    padding: 12px;
    color: var(--color-txt-primary);
    border: 1px solid var(--color-line-primary);
    border-radius: 4px;
    font-size: 16px;
    font-weight: 400;
    line-height: 1.25;
   
`


const FileDiv = styled.div`
    align-items: stretch;
    display: grid;
    grid-template-columns: 27.0833333333% minmax(0, 1fr);
    gap: 6px 40px;
`
const FileWrap = styled.div`
    grid-column: 1 / 4;
    width: 100%;
    height: 382px;
    border: 1px solid var(--color-line-primary);
    border-radius: 4px;
    background-color: var(--color-bg-primary);
    position: relative;
`

const FileInput = styled.input`
    display: none;
`

const FileLabel = styled.label`
    width: 100%;
    height: 100%;
    cursor: pointer;
    display: grid;
    place-items: center;
`
const Img = styled.img`
`
const Select = styled.select`
    width: 100%;

    padding: 12px;
    color: var(--color-txt-primary);
    border: 1px solid var(--color-line-primary);
    border-radius: 4px;
    background-color: #fff;
    font-size: 16px;
    font-weight: 400;
    line-height: 1.25;
`

const SectionAcordion = styled.div<{ open: boolean }>`
    max-height: ${props => (props.open ? "100%" : "0")};
    overflow: hidden;
    padding: ${props => (props.open ? "15px" : "0 15px")};
`

const SectionAcordionHeader = styled.summary`
    font-size: 16px;
    font-weight: 500;
    cursor: pointer;
    list-style-type: none;
    display: flex;
    align-items: center;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: flex-start;
    gap: 0 8px;
`

const SectionAcordionTitle = styled.label`
    margin-bottom: 0;
    font-size: 16px;
    font-weight: 500;
    line-height: 1.25;
`


const AddIcon = styled.div`   
    width: 20px;
    height: 20px;
    border: none;
    background-color: #ffffff;
    display: grid;
    place-content: center;
    border-radius: 50%;
    border: 2px solid var(--color-line-primary); 
`

const AddIconImage = styled.span<{ open: boolean }>`
    width: 12px;
    height: 12px;
    background-color: #ffffff;
    position: relative;
    display: block;
    background: var(--color-txt-primary);    
    mask: url(${props => (props.open ? iconSub : iconAdd)}) no-repeat center center / contain;
    -webkit-mask: url(${props => (props.open ? iconSub : iconAdd)}) no-repeat center center / contain;

`

const SectionAcordionContent = styled.div`
    padding-top: 24px;
`

const FileDeleteIcon = styled.span`
    display: block;
    width: 40px;
    height: 40px;
    mask: url(${iconCross}) no-repeat center center / contain;
    -webkit-mask: url(${iconCross}) no-repeat center center / contain;
    background-color: var(--color-txt-primary);
`;

// 画像削除ボタン用のスタイル
const ImgWrapper = styled.div`
    position: relative;
`;

const DeleteButton = styled.button`
    position: absolute;
    top: 10px;
    right: 10px;
    background: transparent;
    border: none;
    cursor: pointer;
    padding: 0;
`;