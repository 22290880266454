import React, { Dispatch, useEffect, useState } from 'react';
import styled, { css } from 'styled-components';
import { useTranslation } from 'react-i18next';

import iconArrowLeft from '@images/table/icon_arrow_left.svg';
import iconArrowRight from '@images/table/icon_arrow_right.svg';


interface TablePageNationProps {
    count: number;
    page: number;
    setPage: Dispatch<React.SetStateAction<number>>
    pageSize: number;
    setPageSize: Dispatch<React.SetStateAction<number>>
}

/**
 * 検索テーブルのページネーション
 * 
 * @param param0 
 * @returns 
 */
const TablePageNation: React.FC<TablePageNationProps> = ({ count, page, setPage, pageSize, setPageSize }) => {
    const { t } = useTranslation();

    // 表示件数
    const handleInputChangeSelect = (event: React.ChangeEvent<HTMLSelectElement>, key: string) => {
        const value = event.target.value;
        if (key === 'page_size') {
            setPageSize(Number(value));
        }
    };

    const [totalPages, setTotalPages] = useState<number>(Math.ceil(count / pageSize)); // ページ数を計算
    useEffect(() => {
        setTotalPages(Math.ceil(count / pageSize))
    }, [count, pageSize])
    // const totalPages = Math.ceil(count / pageSize);

    const renderPageNumbers = () => {
        const range = (start: number, end: number) => {
            return Array.from({ length: end - start + 1 }, (_, i) => i + start);
        };

        // 1〜9ページ目までを表示
        if (totalPages <= 9) {
            return range(0, totalPages -1);
        }

        // 最初のページと最後のページは常に表示
        const pages = [0];

        // 9ページ目以降の場合、省略しつつ範囲を動的に設定
        if (page >= 9) {
            pages.push(-1); // 省略記号として-1を挿入
            pages.push(...range(page - 1, Math.min(page + 1, totalPages - 2)));
        } else {
            pages.push(...range(1, Math.min(8, totalPages - 2)));
        }

        // 最後のページは常に表示
        pages.push(totalPages - 1);

        return pages.reduce<number[]>((acc, page) => {
            if (acc.length > 0 && page - acc[acc.length - 1] > 1) {
                acc.push(-1); // 省略記号
            }
            acc.push(page);
            return acc;
        }, []);
    };


    return (
        <>
            <PageNationWrapper>
                <DisplayCountView>
                    {t('displayRange', { count, pageSize})}
                </DisplayCountView>

                <PageOptionArrowButton
                    type='button'
                    onClick={() => {
                        setPage(page - 1)
                    }}
                    disabled={page === 1}
                >
                    <ButtonInner>
                        <IconArrowLeft />
                    </ButtonInner>
                </PageOptionArrowButton>

                {renderPageNumbers().map((pageNumber, index) => {
                    if (pageNumber === -1) {
                        return (
                            <span key={index} style={{ margin: '0 8px' }}>
                                ...
                            </span>
                        );
                    }
                    return (
                        <PageOptionButton
                            type='button'
                            key={pageNumber}
                            onClick={() => setPage(pageNumber + 1)}
                        >
                            <PageOptionInner disabled={page === pageNumber + 1}>
                                {pageNumber + 1}
                            </PageOptionInner>
                        </PageOptionButton>
                    );
                })}

                <PageOptionArrowButton
                    type='button'
                    onClick={() => {
                        setPage(page + 1);
                    }}
                    disabled={page === totalPages}
                >
                    <ButtonInner>
                        <IconArrowRight />
                    </ButtonInner>
                </PageOptionArrowButton>

                <div style={{ display: 'inline-block' }}>
                    <DisplayCount>{t('表示件数')}</DisplayCount>
                    <Label>
                        <PageNationViewSelect
                            style={{ margin: '5px' }}
                            value={pageSize}
                            onChange={(event) => {
                                handleInputChangeSelect(event, "page_size");
                            }}
                        >
                            {[10, 20, 30].map((pageSize) => (
                                <option key={pageSize} value={pageSize}>
                                    {pageSize}
                                </option>
                            ))}
                        </PageNationViewSelect>
                    </Label>
                </div>
            </PageNationWrapper>
        </>
    );
};

export default TablePageNation;

const ButtonInner = styled.div``;

// ページネーション
const IconArrowLeft = styled.span`
mask: url(${iconArrowLeft}) no-repeat center center / contain;
-webkit-mask: url(${iconArrowLeft}) no-repeat center center / contain;
display: block;
width: 100%;
height: 100%;
width: 20px;
height: 20px;
background: var(--color-txt-primary);
`;

const IconArrowRight = styled.span`
mask: url(${iconArrowRight}) no-repeat center center / contain;
-webkit-mask: url(${iconArrowRight}) no-repeat center center / contain;
display: block;
width: 100%;
height: 100%;
width: 20px;
height: 20px;
background: var(--color-txt-primary);
`;

const PageOptionArrowButton = styled.button`
height: 18px;
width: 24px;
cursor: pointer;
text-decoration: none;
color: inherit;
appearance: none;
background: transparent;
border: none;
border-radius: 0;
font: inherit;
outline: none;
`;

const PageOptionButton = styled.button`
cursor: pointer;
width: 34px;
height: 24px;
appearance: none;
background: transparent;
border: none;
border-radius: 0;
font: inherit;
outline: none;
`;

const PageOptionInner = styled.span<{ disabled: boolean }>`
  display: block;
  width: 100%;
  height: 100%;
  font-size: 13px;
  line-height: 20px;
  font-weight: 500;
  border: 1px solid var(--color-line-primary);
  border-radius: 50%;
  text-align: center;
  color: ${({ disabled }) => (disabled ? '#fff' : 'inherit')};
  background-color: ${({ disabled }) => (disabled ? 'var(--color-site-secondary)' : 'transparent')};
  border-color: ${({ disabled }) => (disabled ? 'var(--color-site-secondary)' : 'var(--color-line-primary)')};
`;

const Label = styled.label`
cursor: pointer;
`;

const PageNationViewSelect = styled.select`
padding: 6px 20px 6px 20px;
font-size: 13px;
margin: 8px 0;
position: relative;
cursor: pointer !important;
border: 1px solid var(--color-line-primary);
border-radius: 4px;
background-color: #fff;
font-weight: 400;
line-height: 1.25;
font: inherit;
outline: none;
`;

const PageNationWrapper = styled.div`
gap: 0 24px;
align-items: center;
`;

const DisplayCount = styled.span`
margin-left: 10px;
font-size: 16px;
font-weight: 500;
line-height: 1.25;
`;

const DisplayCountView = styled.span`
font-size: 16px;
font-weight: 500;
line-height: 1.25;
`;
