import React, { useEffect, useState, createContext } from 'react';
import Modal from '@common/modal/Modal';
import styled, { css } from 'styled-components';
import { LinkMenu } from '@styles/Common';
import { createColumnHelper } from '@tanstack/react-table';
import { useGetUserDetail, useSearchProsess } from '@hooks/useBackendApi';
import { getCurrentDefaultDatabasePackId, getCurrentUserInfo } from '@services/Cookies';
import { formatToLocalTime, getValueByKey } from '@utils/utils';
import ProsessEditDataTable from '@common/table/ProsessEditDataTable';
import { useTranslation } from 'react-i18next';

import iconCheck from '@images/table/icon_check.svg';
import { GetUserDetail } from '@typeList/types';
import TablePageNation from '@common/pagenation/TablePageNation';
import { FadeLoader } from 'react-spinners';
import DateInput from '@common/date_picker/DateInput';


export interface ProcessSearchContextType {
    inputDatabasePackId: number | undefined;
    inputDatabasePackName: string | undefined;
}

const defaulutContextvalue: ProcessSearchContextType = {
    inputDatabasePackId: 0,
    inputDatabasePackName: ""
}

export const ProcessSearch = createContext<ProcessSearchContextType>(defaulutContextvalue);

interface ProcessSearchModalProps {
    userData: GetUserDetail | null;
}


/**
 * 005　プロセス検索画面
 * @returns 
 */
const ProcessSearchModal: React.FC<ProcessSearchModalProps> = ({ userData }) => {
    const { t } = useTranslation();
    const [isModalOpen, setIsModalOpen] = useState(false);
    const openModal = () => setIsModalOpen(true);
    const closeModal = () => setIsModalOpen(false);

    // データベースパック
    const [inputDatabasePackId, setInputDatabasePackId] = useState<number | undefined>(userData?.default_database_pack_id);
    const [databasePacks, setDatabasePacks] = useState<any[]>([]);
    const [inputDatabasePackName, setInputDatabasePackName] = useState("");
    useEffect(() => {
        if (userData?.licenses) {
            const filteredDatabasePacks = userData?.licenses
                .filter(license => !license.is_inactive) // is_inactive = false のみを対象
                .map(license => ({
                    id: license.role.database_pack.id,
                    name: license.role.database_pack.name,
                }));

            // id の重複を Set で除去し、再度配列に戻す
            const uniqueDatabasePacks = Array.from(new Map(
                filteredDatabasePacks.map(pack => [pack.id, pack]) // id をキーにした Map を生成
            ).values());

            setDatabasePacks(uniqueDatabasePacks);
            setInputDatabasePackId(getCurrentDefaultDatabasePackId() ?? uniqueDatabasePacks[0].id);
            setInputDatabasePackName(getValueByKey(databasePacks, 'id', Number(inputDatabasePackId), 'name'));
        }
    }, [userData]);

    useEffect(() => {
        if(getCurrentDefaultDatabasePackId()){
            setInputDatabasePackId(getCurrentDefaultDatabasePackId())
        }
    }, [getCurrentDefaultDatabasePackId()])

    const handleInputChangeSelect = (event: React.ChangeEvent<HTMLSelectElement>, key: string) => {
        switch (key) {
            case 'database_pack_id':
                setInputDatabasePackId(Number(event.target.value));
                setInputDatabasePackName(getValueByKey(databasePacks, 'id', Number(event.target.value), 'name'));
                break;
        }
    };

    // 検索テキスト
    const [inputSearchText, setInputSearchText] = useState<string>("");

    //　プロセス名、プロセス情報全体検索ラジオボタン 
    const [inputFulltextSearch, setInputFulltextSearch] = useState<boolean>(false);
    const [inputFulltextSearchRadio, setInputFulltextSearchRadio] = useState<string>("1");

    // 自分の更新したプロセスのみ検索
    const [inputCurrentUserUpdatedCheck, setInputCurrentUserUpdatedCheck] = useState<boolean>(false);

    // 作成日
    const [inputIsCreatedDateCheck, setInputIsCreatedDateCheck] = useState<boolean>(false);
    const [inputCreatedDateFrom, setInputCreatedDateFrom] = useState<string | null>(null);
    const [inputCreatedDateTo, setInputCreatedDateTo] = useState<string | null>(null);

    // 更新日
    const [inputIsUpdatedDateCheck, setInputIsUpdatedDateCheck] = useState<boolean>(false);
    const [inputUpdatedDateFrom, setInputUpdatedDateFrom] = useState<string | null>(null);
    const [inputUpdatedDateTo, setInputUpdatedDateTo] = useState<string | null>(null);

    const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>, key: string) => {
        switch (key) {
            case "search_text":
                setInputSearchText(event.target.value);
                break;
            case "is_fulltext_search":
                if (event.target.value === "1") {
                    setInputFulltextSearch(false);
                    setInputFulltextSearchRadio("1");
                }
                if (event.target.value === "2") {
                    setInputFulltextSearch(true);
                    setInputFulltextSearchRadio("2");
                }
                break;
            case "is_default_language":
                // 処理追加
                break;
            case "is_current_user_updated":
                setInputCurrentUserUpdatedCheck(event.target.checked);
                break;
            case "product_type":
                setInputCurrentUserUpdatedCheck(event.target.checked);
                break;
            case "is_created_date":
                setInputIsCreatedDateCheck(event.target.checked);
                break;
            case "created_date_from":
                setInputCreatedDateFrom(event.target.value);
                break;
            case "created_date_to":
                setInputCreatedDateTo(event.target.value);
                break;
            case "is_updated_date":
                setInputIsUpdatedDateCheck(event.target.checked);
                break;
            case "updated_date_from":
                setInputUpdatedDateFrom(event.target.value);
                break;
            case "updated_date_to":
                setInputUpdatedDateTo(event.target.value);
                break;
        }
    };

    // ページング
    const [page, setPage] = useState<number>(1)
    const [pageSize, setPageSize] = useState<number>(10)

    // テーブル表示用
    const [tableData, setTableData] = useState<any | null>(null);
    const [tableDataLoading, setTableDataLoading] = useState<boolean>(false);
    
    // プロセス検索APIのカスタムフック
    const { searchProsesses, searchProsessesData, searchProsessesError, searchProsessesLoading } = useSearchProsess();

    // ボタン連打制御
    const [isDisabled, setIsDisabled] = useState(false);
    useEffect(() => {
        if(tableDataLoading){
            setIsDisabled(true);
        } else {
            setIsDisabled(false);
        }
    },[tableDataLoading])

    // プロセス検索
    const handleSearchButtonClick = async () => {
        if(page !== 1){
            setPage(1)
        } else {
            search();
        }
    };

    const search = async() =>{
        if (isDisabled) return;
        setTableDataLoading(true);

        const formatDate = (date: Date, isEndOfDay: boolean = false) => {
            if (isEndOfDay) {
                date.setHours(23, 59, 59, 999); // 23:59:59.999 に設定
            } else {
                date.setHours(0, 0, 0, 0); // 00:00:00.000 に設定
            }

            const year = date.getFullYear();
            const month = String(date.getMonth() + 1).padStart(2, '0');
            const day = String(date.getDate()).padStart(2, '0');
            const hours = String(date.getHours()).padStart(2, '0');
            const minutes = String(date.getMinutes()).padStart(2, '0');
            const seconds = String(date.getSeconds()).padStart(2, '0');

            return `${year}-${month}-${day}T${hours}:${minutes}:${seconds}`;
        };

        const formattedCreatedDateFrom = inputCreatedDateFrom
            ? formatDate(new Date(inputCreatedDateFrom))
            : null;

        const formattedCreatedDateTo = inputCreatedDateTo
            ? formatDate(new Date(inputCreatedDateTo), true)
            : null;

        const formattedUpdatedDateFrom = inputUpdatedDateFrom
            ? formatDate(new Date(inputUpdatedDateFrom))
            : null;

        const formattedUpdatedDateTo = inputUpdatedDateTo
            ? formatDate(new Date(inputUpdatedDateTo), true)
            : null;

        try {
            await searchProsesses(
                inputDatabasePackId,
                inputSearchText,
                page,
                pageSize,
                inputFulltextSearch,
                inputCurrentUserUpdatedCheck,
                inputIsCreatedDateCheck,
                formattedCreatedDateFrom,
                formattedCreatedDateTo,
                inputIsUpdatedDateCheck,
                formattedUpdatedDateFrom,
                formattedUpdatedDateTo
            );
            // await flattenedData(searchResults);
        } catch (error) {
            console.log(error);
        } finally {
            setTableDataLoading(false);
        }
    }

    // API呼び出し
    useEffect(() => {
        if (inputDatabasePackId && isModalOpen) {
            search();
        }
    }, [page])

    // 表示切替時はページをリセット
    useEffect(() => {
        if (inputDatabasePackId && page === 1 && isModalOpen) {
            search();
        }
        setPage(1);
    }, [pageSize])

    useEffect(() => {
        if (searchProsessesData) {
            setTableData(searchProsessesData.results)
        }
    }, [searchProsessesData]);

    const columnHelper = createColumnHelper<any>();
    const columns = [
        columnHelper.accessor('process_info.name', {
            header: () => t('名前'),
            cell: info => info.renderValue(),
        }),
        columnHelper.accessor('product_name', {
            header: () => t('出力中間フロー'),
            cell: info => info.renderValue(),
        }),
        columnHelper.accessor(row => {
            // identifier_scheme_values が配列であれば、その中から name: value の形に変換し、改行で結合
            return row.product_identifier_scheme_values
                .map((scheme: any) => `${scheme.identifier_scheme.name} : ${scheme.value}`)
                .join('\n');  // 改行で結合
        }, {
            id: 'product_identifier_scheme_values',  // 必須の id プロパティを追加
            header: () => t('外部ID'),
            cell: info => {
                // 改行を反映させるために <pre> タグを使用
                return <pre>{info.getValue()}</pre>;
            },
        }),
        columnHelper.accessor('process_info.region_name', {
            header: () => t('地域'),
            cell: info => info.renderValue(),
        }),
        columnHelper.accessor('process_info.updated_at', {
            header: () => t('最終更新日'),
            cell: info => formatToLocalTime(String(info.renderValue())),
        }),
        columnHelper.accessor('process_info.information_sources', {
            header: () => t('情報源分類'),
            cell: info => info.renderValue(),
        }),
        columnHelper.accessor('process_info.creator_department', {
            header: () => t('組織'),
            cell: info => info.renderValue(),
        }),
        columnHelper.accessor('process_info.is_database_pack_master', {
            header: () => t('マスタデータ'),
            cell: info => {
                return (
                    <>
                        {info.renderValue() && (
                            <span>
                                <CheckButton>
                                    <CheckButtonIconWrap>
                                        <CheckButtonIcon></CheckButtonIcon>
                                    </CheckButtonIconWrap>
                                </CheckButton>
                            </span>
                        )}
                    </>
                )
            }
        }),
        columnHelper.accessor('process_info.technical_scope', {
            header: () => t('技術の範囲'),
            cell: info => info.renderValue(),
        }),
        columnHelper.accessor('gcg_result_amount', {
            header: () => (
                <div>
                    <span title="IPCC 2021 GWP 100a with LULUC">
                        {t('GHG排出原単位')}
                    </span>
                </div>
            ),
            cell: info => info.renderValue(),
        }),
    ];

    return (
        <>
            <LinkMenu onClick={openModal} type='button'>
                {t('検索')}
            </LinkMenu>
            {databasePacks.length > 0 &&
                <>
                    <Modal isOpen={isModalOpen} onClose={closeModal}>
                        <Inner>
                            <ProcessSearch.Provider value={{ inputDatabasePackId, inputDatabasePackName }}>
                                <Title>{t('プロセス検索')}</Title>
                                <SectionBody>
                                    <SectionContent>
                                        <InputPrimary
                                            type="search"
                                            value={inputSearchText ?? ""}
                                            onChange={(event) => handleInputChange(event, "search_text")}
                                            onKeyDown={(event) => event.key === 'Enter' && handleSearchButtonClick()}
                                        />
                                        <StyledButton type='button' onClick={handleSearchButtonClick} disabled={isDisabled}>{t('検索')}</StyledButton>
                                    </SectionContent>
                                    <SectionContent>
                                        <Radio>
                                            <RadioLabel>
                                                <RadioInput
                                                    type="radio"
                                                    name="productNameSearch"
                                                    id="1"
                                                    value="1"
                                                    checked={inputFulltextSearchRadio === "1"}
                                                    onChange={(event) => handleInputChange(event, "is_fulltext_search")}
                                                />
                                                <RadioText>{t('プロセス名で検索')}</RadioText>
                                            </RadioLabel>
                                            <RadioLabel>
                                                <RadioInput
                                                    type="radio"
                                                    name="fulltextSearch"
                                                    id="2"
                                                    value="2"
                                                    checked={inputFulltextSearchRadio === "2"}
                                                    onChange={(event) => handleInputChange(event, "is_fulltext_search")}
                                                />
                                                <RadioText>{t('プロセス情報全体で検索')}</RadioText>
                                            </RadioLabel>
                                        </Radio>
                                    </SectionContent>
                                    <SectionTab>
                                        <SectionTabContent>
                                            <SectionColumn>
                                                <SectionColumnTitle>
                                                    <CheckBox>
                                                        <CheckBoxLabel>
                                                            {/* <CheckBoxInput type="checkbox" /> */}
                                                            <CheckBoxText>{t('データベースパック')}</CheckBoxText>
                                                        </CheckBoxLabel>
                                                    </CheckBox>
                                                </SectionColumnTitle>

                                                <SectionColumnContent>
                                                    <SelectPrimary>
                                                        <SelectInner
                                                            name="datadatabase-packset"
                                                            id="database-pack-select"
                                                            onChange={(event) => handleInputChangeSelect(event, 'database_pack_id')}
                                                            value={inputDatabasePackId ?? ''}
                                                        >
                                                            {databasePacks.map((database_pack) => (
                                                                <option key={database_pack.id} value={database_pack.id}>
                                                                    {database_pack.name}
                                                                </option>
                                                            ))}
                                                        </SelectInner>

                                                    </SelectPrimary>


                                                    <SectionColumnTitle>
                                                        <CheckBox>
                                                            <CheckBoxLabel>
                                                                <CheckBoxInput
                                                                    type="checkbox"
                                                                    checked={inputCurrentUserUpdatedCheck}
                                                                    onChange={(event) => handleInputChange(event, "is_current_user_updated")}
                                                                />
                                                                <CheckBoxText>{t('自分の更新したプロセスのみ検索')}</CheckBoxText>
                                                            </CheckBoxLabel>
                                                        </CheckBox>
                                                    </SectionColumnTitle>
                                                </SectionColumnContent>

                                                <SectionTabContent>
                                                    <SectionColumn>

                                                        <SectionColumnTitle>
                                                            <CheckBox>
                                                                <CheckBoxLabel>
                                                                    <CheckBoxInput
                                                                        type="checkbox"
                                                                        checked={inputIsCreatedDateCheck}
                                                                        onChange={(event) => handleInputChange(event, "is_created_date")}
                                                                    />
                                                                    <CheckBoxText>{t('作成日')}</CheckBoxText>
                                                                </CheckBoxLabel>
                                                            </CheckBox>
                                                        </SectionColumnTitle>
                                                        <SectionColumnContent>

                                                            <DateInput
                                                                value={inputCreatedDateFrom || ""}
                                                                onChange={(event) => handleInputChange(event, "created_date_from")}
                                                            />
                                                            <DateSpan>〜</DateSpan>
                                                            <DateInput
                                                                value={inputCreatedDateTo || ""}
                                                                onChange={(event) => handleInputChange(event, "created_date_to")}
                                                            />
                                                        </SectionColumnContent>
                                                    </SectionColumn>
                                                </SectionTabContent>

                                                <SectionTabContent>
                                                    <SectionColumn>

                                                        <SectionColumnTitle>
                                                            <CheckBox>
                                                                <CheckBoxLabel>
                                                                    <CheckBoxInput
                                                                        type="checkbox"
                                                                        checked={inputIsUpdatedDateCheck}
                                                                        onChange={(event) => handleInputChange(event, "is_updated_date")}
                                                                    />
                                                                    <CheckBoxText>{t('更新日')}</CheckBoxText>
                                                                </CheckBoxLabel>
                                                            </CheckBox>
                                                        </SectionColumnTitle>
                                                        <SectionColumnContent>

                                                            <DateInput
                                                                value={inputUpdatedDateFrom || ""}
                                                                onChange={(event) => handleInputChange(event, "updated_date_from")}
                                                            />
                                                            <DateSpan>〜</DateSpan>
                                                            <DateInput
                                                                value={inputUpdatedDateTo || ""}
                                                                onChange={(event) => handleInputChange(event, "updated_date_to")}
                                                            />
                                                        </SectionColumnContent>
                                                    </SectionColumn>
                                                </SectionTabContent>

                                            </SectionColumn>
                                        </SectionTabContent>
                                    </SectionTab>
                                </SectionBody>
                                <SectionTable>
                                    {searchProsessesError && <div>{searchProsessesError}</div>}
                                    {searchProsessesLoading && 
                                        <FadeLoader
                                            color="#48bdbb"
                                            height={10}
                                            radius={2}
                                            width={5}
                                        />
                                    }
                                    {tableData && !searchProsessesLoading &&
                                        <>
                                            <TablePageNation
                                                count={searchProsessesData?.count || 0}
                                                page={page}
                                                setPage={setPage}
                                                pageSize={pageSize}
                                                setPageSize={setPageSize}
                                            />
                                            <ProsessEditDataTable
                                                columns={columns}
                                                data={tableData}
                                                setIsModalOpen={setIsModalOpen}
                                                userData={userData}
                                            />
                                        </>
                                    }
                                </SectionTable>
                            </ProcessSearch.Provider>
                        </Inner>
                    </Modal>
                </>
            }
        </>
    );
};

export default ProcessSearchModal;

const Title = styled.h2`
    font-size: 18px;
    padding: 0 0 20px;
    margin-bottom: 20px;
    border-bottom: 1px solid var(--color-line-primary);
    font-size: 16px;
    font-weight: 500;    
    line-height: 1.25;    
`
const Inner = styled.div`
    width: 100%;
    padding: 120px 10%;
    margin: 0 auto;
`

const Data = styled.div`
    margin: 0;
    padding: 0;
    border: 0;
    font-size: 100%;
    font: inherit;
    vertical-align: baseline;
    box-sizing: border-box;
`

const SectionTable = styled.section`
    margin-bottom: 32px;
    display: block;
  
`

const SectionBody = styled.div`
`

const DataTitle = styled.dt`
    margin-bottom: 12px;
    font-size: 16px;
    font-weight: 500;
    line-height: 1.25;
`

const SectionContent = styled.dd`
    margin-bottom: 32px;
    display: flex;
    align-items: center;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: flex-start;
    gap: 16px;    
`

const SectionContentTerm = styled.dd`
    margin-bottom: 32px;
    display: flex;
    align-items: center;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: flex-start;
    gap: 12px;
`

const InputPrimary = styled.input`
    width: min(100%, 340px);

    padding: 12px;
    color: var(--color-txt-primary);
    border: 1px solid var(--color-line-primary);
    border-radius: 4px;
    background-color: #fff;
    font-size: 16px;
    font-weight: 400;
    line-height: 1.25;
`
const SelectPrimary = styled.label`
    width: min(100%, 340px);
    position: relative;
    display: block;
    cursor: pointer;
`

const SelectInner = styled.select`
    color: rgb(85, 85, 85);
    position: relative;
    width: 100%;
    padding: 12px 32px 12px 12px;
    cursor: pointer !important;
    border: 1px solid var(--color-line-primary);
    border-radius: 4px;
    background-color: #fff;
    font-size: 16px;
    font-weight: 400;
    line-height: 1.25;
`

const CheckBox = styled.div`
    display: flex;
    align-items: center;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: flex-start;
    gap: 54px;
`

const CheckBoxLabel = styled.label`
    display: flex;
    align-items: center;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: flex-start;
    gap: 6px;
    cursor: pointer;
  
`

const CheckBoxText = styled.span`
    font-size: 16px;
    font-weight: 500;
`

const SectionTab = styled.div`
    display: flex;
    align-items: flex-start;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: flex-start;
    gap: 32px 54px;
`

const SectionTabContent = styled.div`
    display: block;
    z-index: 5;
    width: 100%;
    order: 2;
    position: relative;    
`


const Radio = styled.div`
    display: flex;
    align-items: center;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: flex-start;
    gap: 54px; 
`

const RadioLabel = styled.label`
    order: 1;
    
    isplay: flex;
    align-items: center;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: flex-start;
    gap: 6px;
    width: fit-content;
    cursor: pointer;
    position: relative;    
`
const RadioText = styled.span`
    align-items: center;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: flex-start;
    gap: 0 20px;
    font-size: 16px;
    white-space: nowrap;
    font-weight: 500; 
`

const SectionColumn = styled.div`
    margin-bottom: 40px;
`

const SectionColumnTitle = styled.div`
    margin-bottom: 12px;
    font-size: 16px;
    font-weight: 500;
    line-height: 1.25;

`

const SectionColumnContent = styled.div`
    margin-bottom: 32px;
    display: flex;
    align-items: center;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: flex-start;
    gap: 16px;
`

const StyledButton = styled.button`
    background-color: var(--color-site-secondary);
    color: #fff;

    padding: 14px 30px;
    border-radius: 4px;
    white-space: nowrap;

    display: inline-flex;
    align-items: center;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: center;
    cursor: pointer;
    white-space: nowrap;
    gap: 0 4px;

    appearance: none;
    border: none;
    font: inherit;
    outline: none;      

  &:hover {
    background-color: var(--color-gray--01);
    color: inherit;

  }
`

const SectionResult = styled.div`
    padding: 0 0 20px;
    margin-bottom: 20px;
    border-bottom: 1px solid var(--color-line-primary);
    display: flex;
    justify-content: space-between;
`

const CheckBoxInput = styled.input`
    accent-color: var(--color-site-secondary);
`

const RadioInput = styled.input`
    accent-color: var(--color-site-secondary);
`

const DateSpan = styled.span`
font-size: 12px;
white-space: nowrap;
font-weight: 500;
line-height: 1.25;
`

const CheckButton = styled.button`
appearance: none;
background: transparent;
border: none;
border-radius: 0;
font: inherit;
outline: none;
`

const CheckButtonIconWrap = styled.div`
appearance: none;
background: transparent;
border: none;
border-radius: 0;
font: inherit;
outline: none;
`

const CheckButtonIcon = styled.span`
background-color: var(--color-site-secondary);
mask: url(${iconCheck}) no-repeat center center / contain;
-webkit-mask: url(${iconCheck}) no-repeat center center / contain;
display: block;
width: 20px;
height: 20px;
`