import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { useGetProcessHistories } from '@hooks/useBackendApi';
import { ProcessHistories } from '@typeList/types';
import { useNavigate } from 'react-router-dom';

/**
 * 025　最近開いたプロセス
 * @returns 
 */
const ProcessHistory = () => {
    const { t } = useTranslation();
    const [isHovered, setIsHovered] = useState(false);
    const { getProcessHistories, processHistoriesData } = useGetProcessHistories();
    const [histories, setHistories] = useState<ProcessHistories[] | null>(null);

    useEffect(() => {
        if (isHovered) {
            try {
                getProcessHistories();
            } catch (error) {
                console.log(error);
            }
        }
    }, [isHovered]);

    useEffect(() => {
        if (processHistoriesData) {
            // access_atの降順でソートし、5件まで取得
            const sortedHistories = [...processHistoriesData]
                .sort((a, b) => new Date(b.access_at).getTime() - new Date(a.access_at).getTime())
                .slice(0, 5);
            setHistories(sortedHistories);
        }
    }, [processHistoriesData]);

    const navigate = useNavigate();
    const handleClick = (process_id: number) => {
        window.location.href = `/process/process_view/${process_id}`;
    }

    return (
        <Container
            onMouseEnter={() => setIsHovered(true)}
            onMouseLeave={() => setIsHovered(false)}
        >
            <LinkMenu>{t('閲覧履歴')}</LinkMenu>
            {isHovered && histories && (
                <Tooltip>
                    {histories.map((history: ProcessHistories) => (
                        <HistoryItem key={history.process_id}>
                            <Button type='button' onClick={() => handleClick(history.process_id)}>
                                {history.process_name}
                            </Button>
                        </HistoryItem>
                    ))}
                </Tooltip>
            )}
        </Container>
    );
};

export default ProcessHistory;

const Container = styled.div`
    position: relative;
    display: inline-block;
`;

const Tooltip = styled.div`
    position: absolute;
    top: 100%;
    left: 0;
    background-color: var(--color-site-secondary);
    padding: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    z-index: 100;
    width: 300px;
`;

const HistoryItem = styled.div`
    margin: 20px 0px;
    color: white;
`;

const LinkMenu = styled.a`
    cursor: pointer;
    text-decoration: none;
    color: inherit;
    display: block;
    width: fit-content;
    padding: 12px;
    color: #fff;
    font-weight: 700;
    line-height: 1;
    &:hover {
        text-decoration: underline;
    }
`;

const Title = styled.h2`
    font-size: 18px;
    padding: 0 0 10px;
    margin-bottom: 10px;
    border-bottom: 1px solid var(--color-line-primary);
    font-size: 16px;
    font-weight: 500;
    line-height: 1.25;
`;

const Button = styled.button`
    cursor: pointer;
    background-color: var(--color-site-secondary);
    appearance: none;
    border: none;
    font: inherit;
    outline: none;
    color: white;
`