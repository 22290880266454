// AlertDialog.tsx
import React, { useState } from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import styled from 'styled-components';
import ModalButton from '@common/button/ModalButton';
import { useTranslation } from 'react-i18next';

type SaveDialogProps = {
  message: string;
  open: boolean;
  onClose: () => void;
  onConfirm: () => void;
};

const SaveDialog: React.FC<SaveDialogProps> = ({ message, open, onClose, onConfirm }) => {
    const { t } = useTranslation();
    return (
        <Dialog
            open={open}
            onClose={onClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
        >
            <DialogContent>
                <DialogContentInner>
                    <DialogContentInnerText>
                        {message}
                    </DialogContentInnerText>
                </DialogContentInner>
                <DialogActions>
                    <ModalButton onClick={onClose} text={t('閉じる')} />
                    <ModalButton onClick={() => { onConfirm(); onClose(); }} text='OK' />
                </DialogActions>
            </DialogContent>
        </Dialog>
    );
};

export default SaveDialog;

const DialogContentInner = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100px; /* ダイアログの高さに応じて調整 */
    font-size: 16px;
    text-align: center;
    margin-left: 25px; 
    margin-right: 20px; 
`

const DialogContentInnerText = styled.span`
    font-size: 16px;
    margin-top: 10px; /* ローダーとテキストの間の余白 */
`