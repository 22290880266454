import React from 'react';
import styled from 'styled-components';
import { ButtonProps as MUIButtonProps } from '@mui/material';
import iconReload from '@images/button/icon_reload.svg';

interface CustomButtonProps extends MUIButtonProps {
  onClick: () => void;
  text: string;
}

const ReloadButton: React.FC<CustomButtonProps> = ({ onClick, text}) => {
  return (
    <StyledButton onClick={onClick}>
        <Icon>
            <IconImage />
        </Icon>
        <ButtonText>{text}</ButtonText>
    </StyledButton>
  );
};

export default ReloadButton;

const StyledButton = styled.button`
    padding: 4px 12px;
    border-radius: 20px;
    
    display: inline-flex;
    align-items: center;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: center;
    cursor: pointer;
    white-space: nowrap;
    background-color: var(--color-gray--01);
    gap: 0 4px;

    appearance: none;
    border: none;
    font: inherit;
    outline: none;

    color: inherit;

    &:hover {
        background-color: var(--color-site-secondary);
        text-decoration: none !important;
        color: white;
    }       
`
const Icon = styled.div`   

`

const IconImage = styled.span`
    width: 24px;
    height: 24px;
    position: relative;
    display: block;
    background: var(--color-site-secondary); 
    mask: url(${iconReload}) no-repeat center center / contain;
    -webkit-mask: url(${iconReload}) no-repeat center center / contain;
`

const ButtonText = styled.span`
    font-size: 12px;
    font-weight: 500;
    `