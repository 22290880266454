import React, { useRef } from 'react';
import { useDrag, useDrop } from 'react-dnd';
import styled from 'styled-components';
import { flexRender } from '@tanstack/react-table';
import iconArrowDown from '@images/table/icon_arrow_down.svg';
import iconArrowUp from '@images/table/icon_arrow_up.svg';

interface DragItem {
    index: number;
    id: string;
    type: string;
}

const DraggableHeader: React.FC<{
    header: any;
    index: number;
    moveColumn: (dragIndex: number, hoverIndex: number) => void;
}> = ({ header, index, moveColumn }) => {
    const ref = useRef<HTMLTableCellElement>(null);

    const [, drop] = useDrop({
        accept: 'COLUMN',
        hover: (item: DragItem, monitor) => {
            if (!ref.current) {
                return;
            }
            const dragIndex = item.index;
            const hoverIndex = index;

            if (dragIndex === hoverIndex) {
                return;
            }

            const hoverBoundingRect = ref.current?.getBoundingClientRect();
            const hoverMiddleX = (hoverBoundingRect.right - hoverBoundingRect.left) / 2;
            const clientOffset = monitor.getClientOffset();
            const hoverClientX = clientOffset!.x - hoverBoundingRect.left;

            if (dragIndex < hoverIndex && hoverClientX < hoverMiddleX) {
                return;
            }

            moveColumn(dragIndex, hoverIndex);
            item.index = hoverIndex;
        },
    });

    const [{ isDragging }, drag] = useDrag({
        type: 'COLUMN',
        item: { index },
        collect: (monitor) => ({
            isDragging: monitor.isDragging(),
        }),
    });

    drag(drop(ref));

    return (
        <Th
            ref={ref}
            style={{
                opacity: isDragging ? 0.5 : 1,
                cursor: isDragging ? 'grabbing' : 'grab',
            }}
            onClick={header.column.getCanSort() ? header.column.getToggleSortingHandler() : undefined}
        >
            <ThInner>
                {flexRender(header.column.columnDef.header, header.getContext())}
                {header.column.getIsSorted() ? (header.column.getIsSorted() === 'desc' ? <IconArrowDown /> : <IconArrowUp />) : ''}
            </ThInner>
        </Th>
    );
};

export default DraggableHeader;


const Th = styled.th`
font-size: 13px;
font-weight: 700;
line-height: 1.25;
color: var(--color-site-primary);
padding: 12px;
align-content: center;
overflow: hidden;
text-overflow: ellipsis;
white-space: nowrap;
min-height: 40px;
`

const ThInner = styled.div`
width: fit-content;
display: flex;
align-items: center;
flex-direction: row;
flex-wrap: nowrap;
justify-content: flex-start;
gap: 4px;
white-space: nowrap;
`;

const IconArrowDown = styled.span`
mask: url(${iconArrowDown}) no-repeat center center / contain;
-webkit-mask: url(${iconArrowDown}) no-repeat center center / contain;
display: block;
width: 100%;
height: 100%;
width: 20px;
height: 20px;
background: var(--color-txt-primary);
`;

const IconArrowUp = styled.span`
mask: url(${iconArrowUp}) no-repeat center center / contain;
-webkit-mask: url(${iconArrowUp}) no-repeat center center / contain;
display: block;
width: 100%;
height: 100%;
width: 20px;
height: 20px;
background: var(--color-txt-primary);
`;